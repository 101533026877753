import { WinnerBall } from 'constants/common';

export enum ACTIONS {
  RESET_COUNTER_STATE = 'RESET_COUNTER_STATE',
  SET_INITIAL_COUNTER_STATE = 'SET_INITIAL_COUNTER_STATE',
  UPDATE_BALLS_COUNTER = 'UPDATE_BALLS_COUNTER',
}

export type IBallsCounter = Record<WinnerBall, number>;

export interface IBallsCounterState {
  ballsCounter: IBallsCounter;
}
