import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import { createGlobalStyle } from 'styled-components';

// TODO vh is about 88% support (caniuse stats), try on qq/baidu/kaios browsers, maybe need to replace with another solution
export const DesktopAspectRatio = createGlobalStyle`
  @media (max-aspect-ratio: 16/9) {
    html {
      font-size: 0.078125vw;
    }
  }

  @media (min-aspect-ratio: 16/9) {
    html {
      font-size: 0.13888888889vh;
      font-size: ${isOldBrowserIOS() ? '0.13888888889vh' : '0.13888888889dvh'};
    }
  }
`;

export const TabletLandscapeAspectRatio = createGlobalStyle`
  @media (max-aspect-ratio: 16/9) {
    html {
      font-size: 0.078125vw;
    }
  }

  @media (min-aspect-ratio: 16/9) {
    html {
      font-size: ${isOldBrowserIOS() ? '0.13888888889vh' : '0.13888888889dvh'};
    }
  }
`;

export const TabletPortraitAspectRatio = createGlobalStyle`
  // in between
  @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 772/1000) {
    html {
      font-size: 0.120420833333vw;
    }
  }

  // in between
  @media (min-aspect-ratio: 771/1000) and (max-aspect-ratio: 82/100) {
    html {
      font-size: 0.123020833333vw;
    }
  }

  // maximum >= 81/100
  @media (min-aspect-ratio: 81/100) {
    html {
      font-size: 0.11933333331vw;
    }
  }

  // minimum <= 3/4
  @media (max-aspect-ratio: 3/4) {
    html {
      font-size: 0.13020833333vw;
    }
  }
`;

export const MobileAspectRatio = createGlobalStyle`
  @media (max-aspect-ratio: 3/5) {
    html {
      font-size: 0.27777777778vw;
    }
  }

  @media (min-aspect-ratio: 3/5) {
    html {
      font-size: 0.16666666667dvh;
      font-size: ${isOldBrowserIOS() ? '0.16666666667vh' : '0.16666666667dvh'};
    }
  }
`;
