import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import { ListItem, Wrapper, ItemValue, ItemNickname, WrapperChatList } from './styles';
import { Device } from 'types';
import { IChatMessage } from 'core/widgets/ChatPanel/types';

interface IChat {
  messages: IChatMessage[];
  deviceType: Device;
}

export const Chat = ({ messages, deviceType }: IChat) => {
  const { t, i18n } = useTranslation();
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const isAspectRatioFit = useMemo(
    () => windowHeight > (windowWidth * 9) / 16,
    [windowHeight, windowWidth],
  );

  return (
    <WrapperChatList
      $deviceType={deviceType}
      $isSmallSize={i18n.language === 'tl' || i18n.language === 'pt'}
      $isAspectRatioFit={isAspectRatioFit}>
      <Wrapper>
        {messages &&
          messages?.map((message: IChatMessage) => (
            <ListItem key={`${message?.playerName}_${uuidv4()}`}>
              <ItemNickname $isSelected={message.fromSupport}>{message.playerName}:</ItemNickname>
              <ItemValue> {message.message}</ItemValue>
            </ListItem>
          ))}
      </Wrapper>
    </WrapperChatList>
  );
};
