import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.div<{ $deviceType: Device }>`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 3;
  overflow: hidden;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        top: 16rem;
        left: 16rem;
        height: 120rem;
        width: 120rem;
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 88rem;
        left: 24rem;
        height: 64rem;
        width: 64rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 40rem;
        left: 8rem;
        height: 32rem;
        width: 32rem;
      `;
    }
  }}
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const Title = styled.p`
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;

  ${({ theme: { text } }) => css`
    color: ${text.secondaryDefault};
  `}
`;
