import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const SoundSettingsWrapper = styled.div``;

export const SoundSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SoundSwitchToggle = styled.div``;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SoundWrapper = styled.div`
  border-bottom: 1rem solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(270deg, #121011 0%, #434343 52.08%, #121011 100%);
  font-size: 12rem;
  padding-bottom: 40rem;
`;

export const SoundSlider = styled.div`
  margin-bottom: 40rem;
`;
