import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IRoundStatus } from './types';

const statusOfRoundSelector = (state: IState) => state.roundStatusReducer;

export const roundStatusSelector = createSelector(
  statusOfRoundSelector,
  (state: IRoundStatus) => state.roundStatus,
);
