import styled from 'styled-components';

export const Wrapper = styled.svg`
  position: absolute;
  top: 15rem;
  right: 0;
  height: 52rem;
  width: 102rem;
  clip-path: polygon(45% 0, 100% 0, 100% 100%, 0% 100%);

  & g {
    cursor: pointer;
    &:hover {
      & #path_background {
        fill: url(#paint0_linear_59_26942);
        stroke: none;
      }
      & #path_border_one {
        stroke: url(#paint1_linear_59_26942);
      }
      & #path_border_two {
        stroke: url(#paint2_linear_59_26942);
      }
    }
  }
`;
