import React from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from 'types';
import { ToastTypes } from 'types/toast';
import { IToastProps } from './types';
import { ToastMessage, Wrapper, ToastItem } from './styles';

export const ToastMobile = ({ type = ToastTypes.DEFAULT, message }: IToastProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper $deviceType={Device.Mobile}>
      <ToastMessage type={type} $deviceType={Device.Mobile}>
        {t(message)}
      </ToastMessage>
      <ToastItem
        type={type}
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 184 28"
        fill="none">
        <path d="M0 28L184 28V0L0 0L0 28Z" fill="url(#paint0_linear_1169_1432)" />
        <path
          d="M0.5 25.5L183.5 25.5V2.5L0.5 2.5L0.5 25.5Z"
          stroke="url(#paint1_linear_1169_1432)"
        />

        <defs>
          <linearGradient
            id="paint0_linear_1169_1432"
            x1="0"
            y1="14"
            x2="184"
            y2="14"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#121011" stopOpacity="0" />
            <stop offset="0.25" stopColor="#121011" />
            <stop offset="0.65" stopColor="#121011" />
            <stop offset="1" stopColor="#121011" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1169_1432"
            x1="184"
            y1="14"
            x2="0"
            y2="14"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F1E6D2" stopOpacity="0" />
            <stop offset="0.5" stopColor="#F1E6D2" />
            <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
          </linearGradient>
        </defs>
      </ToastItem>
    </Wrapper>
  );
};
