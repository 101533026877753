import { VideoQualities } from 'core/widgets/VideoSettings/types';
import { Device } from 'types';

export type VideoStreamType = {
  index: number;
  quality: VideoQualities;
  streamName: string;
};

export enum VideoPlayerState {
  PLAYING = 5,
  PAUSED = 6,
}

export enum VideoPlayerPauseReasons {
  normal = 'normal',
  destroy = 'destroy',
  notenoughdata = 'notenoughdata',
  streamnotfound = 'streamnotfound',
  sourcestreamstopped = 'sourcestreamstopped',
}

export type VideoPlayerStateChangeEvent = {
  state: VideoPlayerState;
  data: {
    reason: VideoPlayerPauseReasons;
  };
};

export type VideoPlayerSwitchStreamEvent = {
  name: string;
  data: {
    entry?: {
      index: number;
      label: VideoQualities;
    };
  };
};

export interface IVideoStreamProps {
  id: string;
  isLoading: boolean;
  isTryingToReload: boolean;
}

export interface IDeviceVideoStreamProps extends IVideoStreamProps {
  deviceType?: Device;
}
