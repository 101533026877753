import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { BetType, IBetState } from './types';
import { getSummaryBet } from './utils';

export const betRootSelector = (state: IState): IBetState => state.betReducer;

export const placedBetSelector = createSelector(
  betRootSelector,
  (state: IBetState): BetType => state.bets.placed,
);

export const confirmedBetSelector = createSelector(betRootSelector, (state: IBetState): BetType[] =>
  state.bets.confirmed.map((bet) => ({
    ...bet.body,
  })),
);

export const summaryBetSelector = createSelector(
  betRootSelector,
  (state: IBetState): BetType =>
    getSummaryBet([
      state.bets.placed,
      ...state.bets.confirmed.map((bet) => ({
        ...bet.body,
      })),
    ]),
);

export const isEmptySlotsSelector = createSelector(
  summaryBetSelector,
  (summaryBets: BetType): boolean =>
    Object.values(summaryBets).every((slotValue) => slotValue === 0),
);
