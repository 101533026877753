import { Button } from 'components/Button';
import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Message = styled.p`
  text-align: center;
  z-index: 100;
`;

export const ButtonConfirm = styled(Button)`
  max-width: unset;
  padding: 9rem 32rem;
  border-width: 1rem;
  font-size: 14rem;
  line-height: 18rem;
  text-transform: capitalize;
  z-index: 100;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: -12rem;
  top: -12rem;
  height: 32rem;
  width: 32rem;
  border: 1px solid #2b2a2b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 101;
  ${({ theme: { background, border } }) => css`
    background: ${background.primary};
    &:hover {
      border: 1px solid;
      border-color: ${border.accent};
    }
  `}
`;

export const Wrapper = styled.div<{ $deviceType?: Device }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: min-content;
  border-style: none;
  ${({ theme: { border } }) => css`
    border-color: ${border.primaryDefault};
  `}
  white-space: pre-line;

  &:after {
    content: '';
    position: absolute;
    border: 1rem solid;
    border-image-source: linear-gradient(
      270deg,
      rgba(241, 230, 210, 0) 0%,
      #f1e6d2 50%,
      rgba(241, 230, 210, 0) 100%
    );
    border-image-slice: 1;
    pointer-events: none;
  }

  ${({ $deviceType, theme: { background } }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        width: 77%;
        max-width: 278rem;
        margin: calc(56.25vw - 44rem) auto 0;
        padding: 32rem 24rem 40rem;
        border-width: 2rem;
        background: ${background.primary};

        &:after {
          top: 2rem;
          left: 2rem;
          right: 2rem;
          bottom: 2rem;
        }

        ${Message} {
          font-size: 12rem;
          line-height: 18rem;
        }

        ${ButtonConfirm} {
          margin-top: 32rem;
        }
      `;
    }

    return css`
      max-width: 538rem;
      margin: ${$deviceType === Device.Tablet_Portrait ? '128rem auto 0' : '-200rem auto 0'};
      padding: 64rem;
      border-width: 4rem;
      background: linear-gradient(180deg, rgba(18, 16, 17, 0.8) 40%, rgba(52, 52, 52, 0.16) 100%);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(18, 16, 17, 0.6);
        pointer-events: none;
      }

      &:after {
        top: 4rem;
        left: 4rem;
        right: 4rem;
        bottom: 4rem;
      }

      ${Message} {
        font-size: 16rem;
      }

      ${ButtonConfirm} {
        margin-top: 40rem;
      }
    `;
  }}
`;
