import React, { FunctionComponent } from 'react';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Grid } from 'widgets/Scoreboard/ScoreboardGrid';
import { BeadRoadChild } from './BeadRoadChild';
import { IScoreboardRoad } from '../types';

export const BeadRoadMobile: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [2, 26],
}) => (
  <Group size={{ width: 164, height: 109 }} position={position}>
    <Grid
      col={9}
      row={6}
      cellSize={18}
      childItemType={BeadRoadChild}
      roadData={roadData}
      position={[0, 0]}
    />
  </Group>
);
