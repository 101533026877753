import React from 'react';
import { useTranslation } from 'react-i18next';
import { WinnerBall } from 'constants/common';
import { ContentWrapper, PredictionShortcutStyled, ShortcutText } from './styles';
import { IPredictionShortcutProps } from './types';

export const BasePredictionShortcut = ({
  size = { width: 107, height: 40 },
  predictedBall,
  onClick,
  position = [0, 0],
}: IPredictionShortcutProps) => {
  const { t, i18n } = useTranslation();
  const [positionX, positionY] = position;

  return (
    <PredictionShortcutStyled
      onClick={onClick}
      width={size.width}
      height={size.height}
      x={positionX}
      y={positionY}
      viewBox="0 0 107 40"
      fill="none">
      <path d="M0.5 0.5H90.7597L106.36 20L90.7597 39.5H0.5V0.5Z" fill="#121011" fillOpacity="0.4" />
      {predictedBall === WinnerBall.RED && (
        <>
          <path
            d="M0.5 0.5H90.7597L106.36 20L90.7597 39.5H0.5V0.5Z"
            fill="url(#paint0_linear_984_5901)"
          />
          <path
            d="M0.5 0.5H90.7597L106.36 20L90.7597 39.5H0.5V0.5Z"
            stroke="url(#paint1_linear_984_5901)"
          />
        </>
      )}
      {predictedBall === WinnerBall.BLUE && (
        <>
          <path
            d="M0.5 0.5H90.7597L106.36 20L90.7597 39.5H0.5V0.5Z"
            fill="url(#paint0_linear_984_5904)"
          />
          <path
            d="M0.5 0.5H90.7597L106.36 20L90.7597 39.5H0.5V0.5Z"
            stroke="url(#paint1_linear_984_5904)"
          />
        </>
      )}
      <defs>
        <linearGradient
          id="paint0_linear_984_5901"
          x1="53.5"
          y1="0"
          x2="53.5"
          y2="40"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C8102E" stopOpacity="0" />
          <stop offset="1" stopColor="#AA001B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_984_5901"
          x1="-43.0326"
          y1="20"
          x2="132.587"
          y2="20"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#C8102E" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_984_5904"
          x1="53.5"
          y1="0"
          x2="53.5"
          y2="40"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#41B6E6" stopOpacity="0" />
          <stop offset="1" stopColor="#008DC7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_984_5904"
          x1="-43.0326"
          y1="20"
          x2="132.587"
          y2="20"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#41B6E6" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
      </defs>
      <ContentWrapper x="16" y="12" width="70" height="18" viewBox="0 0 70 18">
        <ShortcutText y="50%" dominantBaseline="middle" $isSmallSize={i18n.language === 'id'}>
          {predictedBall === WinnerBall.RED ? t('scoreboard.ifBankerWinTitle') : null}
          {predictedBall === WinnerBall.BLUE ? t('scoreboard.ifPlayerWinTitle') : null}
        </ShortcutText>
      </ContentWrapper>
    </PredictionShortcutStyled>
  );
};
