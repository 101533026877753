import React, { FunctionComponent } from 'react';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Board, Grid, RoadLabel } from 'widgets/Scoreboard/ScoreboardGrid';
import { IScoreboardRoad } from '../types';
import { CockroachPigChild } from './CockroachPigChild';

export const CockroachPigTablet: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [635, 68],
}) => (
  <Group size={{ width: 106, height: 54 }} position={position}>
    <g transform="translate(0, 54) rotate(-90)">
      <RoadLabel
        boardName={Board.CockroachPig}
        size={{ width: 54, height: 6 }}
        labelSize="S"
        isMultiline
      />
    </g>
    <Grid
      col={10}
      row={6}
      cellSize={9}
      childItemType={CockroachPigChild}
      roadData={roadData}
      childItemSize={{ width: 9, height: 9 }}
      position={[11, 0]}
    />
  </Group>
);
