import React from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from 'types';
import { IconNames } from 'components';
import { ArrowStyled, ScoreboardPortraitWrapper, Text } from './styles';
import { IDeviceScoreboardButtonProps } from './types';

export const ScoreboardButtonPortrait = ({
  isActive,
  deviceType = Device.Mobile,
  onScoreboardButtonClick,
}: IDeviceScoreboardButtonProps) => {
  const { t } = useTranslation();

  return (
    <ScoreboardPortraitWrapper onClick={onScoreboardButtonClick} $deviceType={deviceType}>
      <Text>{t('scoreboard.title')}</Text>
      <ArrowStyled name={IconNames.ArrowDown} $isActive={isActive} />
    </ScoreboardPortraitWrapper>
  );
};
