import { put } from 'redux-saga/effects';
import { NotificationsChat } from 'types';

import { setIsBlockedChat } from 'core/widgets/ChatPanel/actions';
import { OnUserChatBlockedData } from '../../types';

export function* onUserChatBlockedSaga({ type: notificationType }: OnUserChatBlockedData) {
  if (notificationType === NotificationsChat.USER_CHAT_BLOCKED) {
    yield put(setIsBlockedChat(true));
  }
}
