import { IStagePlayersObjectJackpot } from 'core/widgets/Jackpot/types';
import { Moment } from 'moment';

export interface ISvgSize {
  height: number;
  width: number;
}

export type ISvgStartPosition = [number, number]; // x, y

export interface IBaseSvgParams {
  size?: ISvgSize;
  position?: ISvgStartPosition;
  textAnchor?: 'start' | 'middle' | 'end';
}

export type OptionType<TValue = string, TLabel = TValue> = {
  label: TLabel;
  value: TValue;
  subtitle?: string;
};

export enum SLOTS {
  player = 'player',
  tie = 'tie',
  banker = 'banker',
}

export enum Device {
  Desktop = 'DESKTOP',
  Tablet_Portrait = 'TABLET_PORTRAIT',
  Tablet_Landscape = 'TABLET_LANDSCAPE',
  Mobile = 'MOBILE',
}

export interface IJackpotSlot {
  jackpotSum?: string | null;
  isActiveSlot: boolean;
  stage?: IStagePlayersObjectJackpot | null;
  dateTimer?: Date | null;
  onClick?: () => void;
}
