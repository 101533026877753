import React from 'react';
import { DropdownIndicatorProps, components } from 'react-select';
import styled, { css } from 'styled-components';
import { Icon, IconNames } from 'components';
import { ISelectOption } from '../types';

export const DropdownIndicator = (props: DropdownIndicatorProps<ISelectOption, false>) => {
  const { isDisabled } = props;
  return (
    <components.DropdownIndicator {...props}>
      <DropdownWrapper $isDisabled={isDisabled}>
        <TriangleTop name={IconNames.TriangleDown} />
        <TriangleDown name={IconNames.TriangleDown} />
      </DropdownWrapper>
    </components.DropdownIndicator>
  );
};

const DropdownWrapper = styled.div<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) => {
    if ($isDisabled) {
      return css`
        & path {
          fill: #434343;
        }
      `;
    }
  }}
`;

const TriangleDown = styled(Icon)`
  display: block;
  height: 6rem;
  width: 9rem;
`;

const TriangleTop = styled(TriangleDown)`
  margin-bottom: 2rem;
  transform: scaleY(-1);
`;
