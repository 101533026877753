import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Swiper as SwiperType } from 'swiper';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { CarouselChipsResponsive } from './Responsive';
import { IBaseCarouselChipsProps } from './types';
import AudioService from 'services/AudioService';

export const CarouselChips = (props: IBaseCarouselChipsProps) => {
  const swiperRef = useRef<SwiperType>();
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);

  const onBeforeInit = useCallback((swiper: SwiperType) => {
    swiperRef.current = swiper;
  }, []);

  const onButtonPrevClick = useCallback(() => {
    swiperRef.current?.slidePrev();
    if (!gameSoundMute) {
      AudioService.playClickSound('slider', soundEffects.volume);
    }
  }, [gameSoundMute, soundEffects]);

  const onButtonNextClick = useCallback(() => {
    swiperRef.current?.slideNext();
    if (!gameSoundMute) {
      AudioService.playClickSound('slider', soundEffects.volume);
    }
  }, [gameSoundMute, soundEffects]);

  return (
    <>
      <CarouselChipsResponsive
        {...props}
        onBeforeInit={onBeforeInit}
        onButtonNextClick={onButtonNextClick}
        onButtonPrevClick={onButtonPrevClick}
      />
    </>
  );
};
