import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { SlotBlueMaximizedTablet, SlotBlueMinimizedTablet } from './components';

export const SlotBlueTablet = (props: ISlotProps) => {
  const { isMinimized } = props;
  return isMinimized ? (
    <SlotBlueMinimizedTablet {...props} />
  ) : (
    <SlotBlueMaximizedTablet {...props} />
  );
};
