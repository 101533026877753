import React from 'react';

import { TabWrapper, TabItem, TabItemValue } from './styles';

export const Tabs = ({ tabs, onClick }: any) => (
  <TabWrapper>
    {tabs?.map((tab: any, index: number) => (
      <TabItem key={tab.name} $isSelected={tab.active} onClick={() => onClick(index)}>
        <TabItemValue>{tab.name}</TabItemValue>
      </TabItem>
    ))}
  </TabWrapper>
);
