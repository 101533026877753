import { SettingPanels } from 'widgets/Settings/types';

export enum ACTIONS {
  MANAGE_SETTINGS_PANEL = 'MANAGE_SETTINGS_PANEL',
}

export interface ISettingsPanelStatusState {
  settingsPanel: SettingPanels | null;
  hasClickJackpotSlot: boolean;
}
