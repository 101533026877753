import { ActionType, createReducer } from 'typesafe-actions';
import {
  clearDoubleBetAction,
  confirmDoubleBetAction,
  doubleBetSuccessAction,
  errorDoubleBetAction,
  resetDoubleBetAction,
} from './actions';
import { IBetDoubleState } from './types';

export const betDoubleState: IBetDoubleState = {
  isUsed: false,
  isConfirmed: false,
};

const actions = {
  doubleBetSuccessAction,
  clearDoubleBetAction,
  confirmDoubleBetAction,
  errorDoubleBetAction,
  resetDoubleBetAction,
};

export const betDoubleReducer = createReducer<IBetDoubleState, ActionType<typeof actions>>(
  betDoubleState,
)
  .handleAction(doubleBetSuccessAction, (state) => ({
    ...state,
    isUsed: true,
  }))
  .handleAction(clearDoubleBetAction, (state) => ({
    ...state,
    isUsed: state.isConfirmed,
  }))
  .handleAction(confirmDoubleBetAction, (state) => ({
    ...state,
    isConfirmed: state.isUsed,
  }))
  .handleAction(errorDoubleBetAction, (state) => ({
    ...state,
    isUsed: state.isUsed && !state.isConfirmed ? false : state.isUsed,
  }))
  .handleAction(resetDoubleBetAction, (state) => ({
    ...state,
    isUsed: false,
    isConfirmed: false,
  }));
