import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import {
  applySoundMuteAction,
  changeIsStartLoadingSounds,
  changeSoundMuteAction,
  changeSoundVolumeAction,
} from 'core/widgets/SoundSettings/action';
import { Sounds } from 'core/widgets/SoundSettings/types';
import { ISoundSettingsPanelProps } from './types';
import { SoundSettingsPanelResponsive } from './Responsive';
import AudioService from 'services/AudioService';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { tableIdSelector } from 'core/widgets/TableSettings';

export const SoundSettingsPanel = ({ onCloseClick }: ISoundSettingsPanelProps) => {
  const {
    applied,
    gameSoundMute,
    music,
    soundEffects,
    digitalVoice,
    studioSound,
    isStartLoadingSounds,
  } = useSelector(soundSettingsSelector);
  const tableId = useSelector(tableIdSelector);
  const { operatorId } = useSelector(authInfoSelector);

  const dispatch = useDispatch();

  const onSwitchChange = useCallback(
    (value: boolean) => {
      if (!value && !isStartLoadingSounds) {
        dispatch(changeIsStartLoadingSounds(true));
      }
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }

      dispatch(changeSoundMuteAction(value));
      AudioService.changeSoundSettings(value);
    },
    [dispatch, gameSoundMute, soundEffects, isStartLoadingSounds],
  );

  const onSwitchSoundChange = useCallback(
    (mute: boolean, type: Sounds) => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }
      AudioService.changeSoundMuteSettings({ mute, type });
      dispatch(applySoundMuteAction({ mute, type }));
    },
    [dispatch, gameSoundMute, soundEffects],
  );

  const onSliderChange = useCallback(
    (volume: number, type: Sounds) => {
      dispatch(changeSoundVolumeAction({ volume, type }));
      AudioService.changeSoundVolumeSettings({ volume, type });
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      if (operatorId && tableId) {
        localStorage.setItem(`${operatorId}_${tableId}_music`, JSON.stringify(music));
        localStorage.setItem(`${operatorId}_${tableId}_soundEffects`, JSON.stringify(soundEffects));
        localStorage.setItem(`${operatorId}_${tableId}_digitalVoice`, JSON.stringify(digitalVoice));
        localStorage.setItem(`${operatorId}_${tableId}_studioSound`, JSON.stringify(studioSound));
      }
    },
    [music, soundEffects, digitalVoice, studioSound, operatorId, tableId],
  );

  return (
    <>
      <SoundSettingsPanelResponsive
        value={applied.volume || 0}
        isSoundMuted={applied.mute || false}
        gameSoundMute={gameSoundMute}
        music={music}
        soundEffects={soundEffects}
        digitalVoice={digitalVoice}
        studioSound={studioSound}
        onSwitchChange={onSwitchChange}
        onSliderChange={onSliderChange}
        onCloseClick={onCloseClick}
        onSwitchSoundChange={onSwitchSoundChange}
      />
    </>
  );
};
