import { all, takeEvery } from 'redux-saga/effects';
import { ACTIONS } from '../types';
import { updateBalanceSaga } from './updateBalanceSaga';
import { updateTotalBetSaga } from './updateTotalBetSaga';

export function* userRootSaga() {
  yield all([
    takeEvery(ACTIONS.UPDATE_BALANCE, updateBalanceSaga),
    takeEvery(ACTIONS.UPDATE_TOTAL_BET, updateTotalBetSaga),
  ]);
}
