import React, { memo } from 'react';
import { Device } from 'types';
import { DoubleBetButton } from 'widgets/DoubleBetButton';
import { RepeatBetButton } from 'widgets/RepeatBetButton';
import { CarouselChips } from './components';
import { IChipsProps } from './data/types';
import { Wrapper } from './styles';

export const BaseChips = memo(
  ({
    isActiveJP,
    currentChip,
    chipItems,
    onChipClick,
    deviceType = Device.Desktop,
  }: IChipsProps) => (
    <Wrapper $deviceType={deviceType}>
      <DoubleBetButton />
      <CarouselChips
        isActiveJP={isActiveJP}
        chips={chipItems}
        currentChip={currentChip}
        onClick={onChipClick}
      />
      <RepeatBetButton />
    </Wrapper>
  ),
);
