import React from 'react';
import { MobileComponent, TabletPortraitComponent } from 'components';
import { ChatButtonPortrait } from './ChatButton.portrait';
import { IChatButtonProps } from './types';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const ChatButtonResponsive = (props: IChatButtonProps) => (
  <>
    <TabletPortraitComponent>
      <ChatButtonPortrait {...props} offsetRight={59} />
    </TabletPortraitComponent>
    <MobileComponent>
      <ChatButtonPortrait
        {...props}
        offsetRight={isOldBrowserIOS() ? 22 : 24}
        offsetLeft={'initial'}
      />
    </MobileComponent>
  </>
);
