import { ReactElement } from 'react';
import { useMobileOrientation, isMobileOnly, isTablet, isDesktop } from 'react-device-detect';

interface IResponsiveComponentProps {
  children: ReactElement;
}

export const DesktopComponent = ({ children }: IResponsiveComponentProps) =>
  isDesktop ? children : null;

export const TabletLandscapeComponent = ({ children }: IResponsiveComponentProps) => {
  const { isLandscape } = useMobileOrientation();
  const isTabletLandscape = isTablet && isLandscape;

  return isTabletLandscape ? children : null;
};

export const TabletPortraitComponent = ({ children }: IResponsiveComponentProps) => {
  const { isPortrait } = useMobileOrientation();
  const isTabletPortrait = isTablet && isPortrait;

  return isTabletPortrait ? children : null;
};

export const MobileComponent = ({ children }: IResponsiveComponentProps) => {
  const isMobilePortrait = isMobileOnly;

  return isMobilePortrait ? children : null;
};
