import React from 'react';
import { isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { Device } from 'types';
import { AppPortrait } from 'AppPortrait';
import { AppLandscape } from 'AppLandscape';
import { useDispatch } from 'react-redux';
import { setDeviceOrientation } from 'core/widgets/Auth/actions';

export enum PlatformType {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE',
  Other = 'OTHER',
}

// TODO add func-arguments (device-components) and replace all Responsive.tsx-components with hook
export const useDevice = () => {
  const { isLandscape, isPortrait } = useMobileOrientation();
  const isTabletLandscape = isTablet && isLandscape;
  const isTabletPortrait = isTablet && isPortrait;
  const isMobileLandscape = isMobileOnly && isLandscape;
  const isMobilePortrait = isMobileOnly && isPortrait;

  const dispatch = useDispatch();

  if (isMobileLandscape) {
    dispatch(setDeviceOrientation(true));
  }

  if (isMobilePortrait) {
    dispatch(setDeviceOrientation(false));
  }

  if (isMobileLandscape || isMobilePortrait)
    return {
      rootDeviceComponent: <AppPortrait deviceType={Device.Mobile} />,
      platformType: PlatformType.Mobile,
    };
  if (isTabletPortrait)
    return {
      rootDeviceComponent: <AppPortrait deviceType={Device.Tablet_Portrait} />,
      platformType: PlatformType.Other,
    };
  if (isTabletLandscape)
    return {
      rootDeviceComponent: <AppLandscape />,
      platformType: PlatformType.Other,
    };
  return {
    rootDeviceComponent: <AppLandscape />,
    platformType: PlatformType.Desktop,
  };
};
