import React from 'react';
import { Device } from 'types';
import { Select, SettingsPanel, SwitchToggle } from 'components';
import { useTranslation } from 'react-i18next';
import { SelectWrapper, SwitchToggleWrapper, Wrapper } from './styles';
import { IGeneralSettingsPanelComponentProps } from './types';

export const GeneralSettingsPanelComponent = ({
  isAutoConfirmEnabled,
  onAutoConfirmChange,
  currentLanguage,
  languageOptions,
  onLanguageChange,
  deviceType = Device.Desktop,
  onCloseClick,
}: IGeneralSettingsPanelComponentProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SettingsPanel onCloseClick={onCloseClick} deviceType={deviceType}>
        <SettingsPanel.Title>{t('settings.general.title')}</SettingsPanel.Title>
        <SettingsPanel.Item>
          <SettingsPanel.ItemTitle>
            {t('settings.general.languageItemTitle')}
          </SettingsPanel.ItemTitle>
          <SelectWrapper>
            <Select value={currentLanguage} options={languageOptions} onChange={onLanguageChange} />
          </SelectWrapper>
        </SettingsPanel.Item>
      </SettingsPanel>
    </Wrapper>
  );
};
