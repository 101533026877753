/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Device } from 'types';
import { WINNING_AMOUNT } from 'constants/common';
import { SlotInnerWrapper, SlotTitle, SlotValue } from 'widgets/Slots/styles';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import getChipByValue from 'utils/getChipByValue';
import { CHIPS_CURRENCIES } from 'widgets/Chips/data/constants';
import { Wrapper } from './styles';
import { ConfirmBet } from '../ConfirmBet';
import { ISlotContentProps } from './types';
import { SlotBetResponsive } from '../SlotBet';

export const SlotContent = ({
  bet,
  isMinimized,
  hasPlacedBet,
  isCurrent,
  isWinner,
  onBetConfirm,
  onBetClear,
  slot,
  innerPositionsConfig: { betValue, confirmBet },
  size,
  deviceType = Device.Desktop,
  isActiveJP,
  isBettingTime,
}: ISlotContentProps) => {
  const { t } = useTranslation();

  const { currency } = useSelector(authInfoSelector);
  const color = useMemo(
    () =>
      getChipByValue(bet, currency ? CHIPS_CURRENCIES[currency] : CHIPS_CURRENCIES['USD']).color,
    [bet, currency],
  );

  return (
    <Wrapper $deviceType={deviceType} $isMinimized={isMinimized}>
      <foreignObject height={size.height} width={size.width}>
        <SlotInnerWrapper
          $slot={slot}
          $deviceType={deviceType}
          $isMinimized={isMinimized}
          $hasPlacedBet={hasPlacedBet}>
          <SlotTitle>{t(`slots.${slot}`)}</SlotTitle>
          <SlotValue>{WINNING_AMOUNT[slot]}:1</SlotValue>
        </SlotInnerWrapper>
      </foreignObject>
      {hasPlacedBet ? (
        <SlotBetResponsive
          x={betValue.positionX}
          y={betValue.positionY}
          bet={bet}
          size={betValue.size}
          slotId={slot}
          isActiveJP={isActiveJP}
          color={color}
        />
      ) : null}
      {isBettingTime && hasPlacedBet ? (
        <svg
          x={confirmBet.positionX}
          y={confirmBet.positionY || (hasPlacedBet ? '-18' : '-22')}
          width={
            deviceType === Device.Tablet_Portrait
              ? '37'
              : deviceType === Device.Mobile
                ? '31'
                : '28'
          }
          height={
            deviceType === Device.Tablet_Portrait
              ? '60'
              : deviceType === Device.Mobile
                ? '40'
                : '26'
          }>
          <ConfirmBet onBetConfirm={onBetConfirm} onBetClear={onBetClear} deviceType={deviceType} />
        </svg>
      ) : null}
      {deviceType === Device.Tablet_Portrait ? (
        <defs>
          <filter
            id="filter0_d_5852_58296"
            x="6"
            y="6"
            width="20"
            height="22"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0705882 0 0 0 0 0.0627451 0 0 0 0 0.0666667 0 0 0 0.4 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5852_58296"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5852_58296"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_i_5852_58296"
            x="11"
            y="11"
            width="10"
            height="10"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 0.733333 0 0 0 0 0.576471 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5852_58296" />
          </filter>
          <linearGradient
            id="paint0_linear_5852_58296"
            x1="16"
            y1="32"
            x2="16"
            y2="0"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#343434" />
            <stop offset="1" stopColor="#343434" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5852_58296"
            x1="32"
            y1="16"
            x2="0"
            y2="16"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2B2A2B" />
            <stop offset="0.5" stopColor="#434343" />
            <stop offset="1" stopColor="#2B2A2B" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_5852_58296"
            x1="16"
            y1="11"
            x2="16"
            y2="21"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFF7EB" />
            <stop offset="1" stopColor="#D4BB93" />
          </linearGradient>
        </defs>
      ) : (
        <>
          <defs>
            <filter
              id="filter0_d_1097_79621"
              x="2"
              y="2"
              width="20"
              height="22"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0705882 0 0 0 0 0.0627451 0 0 0 0 0.0666667 0 0 0 0.4 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1097_79621"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1097_79621"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_i_1097_79621"
              x="7"
              y="7"
              width="10"
              height="10"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.831373 0 0 0 0 0.733333 0 0 0 0 0.576471 0 0 0 1 0"
              />
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1097_79621" />
            </filter>
            <linearGradient
              id="paint0_linear_1097_79621"
              x1="12"
              y1="24"
              x2="12"
              y2="0"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#343434" />
              <stop offset="1" stopColor="#343434" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1097_79621"
              x1="24"
              y1="12"
              x2="0"
              y2="12"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#2B2A2B" />
              <stop offset="0.5" stopColor="#434343" />
              <stop offset="1" stopColor="#2B2A2B" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1097_79621"
              x1="12"
              y1="7"
              x2="12"
              y2="17"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" />
              <stop offset="1" stopColor="#D4BB93" />
            </linearGradient>
          </defs>
        </>
      )}
    </Wrapper>
  );
};
