import React from 'react';
import ReactSelect from 'react-select';

import { ISelectOption, ISelectProps } from './types';
import { controlStyles, menuStyles, optionStyles, valueContainerStyles } from './styles';
import { DropdownIndicator } from './CustomSelectComponents';

export const Select = ({
  value,
  options,
  placeholder = '',
  isDisabled = false,
  onChange,
}: ISelectProps) => {
  const onChangeSelect = (valueSelect: any) => {
    onChange(valueSelect);
  };

  return (
    <>
      <ReactSelect<ISelectOption>
        placeholder={placeholder}
        value={value}
        options={options}
        onChange={onChangeSelect}
        getOptionLabel={(option: ISelectOption) => option.label}
        getOptionValue={(option: ISelectOption) => option.value}
        isDisabled={isDisabled}
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        unstyled
        styles={{
          control: controlStyles,
          valueContainer: valueContainerStyles,
          menu: menuStyles,
          option: optionStyles,
          menuList: (base) => ({
            ...base,
            '::-webkit-scrollbar': {
              width: '1px',
              height: '0px',
              background: '#434343',
            },
            '::-webkit-scrollbar-track': {
              borderRadius: '2px',
            },
            '::-webkit-scrollbar-thumb': {
              width: '3px',
              borderRadius: '2px',
              backgroundColor: '#d4bb93',
            },
          }),
        }}
      />
    </>
  );
};
