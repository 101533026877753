import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletPortraitComponent,
  TabletLandscapeComponent,
} from 'components';
import { IToastProps } from './types';
import { BaseToast } from './Toast.base';
import { ToastMobile } from './Toast.mobile';

export const ToastResponsive = (props: IToastProps) => (
  <>
    <DesktopComponent>
      <BaseToast {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseToast {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseToast {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <ToastMobile {...props} />
    </MobileComponent>
  </>
);
