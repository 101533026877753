import React from 'react';
import { Device } from 'types';
import { IconNames } from 'components/Icon';
import { CloseIcon, Item, ItemTitle, Title, Wrapper, VersionInfo } from './styles';
import { ISettingsPanelProps } from './types';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const SettingsPanel = ({
  children,
  deviceType = Device.Desktop,
  onCloseClick,
  hasVersionInfo = true,
}: ISettingsPanelProps) => (
  <Wrapper $deviceType={deviceType} $isOldBrowser={isOldBrowserIOS()}>
    <CloseIcon name={IconNames.Cross} onClick={onCloseClick} />
    {children}
    {hasVersionInfo && <VersionInfo>HOLI BAC {process.env.REACT_APP_PLATFORM_VERSION}</VersionInfo>}
  </Wrapper>
);

SettingsPanel.Title = Title;
SettingsPanel.Item = Item;
SettingsPanel.ItemTitle = ItemTitle;
