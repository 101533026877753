import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SLOTS } from 'types';
import { useModal, useCloseGame } from 'hooks';
import { PopupConfirm } from 'components';
import { confirmedBetSelector } from 'core/widgets/Bet';
import { getSummaryBet } from 'core/widgets/Bet/utils';
import { CloseGameButtonResponsive } from './Responsive';
import { authInfoSelector } from 'core/widgets/Auth/selectors';

export const CloseGameButton = () => {
  const { t } = useTranslation();
  const { isShown, openModal, closeModal } = useModal();
  const confirmedBets = useSelector(confirmedBetSelector);
  const { mode, lobbyUrl, closeGameMessage } = useSelector(authInfoSelector);

  const closeGame = useCloseGame(mode, closeGameMessage, lobbyUrl);

  const onCloseGameClick = useCallback(() => {
    const betsSummary = getSummaryBet(confirmedBets);
    if (betsSummary[SLOTS.banker] + betsSummary[SLOTS.player] + betsSummary[SLOTS.tie]) {
      openModal();
      return;
    }
    closeGame();
  }, [closeGame, confirmedBets, openModal]);

  return (
    <>
      {isShown ? (
        <PopupConfirm onConfirm={closeGame} onClose={closeModal}>
          {t('notices.exitWithBets')}
        </PopupConfirm>
      ) : null}
      <CloseGameButtonResponsive onCloseGame={onCloseGameClick} />
    </>
  );
};
