import { CHIPS_COLORS } from 'widgets/Chips/data/currencies/colors';
import { getValueChip } from './ChipsValues';
import { IChipComponentSvg } from './Chip';

export const ActiveJackpotChip = ({ color, value, slotId }: IChipComponentSvg) => (
  <svg
    id={color}
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="chip_body">
      <g filter="url(#filter0_d_683_64487)">
        <path
          d="M34 62C49.464 62 62 49.464 62 34C62 18.536 49.464 6 34 6C18.536 6 6 18.536 6 34C6 49.464 18.536 62 34 62Z"
          fill="#2B2A2B"
        />
        <path
          d="M34 63C50.0163 63 63 50.0163 63 34C63 17.9837 50.0163 5 34 5C17.9837 5 5 17.9837 5 34C5 50.0163 17.9837 63 34 63Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <path
        d="M34 62C49.464 62 62 49.464 62 34C62 18.536 49.464 6 34 6C18.536 6 6 18.536 6 34C6 49.464 18.536 62 34 62Z"
        fill={`url(#paint0_linear_683_64487_${color}_${slotId})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.861 34.9543L33.8157 34.909V34.9543H33.7703V34.9997V34.9543H33.725V34.909H33.6797H33.725L33.6797 34.8637H33.725V34.8184L33.7703 34.8637V34.8184V34.8637L33.8157 34.8184V34.8637H33.861L33.8157 34.909H33.861H33.8157L33.861 34.9543Z"
        fill="#121011"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0025 13.9551C22.9116 13.9551 13.957 22.9551 13.957 34.0005C13.957 45.0914 22.957 54.046 34.0025 54.046C45.0934 54.046 54.0479 45.046 54.0479 34.0005C54.0934 22.9096 45.0934 13.9551 34.0025 13.9551ZM34.0025 51.7733C23.9116 51.7733 16.2298 44.046 16.2298 34.0005C16.2298 23.9096 23.957 16.2278 34.0025 16.2278C44.0479 16.2278 51.7752 23.9551 51.7752 34.0005C51.8207 44.046 44.0934 51.7733 34.0025 51.7733Z"
        fill="url(#paint1_linear_683_64487)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5004 61.2257C38.4094 61.7257 36.2731 61.9984 34.0458 61.9984C31.6822 61.9984 29.364 61.6802 27.1367 61.1348L28.3185 56.4984C28.6367 55.2257 29.864 54.453 31.1367 54.6348C32.0913 54.7711 33.0458 54.8166 34.0458 54.8166C34.9094 54.8166 35.7276 54.7711 36.5458 54.6802C37.8185 54.5439 39.0004 55.3166 39.3185 56.5439L40.5004 61.2257Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9055 6.86252L39.7237 11.4989C39.4055 12.7716 38.1783 13.5443 36.9055 13.3625C35.951 13.2262 34.9964 13.1807 33.9964 13.1807C32.8601 13.1807 31.7692 13.2716 30.7237 13.4534C29.4055 13.6807 28.1783 12.8625 27.8146 11.5898L26.6328 6.99888C28.951 6.36252 31.451 6.04433 33.951 6.04433C36.4055 5.99888 38.6783 6.31706 40.9055 6.86252Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0909 50.9521L14.7727 54.3157C11.5 51.2248 8.95455 47.3612 7.5 42.9976L12.1364 41.543C13.3182 41.1794 14.6364 41.7248 15.1818 42.9067C16 44.5885 17 46.1339 18.1818 47.543C19.0455 48.543 19 49.9976 18.0909 50.9521Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.999 26.4997L56.5444 27.8634C55.2717 28.2725 53.908 27.6361 53.408 26.4088C52.6353 24.4997 51.6353 22.6816 50.3626 21.0906C49.5444 20.0906 49.5899 18.6361 50.499 17.727L53.8626 14.2725C57.2262 17.6361 59.6808 21.8179 60.999 26.4997Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2269 21.634C16.0451 23.2249 15.0905 24.9976 14.4087 26.9067C13.9542 28.134 12.5906 28.8158 11.3633 28.4521L6.86328 27.1794C7.99964 22.5431 10.3633 18.3612 13.4996 14.9521L16.9542 18.3612C17.8633 19.2249 17.9542 20.634 17.2269 21.634Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.6356 42.5892C59.181 47.0437 56.681 50.9982 53.3629 54.1801L50.0447 50.9073C49.0901 49.9528 49.0447 48.4528 49.9083 47.4528C51.1356 45.9982 52.181 44.4073 52.9538 42.6346C53.4538 41.4982 54.7265 40.9073 55.9538 41.271L60.6356 42.5892Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2756 13.4552C22.2756 14.0461 21.7756 14.5461 21.1847 14.5461C20.5938 14.5461 20.0938 14.0461 20.0938 13.4552C20.0938 12.8643 20.5938 12.3643 21.1847 12.3643C21.7756 12.3643 22.2756 12.8188 22.2756 13.4552Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.7287 13.4552C46.7287 14.0461 46.2287 14.5461 45.6378 14.5461C45.0469 14.5461 44.5469 14.0461 44.5469 13.4552C44.5469 12.8643 45.0469 12.3643 45.6378 12.3643C46.2287 12.3643 46.7287 12.8188 46.7287 13.4552Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3654 55.1368C23.3654 55.7277 22.8654 56.2277 22.2745 56.2277C21.6836 56.2277 21.1836 55.7277 21.1836 55.1368C21.1836 54.5459 21.6836 54.0459 22.2745 54.0459C22.8654 54.0459 23.3654 54.5459 23.3654 55.1368Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.3185 55.4561C46.3185 56.047 45.8185 56.547 45.2276 56.547C44.6367 56.547 44.1367 56.047 44.1367 55.4561C44.1367 54.8652 44.6367 54.3652 45.2276 54.3652C45.864 54.3197 46.3185 54.8197 46.3185 55.4561Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.8654 34.9093C58.8654 35.5002 58.3654 36.0002 57.7745 36.0002C57.1836 36.0002 56.6836 35.5002 56.6836 34.9093C56.6836 34.3184 57.1836 33.8184 57.7745 33.8184C58.3654 33.8184 58.8654 34.2729 58.8654 34.9093Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8185 34.9991C10.8185 35.59 10.3185 36.09 9.72763 36.09C9.13672 36.09 8.63672 35.59 8.63672 34.9991C8.63672 34.4081 9.13672 33.9081 9.72763 33.9081C10.3185 33.8627 10.8185 34.3627 10.8185 34.9991Z"
        fill="#2B2A2B"
      />
      <path
        d="M34.0469 52.7275C44.2641 52.7275 52.5469 44.4448 52.5469 34.2275C52.5469 24.0103 44.2641 15.7275 34.0469 15.7275C23.8296 15.7275 15.5469 24.0103 15.5469 34.2275C15.5469 44.4448 23.8296 52.7275 34.0469 52.7275Z"
        fill="#FFF7EB"
      />
      {value ? getValueChip(value) : null}
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.95703 41.136C6.95703 41.136 12.5934 61.0906 34.0479 60.9087C56.6388 60.7269 61.1388 40.8633 61.1388 40.8633C61.1388 40.8633 56.4116 61.9542 34.0025 61.9542C11.5934 61.9996 6.95703 41.136 6.95703 41.136Z"
        fill="#121011"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_683_64487"
        x="0"
        y="0"
        width="68"
        height="68"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_683_64487" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_683_64487"
          result="shape"
        />
      </filter>
      <linearGradient
        id={`paint0_linear_683_64487_${color}_${slotId}`}
        x1="34"
        y1="6"
        x2="34"
        y2="62"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.635417" stopColor={color} />

        <stop offset="0.859375" stopColor="#B900FA" />
        <stop offset="1" stopColor="#5B10F2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_683_64487"
        x1="34.2895"
        y1="13.4287"
        x2="33.7508"
        y2="54.3714"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#121011" stopOpacity="0.4" />
        <stop offset="1" stopColor="#FFF7EB" />
      </linearGradient>
    </defs>
  </svg>
);
