import React from 'react';
import { MobileComponent, TabletPortraitComponent } from 'components';
import { WinnersButtonPortrait } from './WinnersButton.portrait';
import { IWinnersButtonProps } from './types';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const WinnersButtonResponsive = (props: IWinnersButtonProps) => (
  <>
    <TabletPortraitComponent>
      <WinnersButtonPortrait {...props} offsetRight={151} />
    </TabletPortraitComponent>
    <MobileComponent>
      <WinnersButtonPortrait
        {...props}
        offsetRight={isOldBrowserIOS() ? 63 : 68}
        offsetLeft={'initial'}
      />
    </MobileComponent>
  </>
);
