import React from 'react';
import { Icon, IconNames, TiltedRightButton } from 'components';
import { IPortraitChatButtonProps } from './types';

export const ChatButtonPortrait = ({
  onOpenChat,
  offsetLeft,
  offsetRight,
}: IPortraitChatButtonProps) => (
  <TiltedRightButton onClick={onOpenChat} offsetRight={offsetRight} offsetLeft={offsetLeft}>
    <Icon name={IconNames.Chat} />
  </TiltedRightButton>
);
