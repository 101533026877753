import React from 'react';
import { useSelector } from 'react-redux';
import { Device } from 'types';
import { Slots } from 'widgets/Slots';
import { Chips } from 'widgets/Chips';
import { TableInfo } from 'widgets/TableInfo';
import { Scoreboard } from 'widgets/Scoreboard';
import { ScoreboardButton } from 'widgets/ScoreboardToggleButton';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { IDeviceGamePanelProps } from './types';
import {
  PortraitBetPanelStyled,
  ContentWrapper,
  PortraitInnerWrapper,
  PortraitWrapper,
} from './styles';

export const GamePanelPortrait = ({ deviceType = Device.Mobile }: IDeviceGamePanelProps) => {
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);

  return (
    <PortraitWrapper $deviceType={deviceType}>
      <ContentWrapper>
        <PortraitInnerWrapper>
          <Slots />
          <PortraitBetPanelStyled>
            <Chips />
          </PortraitBetPanelStyled>
          <ScoreboardButton />
          {isScoreboardOpen && <Scoreboard />}
        </PortraitInnerWrapper>
        <TableInfo />
      </ContentWrapper>
    </PortraitWrapper>
  );
};
