import React from 'react';
import styled from 'styled-components';
import { ISvgSize } from 'types';
import generateShortId from 'utils/generateShortId';
import { ScoreboardColors, scoreboardColors } from '../types';

export interface IRect {
  size?: ISvgSize;
  strokeWidth?: number;
  fill?: string;
  stroke?: string;
  defaultGradient?: boolean;
}

const RectStyled = styled.svg``;

export const Rect = ({
  size = { width: 16, height: 16 },
  strokeWidth = 0.5,
  fill = 'transparent',
  defaultGradient = false,
  stroke = scoreboardColors[ScoreboardColors.LightDark],
}: IRect) => {
  const id = generateShortId();

  return (
    <RectStyled width={size.width} height={size.height}>
      <rect
        width={size.width}
        height={size.height}
        fill={defaultGradient ? `url(#paint0_linear_998_5936-${id})` : fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      {defaultGradient && (
        <defs>
          <linearGradient
            id={`paint0_linear_998_5936-${id}`}
            x1={size.width / 2}
            y1="0"
            x2={size.width / 2}
            y2={size.height}
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#121011" stopOpacity="0" />
            <stop offset="1" stopColor="#2B2A2B" />
          </linearGradient>
        </defs>
      )}
    </RectStyled>
  );
};
