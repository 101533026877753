import React from 'react';
import styled from 'styled-components';
import { IBaseSvgParams } from 'types';
import { ScoreboardColors, scoreboardColors } from 'widgets/Scoreboard/types';

export interface ITieBallQuantityText extends IBaseSvgParams {
  quantity: number;
  fontSize?: number;
  fill?: string;
}

const TieBallQuantityTextStyled = styled.svg``;

export const TieBallQuantityText = ({
  quantity,
  size = { height: 16, width: 16 },
  position = [0, 1],
  fontSize = 10,
  fill = scoreboardColors[ScoreboardColors.OffWhite],
}: ITieBallQuantityText) => {
  const [positionX, positionY] = position;
  return (
    <TieBallQuantityTextStyled
      height={size.height}
      width={size.width}
      x={positionX}
      y={positionY}
      viewBox="0 0 16 16">
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={fontSize}
        fill={fill}>
        {quantity}
      </text>
    </TieBallQuantityTextStyled>
  );
};
