import { summaryBetSelector } from 'core/widgets/Bet/selectors';
import { BetType } from 'core/widgets/Bet/types';
import { put, select } from 'redux-saga/effects';
import { SLOTS } from 'types';
import { setConfirmationSlotAction } from '../actions';

export function* setClosestConfirmationSlotSaga() {
  const summaryBet: BetType = yield select(summaryBetSelector);
  const slotNameWithValue: string | undefined = Object.entries(summaryBet)
    .reverse()
    .find(([, slotValue]) => slotValue !== 0)?.[0];

  if (slotNameWithValue) {
    yield put(setConfirmationSlotAction(slotNameWithValue as SLOTS));
  }
}
