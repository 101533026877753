import { ActionType, createReducer } from 'typesafe-actions';
import {
  connectToWebSocketSuccessAction,
  connectToWebSocketFailureAction,
  socketСonnectedAction,
  socketDisconnectedAction,
} from './actions';
import { IWebSocketState } from './types';

export const authState: IWebSocketState = {
  isConnected: false,
  isConnectionFailed: false,
  isSocketDisconnected: false,
};

const actions = {
  connectToWebSocketSuccessAction,
  connectToWebSocketFailureAction,
  socketСonnectedAction,
  socketDisconnectedAction,
};

export const webSocketReducer = createReducer<IWebSocketState, ActionType<typeof actions>>(
  authState,
)
  .handleAction(connectToWebSocketSuccessAction, (state) => ({
    ...state,
    isConnected: true,
  }))
  .handleAction(connectToWebSocketFailureAction, (state) => ({
    ...state,
    isConnectionFailed: true,
  }))
  .handleAction(socketСonnectedAction, (state) => ({
    ...state,
    isSocketDisconnected: false,
  }))
  .handleAction(socketDisconnectedAction, (state) => ({
    ...state,
    isSocketDisconnected: true,
  }));
