import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IWinnerBallState } from './types';

const winnerItemSelector = (state: IState) => state.winnerBallReducer;

export const winnerBallSelector = createSelector(
  winnerItemSelector,
  (state: IWinnerBallState) => state.winnerBall,
);
