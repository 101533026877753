import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Grid } from 'widgets/Scoreboard/ScoreboardGrid';
import { IScoreboardRoad } from '../types';
import { SmallRoadChild } from './SmallRoadChild';

const WideBorder = styled.svg``;

export const SmallRoadMobile: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [168, 80],
}) => (
  <Group size={{ width: 95, height: 54 }} position={position}>
    <Grid
      col={10}
      row={6}
      cellSize={9}
      position={[0, 0]}
      childItemType={SmallRoadChild}
      childItemSize={{ width: 9, height: 9 }}
      roadData={roadData}
    />
    <WideBorder
      width="1"
      height="54"
      x="89"
      viewBox="0 0 1 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="0.25" height="54" stroke="#343434" strokeWidth="0.25" />
    </WideBorder>
  </Group>
);
