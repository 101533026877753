export enum CHIPS_COLORS {
  BLUE = '#2971E7',
  YELLOW = '#FFCC17',
  RED = '#C02E00',
  VIOLET = '#775BA7',
  GREEN = '#B9BB34',
  ORANGE = '#F8951D',
  LIGHT_BLUE = '#57B5E3',
  SCARLET = '#CD0333',
  PURPLE = '#903D9D',
  LIGHT_GREEN = '#8CB56E',
  PINK = '#DB4CAA',
  TURQUOISE = '#0191B0',
}
