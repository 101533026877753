import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  DrawnBall,
  Toast,
  Notices,
  TopPanel,
  VideoStream,
  GamePanel,
  PreloaderMain,
  Timer,
  WinStatus,
  Settings,
  WinnersTicker,
  MobileLandscapeStub,
} from 'widgets';
import { Device } from 'types';
import { MobileAspectRatio, TabletPortraitAspectRatio } from 'constants/theme';
import { CountdownStage } from 'widgets/CountdownStage';
import { WinJP } from 'widgets/WinJP';
import { isInIframe, isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import { useSelector } from 'react-redux';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { useMobileOrientation } from 'react-device-detect';

type PortraitViewDevices = Device.Mobile | Device.Tablet_Portrait;

const portraitAspectRatioStyles = {
  [Device.Mobile]: <MobileAspectRatio />,
  [Device.Tablet_Portrait]: <TabletPortraitAspectRatio />,
};

interface IAppPortrait {
  deviceType?: PortraitViewDevices;
}

export const AppPortrait = ({ deviceType = Device.Mobile }: IAppPortrait) => {
  const { isLandscape, isPortrait } = useMobileOrientation();
  const { isMobileLandscape } = useSelector(authInfoSelector);
  const [isToolbarShowed, setIsToolbarShowed] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (isOldBrowserIOS() && !isInIframe()) {
      if (isPortrait) {
        const onResize = () => {
          setWindowHeight((prevState) => {
            if (prevState >= window.innerHeight) {
              setIsToolbarShowed(true);
            } else setIsToolbarShowed(false);

            return window.innerHeight;
          });
        };

        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
      }
    }

    if (isLandscape) {
      setIsToolbarShowed(false);
    }
  }, [windowHeight, isMobileLandscape, isLandscape, isPortrait]);

  return (
    <>
      {isMobileLandscape && <MobileLandscapeStub />}
      <PortraitWrapper
        id="ratio-container"
        $deviceType={deviceType}
        $isOldBrowser={isOldBrowserIOS()}
        $isToolbarShowed={isToolbarShowed}>
        {portraitAspectRatioStyles[deviceType]}

        <ContentWrapper>
          <TopPanel />
          <VideoStream />
          <GamePanel />
          <CountdownStage />
          <PreloaderMain />
          <Settings />
          <Timer />
          <Toast />
          <DrawnBall />
          <Notices />
          <WinStatus />
          <WinJP />
          <WinnersTicker />
        </ContentWrapper>
      </PortraitWrapper>
    </>
  );
};

const PortraitWrapper = styled.div<{
  $deviceType: Device;
  $isOldBrowser: boolean;
  $isToolbarShowed: boolean;
}>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 100vw;
  ${({ $deviceType, $isOldBrowser, $isToolbarShowed }) => {
    if ($deviceType === Device.Tablet_Portrait)
      return css`
        /* width: calc(100dvh * 768 / 1000); */
        max-height: calc(100vw * 1000 / 768);
        width: ${$isOldBrowser ? 'calc(100vh * 768 / 1000 - 50px)' : 'calc(100dvh * 768 / 1000)'};
      `;
    if ($deviceType === Device.Mobile)
      return css`
        max-height: calc(100vw * 5 / 3);
        width: ${$isOldBrowser
          ? isInIframe()
            ? 'calc(100vh * 3 / 5)'
            : 'calc(100vh * 3 / 5 - 50px)'
          : 'calc(100dvh * 3 / 5)'};
        top: ${$isToolbarShowed ? '28rem' : '0'};
      `;
  }}
  /* height: 100dvh; */
  height: ${({ $isOldBrowser }) =>
    $isOldBrowser ? (isInIframe() ? 'calc(100vh)' : 'calc(100vh - 50px)') : '100dvh'};
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
