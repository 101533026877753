import { getAspectRatioIphone, AspectRatioIphone, isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.div<{ $deviceType: Device }>`
  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        position: absolute;
        top: 15rem;
        left: 50%;
        transform: translateX(-50%);
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        position: relative;
        width: 100%;
        display: flex;
        height: 65rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        position: relative;
        width: 100%;
        display: flex;
        height: 37rem;
        margin-top: ${isOldBrowserIOS() && getAspectRatioIphone() !== AspectRatioIphone.RATIO_16_9
          ? '12rem'
          : '0'};
      `;
    }
  }}
`;
