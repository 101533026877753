import React from 'react';
import { useSelector } from 'react-redux';

import { jackpotInfoWinningDrawSelector } from 'core/widgets/Jackpot/selectors';
import { WinJPResponsive } from './Responsive';
import { transformToCurrencyDecimalJackpot } from 'utils/transformToCurrency';

export const WinJP = () => {
  const {
    amountJackpotUserWon,
    isUserWonInJP,
    showJPPopup,
    jackpotAmount,
    jackpotCurrency,
    currencyJackpotUserWon: currency,
  } = useSelector(jackpotInfoWinningDrawSelector);

  const sum = isUserWonInJP ? amountJackpotUserWon : (jackpotAmount as number);

  if (!showJPPopup || !sum) {
    return null;
  }

  const sumWon = transformToCurrencyDecimalJackpot({
    value: sum,
    currency: isUserWonInJP ? currency : jackpotCurrency,
  }).replace(/-/g, '');

  return <WinJPResponsive isPlayerWon={isUserWonInJP} sum={sumWon} />;
};
