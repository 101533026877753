import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { ISettingsPanelStatusState } from './types';

const manageSettingsPanelSelector = (state: IState) => state.settingsPanelReducer;

export const settingsPanelSelector = createSelector(
  manageSettingsPanelSelector,
  (state: ISettingsPanelStatusState) => state.settingsPanel,
);

export const shasClickJackpotSlotSelector = createSelector(
  manageSettingsPanelSelector,
  (state: ISettingsPanelStatusState) => state.hasClickJackpotSlot,
);
