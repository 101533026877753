import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IGeneralSettingsState } from './types';

export const generalSettingsRootSelector = (state: IState) => state.generalSettingsReducer;

export const autoConfirmSelector = createSelector(
  generalSettingsRootSelector,
  (state: IGeneralSettingsState) => state.autoConfirm,
);
