import { head, init, nth } from 'ramda';
import 'core-js/features/array/at';
import { findLastIndex } from 'lodash';
import { WinnerBall } from 'constants/common';
import { IRoadItem, IRoadItems } from 'widgets/Scoreboard/types';
import { DEFAULT_BOARD_ROWS_COUNT } from 'widgets/Scoreboard/ScoreboardGrid';
import {
  addColumnToRoad,
  addItemToAnalyticsRoad,
  addNewItemAfterPrediction,
  addTieAfterPrediction,
  countConsecutiveTies,
  removeLastColumn,
} from './common';
import { IColumn, IColumnState, IMatrixes, IRoads, IMatrix } from '../types';
import {
  removeLastPrediction,
  removePredictionFromLastColumn,
  removePredictions,
} from './prediction';

const hasEnoughItems = (columnNumber: number, rowNumber: number, offset: number): boolean =>
  columnNumber >= offset || (columnNumber >= offset - 1 && rowNumber > 0);

export const transformWinnerBallToRoadItem = (
  winnerBall: WinnerBall,
  winnerBallsData: IRoadItem[],
): IRoadItem => ({
  outcome: winnerBall,
  ...(winnerBall === WinnerBall.GOLD && {
    isTie: true,
    consecutiveTiesQty: countConsecutiveTies(winnerBallsData),
  }),
});

export const transformWinnerBallsToInitialRoad = (winnerBallsData: WinnerBall[]): IRoadItem[] =>
  winnerBallsData.reduce((acc: IRoadItem[], winner) => {
    const newTransformedItem = transformWinnerBallToRoadItem(winner, acc);
    return [...acc, newTransformedItem];
  }, []);

const transformDataToMatrix = (data: IRoadItem[], currentMatrix: IMatrix = []) =>
  data.reduce((accumulator: IMatrix, item: IRoadItem) => {
    const { isTie, outcome, consecutiveTiesQty } = item;
    const next = { ...item };
    const isFirstItem: boolean = accumulator.length === 0;
    const lastColumn: IColumn = nth(-1, accumulator) || [];
    const lastItem: IRoadItem | undefined = nth(-1, lastColumn);

    if (isFirstItem || !lastItem) {
      return [[next]];
    }

    const { outcome: previousOutcome, isPrediction: isPreviousPrediction } = lastItem;

    const isColumnChanged: boolean =
      !isTie && outcome !== previousOutcome && previousOutcome !== WinnerBall.GOLD;

    if (isPreviousPrediction) {
      if (isTie) {
        return addTieAfterPrediction(accumulator, lastColumn);
      }
      const isFirstColumn = accumulator.length === 1;
      const firstColumn: IColumn | undefined = head(currentMatrix) || [];
      const firstItem: IRoadItem | undefined = head(firstColumn);
      const isFirstItemGold = firstItem && firstItem.outcome === WinnerBall.GOLD;
      const isFirstConfirmedBallAfterGold = firstColumn.length === 2;
      if (isFirstColumn && isFirstItemGold && isFirstConfirmedBallAfterGold) {
        return [[...init(firstColumn), next]];
      }
      return addNewItemAfterPrediction(next, isColumnChanged, accumulator, lastColumn);
    }

    if (isColumnChanged) {
      return [...accumulator, [next]];
    }

    if (isTie && lastItem) {
      return [
        ...init(accumulator),
        [...init(lastColumn), { ...lastItem, isTie: true, consecutiveTiesQty }],
      ];
    }

    return [...init(accumulator), [...lastColumn, next]];
  }, currentMatrix);

const getStartColumnIndex = (
  currentRoadData: IRoadItems,
  matrix: IMatrix,
  rowCount: number,
  newItem: IRoadItem | undefined,
): number => {
  if (matrix.length === 1 || !newItem) return 0;
  const firstRow = currentRoadData.filter(
    (item, i) => item !== undefined && i % rowCount === 0,
  ) as IRoadItem[];
  const firstRowLastItem = nth(-1, firstRow);

  if (firstRowLastItem && firstRowLastItem.outcome !== newItem.outcome) {
    return firstRow.length;
  }

  const lastDissimilarHeadItemIndex = findLastIndex(
    firstRow,
    (item) => item.outcome !== newItem.outcome,
  );

  return lastDissimilarHeadItemIndex + 1;
};

const transformRoad = (
  matrix: IMatrix,
  rowCount: number = DEFAULT_BOARD_ROWS_COUNT,
  currentRoadData: IRoadItems = [],
): IRoadItems => {
  if (currentRoadData.length) {
    const lastColumnStart = matrix.length - 1;
    const lastColumn = matrix[lastColumnStart];
    const lastItem = nth(-1, lastColumn);
    const columnStartIndex = getStartColumnIndex(currentRoadData, matrix, rowCount, lastItem);
    const isTie = lastItem ? lastItem.isTie : false;

    const { accumulator: roadData }: IColumnState = removeLastColumn(
      currentRoadData,
      columnStartIndex,
      lastColumn,
      rowCount,
      isTie,
    );

    return addColumnToRoad(rowCount, roadData, lastColumn, columnStartIndex);
  }

  return matrix.reduce(
    (accumulator: IRoadItems, column: IRoadItem[], columnIndex: number) =>
      addColumnToRoad(rowCount, accumulator, column, columnIndex),
    [],
  );
};

const transformAnaliticMatrix = (
  bigRoadMatrix: IMatrix,
  offset: number,
  inputMatrix: IMatrix = [],
): IMatrix => {
  if (inputMatrix.length) {
    const columnNumber = bigRoadMatrix.length - 1;
    const lastColumn = nth(-1, bigRoadMatrix) || [];
    const lastItem = nth(-1, lastColumn);
    const isPrediction = lastItem ? lastItem.isPrediction : false;
    const rowNumber = lastColumn.length - 1;

    return addItemToAnalyticsRoad(
      inputMatrix,
      bigRoadMatrix,
      columnNumber,
      rowNumber,
      offset,
      isPrediction,
    );
  }

  return bigRoadMatrix.reduce(
    (accumulator: IMatrix, column: IColumn, columnNumber: number) =>
      column.reduce(
        (result: IMatrix, item: IRoadItem, rowNumber: number) => {
          const isEnoughData: boolean = hasEnoughItems(columnNumber, rowNumber, offset);

          if (!isEnoughData) {
            return [...result];
          }
          return addItemToAnalyticsRoad(
            result,
            bigRoadMatrix,
            columnNumber,
            rowNumber,
            offset,
            item.isPrediction,
          );
        },
        [...accumulator],
      ),
    [...inputMatrix],
  );
};

export const transformAllRoads = (payload: IColumn, matrixes: IMatrixes, roads: IRoads) => {
  const rowCount = DEFAULT_BOARD_ROWS_COUNT;
  const firstPayloadItem = head(payload);
  const isNewTieItem = firstPayloadItem && firstPayloadItem.isTie && payload.length === 1;

  const beadRoadData = [...removeLastPrediction(roads.beadRoadData), ...payload];

  const bigRoadMatrix = transformDataToMatrix(payload, matrixes.bigRoadMatrix);
  const bigRoadData = transformRoad(bigRoadMatrix, rowCount, removePredictions(roads.bigRoadData));

  const smallRoadMatrix = isNewTieItem
    ? removePredictionFromLastColumn(matrixes.smallRoadMatrix)
    : transformAnaliticMatrix(bigRoadMatrix, 3, matrixes.smallRoadMatrix);
  const smallRoadData = isNewTieItem
    ? removePredictions(roads.smallRoadData)
    : transformRoad(smallRoadMatrix, rowCount, removePredictions(roads.smallRoadData));

  const cockroachPigRoadMatrix = isNewTieItem
    ? removePredictionFromLastColumn(matrixes.cockroachPigRoadMatrix)
    : transformAnaliticMatrix(bigRoadMatrix, 4, matrixes.cockroachPigRoadMatrix);
  const cockroachPigRoadData = isNewTieItem
    ? removePredictions(roads.cockroachPigRoadData)
    : transformRoad(
        cockroachPigRoadMatrix,
        rowCount,
        removePredictions(roads.cockroachPigRoadData),
      );

  return {
    matrixes: { bigRoadMatrix, smallRoadMatrix, cockroachPigRoadMatrix },
    roads: { bigRoadData, beadRoadData, smallRoadData, cockroachPigRoadData },
  };
};
