import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITableInfoProps } from './types';
import {
  LandscapeWrapper,
  ContainerLeft,
  ItemInfoLandscape,
  ItemInfoLabel,
  ItemInfoValue,
  ContainerRight,
} from './styles';

export const TableInfoLandscape = ({ betsInfo, gameInfo }: ITableInfoProps) => {
  const { i18n } = useTranslation();
  return (
    <LandscapeWrapper>
      <ContainerLeft>
        {betsInfo.map(({ label, value }, i) => (
          <ItemInfoLandscape key={i} $isBigContent={i18n.language === 'tl'}>
            <ItemInfoLabel>{label}: </ItemInfoLabel>
            <ItemInfoValue>{value}</ItemInfoValue>
          </ItemInfoLandscape>
        ))}
      </ContainerLeft>
      <ContainerRight>
        {gameInfo.map(({ label, value }, i) => (
          <ItemInfoLandscape key={i} $isBigContent={i18n.language === 'tl'}>
            <ItemInfoLabel>{label}: </ItemInfoLabel>
            <ItemInfoValue>{value}</ItemInfoValue>
          </ItemInfoLandscape>
        ))}
      </ContainerRight>
    </LandscapeWrapper>
  );
};
