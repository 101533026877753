import { changeRoundStateAction } from 'core/widgets/RoundStates/actions';
import { put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { addToastAction } from 'core/widgets/Toast/actions';
import { roundStatusMessages } from 'constants/toastMessages';
import { clearPlacedBetAction } from 'core/widgets/Bet/actions';
import { OnDrawFinishedData } from '../../WinnerBall/types';
import { RoundStates } from '../../RoundStates/types';
import { setIsTheLast2SecondsInBettingTimeToStoreAction } from 'core/widgets/BettingTime/actions';

export function* onBettingTimeFinishedSaga({ type: notificationType }: OnDrawFinishedData) {
  if (notificationType === Notifications.BETTING_TIME_FINISHED) {
    yield put(changeRoundStateAction({ roundState: RoundStates.BettingTimeFinished }));
    yield put(addToastAction({ value: roundStatusMessages.BETTING_TIME_FINISHED }));
    yield put(setIsTheLast2SecondsInBettingTimeToStoreAction(false));
  }
}
