import { put, takeLatest, delay, select } from 'redux-saga/effects';
import {
  decrementTimeLeftAction,
  stopDecrementTimeLeftAction,
} from './widgets/BettingTime/actions';
import { ACTIONS } from './widgets/BettingTime/types';
import { timeLeftSelector } from './widgets/BettingTime';

function* decrementTimeLeftSaga() {
  while (true) {
    const timeLeft: number = yield select(timeLeftSelector);

    if (timeLeft > 0) {
      yield delay(1000);
      yield put(decrementTimeLeftAction());
    } else {
      yield put(stopDecrementTimeLeftAction());
      break;
    }
  }
}

export function* watchDecrementTimeLeftSaga() {
  yield takeLatest(ACTIONS.START_DECREMENT_TIME_LEFT, decrementTimeLeftSaga);
}
