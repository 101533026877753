import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISvgSize } from 'types';
import { ContentWrapper, RoadLabelStyled, Text } from './styles';
import { Board } from './types';

export interface IRoadLabelProps {
  boardName: Board;
  size?: ISvgSize;
  isMultiline?: boolean;
  labelSize?: 'L' | 'S';
}

export const RoadLabel = ({
  boardName,
  size = { width: 102, height: 14 },
  isMultiline = false,
  labelSize = 'L',
}: IRoadLabelProps) => {
  const { t } = useTranslation();
  const height = !isMultiline ? size.height : 2 * size.height;
  const baseName = t(`scoreboard.${boardName}`);
  const splittedName = baseName.split(' ');

  return (
    <RoadLabelStyled width={size.width} height={height} viewBox={`0 0 ${size.width} ${height}`}>
      <ContentWrapper dominantBaseline="middle" textAnchor="middle">
        {!isMultiline ? (
          <Text $size={labelSize} x="50%" y="50%" letterSpacing="0">
            {baseName}
          </Text>
        ) : (
          <>
            <Text $size={labelSize} x="50%" y="25%" letterSpacing="0">
              {splittedName[0]}
            </Text>
            <Text $size={labelSize} x="50%" y="75%" letterSpacing="0">
              {splittedName[1]}
            </Text>
          </>
        )}
      </ContentWrapper>
    </RoadLabelStyled>
  );
};
