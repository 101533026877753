import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { CloseGameButtonLandscape } from './CloseGameButton.landscape';
import { CloseGameButtonPortrait } from './CloseGameButton.portrait';
import { ICloseGameButtonProps } from './types';

export const CloseGameButtonResponsive = (props: ICloseGameButtonProps) => (
  <>
    <DesktopComponent>
      <CloseGameButtonLandscape {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <CloseGameButtonLandscape {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <CloseGameButtonPortrait {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <CloseGameButtonPortrait {...props} />
    </MobileComponent>
  </>
);
