export enum ACTIONS {
  SET_BALANCE = 'SET_BALANCE',
  SET_BALANCE_IN_BETTING_TIME = 'SET_BALANCE_IN_BETTING_TIME',
  UPDATE_BALANCE = 'UPDATE_BALANCE',
  SET_TOTAL_BET = 'SET_TOTAL_BET',
  UPDATE_TOTAL_BET = 'UPDATE_TOTAL_BET',
}

export interface IUserState {
  balance: number | null;
  balanceInBettingTime: number | null;
  totalBet: number | null;
}
