import { colors } from 'constants/theme';
import styled, { css } from 'styled-components';
import { Device } from 'types';
import { isInIframe } from 'hooks/getDefineDevEnv';

export const WinnerTableContainerScroll = styled.div<{ $deviceType?: Device }>`
  padding: 0rem 12rem 0rem 0;
  z-index: 103;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;
  /* margin-right: 25rem;
  margin-top: 24rem;
  margin-bottom: 40rem; */
  overflow-scrolling: touch;
  overflow-x: hidden;

  .simplebar-scrollable-y {
    width: 100%;
  }

  .simplebar-scrollable-y {
    /* height: calc(100vh - 260rem) !important; */
  }

  .simplebar-track {
    right: -10px;
  }

  .simplebar-track.simplebar-vertical {
    width: 1px;
    background: #434343;

    .simplebar-scrollbar {
      width: 3px;
      border-radius: 2px;

      background-color: #d4bb93;
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 2px;

    background-color: #d4bb93;
  }
  &::-webkit-scrollbar {
    width: 1px;
    background: #434343;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        & > div {
          width: 100%;
        }
        .simplebar-content {
          height: 100%;
        }

        .simplebar-scrollable-x {
          width: 100%;
        }
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        & > div {
          width: 100%;
        }
        .simplebar-content {
          height: 100%;
        }

        .simplebar-scrollable-x {
          width: 100%;
        }
        /* padding: 0rem 16rem 0rem 16rem;
        margin-right: 12rem;
        margin-top: 16rem;
        margin-bottom: 24rem; */

        &::-webkit-scrollbar-thumb {
          width: 2px;
        }
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }

        & p {
          font-size: 10rem;
        }

        & h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 12rem;
        }

        & ul {
          font-size: 10rem;
        }
      `;
    }
  }}
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const HistoryWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;

  position: relative;

  &:after {
    content: '';
    background: linear-gradient(180deg, rgba(52, 52, 52, 0) 21.79%, #191516 100%);
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    height: 100px;
    width: 100%;
  }
`;

export const SelectWrapper = styled.div`
  margin-top: 12rem;
`;

export const ItemWrapper = styled.div<{ $deviceType: Device; $isOldBrowser: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1rem solid;
  border-image-slice: 0 0 1 0;
  border-image-source: linear-gradient(270deg, #121011 0%, #434343 52.08%, #121011 100%);
  font-size: 12rem;
  padding-bottom: 20rem;
  margin-top: -20rem;

  ${({ $deviceType, $isOldBrowser }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        padding: ${$isOldBrowser && !isInIframe() && '8rem 0 16rem'};
      `;
    }
  }}
`;

export const ButtonWrapper = styled.div`
  padding: 0 40rem 0;
`;

export const ButtonText = styled.span<{ $isSmallText: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  width: max-content;
  /* font-size: 9rem; */
  line-height: 14rem;
  margin: 0 auto;

  font-size: ${({ $isSmallText }) => ($isSmallText ? '9rem' : '12rem')};

  svg {
    margin-right: 8px;
    height: 16rem;
    width: 16rem;
  }
`;

export const UserValue = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 4px;
  line-height: 100%;

  svg {
    margin-left: 4px;
    cursor: pointer;
    width: 12rem;
    height: 12rem;
  }

  span {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 4rem;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    right: 0;
    margin-left: -60px;
    border: 1px solid #f1e6d2;
    background: linear-gradient(348deg, #121011 8.54%, #343434 116.17%);
    font-size: 10rem;
    color: #e7d5b9;
  }

  span::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 16px;
    margin-left: -3px;
    border-width: 3px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  span::before {
    content: '';
    position: absolute;
    top: 100%;
    right: 14px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #d4bb93 transparent transparent transparent;
  }

  :hover span {
    visibility: visible;
  }
`;

export const TableContainer = styled.div<{ $hasPadding: boolean }>`
  /* margin: 16rem 24rem 30rem 24rem; */
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${({ $hasPadding }) => ($hasPadding ? '0 24rem 30rem 24rem' : '16rem 24rem 30rem 24rem')};

  &::after {
    content: '';
    background: linear-gradient(
      180deg,
      #1b191a00 3.79%,
      #1b191a05 5.79%,
      #1b191a10 8.79%,
      #1b191a22 11.79%,
      #1b191a40 26.79%,
      #1b191a82 40.79%,
      #1b191ae6 70%,
      #1b191a 100%
    );
    bottom: -5px;
    left: 0;
    position: absolute;
    z-index: 1000;
    height: 30px;
    width: 100%;

    @media only screen and (max-width: 450px) {
      height: 30px;
    }
  }

  /* width: 103%;
  margin: -24rem 2.5rem 0;
  padding: ${({ $hasPadding }) => ($hasPadding ? '-24rem 2.5rem 0' : '24rem 2.5rem 0')}; */
`;

export const DateInfo = styled.div<{ $deviceType: Device }>`
  color: ${colors.Subtitle};
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10rem;
  line-height: 12rem;
  letter-spacing: 0.3px;
  text-transform: uppercase;

  padding: 0 24rem;
  font-size: ${({ $deviceType }) => ($deviceType === Device.Desktop ? '10rem' : '8rem')};
  line-height: ${({ $deviceType }) => ($deviceType === Device.Desktop ? '16rem' : '10rem')};
`;

export const DatesWrapper = styled.div<{ $deviceType: Device }>`
  margin-top: -20rem;
`;

export const DateValue = styled.span<{ $deviceType: Device }>`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10rem;
  line-height: 12rem;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #e7d5b9;

  font-size: ${({ $deviceType }) => ($deviceType === Device.Desktop ? '10rem' : '8rem')};
  line-height: ${({ $deviceType }) => ($deviceType === Device.Desktop ? '12rem' : '10rem')};
`;

export const WrapperWinnerTable = styled.div<{
  $deviceType: Device;
  $isSmallSize: boolean;
  $isAspectRatioFit: boolean;
}>`
  /* width: ${({ $deviceType }) =>
    $deviceType === Device.Mobile
      ? '75%'
      : $deviceType === Device.Tablet_Portrait
        ? '94%'
        : '107%'};
  margin-left: ${({ $deviceType }) =>
    $deviceType === Device.Mobile ? '4%' : $deviceType === Device.Tablet_Portrait ? '-4%' : '0'}; */

  width: 100%;

  height: ${({ $deviceType, $isSmallSize, $isAspectRatioFit }) =>
    $deviceType === Device.Desktop
      ? $isSmallSize
        ? `calc(${$isAspectRatioFit ? '100vw * 9 / 16' : '100vh'} - 350rem)`
        : `calc(${$isAspectRatioFit ? '100vw * 9 / 16' : '100vh'} - 320rem)`
      : $deviceType === Device.Tablet_Landscape
        ? '36vh'
        : '100%'};
  /* max-height: ${({ $deviceType }) => ($deviceType === Device.Desktop ? '50vh' : 'auto')}; */
`;

// width: 118%;
//     margin-left: -6%;
//     height: 36vh;

export const ItemTitle = styled.div`
  font-size: 12rem;
  line-height: 14rem;
  text-transform: uppercase;
  white-space: pre-line;
`;

export const EmptyJackpotItemTitle = styled(ItemTitle)<{ $deviceType: Device }>`
  text-align: center;
  padding: ${({ $deviceType }) => ($deviceType === Device.Desktop ? '0' : '10rem 0')};
`;

export const Empty = styled.div<{ $deviceType: Device }>`
  background: var(--medium-dark-2-b-2-a-2-b, #2b2a2b);
  width: 108%;
  height: 100%;

  color: var(--light-gray-686868, #686868);
  text-align: center;
  font-size: 12rem;
  font-weight: 400;
  line-height: 18rem;
  display: flex;
  align-items: center;
  /* padding: 0 10%; */
  white-space: pre-line;
  text-align: center;
  justify-content: center;

  /* width: 104.5%; */
  /* width: 124.5%; */
  width: calc(100% - 1px);
`;

export const StatusJackpot = styled.div<{ $deviceType: Device }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4rem;
  background: #77604c;
  color: #fff7eb;
  text-align: center;
  font-size: 10rem;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  padding: 4rem 8rem;
  margin-left: 8rem;
  font-size: 10rem;
  line-height: 12rem;
  position: relative;
  top: -2rem;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile || $deviceType === Device.Tablet_Portrait) {
      return css`
        padding: 2rem 6rem;
        margin-left: 6rem;
        font-size: 8rem;
        line-height: 10rem;
        position: relative;
        top: -1rem;
      `;
    }
  }}
`;
