import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { Device, SLOTS } from 'types';
import { Wrapper } from '../styles';
import { ISlotInnerPositionsConfig, SlotContent } from '../../SlotContent';

export const SlotGoldMinimizedTablet = ({
  activeChip,
  bet,
  isBettingTime,
  isWinner,
  hasPlacedBet,
  isCurrent,
  isMinimized,
  size = { height: 136, width: 238 },
  isActiveJP,
  onClick,
  onBetConfirm,
  onBetClear,
  onErrorClick,
}: ISlotProps) => {
  const innerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '97',
      positionY: '80',
    },
    confirmBet: {
      positionX: '176',
      positionY: '-32',
    },
  };

  return (
    <Wrapper
      $activeChip={activeChip}
      $isActive={hasPlacedBet}
      $isBettingTime={isBettingTime}
      $isWinner={isWinner}
      onClick={isBettingTime ? onClick : onErrorClick}
      width={size.width}
      height={size.height}
      viewBox="0 0 238 136"
      x="241"
      y="0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_513_3314)">
        <path d="M238 0V136H0V0H123.254H238Z" fill="#28080B" />
        <path d="M238 0V136H0V0H123.254H238Z" fill="url(#paint0_linear_513_3314)" />
      </g>
      <path d="M237 1V135H1V1H123.254H237Z" stroke="url(#paint1_linear_513_3314)" strokeWidth="2" />
      <path d="M237 1V135H1V1H123.254H237Z" stroke="url(#paint2_linear_513_3314)" strokeWidth="2" />

      <g id="goldBets" filter="url(#filter0_i_320_45755)">
        <path d="M238 0V136H0V0H123.254H238Z" fill="#28080B" />
        <path d="M238 0V136H0V0H123.254H238Z" fill="url(#paint0_linear_320_45755)" />
      </g>
      <path
        d="M237 1V135H1V1H123.254H237Z"
        stroke="url(#paint1_linear_320_45755)"
        strokeWidth="2"
      />
      <path
        d="M237 1V135H1V1H123.254H237Z"
        stroke="url(#paint2_linear_320_45755)"
        strokeWidth="2"
      />
      <SlotContent
        bet={bet}
        isCurrent={isCurrent}
        onBetConfirm={onBetConfirm}
        onBetClear={onBetClear}
        isMinimized={isMinimized}
        isWinner={isWinner}
        slot={SLOTS.tie}
        deviceType={Device.Tablet_Portrait}
        hasPlacedBet={hasPlacedBet}
        innerPositionsConfig={innerContentPositions}
        size={size}
        isActiveJP={isActiveJP}
        isBettingTime={isBettingTime}
      />
      <defs>
        <filter
          id="filter0_i_513_3314"
          x="0"
          y="0"
          width="238"
          height="137"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_513_3314" />
        </filter>
        <linearGradient
          id="paint0_linear_513_3314"
          x1="119"
          y1="0"
          x2="119"
          y2="136"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEDB00" />
          <stop offset="0.9999" stopColor="#DCA231" stopOpacity="0.62" />
          <stop offset="1" stopColor="#F9B738" stopOpacity="0.62" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_513_3314"
          x1="119"
          y1="0"
          x2="119"
          y2="136"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_513_3314"
          x1="238"
          y1="68"
          x2="0"
          y2="68"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4BB93" stopOpacity="0" />
          <stop offset="0.5" stopColor="#D4BB93" />
          <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
        </linearGradient>
      </defs>

      <defs id="goldBetsDefs">
        <filter
          id="filter0_i_320_45755"
          x="0"
          y="0"
          width="238"
          height="137"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_320_45755" />
        </filter>
        <linearGradient
          id="paint0_linear_320_45755"
          x1="119"
          y1="0"
          x2="119"
          y2="136"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FEDB00" />
          <stop offset="1" stopColor="#CD7829" stopOpacity="0.69" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_320_45755"
          x1="119"
          y1="0"
          x2="119"
          y2="136"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_320_45755"
          x1="238"
          y1="68"
          x2="0"
          y2="68"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4BB93" stopOpacity="0" />
          <stop offset="0.5" stopColor="#D4BB93" />
          <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Wrapper>
  );
};
