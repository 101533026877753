export const colors = {
  LightGold: '#E7D5B9',
  Black: '#121011',
  MediumDark: '#2B2A2B',
  Gray: '#434343',
  Gold: '#D4BB93',
  Ruby: '#ED1A4C',
  LightGreen: '#43AC1D',
  SlotBackgroundRed: '#931429',
  SlotBackgroundBlack: '#931429',
  Eggshell: '#F1E6D2',
  SlotRed: '#FFBAC5',
  SlotBlue: '#C5EEFF',
  SlotGold: '#FFFADD',
  SlotText: '#FFF7EB',
  Brown: '#77604C',
  Subtitle: '#938777',
};

export interface IDarkTheme {
  text: {
    primaryDefault: string;
    primaryDisabled: string;
    secondaryDefault: string;
    acceptDefault: string;
    signalDefault: string;
  };
  border: {
    primaryDefault: string;
    primaryDisabled: string;
    accent: string;
    disabled: string;
  };
  background: {
    primary: string;
    accent: string;
    disabled: string;
    slotsMobileDefault: string;
  };
}

export const darkTheme: IDarkTheme = {
  text: {
    primaryDefault: colors.LightGold,
    primaryDisabled: colors.Gray,
    secondaryDefault: colors.Gold,
    acceptDefault: colors.LightGreen,
    signalDefault: colors.Ruby,
  },
  border: {
    primaryDefault: colors.Black,
    primaryDisabled: colors.Gray,
    accent: colors.Eggshell,
    disabled: colors.MediumDark,
  },
  background: {
    primary: colors.Black,
    accent: colors.Gold,
    disabled: colors.Gray,
    slotsMobileDefault: 'linear-gradient(180deg, #343434 0%, #2B2A2B 100%)',
  },
};
