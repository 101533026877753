import React, { ReactNode, MouseEvent } from 'react';
import { ButtonStyled } from './styles';

export interface IButtonProps {
  children: ReactNode;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
}

export const Button = ({
  children,
  onClick,
  disabled = false,
  className,
  fullWidth,
}: IButtonProps) => (
  <ButtonStyled onClick={onClick} disabled={disabled} className={className} $fullWidth={fullWidth}>
    {children}
  </ButtonStyled>
);
