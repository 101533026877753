export enum ACTIONS {
  CHANGE_ROUND_STATE = 'CHANGE_ROUND_STATE',
}

export enum RoundStates {
  TableOpened = 'TABLE_OPENED',
  RoundStarted = 'ROUND_STARTED',
  BettingTimeStarted = 'BETTING_TIME_STARTED',
  BettingTimeFinished = 'BETTING_TIME_FINISHED',
  DrawStarted = 'DRAW_STARTED',
  DrawFinished = 'DRAW_FINISHED',
  ResultConfirmed = 'RESULT_CONFIRMED',
  RoundFinished = 'ROUND_FINISHED',
  RoundCanceled = 'ROUND_CANCELED',
}

export interface IRoundState {
  roundState: RoundStates;
}
