import { createGlobalStyle, css } from 'styled-components';
import { darkTheme } from './colors';

export const GlobalStyles = createGlobalStyle`
  body {
    max-width: 100vw;
    max-height: 100vh;
    font-family: 'Goldman';
    font-weight: 400;
    line-height: 1.25;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
    color: ${darkTheme.text.primaryDefault};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  body, p {
    margin: 0;
  }

  .ru {
    font-family: 'Play';
    font-weight: 400;
  }

`;
