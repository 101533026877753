import styled, { css } from 'styled-components';

export const blinkingStyles = css`
  animation: blinker 0.6s cubic-bezier(1, 0, 0, 1) infinite alternate;
  @keyframes blinker {
    to {
      opacity: 0;
    }
  }
`;

export const ScoreboardWrapper = styled.div`
  width: 100%;
  height: fit-content;
  ${({ theme: { background } }) => css`
    background-color: ${background.primary};
  `}
`;

export const ScoreboardContent = styled.svg``;
