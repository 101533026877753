const transformToShortNumber = (value: number) => {
  if (value >= 1e6) {
    const result = (value / 1e6).toFixed(6).replace(/^(\d+(?:\.\d*?[1-9](?=0|\b))?)\.?0*$/, '$1');

    return `${result}M`;
  }

  if (value >= 1e3) {
    const result = (value / 1e3).toFixed(3).replace(/^(\d+(?:\.\d*?[1-9](?=0|\b))?)\.?0*$/, '$1');

    return `${result}K`;
  }

  return value.toString();
};

export default transformToShortNumber;
