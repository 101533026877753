import React from 'react';
import { useSelector } from 'react-redux';
import { roundWinnersSelector } from 'core/widgets/WinnersTicker';
import { roundStateSelector } from 'core/widgets/RoundStates';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { jackpotInfoWinningDrawSelector } from 'core/widgets/Jackpot/selectors';
import { WinnersTickerResponsive } from './Responsive';

export const WinnersTicker = () => {
  const roundWinners = useSelector(roundWinnersSelector);
  const roundState = useSelector(roundStateSelector);
  const { winnersListJackpot } = useSelector(jackpotInfoWinningDrawSelector);

  if (winnersListJackpot || roundWinners) {
    return (
      <WinnersTickerResponsive
        roundWinners={[...(winnersListJackpot || []), ...(roundWinners || [])]}
        isHidingStarted={
          roundState === RoundStates.BettingTimeFinished || roundState === RoundStates.DrawStarted
        }
      />
    );
  }

  return null;
};
