import { setBalanceAction } from 'core/widgets/User/actions';
import { put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { OnPlayerLostData } from '../types';

export function* onPlayerLostSaga({ type: notificationType, data: result }: OnPlayerLostData) {
  if (notificationType === Notifications.PLAYER_LOST) {
    const {
      user: { balance },
    } = result;
    yield put(setBalanceAction(balance));
  }
}
