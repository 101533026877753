export enum ToastTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  DEFAULT = 'DEFAULT',
}

export enum ToastDisplayTime {
  ROUND_STATUS = 3000,
  BETS_VALIDATION = 3000,
}

export type ToastValueType = {
  text: string;
  displayTime: ToastDisplayTime;
};

export type ToastMessagesType = {
  [key: string]: ToastValueType;
};
