import { call, put } from 'redux-saga/effects';
// import { ActionType } from 'typesafe-actions';
import { RestService } from 'services/RestAPIService';
import { setHistory } from '../actions';

export function* getHistorySaga() {
  try {
    const { data } = yield call(() => RestService.getHistory());
    yield put(setHistory({ history: data }));
  } catch (err) {
    // TODO: handle errors and show notifications
    console.log(err);
  }
}
