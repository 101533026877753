import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { SlotBlueMaximizedMobile, SlotBlueMinimizedMobile } from './components';

export const SlotBlueMobile = (props: ISlotProps) => {
  const { isMinimized } = props;
  return isMinimized ? (
    <SlotBlueMinimizedMobile {...props} />
  ) : (
    <SlotBlueMaximizedMobile {...props} />
  );
};
