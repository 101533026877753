import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { ISlotProps } from 'widgets/Slots/types';
import { SlotRedLandscape } from './SlotRed.landscape';
import { SlotRedMobile } from './SlotRed.mobile';
import { SlotRedTablet } from './SlotRed.tablet';

export const SlotRedResponsive = (props: ISlotProps) => (
  <>
    <DesktopComponent>
      <SlotRedLandscape {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <SlotRedLandscape {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <SlotRedTablet {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <SlotRedMobile {...props} />
    </MobileComponent>
  </>
);
