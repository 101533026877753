import styled from 'styled-components';

export const CounterRowStyled = styled.svg``;

export const ContentWrapper = styled.svg`
  overflow: visible;
`;

export const Text = styled.text`
  font-size: 14px;
  fill: #e7d5b9;
`;

export const TextMobile = styled.text`
  font-size: 8px;
  fill: #e7d5b9;
`;

export const BallWrapper = styled.svg``;
