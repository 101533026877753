import React from 'react';
// import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';

import { Device } from 'types';
import { HistoryTable } from './HistoryTable';
import { HistoryTableMobile } from './HistoryTable.mobile';

export const HistoryTableResponsive = () => (
  <>
    <DesktopComponent>
      <HistoryTable deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <HistoryTable deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <HistoryTableMobile deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <HistoryTableMobile deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
