import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { IBaseSvgParams } from 'types';

export interface IGroup extends IBaseSvgParams {
  children: ReactNode;
}

const GroupStyled = styled.svg``;

export const Group = ({
  position = [0, 0],
  size = { width: 17, height: 17 },
  children,
}: IGroup) => {
  const [positionX, positionY] = position;

  return (
    <GroupStyled
      width={size.width}
      height={size.height}
      x={positionX}
      y={positionY}
      preserveAspectRatio="none"
      viewBox={`0 0 ${size.width} ${size.height}`}
      xmlns="http://www.w3.org/2000/svg">
      <g fill="transparent" stroke="transparent">
        {children}
      </g>
    </GroupStyled>
  );
};
