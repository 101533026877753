import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { Device, SLOTS } from 'types';
import { Wrapper } from './styles';
import { ISlotInnerPositionsConfig, SlotContent } from '../SlotContent';

export const SlotRedLandscape = ({
  activeChip,
  bet,
  isBettingTime,
  isWinner,
  hasPlacedBet,
  isCurrent,
  isMinimized,
  size = { height: 121, width: 382 },
  isActiveJP,
  onClick,
  onBetConfirm,
  onBetClear,
  onErrorClick,
}: ISlotProps) => {
  const minimizedInnerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '305',
      positionY: '4',
      size: 27,
    },
    confirmBet: {
      positionX: '279',
      positionY: '-18',
    },
  };

  const maximizedInnerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '65',
      positionY: '60',
      size: 40,
    },
    confirmBet: {
      positionX: '279',
      positionY: '-18',
    },
  };

  return (
    <Wrapper
      $activeChip={activeChip}
      $isActive={hasPlacedBet}
      $isBettingTime={isBettingTime}
      $isWinner={isWinner}
      onClick={isBettingTime ? onClick : onErrorClick}
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 382 121"
      x="624"
      y="0"
      fill="none">
      {isMinimized ? (
        <>
          <g filter="url(#filter0_bi_513_4205)">
            <path
              d="M381.249 19.5C381.249 12.8395 378.365 6.32182 372.845 0H0L12.4813 44L13.0486 46L13.6159 48L34.2494 120.739C193.184 109.714 318.125 82.3429 363.054 48C363.922 47.3359 364.761 46.6692 365.57 46C366.372 45.3358 367.145 44.6691 367.889 44C376.627 36.1339 381.249 27.9314 381.249 19.5Z"
              fill="#28080B"
            />
            <path
              d="M381.249 19.5C381.249 12.8395 378.365 6.32182 372.845 0H0L12.4813 44L13.0486 46L13.6159 48L34.2494 120.739C193.184 109.714 318.125 82.3429 363.054 48C363.922 47.3359 364.761 46.6692 365.57 46C366.372 45.3358 367.145 44.6691 367.889 44C376.627 36.1339 381.249 27.9314 381.249 19.5Z"
              fill="url(#paint0_linear_513_4205)"
            />
            <path
              d="M1.32312 1H372.388C377.603 7.08684 380.249 13.2653 380.249 19.5C380.249 27.5372 375.846 35.4909 367.22 43.2568C366.487 43.9162 365.725 44.5739 364.932 45.2296C364.134 45.8903 363.305 46.5489 362.446 47.2055C340.17 64.2334 297.893 79.6264 241.188 92.1371C184.723 104.595 114.095 114.166 34.9899 119.685L14.5779 47.7271L14.0106 45.7271L13.4433 43.7271L1.32312 1Z"
              stroke="url(#paint1_linear_513_4205)"
              strokeWidth="2"
            />
            <path
              d="M1.32312 1H372.388C377.603 7.08684 380.249 13.2653 380.249 19.5C380.249 27.5372 375.846 35.4909 367.22 43.2568C366.487 43.9162 365.725 44.5739 364.932 45.2296C364.134 45.8903 363.305 46.5489 362.446 47.2055C340.17 64.2334 297.893 79.6264 241.188 92.1371C184.723 104.595 114.095 114.166 34.9899 119.685L14.5779 47.7271L14.0106 45.7271L13.4433 43.7271L1.32312 1Z"
              stroke="url(#paint2_linear_513_4205)"
              strokeWidth="2"
            />
          </g>
          <g id="redBets" filter="url(#filter0_bi_3912_6465)">
            <path
              d="M381.249 19.5C381.249 12.8395 378.365 6.32182 372.845 0H0L12.4813 44L13.0486 46L13.6159 48L34.2494 120.739C193.184 109.714 318.125 82.3429 363.054 48C363.922 47.3359 364.761 46.6692 365.57 46C366.372 45.3358 367.145 44.6691 367.889 44C376.627 36.1339 381.249 27.9314 381.249 19.5Z"
              fill="#28080B"
            />
            <path
              d="M381.249 19.5C381.249 12.8395 378.365 6.32182 372.845 0H0L12.4813 44L13.0486 46L13.6159 48L34.2494 120.739C193.184 109.714 318.125 82.3429 363.054 48C363.922 47.3359 364.761 46.6692 365.57 46C366.372 45.3358 367.145 44.6691 367.889 44C376.627 36.1339 381.249 27.9314 381.249 19.5Z"
              fill="url(#paint0_linear_3912_6465)"
            />
            <path
              d="M1.32312 1H372.388C377.603 7.08684 380.249 13.2653 380.249 19.5C380.249 27.5372 375.846 35.4909 367.22 43.2568C366.487 43.9162 365.725 44.5739 364.932 45.2296C364.134 45.8903 363.305 46.5489 362.446 47.2055C340.17 64.2334 297.893 79.6264 241.188 92.1371C184.723 104.595 114.095 114.166 34.9899 119.685L14.5779 47.7271L14.0106 45.7271L13.4433 43.7271L1.32312 1Z"
              stroke="url(#paint1_linear_3912_6465)"
              strokeWidth="2"
            />
            <path
              d="M1.32312 1H372.388C377.603 7.08684 380.249 13.2653 380.249 19.5C380.249 27.5372 375.846 35.4909 367.22 43.2568C366.487 43.9162 365.725 44.5739 364.932 45.2296C364.134 45.8903 363.305 46.5489 362.446 47.2055C340.17 64.2334 297.893 79.6264 241.188 92.1371C184.723 104.595 114.095 114.166 34.9899 119.685L14.5779 47.7271L14.0106 45.7271L13.4433 43.7271L1.32312 1Z"
              stroke="url(#paint2_linear_3912_6465)"
              strokeWidth="2"
            />
          </g>
        </>
      ) : (
        <>
          <g filter="url(#filter0_bi_3770_45703)">
            <path
              d="M381.249 19.5C381.249 12.8395 378.365 6.32182 372.845 0H0L12.4813 44L13.0486 46L13.6159 48L34.2494 120.739C193.184 109.714 318.125 82.3429 363.054 48C363.922 47.3359 364.761 46.6692 365.57 46C366.372 45.3358 367.145 44.6691 367.889 44C376.627 36.1339 381.249 27.9314 381.249 19.5Z"
              fill="#28080B"
            />
            <path
              d="M381.249 19.5C381.249 12.8395 378.365 6.32182 372.845 0H0L12.4813 44L13.0486 46L13.6159 48L34.2494 120.739C193.184 109.714 318.125 82.3429 363.054 48C363.922 47.3359 364.761 46.6692 365.57 46C366.372 45.3358 367.145 44.6691 367.889 44C376.627 36.1339 381.249 27.9314 381.249 19.5Z"
              fill="url(#paint0_linear_3770_45703)"
            />
            <path
              d="M1.32312 1H372.388C377.603 7.08684 380.249 13.2653 380.249 19.5C380.249 27.5372 375.846 35.4909 367.22 43.2568C366.487 43.9162 365.725 44.5739 364.932 45.2296C364.134 45.8903 363.305 46.5489 362.446 47.2055C340.17 64.2334 297.893 79.6264 241.188 92.1371C184.723 104.595 114.095 114.166 34.9899 119.685L14.5779 47.7271L14.0106 45.7271L13.4433 43.7271L1.32312 1Z"
              stroke="url(#paint1_linear_3770_45703)"
              strokeWidth="2"
            />
            <path
              d="M1.32312 1H372.388C377.603 7.08684 380.249 13.2653 380.249 19.5C380.249 27.5372 375.846 35.4909 367.22 43.2568C366.487 43.9162 365.725 44.5739 364.932 45.2296C364.134 45.8903 363.305 46.5489 362.446 47.2055C340.17 64.2334 297.893 79.6264 241.188 92.1371C184.723 104.595 114.095 114.166 34.9899 119.685L14.5779 47.7271L14.0106 45.7271L13.4433 43.7271L1.32312 1Z"
              stroke="url(#paint2_linear_3770_45703)"
              strokeWidth="2"
            />
          </g>
          <g id="redBets" filter="url(#filter0_bi_518_7596)">
            <path
              d="M381.249 19.5C381.249 12.8395 378.365 6.32182 372.845 0H0L12.4813 44L13.0486 46L13.6159 48L34.2494 120.739C193.184 109.714 318.125 82.3429 363.054 48C363.922 47.3359 364.761 46.6692 365.57 46C366.372 45.3358 367.145 44.6691 367.889 44C376.627 36.1339 381.249 27.9314 381.249 19.5Z"
              fill="#28080B"
            />
            <path
              d="M381.249 19.5C381.249 12.8395 378.365 6.32182 372.845 0H0L12.4813 44L13.0486 46L13.6159 48L34.2494 120.739C193.184 109.714 318.125 82.3429 363.054 48C363.922 47.3359 364.761 46.6692 365.57 46C366.372 45.3358 367.145 44.6691 367.889 44C376.627 36.1339 381.249 27.9314 381.249 19.5Z"
              fill="url(#paint0_linear_518_7596)"
            />
            <path
              d="M1.32312 1H372.388C377.603 7.08684 380.249 13.2653 380.249 19.5C380.249 27.5372 375.846 35.4909 367.22 43.2568C366.487 43.9162 365.725 44.5739 364.932 45.2296C364.134 45.8903 363.305 46.5489 362.446 47.2055C340.17 64.2334 297.893 79.6264 241.188 92.1371C184.723 104.595 114.095 114.166 34.9899 119.685L14.5779 47.7271L14.0106 45.7271L13.4433 43.7271L1.32312 1Z"
              stroke="url(#paint1_linear_518_7596)"
              strokeWidth="2"
            />
            <path
              d="M1.32312 1H372.388C377.603 7.08684 380.249 13.2653 380.249 19.5C380.249 27.5372 375.846 35.4909 367.22 43.2568C366.487 43.9162 365.725 44.5739 364.932 45.2296C364.134 45.8903 363.305 46.5489 362.446 47.2055C340.17 64.2334 297.893 79.6264 241.188 92.1371C184.723 104.595 114.095 114.166 34.9899 119.685L14.5779 47.7271L14.0106 45.7271L13.4433 43.7271L1.32312 1Z"
              stroke="url(#paint2_linear_518_7596)"
              strokeWidth="2"
            />
          </g>
        </>
      )}
      <SlotContent
        bet={bet}
        isCurrent={isCurrent}
        onBetConfirm={onBetConfirm}
        onBetClear={onBetClear}
        isMinimized={isMinimized}
        isWinner={isWinner}
        slot={SLOTS.banker}
        deviceType={Device.Desktop}
        hasPlacedBet={hasPlacedBet}
        innerPositionsConfig={
          isMinimized ? minimizedInnerContentPositions : maximizedInnerContentPositions
        }
        size={size}
        isActiveJP={isActiveJP}
        isBettingTime={isBettingTime}
      />
      {isMinimized ? (
        <>
          <defs>
            <filter
              id="filter0_bi_513_4205"
              x="-5.65752"
              y="-5.65752"
              width="392.565"
              height="132.054"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_513_4205"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_513_4205"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_513_4205" />
            </filter>
            <linearGradient
              id="paint0_linear_513_4205"
              x1="190.625"
              y1="0"
              x2="190.625"
              y2="121"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#C8102E" />
              <stop offset="0.450698" stopColor="#C8102E" stopOpacity="0.4" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_513_4205"
              x1="190.625"
              y1="0"
              x2="190.625"
              y2="120.739"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_513_4205"
              x1="477"
              y1="60.3694"
              x2="-110.5"
              y2="60.3694"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF1400" stopOpacity="0" />
              <stop offset="0.5" stopColor="#FF1400" />
              <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
              <stop offset="1" stopColor="#FF1400" stopOpacity="0" />
            </linearGradient>
          </defs>
          <defs id="redBetsDefs">
            <filter
              id="filter0_bi_3912_6465"
              x="-5.65752"
              y="-5.65752"
              width="392.564"
              height="132.053"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3912_6465"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_3912_6465"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3912_6465" />
            </filter>
            <linearGradient
              id="paint0_linear_3912_6465"
              x1="190.625"
              y1="0"
              x2="190.625"
              y2="120.739"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#C8102E" />
              <stop offset="0.520833" stopColor="#C8102E" stopOpacity="0.1" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3912_6465"
              x1="190.625"
              y1="0"
              x2="190.625"
              y2="120.739"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3912_6465"
              x1="477"
              y1="60.3694"
              x2="-110.5"
              y2="60.3694"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF1400" stopOpacity="0" />
              <stop offset="0.5" stopColor="#FF1400" />
              <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
              <stop offset="1" stopColor="#FF1400" stopOpacity="0" />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          {' '}
          <defs>
            <filter
              id="filter0_bi_3770_45703"
              x="-5.65752"
              y="-5.65752"
              width="392.564"
              height="132.054"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3770_45703"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_3770_45703"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3770_45703" />
            </filter>
            <linearGradient
              id="paint0_linear_3770_45703"
              x1="190.625"
              y1="0"
              x2="190.625"
              y2="120.739"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#C8102E" />
              <stop offset="1" stopColor="#C8102E" stopOpacity="0.4" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3770_45703"
              x1="190.625"
              y1="0"
              x2="190.625"
              y2="120.739"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3770_45703"
              x1="477"
              y1="60.3694"
              x2="-110.5"
              y2="60.3694"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF1400" stopOpacity="0" />
              <stop offset="0.5" stopColor="#FF1400" />
              <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
              <stop offset="1" stopColor="#FF1400" stopOpacity="0" />
            </linearGradient>
          </defs>
          <defs id="redBetsDefs">
            <filter
              id="filter0_bi_518_7596"
              x="-5.65752"
              y="-5.65752"
              width="392.565"
              height="132.053"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_518_7596"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_518_7596"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_518_7596" />
            </filter>
            <linearGradient
              id="paint0_linear_518_7596"
              x1="190.625"
              y1="0"
              x2="190.625"
              y2="120.739"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#C8102E" />
              <stop offset="1" stopColor="#C8102E" stopOpacity="0.1" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_518_7596"
              x1="190.625"
              y1="0"
              x2="190.625"
              y2="120.739"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_518_7596"
              x1="477"
              y1="60.3694"
              x2="-110.5"
              y2="60.3694"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF1400" stopOpacity="0" />
              <stop offset="0.5" stopColor="#FF1400" />
              <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
              <stop offset="1" stopColor="#FF1400" stopOpacity="0" />
            </linearGradient>
          </defs>
        </>
      )}
    </Wrapper>
  );
};
