import React from 'react';
import { totalBallsCounterSelector } from 'core/widgets/Scoreboard/BallsCounter';
import { useSelector } from 'react-redux';
import { WinnerBall } from 'constants/common';
import { BallsCounterStyled, ContentWrapper } from './styles';
import { IBallsCounterProps } from './types';
import { BallsCounterRow } from './components/BallsCounterRow';

export const BallsCounterLandscape = ({
  size = { width: 81, height: 102 },
}: IBallsCounterProps) => {
  const ballsCount = useSelector(totalBallsCounterSelector);

  return (
    <BallsCounterStyled
      width={size.width}
      height={size.height}
      viewBox="0 0 81 102"
      y="6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80 1V101H0V1H41.4299H80Z"
        fill="url(#paint0_linear_84_31114)"
        stroke="url(#paint1_linear_84_31114)"
      />
      <mask id="path-2-inside-1_84_31114" fill="white">
        <path d="M0 34H80V35H0V34Z" />
      </mask>
      <path
        d="M0 34V33H-1V34H0ZM80 34H81V33H80V34ZM80 35V36H81V35H80ZM0 35H-1V36H0V35ZM0 35H80V33H0V35ZM79 34V35H81V34H79ZM80 34H0V36H80V34ZM1 35V34H-1V35H1Z"
        fill="url(#paint2_linear_84_31114)"
        mask="url(#path-2-inside-1_84_31114)"
      />
      <mask id="path-4-inside-2_84_31114" fill="white">
        <path d="M0 67H80V68H0V67Z" />
      </mask>
      <path
        d="M0 67V66H-1V67H0ZM80 67H81V66H80V67ZM80 68V69H81V68H80ZM0 68H-1V69H0V68ZM0 68H80V66H0V68ZM79 67V68H81V67H79ZM80 67H0V69H80V67ZM1 68V67H-1V68H1Z"
        fill="url(#paint3_linear_84_31114)"
        mask="url(#path-4-inside-2_84_31114)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_84_31114"
          x1="40"
          y1="101"
          x2="40"
          y2="1"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_84_31114"
          x1="0"
          y1="51"
          x2="80"
          y2="51"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_84_31114"
          x1="80"
          y1="34.5"
          x2="0"
          y2="34.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#121011" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#121011" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_84_31114"
          x1="80"
          y1="67.5"
          x2="0"
          y2="67.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#121011" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#121011" />
        </linearGradient>
      </defs>
      <ContentWrapper width="80" height="100" viewBox="0 0 80 100">
        <BallsCounterRow ballType={WinnerBall.BLUE} quantity={ballsCount.BLUE} />
        <BallsCounterRow ballType={WinnerBall.GOLD} quantity={ballsCount.GOLD} position={[0, 34]} />
        <BallsCounterRow ballType={WinnerBall.RED} quantity={ballsCount.RED} position={[0, 68]} />
      </ContentWrapper>
    </BallsCounterStyled>
  );
};
