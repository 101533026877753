import { Notifications } from 'types';
import {
  setCountdownJackpot,
  setIsUserWonInJP,
  setJackpotWon,
  setStageJackpot,
  setShowJPPopup,
  setJackpotWonWinners,
  setInfoJackpotDraw,
  setInfoDisplayingJackpotWin,
} from 'core/widgets/Jackpot/actions';
import { delay, put, select } from 'redux-saga/effects';
import {
  currentJackpotSelector,
  jackpotInfoWinningDrawSelector,
} from 'core/widgets/Jackpot/selectors';
import { OnJackpotFinishedData } from '../../types';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { IPlayerJP } from 'core/widgets/Jackpot/types';

// eslint-disable-next-line require-yield
export function* onJackpotFinishesSaga({
  type: notificationType,
  data: result,
}: OnJackpotFinishedData) {
  const { players, amount, currency } = result;
  const { jackpotOn } = yield select(authInfoSelector);
  const { winDuration } = yield select(jackpotInfoWinningDrawSelector);

  const { uuid } = yield select(currentJackpotSelector);

  if (!jackpotOn && players?.length) {
    yield put(
      setJackpotWonWinners({
        players,
      }),
    );
    return;
  }

  const player = players?.find(({ uuid: playerUid }: IPlayerJP) => playerUid === uuid);

  if (notificationType === Notifications.JACKPOT_FINISHED) {
    yield put(setIsUserWonInJP(Boolean(player)));

    if (players?.length) {
      yield put(setShowJPPopup(true));
    }

    yield put(
      setInfoJackpotDraw({
        jackpotAmount: amount,
        jackpotCurrency: currency,
      }),
    );
    yield put(
      setJackpotWon({
        amount: player?.amount || amount,
        currency: player?.currency || currency,
        players: null,
      }),
    );
    yield put(setStageJackpot(null));
    yield put(setCountdownJackpot(null));
    yield put(manageSettingsPanelAction({ settingsPanel: null }));
    // -------

    yield delay(players?.length ? ((winDuration as number) - 1) * 1000 || 3000 : 0);

    yield put(setIsUserWonInJP(false));
    yield put(setShowJPPopup(false));

    // -------
    yield put(
      setJackpotWon({
        amount: player?.amount || amount,
        currency: player?.currency || currency,
        players,
      }),
    );

    yield put(
      setInfoJackpotDraw({
        jackpotAmount: null,
        jackpotCurrency: null,
      }),
    );
    yield put(
      setInfoDisplayingJackpotWin({
        winDuration: null,
      }),
    );
  }
}
