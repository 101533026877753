import React from 'react';
import generateShortId from 'utils/generateShortId';
import { BeadRoadChildStyled } from './styles';
import { IBeadRoadItem } from './types';

export const RedItem = ({ size = { width: 12, height: 12 }, position = [2, 2] }: IBeadRoadItem) => {
  const [positionX, positionY] = position;
  const id = generateShortId();

  return (
    <BeadRoadChildStyled
      width={size.width}
      height={size.height}
      viewBox="0 0 12 12"
      x={positionX}
      y={positionY}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.32935" cy="5.99609" r="5.5" fill="#C8102E" />
      <circle cx="6.32935" cy="5.99609" r="5.5" fill={`url(#paint0_radial_1_36-${id})`} />

      <defs>
        <radialGradient
          id={`paint0_radial_1_36-${id}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.39185 4.62109) rotate(45) scale(4.37522)">
          <stop stopColor="#FFC1CB" />
          <stop offset="0.473958" stopColor="#C8102E" />
          <stop offset="1" stopColor="#880016" />
        </radialGradient>
      </defs>
    </BeadRoadChildStyled>
  );
};
