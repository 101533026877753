export const roundToFixed = (value: number, precision: number) => {
  const numberString = value.toString();

  const decimalIndex = numberString.indexOf('.');

  if (decimalIndex === -1) {
    return value;
  }

  const integerPart = numberString.slice(0, decimalIndex);
  const decimalPart = numberString.slice(decimalIndex + 1, decimalIndex + 3);

  const truncatedString = integerPart + '.' + decimalPart;

  return parseFloat(truncatedString);
};

export const roundToFixed2 = (value: number, precision: number) => {
  const multiplier = 10 ** precision;
  return (Math.round(value * multiplier) / multiplier).toFixed(2);
};

export const getIntegerHandler = (int: number): string => (int ? int.toFixed(2) : (0).toFixed(2));

export const preciseAddSubtract = (num1: number, num2: number, operation: 'add' | 'subtract') => {
  const decimalPlaces = (num: number) => {
    const match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) return 0;
    return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
  };

  const maxDecimalPlaces = Math.max(decimalPlaces(num1), decimalPlaces(num2));
  const factor = Math.pow(10, maxDecimalPlaces);
  const intNum1 = Math.round(num1 * factor);
  const intNum2 = Math.round(num2 * factor);

  let resultInt;
  if (operation === 'add') {
    resultInt = intNum1 + intNum2;
  } else if (operation === 'subtract') {
    resultInt = intNum1 - intNum2;
  } else {
    throw new Error('Unsupported operation');
  }

  const result = resultInt / factor;
  return result;
};
