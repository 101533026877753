import React from 'react';
import { WinnerBall } from 'constants/common';
import { PredictionShortcut } from './components/PredictionShortcut';
import { PredictionSectionStyled } from './styles';
import { IPredictionsSection } from './types';

export const PredictionsSectionTablet = ({
  size = { width: 107, height: 120 },
  position = [90, 0],
  predictWinnerBall,
}: IPredictionsSection) => {
  const [positionX, positionY] = position;

  return (
    <PredictionSectionStyled
      x={positionX}
      y={positionY}
      width={size.width}
      height={size.height}
      viewBox="0 0 107 120"
      xmlns="http://www.w3.org/2000/svg">
      <PredictionShortcut
        predictedBall={WinnerBall.RED}
        position={[0, 20]}
        onClick={() => predictWinnerBall(WinnerBall.RED)}
      />
      <PredictionShortcut
        predictedBall={WinnerBall.BLUE}
        position={[0, 70]}
        onClick={() => predictWinnerBall(WinnerBall.BLUE)}
      />
    </PredictionSectionStyled>
  );
};
