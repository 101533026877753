import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IRoundState } from './types';

const stateOfRoundSelector = (state: IState) => state.roundStateReducer;

export const roundStateSelector = createSelector(
  stateOfRoundSelector,
  (state: IRoundState) => state.roundState,
);
