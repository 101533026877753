import React, { ComponentType, ReactElement } from 'react';
import { WinnerBall } from 'constants/common';
import { BlueItem, GoldItem, RedItem } from './components';
import { IRoadItem } from '../types';

export interface IBeadRoadItem {
  children: IRoadItem;
}

const outcomeBeadRoadChildMap: Record<WinnerBall, ReactElement> = {
  [WinnerBall.RED]: <RedItem />,
  [WinnerBall.BLUE]: <BlueItem />,
  [WinnerBall.GOLD]: <GoldItem />,
};

export const BeadRoadChild: ComponentType<IBeadRoadItem> = ({
  children: { outcome },
}: IBeadRoadItem) => outcomeBeadRoadChildMap[outcome];
