import { CHIPS_COLORS } from './colors';

export const CHIP_05_YELLOW = {
  color: CHIPS_COLORS.YELLOW,
  value: 0.5,
};

export const CHIP_1_RED = {
  color: CHIPS_COLORS.RED,
  value: 1,
};

export const CHIP_2_VIOLET = {
  color: CHIPS_COLORS.VIOLET,
  value: 2,
};

export const CHIP_5_GREEN = {
  color: CHIPS_COLORS.GREEN,
  value: 5,
};

export const CHIP_10_ORANGE = {
  color: CHIPS_COLORS.ORANGE,
  value: 10,
};

export const CHIP_20_LIGHT_BLUE = {
  color: CHIPS_COLORS.LIGHT_BLUE,
  value: 20,
};

export const CHIP_50_SCARLET = {
  color: CHIPS_COLORS.SCARLET,
  value: 50,
};

export const CHIP_100_PURPLE = {
  color: CHIPS_COLORS.PURPLE,
  value: 100,
};

export const CHIP_500_LIGHT_GREEN = {
  color: CHIPS_COLORS.LIGHT_GREEN,
  value: 500,
};

export const CHIP_1K_PINK = {
  color: CHIPS_COLORS.PINK,
  value: 1e3,
};

export const CHIP_2K_TURQUOISE = {
  color: CHIPS_COLORS.TURQUOISE,
  value: 2e3,
};

export const CHIP_5K_PINK = {
  color: CHIPS_COLORS.PINK,
  value: 5e3,
};

export const CHIP_10K_TURQUOISE = {
  color: CHIPS_COLORS.TURQUOISE,
  value: 10e3,
};

export const CHIP_50K_LIGHT_GREEN = {
  color: CHIPS_COLORS.LIGHT_GREEN,
  value: 50e3,
};

export const chipsMIN20 = [
  {
    color: CHIPS_COLORS.YELLOW,
    value: 50,
  },
  {
    color: CHIPS_COLORS.RED,
    value: 100,
  },
  {
    color: CHIPS_COLORS.VIOLET,
    value: 200,
  },
  {
    color: CHIPS_COLORS.GREEN,
    value: 500,
  },
  {
    color: CHIPS_COLORS.ORANGE,
    value: 1e3,
  },
];

export const chipsMIN200 = [
  {
    color: CHIPS_COLORS.YELLOW,
    value: 500,
  },
  {
    color: CHIPS_COLORS.RED,
    value: 1e3,
  },
  {
    color: CHIPS_COLORS.VIOLET,
    value: 2e3,
  },
  {
    color: CHIPS_COLORS.GREEN,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.ORANGE,
    value: 10e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 20e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 50e3,
  },
  {
    color: CHIPS_COLORS.PURPLE,
    value: 100e3,
  },
];

export const chipsMIN2k = [
  {
    color: CHIPS_COLORS.YELLOW,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.RED,
    value: 10e3,
  },
  {
    color: CHIPS_COLORS.VIOLET,
    value: 20e3,
  },
  {
    color: CHIPS_COLORS.GREEN,
    value: 50e3,
  },
  {
    color: CHIPS_COLORS.ORANGE,
    value: 100e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 200e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 500e3,
  },
  {
    color: CHIPS_COLORS.PURPLE,
    value: 1e6,
  },
];

export const chipsMIN02 = [
  CHIP_05_YELLOW,
  CHIP_1_RED,
  CHIP_2_VIOLET,
  CHIP_5_GREEN,
  CHIP_10_ORANGE,
  CHIP_20_LIGHT_BLUE,
  CHIP_50_SCARLET,
  CHIP_100_PURPLE,
  CHIP_500_LIGHT_GREEN,
  CHIP_1K_PINK,
  CHIP_2K_TURQUOISE,
];

export const chipsMAX1 = [CHIP_5K_PINK, CHIP_10K_TURQUOISE];
