import React from 'react';
import { Icon, IconNames } from 'components';
import styled, { css } from 'styled-components';

interface IButtonNavProps {
  type: 'prev' | 'next';
  disabled?: boolean;
  onClick: () => void;
  className?: string;
}

export const ButtonNav = ({ type, disabled = false, onClick, className }: IButtonNavProps) => (
  <Wrapper className={className} disabled={disabled}>
    <IconStyled
      name={type === 'prev' ? IconNames.ArrowLeft : IconNames.ArrowRight}
      onClick={disabled ? undefined : onClick}
    />
  </Wrapper>
);

const Wrapper = styled.div<{ disabled: boolean }>`
  user-select: none;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        opacity: 0.25;
        cursor: auto;
      `;
    }

    return css`
      cursor: pointer;
    `;
  }}
`;

const IconStyled = styled(Icon)`
  height: 20rem;
  width: 20rem;
`;
