import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BasePredictionShortcut } from './PredictionShortcut.base';
import { PredictionShortcutMobile } from './PredictionShortcut.mobile';
import { IPredictionShortcutProps } from './types';

export const PredictionShortcut = (props: IPredictionShortcutProps) => (
  <>
    <DesktopComponent>
      <BasePredictionShortcut {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BasePredictionShortcut {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BasePredictionShortcut {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <PredictionShortcutMobile {...props} />
    </MobileComponent>
  </>
);
