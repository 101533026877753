import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IGameRules } from './types';

const gameRulesSelector = (state: IState) => state.gameRulesReducer;

export const gameRuleSelector = createSelector(
  gameRulesSelector,
  ({ rules, jackpotRules, isShowJackpotRule }: IGameRules) => ({
    rules,
    jackpotRules,
    isShowJackpotRule,
  }),
);
