import React from 'react';
import { winStatusColorsMobile } from '../utils/getColorForWinStatus';
import { IGradientProps } from '../types';

export const GradientsMobile = ({ winnerBall }: IGradientProps) => (
  <defs>
    <filter
      id="filter0_f_85_31142"
      x="158"
      y="24.001"
      width="25.377"
      height="25.3765"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_85_31142" />
    </filter>
    <filter
      id="filter1_f_85_31142"
      x="160"
      y="34.75"
      width="24"
      height="15.25"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_85_31142" />
    </filter>
    <filter
      id="winner_circle_glare_primary"
      x="167.445"
      y="28.4443"
      width="15.2227"
      height="15.2222"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_85_31142" />
    </filter>
    <filter
      id="winner_circle_glare_secondary"
      x="168.557"
      y="28.4443"
      width="13"
      height="13"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_85_31142" />
    </filter>
    <linearGradient
      id="paint0_linear_85_31142"
      x1="0"
      y1="103"
      x2="344"
      y2="103"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#121011" stopOpacity="0" />
      <stop offset="0.25" stopColor="#121011" />
      <stop offset="0.65" stopColor="#121011" />
      <stop offset="1" stopColor="#121011" stopOpacity="0" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_85_31142"
      x1="344"
      y1="103"
      x2="0"
      y2="103"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#F1E6D2" stopOpacity="0" />
      <stop offset="0.5" stopColor="#F1E6D2" />
      <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_85_31142"
      x1="160.276"
      y1="60"
      x2="160.276"
      y2="1.37932"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#F1E6D2" stopOpacity="0" />
      <stop offset="0.38864" stopColor="#F1E6D2" />
    </linearGradient>
    <linearGradient
      id="winner_main_background"
      x1="172"
      y1="2.75781"
      x2="172"
      y2="71.3785"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#121011" stopOpacity="0" />
      <stop offset="1" stopColor={winStatusColorsMobile.mainBackground[winnerBall]} />
    </linearGradient>
    <linearGradient
      id="paint4_linear_85_31142"
      x1="172.143"
      y1="76.5796"
      x2="172.143"
      y2="22.2331"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#F1E6D2" />
      <stop offset="0.168908" stopColor="#F1E6D2" stopOpacity="0.1" />
      <stop offset="1" stopColor="#F1E6D2" />
    </linearGradient>
    <radialGradient
      id="winner_ball_background"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(172 38) rotate(46.3972) scale(8.05555)">
      <stop stopColor={winStatusColorsMobile.circleBackground[winnerBall]} />
      <stop offset="0.934818" stopColor={winStatusColorsMobile.mainBackground[winnerBall]} />
    </radialGradient>
    <radialGradient
      id="winner_ball_top_shining"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(171.999 38.0013) rotate(-133.597) scale(8.0555 8.05542)">
      <stop stopColor={winStatusColorsMobile.shiningPrimaryColorTop[winnerBall]} />
      <stop
        offset="0.934818"
        stopColor={winStatusColorsMobile.shiningSecondaryColorTop[winnerBall]}
      />
    </radialGradient>
    <radialGradient
      id="winner_ball_bottom_shining"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(172 38) rotate(46.3972) scale(8.05555)">
      <stop stopColor={winStatusColorsMobile.shiningPrimaryColorBottom[winnerBall]} />
      <stop
        offset="0.973958"
        stopColor={winStatusColorsMobile.shiningSecondaryColorBottom[winnerBall]}
      />
    </radialGradient>
    <linearGradient
      id="paint8_linear_85_31142"
      x1="202.718"
      y1="56"
      x2="202.718"
      y2="71.4873"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#121011" />
      <stop offset="1" stopColor="#121011" />
    </linearGradient>
    <linearGradient
      id="winner_label_background"
      x1="95.3937"
      y1="64.9999"
      x2="241.205"
      y2="64.9999"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#343434" stopOpacity="0" />
      <stop offset="0.505822" stopColor={winStatusColorsMobile.mainBackground[winnerBall]} />
      <stop offset="1" stopColor="#343434" stopOpacity="0" />
    </linearGradient>
    <linearGradient
      id="paint10_linear_85_31142"
      x1="190.615"
      y1="77.5"
      x2="190.615"
      y2="39.9459"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#F1E6D2" stopOpacity="0" />
      <stop offset="0.339907" stopColor="#F1E6D2" />
      <stop offset="0.802083" stopColor="#F1E6D2" stopOpacity="0.5" />
    </linearGradient>
  </defs>
);
