import React from 'react';
import { Device } from 'types';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { StageText, Wrapper } from './styles';
import { IDeviceCountdownStageProps } from './types';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import moment, { Moment } from 'moment';

export const BaseCountdownStage = ({
  stage,
  dateTimer,
  deviceType = Device.Desktop,
}: IDeviceCountdownStageProps) => {
  const { t, i18n } = useTranslation();
  return (
    <Wrapper $deviceType={deviceType}>
      {stage && (
        <StageText>
          {t('settings.jackpot.stage')} {stage.stageNumber}
        </StageText>
      )}
      {dateTimer && !stage && (
        <Countdown
          date={dateTimer}
          renderer={({ days, hours, minutes, seconds }) => (
            <StageText>
              {hours + days * 24 < 10 ? `0${hours}` : hours + days * 24} :{' '}
              {minutes < 10 ? `0${minutes}` : minutes} : {seconds < 10 ? `0${seconds}` : seconds}
            </StageText>
          )}
        />
      )}
      {(stage || dateTimer) && (
        <svg
          width="112"
          height="38"
          viewBox="0 0 112 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_727_62364)">
            <path
              d="M5 16.6216C5.00014 9.09857 11.0988 3 18.6219 3H91.8743C99.8153 3 106.253 9.43742 106.253 17.3784V17.3784V17.3784C106.253 24.9014 100.154 31 92.6311 31H19.3786C11.4376 31 5.00014 24.5626 5 16.6216V16.6216V16.6216Z"
              fill="#121011"
            />
            <path
              d="M5 16.6216C5.00014 9.09857 11.0988 3 18.6219 3H91.8743C99.8153 3 106.253 9.43742 106.253 17.3784V17.3784V17.3784C106.253 24.9014 100.154 31 92.6311 31H19.3786C11.4376 31 5.00014 24.5626 5 16.6216V16.6216V16.6216Z"
              fill="url(#paint0_linear_727_62364)"
              fillOpacity="0.8"
            />
            <path
              d="M5 16.6216C5.00014 9.09857 11.0988 3 18.6219 3H91.8743C99.8153 3 106.253 9.43742 106.253 17.3784V17.3784V17.3784C106.253 24.9014 100.154 31 92.6311 31H19.3786C11.4376 31 5.00014 24.5626 5 16.6216V16.6216V16.6216Z"
              stroke="#F1E6D2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="0.1 4"
            />
          </g>

          <defs>
            <filter
              id="filter0_d_727_62364"
              x="0"
              y="0"
              width="111.254"
              height="38"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0705882 0 0 0 0 0.0627451 0 0 0 0 0.0666667 0 0 0 0.4 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_727_62364"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_727_62364"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_727_62364"
              x1="55.626"
              y1="-7.11111"
              x2="55.626"
              y2="38"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#121011" stopOpacity="0" />
              <stop offset="0.437411" stopColor="#9A00C1" />
              <stop offset="0.632494" stopColor="#5B10F2" />
              <stop offset="1" stopColor="#121011" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </Wrapper>
  );
};
