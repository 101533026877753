import { applyMiddleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import { configureStore, Tuple } from '@reduxjs/toolkit';

import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';
import { IState } from './reduxTypes';

const sagaMiddleware = createSagaMiddleware();

export const store: Store<IState> = configureStore({
  reducer: rootReducer,
  // composeWithDevTools(applyMiddleware(sagaMiddleware)),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
});

export const initialRootState = {
  ...store.getState(),
};

sagaMiddleware.run(rootSaga);
