import React from 'react';
import { Device } from 'types';
import { MobileComponent, TabletPortraitComponent } from 'components';
import { IMainSettingsPanelComponentProps } from './types';
import { MainSettingsPanelPortrait } from './MainSettingsPanel.portrait';

export const MainSettingsPanel = (props: IMainSettingsPanelComponentProps) => (
  <>
    <TabletPortraitComponent>
      <MainSettingsPanelPortrait {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <MainSettingsPanelPortrait {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
