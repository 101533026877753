import { eventChannel, END, EventChannel } from 'redux-saga';
import { chatWebSocketService } from 'services/ChatWebSocketService';
import { webSocketService } from 'services/WebSocketService';
import { Notifications, NotificationsChat } from 'types';

export function createWebSocketChannel(args: Notifications[]): EventChannel<any> {
  return eventChannel((emitter) => {
    const removeListener = webSocketService.onMessage((notification) => {
      emitter(notification);
    }, args[0]);

    return () => {
      removeListener();
      emitter(END);
    };
  });
}

export function createChatWebSocketChannel(args: NotificationsChat[]): EventChannel<any> {
  return eventChannel((emitter) => {
    const removeListener = chatWebSocketService.onMessage((notification) => {
      emitter(notification);
    }, args[0]);

    return () => {
      removeListener();
      emitter(END);
    };
  });
}
