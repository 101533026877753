import React from 'react';
import { MobileComponent, TabletPortraitComponent } from 'components';
import { IMobileButtonProps } from '../types';
import { LeftSideTabletButton } from './LeftSideTabletButton';
import { LeftSideMobileButton } from './LeftSideMobileButton';

export const LeftSideButton = (props: IMobileButtonProps) => (
  <>
    <TabletPortraitComponent>
      <LeftSideTabletButton {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <LeftSideMobileButton {...props} />
    </MobileComponent>
  </>
);
