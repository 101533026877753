import {
  AspectRatioIphone,
  getAspectRatioIphone,
  isInIframe,
  isOldBrowserIOS,
} from 'hooks/getDefineDevEnv';
import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid;
  border-image-source: linear-gradient(
    270deg,
    rgba(241, 230, 210, 0) 35%,
    #f1e6d2 50%,
    rgba(241, 230, 210, 0) 65%
  );
  border-image-slice: 1;

  ${({ theme: { background } }) => css`
    background-color: ${background.primary};
  `}
`;

export const InnerWrapper = styled.div`
  position: relative;
  height: 80rem;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;

export const PortraitInnerWrapper = styled.div`
  position: absolute;
  top: 22rem;
  bottom: 22rem;
  left: 0;
  right: 0;
  width: 100%;
`;

export const PortraitBetPanelStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
`;

export const PortraitWrapper = styled.div<{ $deviceType: Device }>`
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 4;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        height: 576rem;
        top: 424rem;

        ${PortraitInnerWrapper} {
          top: 30rem;
          bottom: 30rem;
        }

        ${PortraitBetPanelStyled} {
          height: 80rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? isInIframe()
            ? '206rem'
            : '183rem'
          : '213rem'};
        height: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? isInIframe()
            ? '375rem'
            : '354rem'
          : '387rem'};

        ${PortraitInnerWrapper} {
          top: 22rem;
          bottom: 22rem;
        }

        ${PortraitBetPanelStyled} {
          height: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
            ? '42rem'
            : '52rem'};
        }
      `;
    }
  }}
`;
