import React from 'react';
import generateShortId from 'utils/generateShortId';
import { BeadRoadChildStyled } from './styles';
import { IBeadRoadItem } from './types';

export const GoldItem = ({
  size = { width: 12, height: 12 },
  position = [2, 2],
}: IBeadRoadItem) => {
  const [positionX, positionY] = position;
  const id = generateShortId();

  return (
    <BeadRoadChildStyled
      width={size.width}
      height={size.height}
      viewBox="0 0 12 12"
      x={positionX}
      y={positionY}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.32935" cy="6.49609" r="5.5" fill="#FEDB00" />
      <circle cx="6.32935" cy="6.49609" r="5.5" fill={`url(#paint0_radial_2881_76873-${id})`} />
      <defs>
        <radialGradient
          id={`paint0_radial_2881_76873-${id}`}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.39185 5.12109) rotate(63.435) scale(3.84324)">
          <stop stopColor="#FFF5B9" />
          <stop offset="0.505208" stopColor="#FEDB00" />
          <stop offset="1" stopColor="#D5B700" />
        </radialGradient>
      </defs>
    </BeadRoadChildStyled>
  );
};
