interface ICrossBrowserFullScreenDocument extends Document {
  webkitExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  msExitFullscreen?: () => void;
}

const closeFullScreen = () => {
  const documentElem = document as ICrossBrowserFullScreenDocument;

  if (documentElem.exitFullscreen) {
    documentElem.exitFullscreen();
    return;
  }
  if (documentElem.webkitExitFullscreen) {
    /* Safari */
    documentElem.webkitExitFullscreen();
    return;
  }
  if (documentElem.mozCancelFullScreen) {
    /* Mozilla */
    documentElem.mozCancelFullScreen();
    return;
  }
  if (documentElem.msExitFullscreen) {
    /* IE11 */
    documentElem.msExitFullscreen();
  }
};

export default closeFullScreen;
