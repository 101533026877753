import React, { ComponentType } from 'react';
import { Circle } from 'widgets/Scoreboard/BaseComponents';
import { WinnerBall } from 'constants/common';
import { IRoadItem } from '../types';

export interface ISmallRoadItem {
  children: IRoadItem;
}

export const SmallRoadChild: ComponentType<ISmallRoadItem> = ({
  children: { outcome },
}: ISmallRoadItem) =>
  outcome !== WinnerBall.GOLD ? <Circle outcomeBall={outcome} isPainted /> : null;
