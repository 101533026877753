import React from 'react';
import { TabletLeftButtonWrapper, TabletLeftButtonStyled } from './styles';
import { IMobileButtonProps } from '../types';
import { IconWrapper } from '../styles';

export const LeftSideTabletButton = ({ children, isDisabled, onClick }: IMobileButtonProps) => (
  <TabletLeftButtonWrapper>
    <TabletLeftButtonStyled
      $isDisabled={isDisabled}
      width="100"
      height="52"
      viewBox="0 0 100 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g onClick={onClick}>
        <path
          id="ltb_path_background"
          d="M0.5 0.5H51.7811L98.858 51.5H0.5V0.5Z"
          fill="url(#paint0_linear_64_30991)"
          stroke="url(#paint1_linear_64_30991)"
        />
        <path id="ltb_path_inner_border" d="M0.5 0.5H51.7811L98.858 51.5H0.5V0.5Z" stroke="none" />
        <path id="ltb_path_outer_border" d="M0.5 0.5H51.7811L98.858 51.5H0.5V0.5Z" stroke="none" />
        <IconWrapper
          x="24"
          y="16"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          {children}
        </IconWrapper>
      </g>

      <defs>
        <linearGradient
          id="paint0_linear_64_30991"
          x1="34"
          y1="52"
          x2="34"
          y2="0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_64_30991"
          x1="-34"
          y1="26"
          x2="102"
          y2="26"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>

        <linearGradient
          id="paint0_linear_64_31075"
          x1="50"
          y1="0"
          x2="50"
          y2="52"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#1B191A" />
          <stop offset="1" stopColor="#BB9370" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_64_31075"
          x1="50"
          y1="0"
          x2="50"
          y2="52"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_64_31075"
          x1="100"
          y1="26"
          x2="0"
          y2="26"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4BB93" stopOpacity="0" />
          <stop offset="0.5" stopColor="#D4BB93" />
          <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
        </linearGradient>
      </defs>
    </TabletLeftButtonStyled>
  </TabletLeftButtonWrapper>
);
