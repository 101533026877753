import React from 'react';
import { TabletRightButtonWrapper, TabletRightButtonStyled } from './styles';
import { IMobileButtonProps } from '../types';
import { IconWrapper } from '../styles';

export const RightSideTabletButton = ({ children, isDisabled, onClick }: IMobileButtonProps) => (
  <TabletRightButtonWrapper>
    <TabletRightButtonStyled
      $isDisabled={isDisabled}
      width="100"
      height="52"
      viewBox="0 0 100 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g onClick={!isDisabled ? onClick : undefined}>
        <path
          id="rtb_path_background"
          d="M1.14199 51.5L48.2189 0.5H99.5V51.5H1.14199Z"
          fill="url(#paint0_linear_64_31029)"
          stroke="url(#paint1_linear_64_31029)"
        />

        <path
          id="rtb_path_inner_border"
          d="M1.14199 51.5L48.2189 0.5H99.5V51.5H1.14199Z"
          stroke="none"
        />
        <path
          id="rtb_path_outer_border"
          d="M1.14199 51.5L48.2189 0.5H99.5V51.5H1.14199Z"
          stroke="none"
        />
        <IconWrapper
          x="56"
          y="16"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          {children}
        </IconWrapper>
      </g>

      <defs>
        <linearGradient
          id="paint0_linear_64_31029"
          x1="66"
          y1="52"
          x2="66"
          y2="0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_64_31029"
          x1="-2"
          y1="26"
          x2="134"
          y2="26"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>

        <linearGradient
          id="paint0_linear_64_31067"
          x1="50"
          y1="0"
          x2="50"
          y2="52"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#1B191A" />
          <stop offset="1" stopColor="#BB9370" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_64_31067"
          x1="50"
          y1="0"
          x2="50"
          y2="52"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_64_31067"
          x1="100"
          y1="26"
          x2="0"
          y2="26"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4BB93" stopOpacity="0" />
          <stop offset="0.5" stopColor="#D4BB93" />
          <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
        </linearGradient>
      </defs>
    </TabletRightButtonStyled>
  </TabletRightButtonWrapper>
);
