import styled from 'styled-components';

export const Wrapper = styled.svg``;

export const BetClearButton = styled.svg`
  cursor: pointer;
`;

export const BetConfirmButton = styled.svg`
  cursor: pointer;
`;
