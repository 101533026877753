import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IBetConfirmationState } from './types';

export const betConfirmationRootSelector = (state: IState) => state.betConfirmationReducer;

export const confirmationSlotSelector = createSelector(
  betConfirmationRootSelector,
  (state: IBetConfirmationState) => state.position.current,
);

export const previousConfirmationSlotSelector = createSelector(
  betConfirmationRootSelector,
  (state: IBetConfirmationState) => state.position.previous,
);
