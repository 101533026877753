export enum ACTIONS {
  SET_STREAM_NAMES = 'SET_STREAM_NAMES',
  CHANGE_PLAYBACK = 'CHANGE_PLAYBACK',
  APPLY_PLAYBACK = 'APPLY_PLAYBACK',
  CHANGE_AUTO_ADJUST = 'CHANGE_AUTO_ADJUST',
  APPLY_AUTO_ADJUST = 'APPLY_AUTO_ADJUST',
  CHANGE_QUALITY = 'CHANGE_QUALITY',
  CHANGE_QUALITY_FAIL = 'CHANGE_QUALITY_FAIL',
  APPLY_QUALITY = 'APPLY_QUALITY',
}

export enum VideoQualities {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export type StreamNamesType = { [key in VideoQualities]: string };

export interface IVideoSettingsState {
  streamNames: StreamNamesType | null;
  requested: {
    playback: boolean;
    autoAdjust: boolean;
    quality: VideoQualities;
  };
  applied: {
    playback: boolean | null;
    autoAdjust: boolean | null;
    quality: VideoQualities;
  };
  isQualityLoading: boolean;
}
