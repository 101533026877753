import React from 'react';
import { transformToCurrency } from 'utils/transformToCurrency';
import { BetValueInnerWrapper, BetValue, BetValueWrapper, ChipStyled, Wrapper } from './styles';
import { ISlotBetProps } from './types';

export const BaseSlotBet = ({
  bet,
  x,
  y,
  size = 40,
  isActiveJP,
  value,
  color,
  slotId,
}: ISlotBetProps) => (
  <Wrapper x={x} y={y} width={size} height={size} viewBox="0 0 40 40">
    <ChipStyled
      color={color}
      size={{ width: 40, height: 40 }}
      isActiveJP={isActiveJP}
      slotId={slotId}
    />
    <foreignObject x="0" y="0" height="40" width="40" overflow="visible">
      <BetValueWrapper>
        <BetValueInnerWrapper>
          <BetValue>{transformToCurrency({ value: bet, showCurrency: false })}</BetValue>
        </BetValueInnerWrapper>
      </BetValueWrapper>
    </foreignObject>
  </Wrapper>
);
