import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IBettingTime } from './types';

const bettingTimeSelector = (state: IState) => state.bettingTimeReducer;

export const bettingTimeEndSelector = createSelector(
  bettingTimeSelector,
  (state: IBettingTime) => state.bettingTimeEnd,
);

export const isTheLast2SecondsInBettingTimeSelector = createSelector(
  bettingTimeSelector,
  (state: IBettingTime) => state.isTheLast2SecondsInBettingTime,
);

export const timeDifferenceUserServerSelector = createSelector(
  bettingTimeSelector,
  (state: IBettingTime) => state.timeDifferenceUserServer,
);

export const bettingTimeSecondsSelector = createSelector(
  bettingTimeSelector,
  (state: IBettingTime) => state.bettingTime,
);

export const timeLeftSelector = createSelector(
  bettingTimeSelector,
  (state: IBettingTime) => state.timeLeft,
);
