import { ActionType, createReducer } from 'typesafe-actions';
import {
  saveBettingTimeToStoreAction,
  setIsTheLast2SecondsInBettingTimeToStoreAction,
  setTimeDifferenceUserServerToStoreAction,
  setTimeLeftToStoreAction,
  decrementTimeLeftAction,
  stopDecrementTimeLeftAction,
  startDecrementTimeLeftAction,
} from './actions';
import { IBettingTime } from './types';

export const defaultBettingTime: IBettingTime = {
  bettingTimeEnd: new Date().toISOString(),
  isTheLast2SecondsInBettingTime: false,
  timeDifferenceUserServer: 0,
  bettingTime: 0,
  timeLeft: 0,
  isDecrementingTimeLeft: false,
};

const actions = {
  saveBettingTimeToStoreAction,
  setIsTheLast2SecondsInBettingTimeToStoreAction,
  setTimeDifferenceUserServerToStoreAction,
  setTimeLeftToStoreAction,
  decrementTimeLeftAction,
  startDecrementTimeLeftAction,
  stopDecrementTimeLeftAction,
};

export const bettingTimeReducer = createReducer<IBettingTime, ActionType<typeof actions>>(
  defaultBettingTime,
)
  .handleAction(
    saveBettingTimeToStoreAction,
    (state, { payload: { bettingTimeEnd, bettingTime } }) => ({
      ...state,
      bettingTimeEnd,
      bettingTime,
      timeLeft: bettingTime,
    }),
  )
  .handleAction(
    setIsTheLast2SecondsInBettingTimeToStoreAction,
    (state, { payload: isTheLast2SecondsInBettingTime }) => ({
      ...state,
      isTheLast2SecondsInBettingTime,
    }),
  )
  .handleAction(
    setTimeDifferenceUserServerToStoreAction,
    (state, { payload: timeDifferenceUserServer }) => ({
      ...state,
      timeDifferenceUserServer,
    }),
  )
  .handleAction(setTimeLeftToStoreAction, (state, { payload: timeLeft }) => ({
    ...state,
    timeLeft,
  }))
  .handleAction(decrementTimeLeftAction, (state) => ({
    ...state,
    timeLeft: state.timeLeft - 1,
  }))
  .handleAction(startDecrementTimeLeftAction, (state) => ({
    ...state,
    isDecrementingTimeLeft: true,
  }))
  .handleAction(stopDecrementTimeLeftAction, (state) => ({
    ...state,
    isDecrementingTimeLeft: false,
  }));
