import React, { useState, useEffect, useCallback } from 'react';
import closeFullScreen from 'utils/closeFullScreen';
import openFullScreen from 'utils/openFullScreen';
import checkFullScreen from 'utils/checkFullScreen';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { useSelector } from 'react-redux';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { FullScreenModeButtonResponsive } from './Responsive';
import AudioService from 'services/AudioService';

export const FullScreenModeButton = () => {
  const [isFullScreenMode, setFullScreenMode] = useState<boolean>(false);
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);

  const { jackpotOn } = useSelector(authInfoSelector);

  const fullScreenHandler = useCallback(() => {
    const isFullscreen = checkFullScreen();

    setFullScreenMode(isFullscreen);
  }, []);

  useEffect(() => {
    const crossbrowserFullscreenEvents = [
      'fullscreenchange',
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'msfullscreenchange',
    ];

    crossbrowserFullscreenEvents.forEach((eventType) =>
      document.addEventListener(eventType, fullScreenHandler),
    );

    return () => {
      crossbrowserFullscreenEvents.forEach((eventType) =>
        document.removeEventListener(eventType, fullScreenHandler),
      );
    };
  }, [fullScreenHandler]);

  const onFullScreenClick = useCallback(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }

    if (!isFullScreenMode) {
      openFullScreen();
      return;
    }
    closeFullScreen();
  }, [gameSoundMute, isFullScreenMode, soundEffects]);

  return (
    <>
      <FullScreenModeButtonResponsive
        onFullScreenClick={onFullScreenClick}
        isActive={isFullScreenMode}
        hasJackpot={jackpotOn}
      />
    </>
  );
};
