export enum ACTIONS {
  GAME_RULES = 'GAME_RULES',
  SAVE_GAME_RULES_TO_STORE = 'SAVE_GAME_RULES_TO_STORE',
  SET_IS_SHOW_JP_RULE = 'SET_IS_SHOW_JP_RULE',
}

export interface IGameRules {
  rules: string;
  jackpotRules?: string;
  isShowJackpotRule: boolean;
}
