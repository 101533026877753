import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { DoubleBetButtonDesktop } from './DoubleBetButton.desktop';
import { DoubleBetButtonPortrait } from './DoubleBetButton.portrait';
import { DoubleBetButtonTabletLandscape } from './DoubleBetButton.tablet.landscape';
import { IDoubleBetButtonProps } from './types';

export const DoubleBetButtonResponsive = (props: IDoubleBetButtonProps) => (
  <>
    <DesktopComponent>
      <DoubleBetButtonDesktop {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <DoubleBetButtonTabletLandscape {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <DoubleBetButtonPortrait {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <DoubleBetButtonPortrait {...props} />
    </MobileComponent>
  </>
);
