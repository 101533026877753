import { ActionType, createReducer } from 'typesafe-actions';
import { manageSettingsPanelAction } from './actions';
import { ISettingsPanelStatusState } from './types';

export const settingsPanelState: ISettingsPanelStatusState = {
  settingsPanel: null,
  hasClickJackpotSlot: false,
};

const actions = {
  manageSettingsPanelAction,
};

export const settingsPanelReducer = createReducer<
  ISettingsPanelStatusState,
  ActionType<typeof actions>
>(settingsPanelState).handleAction(
  manageSettingsPanelAction,
  (_, { payload: { settingsPanel, hasClickJackpotSlot } }) => ({
    settingsPanel,
    hasClickJackpotSlot: hasClickJackpotSlot || false,
  }),
);
