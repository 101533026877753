/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

export const Red = () => (
  <>
    <path
      d="M0 268L464 268V55L0 55L0 268Z"
      fill="url(#paint0_linear_518_7796)"
      fillOpacity="0.64"
    />
    <rect y="55" width="464" height="213" fill="url(#paint1_linear_518_7796)" fillOpacity="0.6" />
    <path
      d="M0.5 264.5L463.5 264.5V58.5L0.5 58.5L0.5 264.5Z"
      stroke="url(#paint2_linear_518_7796)"
    />
    <path
      d="M232 1.41421L288.586 58L260.586 86H203.414L175.414 58L232 1.41421Z"
      fill="#121011"
      stroke="url(#paint3_linear_518_7796)"
      strokeWidth="2"
    />
    <path d="M232 4L286 58L259 85H205L178 58L232 4Z" fill="url(#paint4_linear_518_7796)" />
    <path
      d="M231.759 17.0137L232.672 47.613L239.034 17.6685L234.468 47.9391L246.075 19.6117L236.178 48.5807L252.656 22.7811L237.746 49.5174L258.566 27.0746L239.121 50.7189L263.614 32.3544L240.26 52.1466L267.638 38.4506L241.125 53.7547L270.509 45.1674L241.689 55.4915L272.134 52.2889L241.934 57.3011L272.462 59.5861L241.853 59.1254L271.482 66.8246L241.446 60.9058L269.224 73.7717L240.728 62.585L265.763 80.2041L239.722 64.1091L261.208 85.9151L238.46 65.429L255.708 90.7211L236.983 66.5024L249.437 94.4676L235.338 67.2947L242.598 97.0342L233.577 67.7805L235.411 98.3385L231.759 67.9442L228.106 98.3385L229.94 67.7805L220.919 97.0342L228.179 67.2947L214.08 94.4676L226.534 66.5024L207.81 90.7211L225.057 65.429L202.309 85.9151L223.795 64.1091L197.754 80.2041L222.789 62.585L194.293 73.7717L222.071 60.9058L192.036 66.8246L221.665 59.1254L191.055 59.5861L221.583 57.3011L191.383 52.2889L221.828 55.4915L193.008 45.1674L222.392 53.7547L195.879 38.4506L223.258 52.1466L199.903 32.3544L224.396 50.7189L204.951 27.0746L225.771 49.5174L210.861 22.7811L227.339 48.5807L217.442 19.6117L229.049 47.9391L224.483 17.6685L230.846 47.613L231.759 17.0137Z"
      fill="url(#paint5_linear_518_7796)"
      fillOpacity="0.15"
    />
    <path
      d="M169.708 93.6757L180.896 82.5H284.096L295.302 94.3149L284.105 105.499L180.897 104.853L169.708 93.6757Z"
      fill="url(#paint6_linear_518_7796)"
    />
    <path
      d="M169.708 93.6757L180.896 82.5H284.096L295.302 94.3149L284.105 105.499L180.897 104.853L169.708 93.6757Z"
      fill="url(#paint7_linear_518_7796)"
    />
    <path
      d="M169.708 93.6757L180.896 82.5H284.096L295.302 94.3149L284.105 105.499L180.897 104.853L169.708 93.6757Z"
      stroke="url(#paint8_linear_518_7796)"
    />
    <circle cx="232" cy="53" r="16" fill="url(#paint9_radial_518_7796)" />
    <g filter="url(#filter0_f_518_7796)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233 38.0004C224.163 38.0004 217 45.1639 217 54.0004C217 58.1632 218.59 61.9546 221.195 64.8008C218.002 61.8756 215.999 57.6714 215.999 52.9994C215.999 44.1629 223.163 36.9994 231.999 36.9994C236.673 36.9994 240.879 39.0034 243.804 42.1991C240.958 39.5915 237.165 38.0004 233 38.0004Z"
        fill="url(#paint10_radial_518_7796)"
      />
    </g>
    <g filter="url(#filter1_f_518_7796)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M247.876 51C246.892 58.8926 240.159 65 232 65C223.841 65 217.108 58.8926 216.124 51C216.042 51.6552 216 52.3227 216 53C216 61.8366 223.163 69 232 69C240.837 69 248 61.8366 248 53C248 52.3227 247.958 51.6552 247.876 51Z"
        fill="url(#paint11_radial_518_7796)"
      />
    </g>
    <g filter="url(#filter2_f_518_7796)">
      <circle cx="236.889" cy="49.8891" r="5.77778" fill="#C8102E" />
    </g>
    <g filter="url(#filter3_f_518_7796)">
      <circle cx="236.889" cy="48.1113" r="4" fill="#FFC1CB" />
    </g>
    <defs>
      <filter
        id="filter0_f_518_7796"
        x="212"
        y="33"
        width="35.8047"
        height="35.8008"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_518_7796" />
      </filter>
      <filter
        id="filter1_f_518_7796"
        x="214"
        y="49"
        width="36"
        height="22"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_518_7796" />
      </filter>
      <filter
        id="filter2_f_518_7796"
        x="227.111"
        y="40.1113"
        width="19.5557"
        height="19.5547"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_518_7796" />
      </filter>
      <filter
        id="filter3_f_518_7796"
        x="228.889"
        y="40.1113"
        width="16"
        height="16"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_518_7796" />
      </filter>
      <linearGradient
        id="paint0_linear_518_7796"
        x1="0"
        y1="161.5"
        x2="464"
        y2="161.5"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#121011" stopOpacity="0" />
        <stop offset="0.25" stopColor="#121011" />
        <stop offset="0.65" stopColor="#121011" />
        <stop offset="1" stopColor="#121011" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_518_7796"
        x1="232"
        y1="55"
        x2="232"
        y2="268"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.4" stopColor="#121011" />
        <stop offset="1" stopColor="#343434" stopOpacity="0.2" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_518_7796"
        x1="464"
        y1="161.5"
        x2="0"
        y2="161.5"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1E6D2" stopOpacity="0" />
        <stop offset="0.5" stopColor="#F1E6D2" />
        <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_518_7796"
        x1="215"
        y1="87"
        x2="215"
        y2="2.00001"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1E6D2" stopOpacity="0" />
        <stop offset="0.38864" stopColor="#F1E6D2" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_518_7796"
        x1="232"
        y1="4"
        x2="232"
        y2="103.5"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#121011" stopOpacity="0" />
        <stop offset="1" stopColor="#B00000" />
        <stop offset="1" stopColor="#880016" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_518_7796"
        x1="232.206"
        y1="111.039"
        x2="232.206"
        y2="32.2369"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1E6D2" />
        <stop offset="0.168908" stopColor="#F1E6D2" stopOpacity="0.1" />
        <stop offset="1" stopColor="#F1E6D2" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_518_7796"
        x1="274.002"
        y1="82"
        x2="274.002"
        y2="102.65"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#121011" />
        <stop offset="1" stopColor="#121011" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_518_7796"
        x1="129"
        y1="93.9999"
        x2="326"
        y2="93.9999"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" stopOpacity="0" />
        <stop offset="0.505822" stopColor="#B30521" />
        <stop offset="1" stopColor="#343434" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_518_7796"
        x1="257.65"
        y1="106.649"
        x2="257.65"
        y2="60.5946"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.25" stopColor="#F1E6D2" stopOpacity="0" />
        <stop offset="0.520833" stopColor="#F1E6D2" />
        <stop offset="0.802083" stopColor="#F1E6D2" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint9_radial_518_7796"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(232 53) rotate(46.3972) scale(12.8889)">
        <stop stopColor="#C8102E" />
        <stop offset="0.934818" stopColor="#AA001B" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_518_7796"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(231.999 52.9996) rotate(-133.601) scale(12.8889 12.8889)">
        <stop stopColor="#FF5873" />
        <stop offset="0.934818" stopColor="#C8102E" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_518_7796"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(232 53) rotate(46.3972) scale(12.8889)">
        <stop stopColor="#FF526E" />
        <stop offset="0.973958" stopColor="#790014" />
      </radialGradient>
    </defs>
  </>
);
