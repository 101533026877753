import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { Device, SLOTS } from 'types';
import { Wrapper } from '../styles';
import { ISlotInnerPositionsConfig, SlotContent } from '../../SlotContent';

export const SlotBlueMaximizedMobile = ({
  activeChip,
  bet,
  isBettingTime,
  isWinner,
  hasPlacedBet,
  isCurrent,
  isMinimized,
  size = { height: 186, width: 114 },
  isActiveJP,
  onClick,
  onBetConfirm,
  onBetClear,
  onErrorClick,
}: ISlotProps) => {
  const innerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '44',
      positionY: '31',
    },
    confirmBet: {
      positionX: '86',
      positionY: '-18',
    },
  };

  return (
    <Wrapper
      $activeChip={activeChip}
      $isActive={hasPlacedBet}
      $isBettingTime={isBettingTime}
      $isWinner={isWinner}
      onClick={isBettingTime ? onClick : onErrorClick}
      width={size.width}
      height={size.height}
      viewBox="0 0 114 186"
      x="0"
      y="0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_518_9196)">
        <path d="M113 1V184H33C15.3269 184 1 169.673 1 152V1H59.0019H113Z" fill="#28080B" />
        <path
          d="M113 1V184H33C15.3269 184 1 169.673 1 152V1H59.0019H113Z"
          fill="url(#paint0_linear_518_9196)"
        />
      </g>
      <path
        d="M113 1V184H33C15.3269 184 1 169.673 1 152V1H59.0019H113Z"
        stroke="url(#paint1_linear_518_9196)"
      />
      <path
        d="M113 1V184H33C15.3269 184 1 169.673 1 152V1H59.0019H113Z"
        stroke="url(#paint2_linear_518_9196)"
      />

      <g id="blueBets" filter="url(#filter0_i_563_8841)">
        <path d="M113 1V184H33C15.3269 184 1 169.673 1 152V1H59.0019H113Z" fill="#28080B" />
        <path
          d="M113 1V184H33C15.3269 184 1 169.673 1 152V1H59.0019H113Z"
          fill="url(#paint0_linear_563_8841)"
        />
      </g>
      <path
        d="M113 1V184H33C15.3269 184 1 169.673 1 152V1H59.0019H113Z"
        stroke="url(#paint1_linear_563_8841)"
      />
      <path
        d="M113 1V184H33C15.3269 184 1 169.673 1 152V1H59.0019H113Z"
        stroke="url(#paint2_linear_563_8841)"
      />
      <SlotContent
        bet={bet}
        isCurrent={isCurrent}
        onBetConfirm={onBetConfirm}
        onBetClear={onBetClear}
        isMinimized={isMinimized}
        isWinner={isWinner}
        slot={SLOTS.player}
        deviceType={Device.Mobile}
        hasPlacedBet={hasPlacedBet}
        innerPositionsConfig={innerContentPositions}
        size={size}
        isActiveJP={isActiveJP}
        isBettingTime={isBettingTime}
      />
      <defs>
        <filter
          id="filter0_i_518_9196"
          x="0.5"
          y="0.5"
          width="113"
          height="185"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_518_9196" />
        </filter>
        <linearGradient
          id="paint0_linear_518_9196"
          x1="57"
          y1="1"
          x2="57"
          y2="184"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#41B6E6" />
          <stop offset="1" stopColor="#41B6E6" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_518_9196"
          x1="57"
          y1="1"
          x2="57"
          y2="184"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_518_9196"
          x1="113"
          y1="92.5"
          x2="1"
          y2="92.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#57B5E3" stopOpacity="0" />
          <stop offset="0.5" stopColor="#57B5E3" />
          <stop offset="1" stopColor="#57B5E3" stopOpacity="0" />
        </linearGradient>
      </defs>
      <defs id="blueBetsDefs">
        <filter
          id="filter0_i_563_8841"
          x="0.5"
          y="0.5"
          width="113"
          height="185"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_563_8841" />
        </filter>
        <linearGradient
          id="paint0_linear_563_8841"
          x1="57"
          y1="1"
          x2="57"
          y2="184"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#41B6E6" />
          <stop offset="1" stopColor="#41B6E6" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_563_8841"
          x1="57"
          y1="1"
          x2="57"
          y2="184"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_563_8841"
          x1="113"
          y1="92.5"
          x2="1"
          y2="92.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#57B5E3" stopOpacity="0" />
          <stop offset="0.5" stopColor="#57B5E3" />
          <stop offset="1" stopColor="#57B5E3" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Wrapper>
  );
};
