import React from 'react';
import { WinnerBall } from 'constants/common';
import { scoreboardColors, ScoreboardColors } from 'widgets/Scoreboard/types';
import { Line } from '../Line';

export type CockroachLineType = WinnerBall.RED | WinnerBall.BLUE;

const lineColorMap: Record<CockroachLineType, string> = {
  [WinnerBall.RED]: scoreboardColors[ScoreboardColors.SignalRed],
  [WinnerBall.BLUE]: scoreboardColors[ScoreboardColors.Blue],
};

export interface ICockroachLine {
  outcome: CockroachLineType;
}

export const CockroachPigLine = ({ outcome }: ICockroachLine) => (
  <Line color={lineColorMap[outcome]} />
);
