import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { TableInfoLandscape } from './TableInfo.landscape';
import { TableInfoPortrait } from './TableInfo.portrait';
import { ITableInfoProps } from './types';

export const TableInfoResponsive = (props: ITableInfoProps) => (
  <>
    <DesktopComponent>
      <TableInfoLandscape {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <TableInfoLandscape {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <TableInfoPortrait {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <TableInfoPortrait {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
