interface ICrossBrowserFullScreenDocument extends Document {
  mozFullScreen?: boolean;
  webkitIsFullScreen?: boolean;
}

const checkFullScreen = (): boolean => {
  const docElement = document as ICrossBrowserFullScreenDocument;
  const isFullscreen =
    docElement.fullscreenElement || docElement.mozFullScreen || docElement.webkitIsFullScreen;

  return Boolean(isFullscreen);
};

export default checkFullScreen;
