import { createAction } from 'typesafe-actions';
import { LANGUAGES } from 'i18n';
import { ACTIONS } from './types';

export const getGameRulesAction = createAction(ACTIONS.GAME_RULES)<LANGUAGES>();
export const saveGameRulesToStore = createAction(ACTIONS.SAVE_GAME_RULES_TO_STORE)<{
  rules: string;
  jackpotRules: string;
}>();
export const setIsShowJackpotRuleAction = createAction(ACTIONS.SET_IS_SHOW_JP_RULE)<boolean>();
