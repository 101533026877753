import React from 'react';
import { Device } from 'types';
import { MobileComponent, TabletPortraitComponent } from 'components';
import { TopPanelPortrait } from './TopPanel.portrait';

export const TopPanel = () => (
  <>
    <TabletPortraitComponent>
      <TopPanelPortrait deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <TopPanelPortrait deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
