import { put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { updateActiveChipAction } from 'core/widgets/ActiveChip/actions';
import { applyAutoConfirmAction } from 'core/widgets/GeneralSettings/actions';
import { totalBallsCounterSelector } from 'core/widgets/Scoreboard/BallsCounter';
import { resetCounterStateAction } from 'core/widgets/Scoreboard/BallsCounter/actions';
import { IBallsCounter } from 'core/widgets/Scoreboard/BallsCounter/types';
import { resetScoreboardStateAction } from 'core/widgets/Scoreboard/Boards/actions';
import { TOTAL_SCOREBOARD_ROUNDS } from 'core/widgets/Scoreboard/types';
import { updateGameIdAction } from 'core/widgets/TableSettings/actions';
import { saveWinnerBallToStoreAction } from 'core/widgets/WinnerBall/actions';
import { Notifications } from 'types';
import { getTotalGameRounds } from '../helpers';
import { OnRoundStartedData } from '../types';
import { setTimeDifferenceUserServerToStoreAction } from 'core/widgets/BettingTime/actions';

export function* onRoundStartedSaga({ type: notificationType, data: result }: OnRoundStartedData) {
  if (notificationType === Notifications.ROUND_STARTED) {
    const {
      round: { gameId, startedAt },
    } = result;

    const date = new Date(startedAt);
    const dateNow = new Date();
    const difference = Math.floor((date.getTime() - dateNow.getTime()) / 1000);

    yield put(setTimeDifferenceUserServerToStoreAction(difference));

    yield put(updateGameIdAction({ gameId }));
    yield put(saveWinnerBallToStoreAction({ winner: null }));
    yield put(updateActiveChipAction());
    yield put(applyAutoConfirmAction());
    Sentry.setTag('game_id', gameId);

    const ballsCounter: IBallsCounter = yield select(totalBallsCounterSelector);
    const currentTotalGameRounds = getTotalGameRounds(ballsCounter);
    if (currentTotalGameRounds === TOTAL_SCOREBOARD_ROUNDS) {
      yield put(resetScoreboardStateAction());
      yield put(resetCounterStateAction());
    }
  }
}
