import { createAction } from 'typesafe-actions';
import { BetType } from '../Bet/types';
import { ACTIONS } from './types';

export const saveRoundBetAction = createAction(ACTIONS.SAVE_ROUND_BET)<BetType[]>();

export const saveRoundBetSuccessAction = createAction(
  ACTIONS.SAVE_ROUND_BET_SUCCESS,
)<BetType | null>();

export const repeatBetAction = createAction(ACTIONS.REPEAT_BET)();

export const repeatBetSuccessAction = createAction(ACTIONS.REPEAT_BET_SUCCESS)();

export const clearRepeatBetAction = createAction(ACTIONS.CLEAR_REPEAT_BET)();

export const confirmRepeatBetAction = createAction(ACTIONS.CONFIRM_REPEAT_BET)();

export const errorRepeatBetAction = createAction(ACTIONS.ERROR_REPEAT_BET)();

export const resetRepeatBetAction = createAction(ACTIONS.RESET_REPEAT_BET)();
