import React from 'react';
import { MobileComponent, TabletPortraitComponent } from 'components';
import { ITiltedMobileButtonProps } from '../types';
import { TiltedRightTabletButton } from './TiltedRightTabletButton';
import { TiltedRightMobileButton } from './TiltedRightMobileButton';

export const TiltedRightButton = (props: ITiltedMobileButtonProps) => (
  <>
    <TabletPortraitComponent>
      <TiltedRightTabletButton {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <TiltedRightMobileButton {...props} />
    </MobileComponent>
  </>
);
