import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { SettingPanels } from 'widgets/Settings/types';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import AudioService from 'services/AudioService';
import { RightSideButton } from 'components';

export const SettingsButtonMobile = () => {
  const dispatch = useDispatch();
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);

  const openMainSettingsPanel = () => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
    dispatch(manageSettingsPanelAction({ settingsPanel: SettingPanels.Main }));
  };

  return (
    <>
      <RightSideButton onClick={openMainSettingsPanel}>
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_2005_112021)">
            <g filter="url(#filter1_i_2005_112021)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.02773 0C8.53017 0 8.1083 0.365936 8.03799 0.858582L7.66665 3.45728C6.96426 3.72517 6.31606 4.10295 5.74306 4.5696L3.30508 3.591C2.84316 3.40564 2.31533 3.58806 2.06655 4.01904L0.933982 5.98094C0.684958 6.41193 0.790915 6.96028 1.18252 7.26755L3.24819 8.88904C3.19057 9.25085 3.16054 9.62193 3.16054 10C3.16054 10.3781 3.19057 10.7491 3.24819 11.1109L1.18252 12.7324C0.790915 13.0397 0.684958 13.5881 0.933982 14.019L2.06655 15.9809C2.31533 16.4119 2.84316 16.5943 3.30508 16.409L5.74306 15.4304C6.31606 15.897 6.96426 16.2748 7.66665 16.5427L8.03799 19.1414C8.1083 19.6341 8.53017 20 9.02773 20H11.2934C11.7909 20 12.2128 19.6341 12.2831 19.1414L12.6544 16.5427C13.3568 16.2748 14.005 15.8971 14.578 15.4305L17.0158 16.409C17.4777 16.5943 18.0055 16.4119 18.2543 15.9809L19.3871 14.019C19.6359 13.5881 19.5299 13.0397 19.1386 12.7324L17.0729 11.111C17.1305 10.7492 17.1605 10.3781 17.1605 10C17.1605 9.87653 17.1574 9.7538 17.151 9.6319C17.1381 9.38052 17.1117 9.13266 17.0729 8.88898L19.1386 7.26755C19.5299 6.96028 19.6359 6.41193 19.3871 5.98094L18.2543 4.01904C18.0055 3.58806 17.4777 3.40564 17.0158 3.591L14.578 4.56953C14.005 4.10292 13.3568 3.72517 12.6544 3.45728L12.2831 0.858582C12.2128 0.365936 11.7909 0 11.2934 0H9.02773ZM10.1606 13C11.8175 13 13.1606 11.6569 13.1606 10C13.1606 8.34315 11.8175 7 10.1606 7C8.50379 7 7.16064 8.34315 7.16064 10C7.16064 11.6569 8.50379 13 10.1606 13Z"
                fill="url(#paint0_linear_2005_112021)"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_2005_112021"
              x="0"
              y="0"
              width="20"
              height="22"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0705882 0 0 0 0 0.0627451 0 0 0 0 0.0666667 0 0 0 0.4 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2005_112021"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2005_112021"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_i_2005_112021"
              x="0.799805"
              y="0"
              width="18.7217"
              height="20"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.831373 0 0 0 0 0.733333 0 0 0 0 0.576471 0 0 0 1 0"
              />
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2005_112021" />
            </filter>
            <linearGradient
              id="paint0_linear_2005_112021"
              x1="10.1605"
              y1="0"
              x2="10.1605"
              y2="20"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" />
              <stop offset="1" stopColor="#D4BB93" />
            </linearGradient>
          </defs>
        </svg>
      </RightSideButton>
    </>
  );
};
