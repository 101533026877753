import { ToastTypes, ToastValueType } from 'types/toast';

export enum ACTIONS {
  ADD_TOAST = 'ADD_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',
}

export type ToastItemType = {
  type?: ToastTypes;
  value: ToastValueType;
};

export interface IToastState {
  toastItem: ToastItemType | null;
}
