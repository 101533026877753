import { CHIPS_COLORS } from 'widgets/Chips/data/currencies/colors';
import { getValueChip } from './ChipsValues';
import { IChipComponentSvg } from './Chip';

export const DefaultChip = ({ color, value }: IChipComponentSvg) => (
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="chip_body">
      <path
        d="M34 66C51.6731 66 66 51.6731 66 34C66 16.3269 51.6731 2 34 2C16.3269 2 2 16.3269 2 34C2 51.6731 16.3269 66 34 66Z"
        fill="#2B2A2B"
        stroke="url(#paint0_linear_5226_53169)"
        strokeWidth="4"
      />
      <path
        d="M34 64C50.5685 64 64 50.5685 64 34C64 17.4315 50.5685 4 34 4C17.4315 4 4 17.4315 4 34C4 50.5685 17.4315 64 34 64Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8525 35.0225L33.8039 34.974V35.0225H33.7553V35.0711V35.0225H33.7068V34.974H33.6582H33.7068L33.6582 34.9254H33.7068V34.8768L33.7553 34.9254V34.8768V34.9254L33.8039 34.8768V34.9254H33.8525L33.8039 34.974H33.8525H33.8039L33.8525 35.0225Z"
        fill="url(#paint1_linear_5226_53169)"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0007 12.5233C22.1176 12.5233 12.5234 22.1662 12.5234 34.0006C12.5234 45.8837 22.1663 55.4779 34.0007 55.4779C45.8838 55.4779 55.478 45.835 55.478 34.0006C55.5267 22.1175 45.8838 12.5233 34.0007 12.5233ZM34.0007 53.0428C23.189 53.0428 14.9585 44.7636 14.9585 34.0006C14.9585 23.1889 23.2377 14.9584 34.0007 14.9584C44.7637 14.9584 53.0429 23.2376 53.0429 34.0006C53.0916 44.7636 44.8124 53.0428 34.0007 53.0428Z"
        fill="url(#paint2_linear_5226_53169)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9637 63.17C38.7234 63.7057 36.4345 63.998 34.0481 63.998C31.5156 63.998 29.0319 63.657 26.6455 63.0726L27.9117 58.1051C28.2527 56.7415 29.5676 55.9135 30.9312 56.1083C31.954 56.2544 32.9767 56.3031 34.0481 56.3031C34.9734 56.3031 35.8501 56.2544 36.7267 56.157C38.0903 56.0109 39.3565 56.8389 39.6975 58.1538L40.9637 63.17Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.4016 4.92419L40.1353 9.89173C39.7944 11.2554 38.4795 12.0833 37.1159 11.8885C36.0931 11.7424 35.0704 11.6937 33.999 11.6937C32.7815 11.6937 31.6126 11.7911 30.4925 11.9859C29.0802 12.2294 27.7652 11.3528 27.3756 9.98913L26.1094 5.0703C28.5931 4.38848 31.2717 4.04757 33.9503 4.04757C36.5801 3.99887 39.0152 4.33978 41.4016 4.92419Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9558 52.1634L13.4006 55.7673C9.89411 52.4556 7.16684 48.316 5.6084 43.6407L10.5759 42.0822C11.8422 41.6926 13.2545 42.277 13.8389 43.5433C14.7155 45.3452 15.787 47.0011 17.0532 48.5108C17.9785 49.5822 17.9298 51.1407 16.9558 52.1634Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.9291 25.9639L58.1564 27.425C56.7928 27.8633 55.3317 27.1815 54.796 25.8665C53.9681 23.8211 52.8967 21.873 51.533 20.1685C50.6564 19.097 50.7051 17.5386 51.6791 16.5646L55.283 12.8633C58.8869 16.4672 61.5168 20.9477 62.9291 25.9639Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0297 20.7509C14.7634 22.4554 13.7407 24.3548 13.0102 26.4002C12.5232 27.7152 11.0621 28.4457 9.74721 28.0561L4.92578 26.6924C6.14331 21.7249 8.67578 17.2444 12.0362 13.5918L15.7375 17.2444C16.7115 18.1697 16.8089 19.6795 16.0297 20.7509Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.5385 43.2027C60.9801 47.9754 58.3015 52.2124 54.7463 55.6215L51.1911 52.115C50.1684 51.0923 50.1197 49.4851 51.045 48.4137C52.36 46.8553 53.4801 45.1507 54.308 43.2514C54.8437 42.0338 56.2074 41.4007 57.5223 41.7903L62.5385 43.2027Z"
        fill="#FFF7EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4353 11.9872C21.4353 12.6203 20.8996 13.156 20.2665 13.156C19.6334 13.156 19.0977 12.6203 19.0977 11.9872C19.0977 11.3541 19.6334 10.8184 20.2665 10.8184C20.8996 10.8184 21.4353 11.3054 21.4353 11.9872Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6365 11.9872C47.6365 12.6203 47.1008 13.156 46.4677 13.156C45.8345 13.156 45.2988 12.6203 45.2988 11.9872C45.2988 11.3541 45.8345 10.8184 46.4677 10.8184C47.1008 10.8184 47.6365 11.3054 47.6365 11.9872Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6043 56.6455C22.6043 57.2786 22.0685 57.8143 21.4354 57.8143C20.8023 57.8143 20.2666 57.2786 20.2666 56.6455C20.2666 56.0124 20.8023 55.4767 21.4354 55.4767C22.0685 55.4767 22.6043 56.0124 22.6043 56.6455Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.197 56.9883C47.197 57.6214 46.6613 58.1571 46.0282 58.1571C45.3951 58.1571 44.8594 57.6214 44.8594 56.9883C44.8594 56.3552 45.3951 55.8195 46.0282 55.8195C46.71 55.7708 47.197 56.3065 47.197 56.9883Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.6404 34.9735C60.6404 35.6066 60.1047 36.1423 59.4716 36.1423C58.8384 36.1423 58.3027 35.6066 58.3027 34.9735C58.3027 34.3404 58.8384 33.8047 59.4716 33.8047C60.1047 33.8047 60.6404 34.2917 60.6404 34.9735Z"
        fill="#2B2A2B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16091 35.0705C9.16091 35.7036 8.62519 36.2393 7.99207 36.2393C7.35895 36.2393 6.82324 35.7036 6.82324 35.0705C6.82324 34.4373 7.35895 33.9016 7.99207 33.9016C8.62519 33.8529 9.16091 34.3886 9.16091 35.0705Z"
        fill="#2B2A2B"
      />
      <path
        d="M33.8214 54.0647C44.7685 54.0647 53.6429 45.1904 53.6429 34.2433C53.6429 23.2962 44.7685 14.4219 33.8214 14.4219C22.8744 14.4219 14 23.2962 14 34.2433C14 45.1904 22.8744 54.0647 33.8214 54.0647Z"
        fill="#FFF7EB"
      />
      {value ? getValueChip(value) : null}
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.02344 41.6457C5.02344 41.6457 11.0624 63.0256 34.0494 62.8308C58.254 62.636 63.0754 41.3535 63.0754 41.3535C63.0754 41.3535 58.0105 63.9509 34.0007 63.9509C9.99097 63.9996 5.02344 41.6457 5.02344 41.6457Z"
        fill="#121011"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5226_53169"
        x1="34"
        y1="4"
        x2="34"
        y2="64"
        gradientUnits="userSpaceOnUse">
        <stop stopOpacity="0" />
        <stop offset="1" stopColor="#131011" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5226_53169"
        x1="33.7261"
        y1="35.0406"
        x2="33.7989"
        y2="34.8841"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#170E1D" />
        <stop offset="1" stopColor="#2E102E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5226_53169"
        x1="34.3082"
        y1="11.9593"
        x2="33.731"
        y2="55.8266"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#121011" stopOpacity="0.4" />
        <stop offset="1" stopColor="#FFF7EB" />
      </linearGradient>
    </defs>
  </svg>
);
