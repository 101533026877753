import { ActionType, createReducer } from 'typesafe-actions';
import { WinnerBall } from 'constants/common';
import {
  resetCounterStateAction,
  setInitialCounterStateAction,
  updateBallsCounterAction,
} from './actions';
import { IBallsCounter, IBallsCounterState } from './types';

const defaultBallsCounter: IBallsCounter = {
  [WinnerBall.BLUE]: 0,
  [WinnerBall.RED]: 0,
  [WinnerBall.GOLD]: 0,
};

export const ballsCounterState: IBallsCounterState = {
  ballsCounter: defaultBallsCounter,
};

const actions = {
  resetCounterStateAction,
  setInitialCounterStateAction,
  updateBallsCounterAction,
};

export const ballsCounterReducer = createReducer<IBallsCounterState, ActionType<typeof actions>>(
  ballsCounterState,
)
  .handleAction(resetCounterStateAction, (state) => ({
    ...state,
    ballsCounter: {
      ...state.ballsCounter,
      ...defaultBallsCounter,
    },
  }))
  .handleAction(setInitialCounterStateAction, (state, { payload: { winnerBallsData } }) => {
    const initialBallCounter: IBallsCounter = winnerBallsData.reduce(
      (counter, winnerBall) => ({
        ...counter,
        [winnerBall]: counter[winnerBall] + 1,
      }),
      defaultBallsCounter,
    );

    return {
      ...state,
      ballsCounter: {
        ...state.ballsCounter,
        ...initialBallCounter,
      },
    };
  })
  .handleAction(updateBallsCounterAction, (state, { payload: { winnerBall } }) => ({
    ...state,
    ballsCounter: {
      ...state.ballsCounter,
      [winnerBall]: state.ballsCounter[winnerBall] + 1,
    },
  }));
