import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { chipsMIN2k } from './chipsValues';

export const CHIPS_MIN_2k: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 2e3,
  },
  ...chipsMIN2k,
];
