import React from 'react';
import { IconNames, Icon } from 'components';
import { Device } from 'types';
import { WinnerBall } from 'constants/common';
import { Wrapper } from './styles';
import { IDeviceDrawnBall } from './types';

export const BaseDrawnBall = ({ winnerBall, deviceType = Device.Desktop }: IDeviceDrawnBall) => {
  switch (winnerBall) {
    case WinnerBall.BLUE:
      return (
        <Wrapper $deviceType={deviceType}>
          <Icon name={IconNames.WinnerBlue} />
        </Wrapper>
      );
    case WinnerBall.RED:
      return (
        <Wrapper $deviceType={deviceType}>
          <Icon name={IconNames.WinnerRed} />
        </Wrapper>
      );
    case WinnerBall.GOLD:
      return (
        <Wrapper $deviceType={deviceType}>
          <Icon name={IconNames.WinnerGold} />
        </Wrapper>
      );
    default:
      return (
        <Wrapper $deviceType={deviceType}>
          <svg width="120" height="120" viewBox="0 0 120 120" fill="none" x="24" y="24">
            <defs>
              <linearGradient
                id="paint0_linear_439_31474"
                x1="64.2001"
                y1="120"
                x2="64.2001"
                y2="-5.69392e-07"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#F1E6D2" stopOpacity="0.4" />
                <stop offset="0.492608" stopColor="#F1E6D2" />
                <stop offset="1" stopColor="#F1E6D2" stopOpacity="0.4" />
              </linearGradient>
            </defs>
            <rect
              x="1"
              y="1"
              width="118"
              height="118"
              fill="#121011"
              stroke="url(#paint0_linear_439_31474)"
              strokeWidth="2"
            />
            <rect
              x="5.2002"
              y="5.2002"
              width="109.6"
              height="109.6"
              stroke="#2B2A2B"
              strokeWidth="2"
            />
            <circle
              cx="59.9997"
              cy="59.9997"
              r="22.9997"
              fill="#2B2A2B"
              stroke="#2B2A2B"
              strokeWidth="2"
            />
          </svg>
        </Wrapper>
      );
  }
};
