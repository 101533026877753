import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import styled, { css } from 'styled-components';
import { Device } from 'types';

const WrapperContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: min-content;
  border-style: none;
  ${({ theme: { border } }) => css`
    border-color: ${border.primaryDefault};
  `}

  &:after {
    content: '';
    position: absolute;
    border: 1rem solid;
    border-image-source: linear-gradient(
      270deg,
      rgba(241, 230, 210, 0) 0%,
      #f1e6d2 50%,
      rgba(241, 230, 210, 0) 100%
    );
    border-image-slice: 1;
    pointer-events: none;
  }
`;

export const Wrapper = styled(WrapperContainer)<{ $deviceType?: Device }>`
  width: 864rem;
  height: 478rem;
  margin: 60rem auto 182rem;
  border-width: 4rem;
  background: linear-gradient(180deg, #121011 40%, rgba(52, 52, 52, 0.2) 100%);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #121011;
    pointer-events: none;
  }

  &:after {
    top: 4rem;
    left: 4rem;
    right: 4rem;
    bottom: 4rem;
    z-index: 102;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        width: 77%;
        width: 278rem;
        /* height: 528rem; */
        /* height: 450rem; */
        height: ${isOldBrowserIOS() ? '450rem' : '528rem'};
        margin: 32rem auto 40rem;
        border-width: 2rem;

        &:after {
          top: 2rem;
          left: 2rem;
          right: 2rem;
          bottom: 2rem;
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        width: 512rem;
        height: 656rem;
        margin: 120rem auto 40rem;
        border-width: 4rem;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          pointer-events: none;
        }

        &:after {
          top: 4rem;
          left: 4rem;
          right: 4rem;
          bottom: 4rem;
        }
      `;
    }
  }}
`;

export const CloseButton = styled.div`
  position: absolute;
  right: -16rem;
  top: -16rem;
  height: 32rem;
  width: 32rem;
  border: 1px solid #2b2a2b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #121011;
  &:hover {
    border: 1px solid #f1e6d2;
  }
  z-index: 101;
`;

export const Head = styled.p<{ $deviceType?: Device }>`
  padding: 22rem 0;
  font-size: 14rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  background: linear-gradient(360deg, #121011 0%, #343434 100%);
  z-index: 100;
  border-bottom: 1rem solid #434343;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        font-size: 10rem;
        padding: 10px 0;
      `;
    }
  }}
`;

export const RulesContainer = styled.div<{ $deviceType?: Device }>`
  z-index: 103;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 25rem;
  margin-top: 24rem;
  margin-bottom: 40rem;
  overflow-x: hidden;

  & p {
    font-size: 14rem;
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 16rem;
  }

  & ul {
    list-style: disc outside;
    font-size: 14rem;

    & li {
      list-style: disc outside;
      line-height: 1.25;
      margin-bottom: 5rem;
    }
  }

  & table {
    table-layout: fixed;
    width: 100%;
    margin: 10rem 0;
    border-collapse: collapse;

    & td {
      border: 1px solid #bb9370;
      padding: 6rem 0;
      text-align: center;
    }
  }

  & img {
    display: block;
    max-width: 100% !important;
    /* width: auto !important; */
    height: auto !important;
    margin: 10rem 0;
    object-fit: cover;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        margin-right: 12rem;
        margin-top: 16rem;
        margin-bottom: 24rem;

        & p {
          font-size: 10rem;
        }

        & h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 12rem;
        }

        & ul {
          font-size: 10rem;
        }
      `;
    }
  }}
`;

export const LoaderWrapper = styled.div<{ $deviceType?: Device }>`
  height: 64rem;
  width: 64rem;
  margin-top: 15%;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        margin-top: 40%;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        margin-top: 60%;
      `;
    }
  }}
`;

export const ScrollableContainer = styled.div<{ $isMobile: boolean }>`
  padding: 0 32rem;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 2px;
    background-color: #d4bb93;
  }
  &::-webkit-scrollbar {
    width: 1px;
    background: #434343;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
  ${({ $isMobile }) => {
    if ($isMobile) {
      return css`
        padding: 0 16rem;
        &::-webkit-scrollbar-thumb {
          width: 2px;
        }
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }
      `;
    }
  }}
`;
