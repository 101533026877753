import styled from 'styled-components';
import loaderSVG from 'assets/icons/loader.svg';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${loaderSVG});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: rotation 1300ms infinite linear;

  @keyframes rotation {
    100% {
      transform: rotate(0deg);
      transform: rotate(360deg);
    }
  }
`;
