import { CHIPS_COLORS } from 'widgets/Chips/data/currencies/colors';

export enum ACTIONS {
  UPDATE_ACTIVE_CHIP = 'UPDATE_ACTIVE_CHIP',
  SET_ACTIVE_CHIP = 'SET_ACTIVE_CHIP',
}

export type ChipType = {
  value: number;
  color: CHIPS_COLORS;
};

export interface IActiveChipState {
  chip: ChipType | null;
}
