import { ActionType, createReducer } from 'typesafe-actions';
import { saveGameRulesToStore, setIsShowJackpotRuleAction } from './actions';
import { IGameRules } from './types';

export const defaultGameRules: IGameRules = {
  rules: '',
  jackpotRules: '',
  isShowJackpotRule: false,
};

const actions = {
  saveGameRulesToStore,
  setIsShowJackpotRuleAction,
};

export const gameRulesReducer = createReducer<IGameRules, ActionType<typeof actions>>(
  defaultGameRules,
)
  .handleAction(saveGameRulesToStore, (state, { payload: { rules, jackpotRules } }) => ({
    ...state,
    rules,
    jackpotRules,
  }))
  .handleAction(setIsShowJackpotRuleAction, (state, { payload }) => ({
    ...state,
    isShowJackpotRule: payload,
  }));
