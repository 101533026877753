import React from 'react';
import { WinnerBall } from 'constants/common';
import { PredictionShortcut } from './components/PredictionShortcut';
import { IPredictionsSection } from './types';
import { PredictionSectionStyled } from './styles';

export const PredictionsSectionMobile = ({
  size = { width: 132, height: 26 },
  position = [220, 0],
  predictWinnerBall,
}: IPredictionsSection) => {
  const [positionX, positionY] = position;

  return (
    <PredictionSectionStyled
      width={size.width}
      height={size.height}
      x={positionX}
      y={positionY}
      fill="none"
      viewBox="0 0 132 26"
      xmlns="http://www.w3.org/2000/svg">
      <PredictionShortcut
        predictedBall={WinnerBall.RED}
        position={[0, 5]}
        onClick={() => predictWinnerBall(WinnerBall.RED)}
      />
      <PredictionShortcut
        predictedBall={WinnerBall.BLUE}
        position={[68, 5]}
        onClick={() => predictWinnerBall(WinnerBall.BLUE)}
      />
    </PredictionSectionStyled>
  );
};
