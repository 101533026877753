import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGES } from 'i18n';
import { useTranslation } from 'react-i18next';

import { getGameRulesAction } from 'core/widgets/GameRules/actions';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { SettingPanels } from 'widgets/Settings/types';
import { ChatButtonResponsive } from './Responsive';
import AudioService from 'services/AudioService';

export const ChatButton = () => {
  const dispatch = useDispatch();

  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const { i18n } = useTranslation();

  const onOpenChat = useCallback(() => {
    dispatch(manageSettingsPanelAction({ settingsPanel: SettingPanels.Chat }));
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
  }, [dispatch, gameSoundMute, soundEffects]);

  return (
    <>
      <ChatButtonResponsive onOpenChat={onOpenChat} />
    </>
  );
};
