import { betMessages } from 'constants/toastMessages';
import { placeBetSuccessAction } from 'core/widgets/Bet/actions';
import { getBetSum } from 'core/widgets/Bet/utils';
import { previousConfirmationSlotSelector } from 'core/widgets/BetConfirmation';
import {
  setClosestConfirmationSlotAction,
  setConfirmationSlotAction,
} from 'core/widgets/BetConfirmation/actions';
import { addToastAction } from 'core/widgets/Toast/actions';
import { balanceSelector } from 'core/widgets/User';
import { put, select } from 'redux-saga/effects';
import { SLOTS } from 'types';
import { ToastTypes } from 'types/toast';
import { repeatBetSuccessAction } from '../actions';
import { betRepeatRootSelector } from '../selectors';
import { IBetRepeatState } from '../types';

export function* repeatBetSaga() {
  const balance: number | null = yield select(balanceSelector);
  const { isUsed, isConfirmed, value }: IBetRepeatState = yield select(betRepeatRootSelector);

  if (value === null || balance === null || isUsed || isConfirmed) {
    return;
  }

  const lastBetSum = getBetSum([value]);

  if (lastBetSum > balance) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
    return;
  }

  yield put(repeatBetSuccessAction());
  yield put(placeBetSuccessAction(value));

  const previousConfirmationSlot: SLOTS | null = yield select(previousConfirmationSlotSelector);

  if (previousConfirmationSlot !== null) {
    yield put(setConfirmationSlotAction(previousConfirmationSlot));
    return;
  }

  yield put(setClosestConfirmationSlotAction());
}
