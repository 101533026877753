import React from 'react';
import { Icon, IconNames } from 'components';
import { Device } from 'types';
import { IDoubleBetButtonProps } from './types';
import { Wrapper, IconWrapper } from './styles';

export const DoubleBetButtonTabletLandscape = ({
  isDisabled,
  onDoubleBetClick,
}: IDoubleBetButtonProps) => (
  <Wrapper
    $isDisabled={isDisabled}
    $deviceType={Device.Tablet_Landscape}
    width="136"
    height="52"
    viewBox="0 0 136 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g onClick={onDoubleBetClick}>
      <path
        id="dbtl_path_background"
        d="M134.809 51.5L84.79 0.5L1.19072 0.5L51.21 51.5L134.809 51.5Z"
        fill="url(#paint0_linear_2489_21233)"
        stroke="url(#paint1_linear_2489_21233)"
      />

      <IconWrapper
        x="58"
        y="16"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <Icon name={isDisabled ? IconNames.DoubleBetDisabled : IconNames.DoubleBet} />
      </IconWrapper>
    </g>

    <defs>
      <linearGradient
        id="paint0_linear_2489_21233"
        x1="68"
        y1="52"
        x2="68"
        y2="0"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" />
        <stop offset="1" stopColor="#343434" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2489_21233"
        x1="136"
        y1="26"
        x2="0"
        y2="26"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" stopOpacity="0" />
        <stop offset="0.5" stopColor="#434343" />
        <stop offset="1" stopColor="#343434" stopOpacity="0" />
      </linearGradient>

      <linearGradient
        id="paint0_linear_24891_21233"
        x1="68"
        y1="0"
        x2="68"
        y2="52"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#1B191A" />
        <stop offset="1" stopColor="#BB9370" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_24891_21233"
        x1="136"
        y1="26"
        x2="0"
        y2="26"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1E6D2" stopOpacity="0" />
        <stop offset="0.5" stopColor="#F1E6D2" />
        <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
      </linearGradient>
    </defs>
  </Wrapper>
);
