import { WinnerBall } from 'constants/common';
import { SLOTS } from 'types';

export enum ACTIONS {
  SET_WIN_STATUS = 'SET_WIN_STATUS',
}

type WinnerBallSlotType = { [key in WinnerBall]: SLOTS };

export const WinnerBallSlot: WinnerBallSlotType = {
  [WinnerBall.BLUE]: SLOTS.player,
  [WinnerBall.GOLD]: SLOTS.tie,
  [WinnerBall.RED]: SLOTS.banker,
};

export interface IWinStatusState {
  winner: WinnerBall;
  winningAmount: number;
  betAmount: number;
}
