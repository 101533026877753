import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IWinStatusState } from './types';

const winStatusRootSelector = (state: IState) => state.winStatusReducer;

export const winStatusSelector = createSelector(
  winStatusRootSelector,
  (state: IWinStatusState | null) => state,
);
