import { ActionType, createReducer } from 'typesafe-actions';
import { changeRoundStateAction } from './actions';
import { IRoundState, RoundStates } from './types';

export const defaultRoundState: IRoundState = {
  roundState: RoundStates.TableOpened,
};

const actions = {
  changeRoundStateAction,
};

export const roundStateReducer = createReducer<IRoundState, ActionType<typeof actions>>(
  defaultRoundState,
).handleAction(changeRoundStateAction, (state, { payload: { roundState } }) => {
  if (
    roundState === RoundStates.BettingTimeStarted &&
    state.roundState === RoundStates.BettingTimeFinished
  ) {
    return state;
  }
  if (
    roundState === RoundStates.BettingTimeStarted &&
    state.roundState === RoundStates.DrawStarted
  ) {
    return state;
  }
  return {
    ...state,
    roundState,
  };
});
