import { Icon } from 'components';
import styled, { css } from 'styled-components';
import { Device } from 'types';
import backgroundSVG from './assets/background.svg';
import backgroundMobileSVG from './assets/background-mobile.svg';
import backgroundTabletPortraitSVG from './assets/background-tablet-portrait.svg';
import { AspectRatioIphone, getAspectRatioIphone, isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const WalletIcon = styled(Icon)``;

export const BalanceCount = styled.p`
  margin-left: 8rem;

  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;

export const Wrapper = styled.div<{ $deviceType: Device }>`
  position: absolute;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        top: 15rem;
        left: 0;
        height: 52rem;
        width: 225rem;
        background-image: url(${backgroundSVG});

        ${WalletIcon} {
          height: 16rem;
          width: 16rem;
          margin-left: 24rem;
        }

        ${BalanceCount} {
          font-size: 14rem;
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 0;
        left: 60rem;
        height: 52rem;
        width: 244rem;
        background-image: url(${backgroundTabletPortraitSVG});

        ${WalletIcon} {
          height: 16rem;
          width: 16rem;
          margin-left: 56rem;
        }

        ${BalanceCount} {
          font-size: 14rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 0;
        left: 24rem;
        height: 28rem;
        width: 148rem;
        background-image: url(${backgroundMobileSVG});
        /* transform: scale(0.9);
        left: 14rem; */
        transform: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? 'scale(0.9)'
          : 'none'};
        left: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? '14rem'
          : '24rem'};

        ${WalletIcon} {
          height: 12rem;
          width: 12rem;
          margin-left: 32rem;
          @media only screen and (max-width: 430px) {
            margin-left: 25rem;
            font-size: 8rem;
          }
        }

        ${BalanceCount} {
          font-size: 10rem;
          @media only screen and (max-width: 430px) {
            margin-left: 5rem;
            font-size: 8rem;
          }
        }
      `;
    }
  }}
`;
