import { Device } from 'types';
import { isInIframe } from 'hooks/getDefineDevEnv';

export const getHorizontalTickerDuration = (
  containerSize: number,
  contentSize: number,
  $deviceType: Device,
) => {
  const speed: number = $deviceType === Device.Mobile ? (isInIframe() ? 20 : 40) : 70;
  return `${(containerSize + contentSize) / speed}s`;
};

export const getVerticalTickerDuration = (winnersQty: number) => {
  if (winnersQty < 10) return '12s';
  if (winnersQty < 30) return '15s';
  if (winnersQty < 50) return '20s';
  return `${winnersQty / 2}s`;
};
