import styled, { css } from 'styled-components';

export const SwitchToggleControl = styled.div`
  height: 16rem;
  width: 16rem;
  border-radius: 50%;
  background: linear-gradient(180deg, #fff7eb 0%, #d4bb93 100%);
  box-shadow: 0px 2rem 0px rgba(18, 16, 17, 0.4);
  transition: transform ease-in-out 0.14s;
`;

export const Wrapper = styled.div<{ $isChecked: boolean }>`
  height: 20rem;
  width: 32rem;
  border-radius: 12rem;
  transition: background-color ease-in-out 0.14s;
  user-select: none;
  cursor: pointer;

  ${({ $isChecked, theme: { background } }) => {
    if ($isChecked) {
      return css`
        background-color: ${background.accent};

        & > ${SwitchToggleControl} {
          transform: translateX(14rem) translateY(1.63rem);
        }
      `;
    }

    return css`
      background-color: ${background.disabled};

      & > ${SwitchToggleControl} {
        transform: translateX(2rem) translateY(1.63rem);
      }
    `;
  }};
`;
