import { OptionType } from 'types';
import { VideoQualities } from './types';

const defaultQualityNameKey = 'settings.video.quality';
export const VIDEO_QUALITY_OPTIONS: OptionType<VideoQualities, string>[] = [
  {
    label: `${defaultQualityNameKey}.high`,
    value: VideoQualities.High,
    subtitle: '1920x1080; 2500 kbit/s; 25fps',
  },
  {
    label: `${defaultQualityNameKey}.medium`,
    value: VideoQualities.Medium,
    subtitle: '852x480; 640 kbit/s; 25 fps',
  },
  {
    label: `${defaultQualityNameKey}.low`,
    value: VideoQualities.Low,
    subtitle: '640x360; 300 kbit/s; 15 fps',
  },
];
