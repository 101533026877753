import { call, put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { addNoticeAction } from 'core/widgets/Notices/actions';
import { Notices } from 'core/widgets/Notices/types';
import { OnGameClosedNoBetsData } from '../types';
import { applyVideoPlaybackAction } from 'core/widgets/VideoSettings/action';
import { webSocketService } from 'services/WebSocketService';

export function* onCloseGameNoBetsSaga({ type: notificationType }: OnGameClosedNoBetsData) {
  if (notificationType === Notifications.PLAYER_AFK) {
    yield put(addNoticeAction({ notification: Notices.NoBetsMade }));
    yield call(() => webSocketService.disconnect());
    yield put(applyVideoPlaybackAction(false));
  }
}
