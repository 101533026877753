import React from 'react';
import { useSelector } from 'react-redux';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { IJackpotSlot } from 'types';
import { SlotJackpotMaximizedMobile } from './components/SlotJackpotMaximized.mobile';
import { SlotJackpotMinimizedMobile } from './components/SlotJackpotMinimized.mobile';

export const SlotJackpotMobile = ({ jackpotSum, isActiveSlot, onClick }: IJackpotSlot) => {
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);

  return isScoreboardOpen ? (
    <SlotJackpotMinimizedMobile
      jackpotSum={jackpotSum}
      isActiveSlot={isActiveSlot}
      onClick={onClick}
    />
  ) : (
    <SlotJackpotMaximizedMobile
      jackpotSum={jackpotSum}
      isActiveSlot={isActiveSlot}
      onClick={onClick}
    />
  );
};
