import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BaseSettings } from './Settings.base';
import { ISettingsProps } from './types';

export const SettingsResponsive = (props: ISettingsProps) => (
  <>
    <DesktopComponent>
      <BaseSettings {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseSettings {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseSettings {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BaseSettings {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
