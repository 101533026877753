import { put, select } from 'redux-saga/effects';

import { setCurrentJackpot, setNextJackpot } from 'core/widgets/Jackpot/actions';
import { OnJackpotChangedData } from '../../types';
import { Notifications } from 'types';
import { currentJackpotSelector, nextJackpotSelector } from 'core/widgets/Jackpot/selectors';
import { IJackpot } from 'core/widgets/Jackpot/types';

export function* onJackpotCollectionStartedSaga({
  type: notificationType,
  data: result,
}: OnJackpotChangedData) {
  if (notificationType === Notifications.JACKPOT_COLLECTION_PERIOD_STARTED) {
    const { jackpotId } = result;
    const { currentJackpot } = yield select(currentJackpotSelector);
    const nextJackpot: IJackpot = yield select(nextJackpotSelector);
    const isCurrent = jackpotId === currentJackpot.jackpotId;

    if (isCurrent) {
      yield put(setCurrentJackpot({ ...currentJackpot, isCollectionPeriod: true }));
      return;
    }

    if (!isCurrent) {
      yield put(setNextJackpot({ ...nextJackpot, isCollectionPeriod: true }));
    }
  }
}
