import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { Device, SLOTS } from 'types';
import { Wrapper } from './styles';
import { ISlotInnerPositionsConfig, SlotContent } from '../SlotContent';

export const SlotBlueLandscape = ({
  activeChip,
  bet,
  isBettingTime,
  hasPlacedBet,
  isCurrent,
  isMinimized,
  isWinner,
  size = { height: 121, width: 383 },
  isActiveJP,
  onClick,
  onBetConfirm,
  onBetClear,
  onErrorClick,
}: ISlotProps) => {
  const minimizedInnerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '320',
      positionY: '4',
      size: 27,
    },
    confirmBet: {
      positionX: '294',
      positionY: '-18',
    },
  };

  const maximizedInnerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '278',
      positionY: '60',
      size: 40,
    },
    confirmBet: {
      positionX: '294',
      positionY: '-18',
    },
  };

  return (
    <Wrapper
      $activeChip={activeChip}
      $isActive={hasPlacedBet}
      $isBettingTime={isBettingTime}
      $isWinner={isWinner}
      onClick={isBettingTime ? onClick : onErrorClick}
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 383 121"
      x="0"
      y="0"
      fill="none">
      {isMinimized ? (
        <>
          <g filter="url(#filter0_bi_513_4203)">
            <path
              d="M8.40422 0C2.88487 6.32182 0 12.8395 0 19.5C0 27.9314 4.62286 36.1339 13.3607 44C14.1039 44.6691 14.877 45.3358 15.6795 46C16.4881 46.6692 17.3266 47.3359 18.1945 48C63.2817 82.4959 188.034 109.958 348 120.885L369.079 48L369.657 46L370.236 44L382.961 0H8.40422Z"
              fill="#28080B"
            />
            <path
              d="M8.40422 0C2.88487 6.32182 0 12.8395 0 19.5C0 27.9314 4.62286 36.1339 13.3607 44C14.1039 44.6691 14.877 45.3358 15.6795 46C16.4881 46.6692 17.3266 47.3359 18.1945 48C63.2817 82.4959 188.034 109.958 348 120.885L369.079 48L369.657 46L370.236 44L382.961 0H8.40422Z"
              fill="url(#paint0_linear_513_4203)"
            />
            <path
              d="M1 19.5C1 13.2653 3.64656 7.08683 8.86145 1H381.631L369.275 43.7222L368.697 45.7222L368.118 47.7222L347.263 119.832C267.643 114.362 196.802 104.792 140.233 92.3042C83.4286 79.7642 41.1583 64.3103 18.8022 47.2058C17.9439 46.5491 17.1155 45.8904 16.3171 45.2296C15.5248 44.5739 14.7623 43.9162 14.0297 43.2568C5.40316 35.4909 1 27.5372 1 19.5Z"
              stroke="url(#paint1_linear_513_4203)"
              strokeWidth="2"
            />
            <path
              d="M1 19.5C1 13.2653 3.64656 7.08683 8.86145 1H381.631L369.275 43.7222L368.697 45.7222L368.118 47.7222L347.263 119.832C267.643 114.362 196.802 104.792 140.233 92.3042C83.4286 79.7642 41.1583 64.3103 18.8022 47.2058C17.9439 46.5491 17.1155 45.8904 16.3171 45.2296C15.5248 44.5739 14.7623 43.9162 14.0297 43.2568C5.40316 35.4909 1 27.5372 1 19.5Z"
              stroke="url(#paint2_linear_513_4203)"
              strokeWidth="2"
            />
          </g>
          <g id="blueBets" filter="url(#filter0_bi_3912_6463)">
            <path
              d="M8.40422 0C2.88487 6.32182 0 12.8395 0 19.5C0 27.9314 4.62286 36.1339 13.3607 44C14.1039 44.6691 14.877 45.3358 15.6795 46C16.4881 46.6692 17.3266 47.3359 18.1945 48C63.2817 82.4959 188.034 109.958 348 120.885L369.079 48L369.657 46L370.236 44L382.961 0H8.40422Z"
              fill="#28080B"
            />
            <path
              d="M8.40422 0C2.88487 6.32182 0 12.8395 0 19.5C0 27.9314 4.62286 36.1339 13.3607 44C14.1039 44.6691 14.877 45.3358 15.6795 46C16.4881 46.6692 17.3266 47.3359 18.1945 48C63.2817 82.4959 188.034 109.958 348 120.885L369.079 48L369.657 46L370.236 44L382.961 0H8.40422Z"
              fill="url(#paint0_linear_3912_6463)"
            />
            <path
              d="M1 19.5C1 13.2653 3.64656 7.08683 8.86145 1H381.631L369.275 43.7222L368.697 45.7222L368.118 47.7222L347.263 119.832C267.643 114.362 196.802 104.792 140.233 92.3042C83.4286 79.7642 41.1583 64.3103 18.8022 47.2058C17.9439 46.5491 17.1155 45.8904 16.3171 45.2296C15.5248 44.5739 14.7623 43.9162 14.0297 43.2568C5.40316 35.4909 1 27.5372 1 19.5Z"
              stroke="url(#paint1_linear_3912_6463)"
              strokeWidth="2"
            />
            <path
              d="M1 19.5C1 13.2653 3.64656 7.08683 8.86145 1H381.631L369.275 43.7222L368.697 45.7222L368.118 47.7222L347.263 119.832C267.643 114.362 196.802 104.792 140.233 92.3042C83.4286 79.7642 41.1583 64.3103 18.8022 47.2058C17.9439 46.5491 17.1155 45.8904 16.3171 45.2296C15.5248 44.5739 14.7623 43.9162 14.0297 43.2568C5.40316 35.4909 1 27.5372 1 19.5Z"
              stroke="url(#paint2_linear_3912_6463)"
              strokeWidth="2"
            />
          </g>
        </>
      ) : (
        <>
          <g filter="url(#filter0_bi_3770_33865)">
            <path
              d="M8.40422 0C2.88487 6.32182 0 12.8395 0 19.5C0 27.9314 4.62286 36.1339 13.3607 44C14.1039 44.6691 14.877 45.3358 15.6795 46C16.4881 46.6692 17.3266 47.3359 18.1945 48C63.2817 82.4959 188.034 109.958 348 120.885L369.079 48L369.657 46L370.236 44L382.961 0H8.40422Z"
              fill="#28080B"
            />
            <path
              d="M8.40422 0C2.88487 6.32182 0 12.8395 0 19.5C0 27.9314 4.62286 36.1339 13.3607 44C14.1039 44.6691 14.877 45.3358 15.6795 46C16.4881 46.6692 17.3266 47.3359 18.1945 48C63.2817 82.4959 188.034 109.958 348 120.885L369.079 48L369.657 46L370.236 44L382.961 0H8.40422Z"
              fill="url(#paint0_linear_3770_33865)"
            />
            <path
              d="M1 19.5C1 13.2653 3.64656 7.08683 8.86145 1H381.631L369.275 43.7222L368.697 45.7222L368.118 47.7222L347.263 119.832C267.643 114.362 196.802 104.792 140.233 92.3042C83.4286 79.7642 41.1583 64.3103 18.8022 47.2058C17.9439 46.5491 17.1155 45.8904 16.3171 45.2296C15.5248 44.5739 14.7623 43.9162 14.0297 43.2568C5.40316 35.4909 1 27.5372 1 19.5Z"
              stroke="url(#paint1_linear_3770_33865)"
              strokeWidth="2"
            />
            <path
              d="M1 19.5C1 13.2653 3.64656 7.08683 8.86145 1H381.631L369.275 43.7222L368.697 45.7222L368.118 47.7222L347.263 119.832C267.643 114.362 196.802 104.792 140.233 92.3042C83.4286 79.7642 41.1583 64.3103 18.8022 47.2058C17.9439 46.5491 17.1155 45.8904 16.3171 45.2296C15.5248 44.5739 14.7623 43.9162 14.0297 43.2568C5.40316 35.4909 1 27.5372 1 19.5Z"
              stroke="url(#paint2_linear_3770_33865)"
              strokeWidth="2"
            />
          </g>
          <g id="blueBets" filter="url(#filter0_bi_533_7383)">
            <path
              d="M8.40422 0C2.88487 6.32182 0 12.8395 0 19.5C0 27.9314 4.62286 36.1339 13.3607 44C14.1039 44.6691 14.877 45.3358 15.6795 46C16.4881 46.6692 17.3266 47.3359 18.1945 48C63.2817 82.4959 188.034 109.958 348 120.885L369.079 48L369.657 46L370.236 44L382.961 0H8.40422Z"
              fill="#28080B"
            />
            <path
              d="M8.40422 0C2.88487 6.32182 0 12.8395 0 19.5C0 27.9314 4.62286 36.1339 13.3607 44C14.1039 44.6691 14.877 45.3358 15.6795 46C16.4881 46.6692 17.3266 47.3359 18.1945 48C63.2817 82.4959 188.034 109.958 348 120.885L369.079 48L369.657 46L370.236 44L382.961 0H8.40422Z"
              fill="url(#paint0_linear_533_7383)"
            />
            <path
              d="M1 19.5C1 13.2653 3.64656 7.08683 8.86145 1H381.631L369.275 43.7222L368.697 45.7222L368.118 47.7222L347.263 119.832C267.643 114.362 196.802 104.792 140.233 92.3042C83.4286 79.7642 41.1583 64.3103 18.8022 47.2058C17.9439 46.5491 17.1155 45.8904 16.3171 45.2296C15.5248 44.5739 14.7623 43.9162 14.0297 43.2568C5.40316 35.4909 1 27.5372 1 19.5Z"
              stroke="url(#paint1_linear_533_7383)"
              strokeWidth="2"
            />
            <path
              d="M1 19.5C1 13.2653 3.64656 7.08683 8.86145 1H381.631L369.275 43.7222L368.697 45.7222L368.118 47.7222L347.263 119.832C267.643 114.362 196.802 104.792 140.233 92.3042C83.4286 79.7642 41.1583 64.3103 18.8022 47.2058C17.9439 46.5491 17.1155 45.8904 16.3171 45.2296C15.5248 44.5739 14.7623 43.9162 14.0297 43.2568C5.40316 35.4909 1 27.5372 1 19.5Z"
              stroke="url(#paint2_linear_533_7383)"
              strokeWidth="2"
            />
          </g>
        </>
      )}

      <SlotContent
        bet={bet}
        isCurrent={isCurrent}
        onBetConfirm={onBetConfirm}
        onBetClear={onBetClear}
        isMinimized={isMinimized}
        isWinner={isWinner}
        slot={SLOTS.player}
        deviceType={Device.Desktop}
        hasPlacedBet={hasPlacedBet}
        innerPositionsConfig={
          isMinimized ? minimizedInnerContentPositions : maximizedInnerContentPositions
        }
        size={size}
        isActiveJP={isActiveJP}
        isBettingTime={isBettingTime}
      />

      {isMinimized ? (
        <>
          <defs>
            <filter
              id="filter0_bi_513_4203"
              x="-5.65752"
              y="-5.65752"
              width="394.276"
              height="132.2"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_513_4203"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_513_4203"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_513_4203" />
            </filter>
            <linearGradient
              id="paint0_linear_513_4203"
              x1="191.481"
              y1="0"
              x2="191.481"
              y2="120.885"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#41B6E6" />
              <stop offset="0.489583" stopColor="#41B6E6" stopOpacity="0.4" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_513_4203"
              x1="191.481"
              y1="0"
              x2="191.481"
              y2="120.885"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_513_4203"
              x1="382.961"
              y1="60.4426"
              x2="0"
              y2="60.4426"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#57B5E3" stopOpacity="0" />
              <stop offset="0.5" stopColor="#57B5E3" />
              <stop offset="1" stopColor="#57B5E3" stopOpacity="0" />
            </linearGradient>
          </defs>
          <defs id="blueBetsDefs">
            <filter
              id="filter0_bi_3912_6463"
              x="-5.65752"
              y="-5.65752"
              width="394.276"
              height="132.2"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3912_6463"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_3912_6463"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3912_6463" />
            </filter>
            <linearGradient
              id="paint0_linear_3912_6463"
              x1="191.481"
              y1="0"
              x2="191.481"
              y2="120.885"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#41B6E6" />
              <stop offset="0.53125" stopColor="#41B6E6" stopOpacity="0.1" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3912_6463"
              x1="191.481"
              y1="0"
              x2="191.481"
              y2="120.885"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3912_6463"
              x1="382.961"
              y1="60.4426"
              x2="0"
              y2="60.4426"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" />
              <stop offset="0.5" stopColor="#57B5E3" />
              <stop offset="1" stopColor="#FFF7EB" />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          <defs>
            <filter
              id="filter0_bi_3770_33865"
              x="-5.65752"
              y="-5.65752"
              width="394.276"
              height="132.2"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3770_33865"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_3770_33865"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3770_33865" />
            </filter>
            <linearGradient
              id="paint0_linear_3770_33865"
              x1="191.481"
              y1="0"
              x2="191.481"
              y2="120.885"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#41B6E6" />
              <stop offset="1" stopColor="#41B6E6" stopOpacity="0.4" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3770_33865"
              x1="191.481"
              y1="0"
              x2="191.481"
              y2="120.885"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3770_33865"
              x1="382.961"
              y1="60.4426"
              x2="0"
              y2="60.4426"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#57B5E3" stopOpacity="0" />
              <stop offset="0.5" stopColor="#57B5E3" />
              <stop offset="1" stopColor="#57B5E3" stopOpacity="0" />
            </linearGradient>
          </defs>
          <defs id="blueBetsDefs">
            <filter
              id="filter0_bi_533_7383"
              x="-5.65752"
              y="-5.65752"
              width="394.276"
              height="132.2"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_533_7383"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_533_7383"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_533_7383" />
            </filter>
            <linearGradient
              id="paint0_linear_533_7383"
              x1="191.481"
              y1="0"
              x2="191.481"
              y2="120.885"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#41B6E6" />
              <stop offset="1" stopColor="#41B6E6" stopOpacity="0.1" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_533_7383"
              x1="191.481"
              y1="0"
              x2="191.481"
              y2="120.885"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_533_7383"
              x1="382.961"
              y1="60.4426"
              x2="0"
              y2="60.4426"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#57B5E3" stopOpacity="0" />
              <stop offset="0.5" stopColor="#57B5E3" />
              <stop offset="1" stopColor="#57B5E3" stopOpacity="0" />
            </linearGradient>
          </defs>
        </>
      )}
    </Wrapper>
  );
};
