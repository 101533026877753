import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-bottom: 40rem;
  z-index: 100;

  ${({ theme: { background } }) => css`
    background-color: ${background.primary};
  `}
`;

export const LoaderWrapper = styled.div`
  height: 64rem;
  width: 64rem;
  margin: auto;
`;

export const LoadingText = styled.span`
  font-size: 14rem;
  line-height: 18rem;
  text-transform: uppercase;
`;
