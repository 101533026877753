import styled, { css } from 'styled-components';
import { Device } from 'types';
import { getHorizontalTickerDuration, getVerticalTickerDuration } from './helpers';
import {
  AspectRatioIphone,
  getAspectRatioIphone,
  isInIframe,
  isOldBrowserIOS,
} from 'hooks/getDefineDevEnv';

export const WinnerStyled = styled.div`
  display: flex;
  text-transform: uppercase;
  align-items: center;

  svg {
    margin-right: 2px;
  }
`;

export const WinAmount = styled.span`
  min-width: fit-content;
  ${({ theme: { text } }) => css`
    color: ${text.secondaryDefault};
  `}
`;

export const WinnerName = styled.span`
  color: rgba(255, 247, 235, 0.8);
`;

export const ContentWrapper = styled.div`
  text-align: left;
  white-space: nowrap;
  margin: 0;
  display: flex;
`;

export const Wrapper = styled.div<{
  $deviceType: Device;
  $isHiding: boolean;
  $winnersQty: number;
  $contentSize: number;
  $containerSize: number;
}>`
  position: absolute;
  overflow: hidden;
  z-index: 3;
  opacity: 1;

  ${({ $isHiding }) =>
    $isHiding &&
    `
    transition: 2s;
    -webkit-transition: 2s;
    opacity: 0;
    pointer-events: none;
`}
  ${({ $deviceType, $winnersQty, $contentSize, $containerSize }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        top: 256rem;
        left: 24rem;
        height: 140rem;
        max-width: 350rem;

        ${ContentWrapper} {
          flex-direction: column;
          min-height: 140rem;
          transform: translateY(100%);
          animation: verticalMoving ${getVerticalTickerDuration($winnersQty)} linear;

          @keyframes verticalMoving {
            0% {
              transform: translateY(224rem);
            }
            100% {
              transform: translateY(-100%);
            }
          }
        }

        ${WinnerStyled} {
          width: 100%;
          font-size: 14rem;
          line-height: 16rem;
          height: 16rem;
          margin-bottom: 2rem;
        }

        ${WinAmount} {
          margin-right: 4rem;
        }

        ${WinnerName} {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 400rem;
        height: 16rem;
        width: 768rem;
        display: flex;

        ${ContentWrapper} {
          width: 1000vw;
          transform: translateX(100%);
          animation: horizontalTabletMoving
            ${getHorizontalTickerDuration($containerSize, $contentSize, $deviceType)} linear;

          @keyframes horizontalTabletMoving {
            0% {
              transform: translateX(768rem);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        }

        ${WinnerStyled} {
          font-size: 14rem;
          line-height: 16rem;
          height: 16rem;
          margin-left: 16rem;
          min-width: min-content;
        }

        ${WinAmount} {
          margin-right: 2rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? isInIframe()
            ? '199rem'
            : '172rem'
          : '203rem'};
        height: 20rem;
        width: 360rem;
        display: flex;
        z-index: 4;

        svg {
          height: 100%;
          width: auto;
        }

        ${ContentWrapper} {
          width: 1000vw;
          transform: translateX(100%);
          animation: horizontalMobileMoving
            ${getHorizontalTickerDuration($containerSize, $contentSize, $deviceType)} linear;

          @keyframes horizontalMobileMoving {
            0% {
              transform: translateX(360rem);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        }

        ${WinnerStyled} {
          font-size: 8rem;
          height: 8rem;
          margin-left: 8rem;
          min-width: min-content;
        }

        ${WinAmount} {
          margin-right: 2rem;
        }
      `;
    }
  }}
`;
