import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { Device } from 'types';
import { BaseDrawnBall } from './DrawnBall.base';
import { IDrawnBall } from './types';

export const DrawnBallResponsive = (props: IDrawnBall) => (
  <>
    <DesktopComponent>
      <BaseDrawnBall {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseDrawnBall {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseDrawnBall {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BaseDrawnBall {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
