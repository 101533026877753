import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { RestService } from 'services/RestAPIService';
import { store } from './core/store';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { getDefineDevEnv, isOldBrowserIOS } from './hooks/getDefineDevEnv';

import './index.css';

RestService.setCredentials({ URL: process.env.REACT_APP_API_URL || 'https://player-api.com/' });

Sentry.init({
  dsn: 'https://8569a02c5450594c93061a74de2438e8@o4507423836864512.ingest.de.sentry.io/4507423978094672',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENV_NAME,
});

Sentry.setTag('version', process.env.REACT_APP_PLATFORM_VERSION);
Sentry.setTag('tz', navigator.language);
Sentry.setTag('language_browser', Intl.DateTimeFormat().resolvedOptions().timeZone);
Sentry.setTag('is_old_browser', isOldBrowserIOS());

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
