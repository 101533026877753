import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BallsCounterRowLandscape } from './BallsCounterRow.landscape';
import { BallsCounterRowMobile } from './BallsCounterRow.mobile';
import { BallsCounterRowTablet } from './BallsCounterRow.tablet';
import { IBallsCounterRowProps } from './types';

export const BallsCounterRow = (props: IBallsCounterRowProps) => (
  <>
    <DesktopComponent>
      <BallsCounterRowLandscape {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BallsCounterRowLandscape {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BallsCounterRowTablet {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BallsCounterRowMobile {...props} />
    </MobileComponent>
  </>
);
