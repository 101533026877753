export enum ACTIONS {
  SAVE_TABLE_SETTINGS_TO_STORE = 'SAVE_TABLE_SETTINGS_TO_STORE',
  UDATE_GAME_ID = 'UPDATE_GAME_ID',
  UPDATE_DEALER = 'UPDATE_DEALER',
}

export interface IBetLimit {
  min: number;
  max: number;
}

export interface ITableSettingsState {
  tableId: string;
  gameId: string | null;
  displayingWinBall: number;
  dealer: {
    nickname: string;
  };
  betLimit: IBetLimit;
}
