import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { Device, IJackpotSlot } from 'types';
import { SlotJackpotLandscape } from './SlotJackpot.landscape';
import { SlotJackpotMobile } from './SlotJackpot.mobile';
import { SlotJackpotTablet } from './SlotJackpot.tablet';
import { JackpotMobile } from 'widgets/Slots/styles';

export const SlotJackpot = ({
  jackpotSum,
  isActiveSlot,
  stage,
  dateTimer,
  onClick,
}: IJackpotSlot) => (
  <>
    <DesktopComponent>
      <SlotJackpotLandscape
        jackpotSum={jackpotSum}
        isActiveSlot={isActiveSlot}
        stage={stage}
        dateTimer={dateTimer}
        onClick={onClick}
      />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <SlotJackpotLandscape
        jackpotSum={jackpotSum}
        isActiveSlot={isActiveSlot}
        stage={stage}
        dateTimer={dateTimer}
        onClick={onClick}
      />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <SlotJackpotTablet
        jackpotSum={jackpotSum}
        isActiveSlot={isActiveSlot}
        stage={stage}
        dateTimer={dateTimer}
        onClick={onClick}
      />
    </TabletPortraitComponent>
    <MobileComponent>
      <SlotJackpotMobile
        jackpotSum={jackpotSum}
        isActiveSlot={isActiveSlot}
        stage={stage}
        dateTimer={dateTimer}
        onClick={onClick}
      />
    </MobileComponent>
  </>
);
