import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { Device, SLOTS } from 'types';
import { Wrapper } from './styles';
import { ISlotInnerPositionsConfig, SlotContent } from '../SlotContent';

export const SlotGoldLandscape = ({
  activeChip,
  bet,
  isBettingTime,
  isWinner,
  hasPlacedBet,
  isCurrent,
  isMinimized,
  size = { height: 126, width: 302 },
  isActiveJP,
  onClick,
  onBetConfirm,
  onBetClear,
  onErrorClick,
}: ISlotProps) => {
  const minimizedInnerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '218',
      positionY: '4',
      size: 27,
    },
    confirmBet: {
      positionX: '193',
      positionY: '-18',
    },
  };

  const maximizedInnerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '51',
      positionY: '60',
      size: 40,
    },
    confirmBet: {
      positionX: '193',
      positionY: '-18',
    },
  };

  return (
    <Wrapper
      $activeChip={activeChip}
      $isActive={hasPlacedBet}
      $isBettingTime={isBettingTime}
      $isWinner={isWinner}
      onClick={isBettingTime ? onClick : onErrorClick}
      xmlns="http://www.w3.org/2000/svg"
      width={size.width}
      height={size.height}
      viewBox="0 0 302 126"
      x="352"
      y="0"
      fill="none">
      {isMinimized ? (
        <>
          <g filter="url(#filter0_bi_513_4204)">
            <path
              d="M152.44 125.999C152.631 125.999 152.819 125.999 153 125.999C205.091 125.947 254.805 124.215 302 121.046L281.316 48L280.75 46L280.184 44L267.724 0H153H149H34.9845L22.2791 44L21.7016 46L21.1241 48L0 121.154C46.8942 124.266 97.2938 125.96 149 125.999C149.621 126 150.378 126 151 126C151.457 126 151.958 126 152.44 125.999Z"
              fill="#28080B"
            />
            <path
              d="M152.44 125.999C152.631 125.999 152.819 125.999 153 125.999C205.091 125.947 254.805 124.215 302 121.046L281.316 48L280.75 46L280.184 44L267.724 0H153H149H34.9845L22.2791 44L21.7016 46L21.1241 48L0 121.154C46.8942 124.266 97.2938 125.96 149 125.999C149.621 126 150.378 126 151 126C151.457 126 151.958 126 152.44 125.999Z"
              fill="url(#paint0_linear_513_4204)"
            />
            <path
              d="M152.999 124.999L152.439 124.999C151.958 125 151.457 125 151 125C150.643 125 150.242 125 149.847 125C149.554 125 149.265 124.999 149.001 124.999C97.7706 124.96 47.8251 123.296 1.30535 120.238L22.0848 48.2774L22.6623 46.2774L23.2398 44.2774L35.7366 1H149H153H266.968L279.221 44.2724L279.788 46.2725L280.354 48.2724L300.701 120.13C253.896 123.246 204.616 124.947 152.999 124.999Z"
              stroke="url(#paint1_linear_513_4204)"
              strokeWidth="2"
            />
            <path
              d="M152.999 124.999L152.439 124.999C151.958 125 151.457 125 151 125C150.643 125 150.242 125 149.847 125C149.554 125 149.265 124.999 149.001 124.999C97.7706 124.96 47.8251 123.296 1.30535 120.238L22.0848 48.2774L22.6623 46.2774L23.2398 44.2774L35.7366 1H149H153H266.968L279.221 44.2724L279.788 46.2725L280.354 48.2724L300.701 120.13C253.896 123.246 204.616 124.947 152.999 124.999Z"
              stroke="url(#paint2_linear_513_4204)"
              strokeWidth="2"
            />
          </g>
          <g id="goldBets" filter="url(#filter0_bi_3912_6464)">
            <path
              d="M152.44 125.999C152.631 125.999 152.819 125.999 153 125.999C205.091 125.947 254.805 124.215 302 121.046L281.316 48L280.75 46L280.184 44L267.724 0H153H149H34.9845L22.2791 44L21.7016 46L21.1241 48L0 121.154C46.8942 124.266 97.2938 125.96 149 125.999C149.621 126 150.378 126 151 126C151.457 126 151.958 126 152.44 125.999Z"
              fill="#28080B"
            />
            <path
              d="M152.44 125.999C152.631 125.999 152.819 125.999 153 125.999C205.091 125.947 254.805 124.215 302 121.046L281.316 48L280.75 46L280.184 44L267.724 0H153H149H34.9845L22.2791 44L21.7016 46L21.1241 48L0 121.154C46.8942 124.266 97.2938 125.96 149 125.999C149.621 126 150.378 126 151 126C151.457 126 151.958 126 152.44 125.999Z"
              fill="url(#paint0_linear_3912_6464)"
            />
            <path
              d="M152.999 124.999L152.439 124.999C151.958 125 151.457 125 151 125C150.643 125 150.242 125 149.847 125C149.554 125 149.265 124.999 149.001 124.999C97.7706 124.96 47.8251 123.296 1.30535 120.238L22.0848 48.2774L22.6623 46.2774L23.2398 44.2774L35.7366 1H149H153H266.968L279.221 44.2724L279.788 46.2725L280.354 48.2724L300.701 120.13C253.896 123.246 204.616 124.947 152.999 124.999Z"
              stroke="url(#paint1_linear_3912_6464)"
              strokeWidth="2"
            />
            <path
              d="M152.999 124.999L152.439 124.999C151.958 125 151.457 125 151 125C150.643 125 150.242 125 149.847 125C149.554 125 149.265 124.999 149.001 124.999C97.7706 124.96 47.8251 123.296 1.30535 120.238L22.0848 48.2774L22.6623 46.2774L23.2398 44.2774L35.7366 1H149H153H266.968L279.221 44.2724L279.788 46.2725L280.354 48.2724L300.701 120.13C253.896 123.246 204.616 124.947 152.999 124.999Z"
              stroke="url(#paint2_linear_3912_6464)"
              strokeWidth="2"
            />
          </g>
        </>
      ) : (
        <>
          <g filter="url(#filter0_bi_3770_45702)">
            <path
              d="M152.44 125.999C152.631 125.999 152.819 125.999 153 125.999C205.091 125.947 254.805 124.215 302 121.046L281.316 48L280.75 46L280.184 44L267.724 0H153H149H34.9845L22.2791 44L21.7016 46L21.1241 48L0 121.154C46.8942 124.266 97.2938 125.96 149 125.999C149.621 126 150.378 126 151 126C151.457 126 151.958 126 152.44 125.999Z"
              fill="#28080B"
            />
            <path
              d="M152.44 125.999C152.631 125.999 152.819 125.999 153 125.999C205.091 125.947 254.805 124.215 302 121.046L281.316 48L280.75 46L280.184 44L267.724 0H153H149H34.9845L22.2791 44L21.7016 46L21.1241 48L0 121.154C46.8942 124.266 97.2938 125.96 149 125.999C149.621 126 150.378 126 151 126C151.457 126 151.958 126 152.44 125.999Z"
              fill="url(#paint0_linear_3770_45702)"
            />
            <path
              d="M152.999 124.999L152.439 124.999C151.958 125 151.457 125 151 125C150.643 125 150.242 125 149.847 125C149.554 125 149.265 124.999 149.001 124.999C97.7706 124.96 47.8251 123.296 1.30535 120.238L22.0848 48.2774L22.6623 46.2774L23.2398 44.2774L35.7366 1H149H153H266.968L279.221 44.2724L279.788 46.2725L280.354 48.2724L300.701 120.13C253.896 123.246 204.616 124.947 152.999 124.999Z"
              stroke="url(#paint1_linear_3770_45702)"
              strokeWidth="2"
            />
            <path
              d="M152.999 124.999L152.439 124.999C151.958 125 151.457 125 151 125C150.643 125 150.242 125 149.847 125C149.554 125 149.265 124.999 149.001 124.999C97.7706 124.96 47.8251 123.296 1.30535 120.238L22.0848 48.2774L22.6623 46.2774L23.2398 44.2774L35.7366 1H149H153H266.968L279.221 44.2724L279.788 46.2725L280.354 48.2724L300.701 120.13C253.896 123.246 204.616 124.947 152.999 124.999Z"
              stroke="url(#paint2_linear_3770_45702)"
              strokeWidth="2"
            />
          </g>

          <g id="goldBets" filter="url(#filter0_bi_518_7575)">
            <path
              d="M152.44 125.999C152.631 125.999 152.819 125.999 153 125.999C205.091 125.947 254.805 124.215 302 121.046L281.316 48L280.75 46L280.184 44L267.724 0H153H149H34.9845L22.2791 44L21.7016 46L21.1241 48L0 121.154C46.8942 124.266 97.2938 125.96 149 125.999C149.621 126 150.378 126 151 126C151.457 126 151.958 126 152.44 125.999Z"
              fill="#28080B"
            />
            <path
              d="M152.44 125.999C152.631 125.999 152.819 125.999 153 125.999C205.091 125.947 254.805 124.215 302 121.046L281.316 48L280.75 46L280.184 44L267.724 0H153H149H34.9845L22.2791 44L21.7016 46L21.1241 48L0 121.154C46.8942 124.266 97.2938 125.96 149 125.999C149.621 126 150.378 126 151 126C151.457 126 151.958 126 152.44 125.999Z"
              fill="url(#paint0_linear_518_7575)"
            />
            <path
              d="M152.999 124.999L152.439 124.999C151.958 125 151.457 125 151 125C150.643 125 150.242 125 149.847 125C149.554 125 149.265 124.999 149.001 124.999C97.7706 124.96 47.8251 123.296 1.30535 120.238L22.0848 48.2774L22.6623 46.2774L23.2398 44.2774L35.7366 1H149H153H266.968L279.221 44.2724L279.788 46.2725L280.354 48.2724L300.701 120.13C253.896 123.246 204.616 124.947 152.999 124.999Z"
              stroke="url(#paint1_linear_518_7575)"
              strokeWidth="2"
            />
            <path
              d="M152.999 124.999L152.439 124.999C151.958 125 151.457 125 151 125C150.643 125 150.242 125 149.847 125C149.554 125 149.265 124.999 149.001 124.999C97.7706 124.96 47.8251 123.296 1.30535 120.238L22.0848 48.2774L22.6623 46.2774L23.2398 44.2774L35.7366 1H149H153H266.968L279.221 44.2724L279.788 46.2725L280.354 48.2724L300.701 120.13C253.896 123.246 204.616 124.947 152.999 124.999Z"
              stroke="url(#paint2_linear_518_7575)"
              strokeWidth="2"
            />
          </g>
        </>
      )}
      <SlotContent
        bet={bet}
        isCurrent={isCurrent}
        onBetConfirm={onBetConfirm}
        onBetClear={onBetClear}
        isMinimized={isMinimized}
        isWinner={isWinner}
        slot={SLOTS.tie}
        deviceType={Device.Desktop}
        hasPlacedBet={hasPlacedBet}
        innerPositionsConfig={
          isMinimized ? minimizedInnerContentPositions : maximizedInnerContentPositions
        }
        size={size}
        isActiveJP={isActiveJP}
        isBettingTime={isBettingTime}
      />

      {isMinimized ? (
        <>
          <defs>
            <filter
              id="filter0_bi_513_4204"
              x="-5.65752"
              y="-5.65752"
              width="313.315"
              height="137.315"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_513_4204"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_513_4204"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_513_4204" />
            </filter>
            <linearGradient
              id="paint0_linear_513_4204"
              x1="151"
              y1="0"
              x2="151"
              y2="126"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FEDB00" />
              <stop offset="0.432292" stopColor="#F9B738" stopOpacity="0.62" />
              <stop offset="0.9999" stopColor="#DCA231" stopOpacity="0.62" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_513_4204"
              x1="151"
              y1="0"
              x2="151"
              y2="126"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_513_4204"
              x1="302"
              y1="63"
              x2="0"
              y2="63"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#D4BB93" stopOpacity="0" />
              <stop offset="0.5" stopColor="#D4BB93" />
              <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
            </linearGradient>
          </defs>
          <defs id="goldBetsDefs">
            <filter
              id="filter0_bi_3912_6464"
              x="-5.65752"
              y="-5.65752"
              width="313.315"
              height="137.315"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3912_6464"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_3912_6464"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3912_6464" />
            </filter>
            <linearGradient
              id="paint0_linear_3912_6464"
              x1="151"
              y1="0"
              x2="151"
              y2="126"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FEDB00" />
              <stop offset="0.515625" stopColor="#CD7829" stopOpacity="0.69" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3912_6464"
              x1="151"
              y1="0"
              x2="151"
              y2="126"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3912_6464"
              x1="302"
              y1="63"
              x2="0"
              y2="63"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#D4BB93" stopOpacity="0" />
              <stop offset="0.5" stopColor="#D4BB93" />
              <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
            </linearGradient>
          </defs>
        </>
      ) : (
        <>
          <defs>
            <filter
              id="filter0_bi_3770_45702"
              x="-5.65752"
              y="-5.65752"
              width="313.315"
              height="137.315"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_3770_45702"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_3770_45702"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3770_45702" />
            </filter>
            <linearGradient
              id="paint0_linear_3770_45702"
              x1="151"
              y1="0"
              x2="151"
              y2="126"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FEDB00" />
              <stop offset="0.9999" stopColor="#DCA231" stopOpacity="0.62" />
              <stop offset="1" stopColor="#F9B738" stopOpacity="0.62" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3770_45702"
              x1="151"
              y1="0"
              x2="151"
              y2="126"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3770_45702"
              x1="302"
              y1="63"
              x2="0"
              y2="63"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#D4BB93" stopOpacity="0" />
              <stop offset="0.5" stopColor="#D4BB93" />
              <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
            </linearGradient>
          </defs>

          <defs id="goldBetsDefs">
            <filter
              id="filter0_bi_518_7575"
              x="-5.65752"
              y="-5.65752"
              width="313.315"
              height="137.315"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.82876" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_518_7575"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_518_7575"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2.82876" />
              <feGaussianBlur stdDeviation="8.48628" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
              />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_518_7575" />
            </filter>
            <linearGradient
              id="paint0_linear_518_7575"
              x1="151"
              y1="0"
              x2="151"
              y2="126"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FEDB00" />
              <stop offset="1" stopColor="#CD7829" stopOpacity="0.69" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_518_7575"
              x1="151"
              y1="0"
              x2="151"
              y2="126"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFF7EB" stopOpacity="0.4" />
              <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_518_7575"
              x1="302"
              y1="63"
              x2="0"
              y2="63"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#D4BB93" stopOpacity="0" />
              <stop offset="0.5" stopColor="#D4BB93" />
              <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
            </linearGradient>
          </defs>
        </>
      )}
    </Wrapper>
  );
};
