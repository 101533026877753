import { useCallback } from 'react';

import { ModeGame } from 'core/widgets/Auth/types';

export const useCloseGame = (mode: ModeGame, message: string | null, lobbyUrl: string | null) => {
  const closeGame = useCallback(() => {
    if (mode === ModeGame.IFRAME) {
      window.parent.postMessage(message ? message : 'GT:EXIT', '*');

      return;
    }

    if (mode === ModeGame.HOLI && lobbyUrl) {
      if (window.top) {
        window.top.location.href = lobbyUrl;
      }

      return;
    }

    if (mode === ModeGame.HOLI && !lobbyUrl) {
      if (document.referrer) {
        window.location.href = document.referrer;
      } else {
        window.opener = null;
        window.open('', '_self');
        window.close();
      }
    }
  }, [mode, message, lobbyUrl]);

  return closeGame;
};
