import React from 'react';
import { IconNames, RightSideButton } from 'components';
import { IRepeatBetButtonProps } from './types';
import { IconStyled } from './styles';

export const RepeatBetButtonPortrait = ({
  onRepeatBetClick,
  isDisabled,
}: IRepeatBetButtonProps) => (
  <RightSideButton isDisabled={isDisabled} onClick={onRepeatBetClick}>
    <IconStyled name={isDisabled ? IconNames.RepeatBetDisabled : IconNames.RepeatBet} />
  </RightSideButton>
);
