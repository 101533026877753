import { SLOTS } from 'types';
import { createAction } from 'typesafe-actions';
import { ACTIONS } from './types';

export const setConfirmationSlotAction = createAction(
  ACTIONS.SET_CONFIRMATION_SLOT,
)<SLOTS | null>();

export const setClosestConfirmationSlotAction = createAction(
  ACTIONS.SET_CLOSEST_CONFIRMATION_SLOT,
)();

export const clearConfirmationSlotAction = createAction(ACTIONS.CLEAR_CONFIRMATION_SLOT)();
