import { ActionType, createReducer } from 'typesafe-actions';
import { saveTableSettingsToStoreAction, updateDealerAction, updateGameIdAction } from './actions';
import { ITableSettingsState } from './types';

export const tableSettingsState: ITableSettingsState | null = null;

const actions = {
  saveTableSettingsToStoreAction,
  updateGameIdAction,
  updateDealerAction,
};

export const tableSettingsReducer = createReducer<
  ITableSettingsState | null,
  ActionType<typeof actions>
>(tableSettingsState)
  .handleAction(
    saveTableSettingsToStoreAction,
    (
      state,
      { payload: { tableId, gameId, dealerNickname, minBetLimit, maxBetLimit, displayingWinBall } },
    ) => ({
      ...state,
      tableId,
      gameId,
      displayingWinBall,
      dealer: {
        nickname: dealerNickname,
      },
      betLimit: {
        min: minBetLimit,
        max: maxBetLimit,
      },
    }),
  )
  .handleAction(updateGameIdAction, (state, { payload: { gameId } }) => {
    if (state === null) {
      return null;
    }

    return {
      ...state,
      gameId,
    };
  })
  .handleAction(updateDealerAction, (state, { payload: { dealerNickname } }) => {
    if (state === null) {
      return null;
    }

    return {
      ...state,
      dealer: {
        nickname: dealerNickname,
      },
    };
  });
