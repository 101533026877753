import { put } from 'redux-saga/effects';
import { Notifications, NotificationsChat } from 'types';

import { OnNewChatMessageData } from '../../types';
import { addedMessageStore } from 'core/widgets/ChatPanel/actions';

export function* onNewChatMessageSaga({ type: notificationType, data }: OnNewChatMessageData) {
  if (notificationType === NotificationsChat.NEW_CHAT_MESSAGE) {
    yield put(addedMessageStore(data));
  }
}
