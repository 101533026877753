import React from 'react';
import { Device } from 'types';
import { transformToCurrency } from 'utils/transformToCurrency';
import { useTranslation } from 'react-i18next';
import { WinnerBall } from 'constants/common';
import { Wrapper, WinInfoText, WinAmount, WinInfoLabel, WinInfoValue, WinTitle } from './styles';
import { IDeviceWinStatusComponentProps } from './types';
import { BaseGradients } from './Components/Gradients.base';
import { Blue } from './utils/Blue';
import { Red } from './utils/Red';
import { Gold } from './utils/Gold';

export const BaseWinStatus = ({
  winnerBall,
  winnerBet,
  winnerAmount,
  deviceType = Device.Desktop,
  currency,
}: IDeviceWinStatusComponentProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper $deviceType={deviceType}>
      <svg
        width="464"
        height="268"
        viewBox="0 0 464 268"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        {winnerBall === WinnerBall.BLUE && <Blue />}
        {winnerBall === WinnerBall.RED && <Red />}
        {winnerBall === WinnerBall.GOLD && <Gold />}
        <WinTitle x="50%" y="94.5" dominantBaseline="central" textAnchor="middle">
          {t('winStatus.title')}
        </WinTitle>
        <WinInfoText x="50.5" y="223" dominantBaseline="hanging">
          <WinInfoLabel>{t('winStatus.betItemLabel')}: </WinInfoLabel>
          <WinInfoValue>{t(`ball.${winnerBall.toLowerCase()}`)}</WinInfoValue>
        </WinInfoText>
        {/* <WinInfoText x="273" y="223" dominantBaseline="hanging">
          <WinInfoLabel>|</WinInfoLabel>
        </WinInfoText> */}
        <WinInfoText x="287.5" y="223" dominantBaseline="hanging">
          <WinInfoLabel>{t('winStatus.betLabel')}: </WinInfoLabel>
          <WinInfoValue>{transformToCurrency({ value: winnerBet, currency })}</WinInfoValue>
        </WinInfoText>
        <WinAmount x="50%" y="145" dominantBaseline="hanging" textAnchor="middle">
          {transformToCurrency({ value: winnerAmount, isWithoutCurrencySeparator: true, currency })}
        </WinAmount>
        <BaseGradients winnerBall={winnerBall} />
      </svg>
    </Wrapper>
  );
};
