import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from 'types';
import { Icon, IconNames, Loader, PopupContainer } from 'components';
import { Wrapper, CloseButton, Head, RulesContainer, LoaderWrapper } from './styles';
import { IPopupConfirmProps } from './types';

export const BasePopupGameRules = forwardRef(
  ({ children, onClose, loading, deviceType = Device.Desktop }: IPopupConfirmProps, ref) => {
    const { t } = useTranslation();

    return (
      <PopupContainer deviceType={deviceType}>
        <Wrapper $deviceType={deviceType} ref={ref as any}>
          <Head $deviceType={deviceType}>{t('settings.gameRules.title')}</Head>
          <CloseButton onClick={onClose}>
            <Icon name={IconNames.Cross} />
          </CloseButton>
          {Boolean(loading) && (
            <LoaderWrapper $deviceType={deviceType}>
              <Loader />
            </LoaderWrapper>
          )}
          <RulesContainer $deviceType={deviceType}>{children}</RulesContainer>
        </Wrapper>
      </PopupContainer>
    );
  },
);
