import { createAction } from 'typesafe-actions';
import { WinnerBall } from 'constants/common';
import { ACTIONS } from './types';

export const updateBallsCounterAction = createAction(ACTIONS.UPDATE_BALLS_COUNTER)<{
  winnerBall: WinnerBall;
}>();

export const setInitialCounterStateAction = createAction(ACTIONS.SET_INITIAL_COUNTER_STATE)<{
  winnerBallsData: WinnerBall[];
}>();

export const resetCounterStateAction = createAction(ACTIONS.RESET_COUNTER_STATE)();
