import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { SlotGoldMaximizedTablet, SlotGoldMinimizedTablet } from './components';

export const SlotGoldTablet = (props: ISlotProps) => {
  const { isMinimized } = props;
  return isMinimized ? (
    <SlotGoldMinimizedTablet {...props} />
  ) : (
    <SlotGoldMaximizedTablet {...props} />
  );
};
