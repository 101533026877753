import { put, select } from 'redux-saga/effects';

import { Notifications } from 'types';
import {
  updateCurrentJackpotUserPointsJP,
  updateNextJackpotUserPointsJP,
} from 'core/widgets/Jackpot/actions';
import { OnJackpotPointAwardedData } from '../../types';
import { currentJackpotSelector, nextJackpotSelector } from 'core/widgets/Jackpot/selectors';
import { IJackpot } from 'core/widgets/Jackpot/types';

export function* onJackpotPointAwardedSaga({
  type: notificationType,
  data: result,
}: OnJackpotPointAwardedData) {
  const { points, jackpotId } = result;
  if (notificationType === Notifications.JACKPOT_POINT_AWARDED) {
    const { currentJackpot } = yield select(currentJackpotSelector);
    const nextJackpot: IJackpot = yield select(nextJackpotSelector);

    const isCurrent = jackpotId === currentJackpot.jackpotId;
    if (isCurrent) {
      yield put(
        updateCurrentJackpotUserPointsJP({
          jackpotId: currentJackpot.jackpotId,
          value: points ? points : 0,
        }),
      );
      return;
    }

    if (!isCurrent) {
      yield put(
        updateNextJackpotUserPointsJP({
          jackpotId: nextJackpot.jackpotId,
          value: points ? points : 0,
        }),
      );
    }
  }
}
