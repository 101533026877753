import { ActionType, createReducer } from 'typesafe-actions';
import { clearConfirmationSlotAction, setConfirmationSlotAction } from './actions';
import { IBetConfirmationState } from './types';

export const betConfirmationState: IBetConfirmationState = {
  position: {
    current: null,
    previous: null,
  },
};

const actions = {
  setConfirmationSlotAction,
  clearConfirmationSlotAction,
};

export const betConfirmationReducer = createReducer<
  IBetConfirmationState,
  ActionType<typeof actions>
>(betConfirmationState)
  .handleAction(setConfirmationSlotAction, (state, { payload }) => ({
    ...state,
    position: {
      ...state.position,
      current: payload,
    },
  }))
  .handleAction(clearConfirmationSlotAction, (state) => ({
    ...state,
    position: {
      ...state.position,
      previous: state.position.current,
      current: null,
    },
  }));
