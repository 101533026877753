import styled, { css } from 'styled-components';
import { Device } from 'types';
import backgroundSVG from './assets/background.svg';
import backgroundMobileSVG from './assets/background-mobile.svg';

export const Text = styled.p`
  font-weight: 400;
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;

export const Wrapper = styled.div<{ $deviceType: Device }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        bottom: 324rem;
        height: 40rem;
        width: 360rem;
        background-image: url(${backgroundSVG});

        ${Text} {
          font-size: 12px;
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 340rem;
        height: 40rem;
        width: 360rem;
        background-image: url(${backgroundSVG});

        ${Text} {
          font-size: 12px;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 164rem;
        height: 28rem;
        width: 184rem;
        background-image: url(${backgroundMobileSVG});

        ${Text} {
          font-size: 8px;
        }
      `;
    }
  }};
`;
