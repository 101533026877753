import { useCallback, useState } from 'react';

type ModalHookType = {
  isShown: boolean;
  openModal: () => void;
  closeModal: () => void;
};

export const useModal = (): ModalHookType => {
  const [isShown, setShown] = useState<boolean>(false);

  const openModal = useCallback(() => {
    setShown(true);
  }, []);

  const closeModal = useCallback(() => {
    setShown(false);
  }, []);

  return {
    isShown,
    openModal,
    closeModal,
  };
};
