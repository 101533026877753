import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { chipsMIN200 } from './chipsValues';

export const CHIPS_MIN_250: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 250,
  },
  ...chipsMIN200,
];
