import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { chipsMIN20 } from './chipsValues';

export const CHIPS_MIN_20: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 20,
  },
  ...chipsMIN20,
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 10e3,
  },
];
