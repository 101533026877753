export enum ACTIONS {
  CHANGE_VOLUME = 'CHANGE_VOLUME',
  APPLY_VOLUME = 'APPLY_VOLUME',
  CHANGE_MUTE = 'CHANGE_MUTE',
  APPLY_MUTE = 'APPLY_MUTE',
  INIT_SOUNDS = 'INIT_SOUNDS',
  CHANGE_IS_START_LOADING_SOUNDS = 'CHANGE_IS_START_LOADING_SOUNDS',
}

export interface ISoundSettings {
  music: {
    volume: number;
    mute: boolean | null;
  };
  soundEffects: {
    volume: number;
    mute: boolean | null;
  };
  digitalVoice: {
    volume: number;
    mute: boolean | null;
  };
  studioSound: {
    volume: number;
    mute: boolean | null;
  };
}

export interface ISoundSettingsState extends ISoundSettings {
  requested: {
    volume: number;
    mute: boolean;
  };
  applied: {
    volume: number;
    mute: boolean | null;
  };
  gameSoundMute: boolean;
  isStartLoadingSounds: boolean;
}

export enum Sounds {
  MUSIC = 'MUSIC',
  SOUND_EFFECTS = 'SOUND EFFECTS',
  DIGITAL_VOICE = 'DIGITAL VOICE',
  STUDIO_SOUND = 'STUDIO SOUND',
}
