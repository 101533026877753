import React, { FunctionComponent } from 'react';
import { ISvgSize } from 'types';
import * as Chips from './Chips';
import { Wrapper } from './styles';
import { CHIPS_COLORS } from 'widgets/Chips/data/currencies/colors';
import { getValueChip } from './ChipsValues';
import { DefaultChip } from './DefaultChip';
import { ActiveJackpotChip } from './ActiveJackpotChip';

export interface IChipComponentSvg {
  color?: CHIPS_COLORS;
  value?: number;
  slotId?: string;
}

export enum ChipNames {
  Chip_05 = 'Chip_05',
  Chip_1 = 'Chip_1',
  Chip_5 = 'Chip_5',
  Chip_10 = 'Chip_10',
  Chip_25 = 'Chip_25',
  Chip_50 = 'Chip_50',
  Chip_100 = 'Chip_100',
  Chip_500 = 'Chip_500',
  Chip_1k = 'Chip_1k',
  Chip_5k = 'Chip_5k',
  Chip_10k = 'Chip_10k',
}

export enum ActiveChipNames {
  Chip_05_active = 'Chip_05_active',
  Chip_1_active = 'Chip_1_active',
  Chip_5_active = 'Chip_5_active',
  Chip_10_active = 'Chip_10_active',
  Chip_25_active = 'Chip_25_active',
  Chip_50_active = 'Chip_50_active',
  Chip_100_active = 'Chip_100_active',
  Chip_500_active = 'Chip_500_active',
  Chip_1k_active = 'Chip_1k_active',
  Chip_5k_active = 'Chip_5k_active',
  Chip_10k_active = 'Chip_10k_active',
}

export interface IChipProps {
  size?: ISvgSize;
  showValue?: boolean;
  className?: string;
  isActiveJP: boolean;
  color?: CHIPS_COLORS;
  value?: number;
  slotId?: string;
}

const ChipsMap: { [key in ActiveChipNames]: FunctionComponent } = Chips;

// {value ? getValueChip(value) : null}

export const Chip = ({
  // name,
  size = { width: 68, height: 68 },
  showValue = false,
  className,
  isActiveJP,
  color,
  value,
  slotId,
}: IChipProps) => {
  return (
    <Wrapper
      $showValue={showValue}
      width={size.width}
      height={size.height}
      viewBox="0 0 68 68"
      className={className}>
      {isActiveJP ? (
        <ActiveJackpotChip value={value} color={color} slotId={slotId || ''} />
      ) : (
        <DefaultChip value={value} color={color} />
      )}
    </Wrapper>
  );
};
