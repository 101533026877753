import React from 'react';
import { TiltedMobileButtonWrapper, TiltedRightMobileButtonStyled } from './styles';
import { ITiltedMobileButtonProps } from '../types';
import { IconWrapper } from '../styles';

export const TiltedRightMobileButton = ({
  children,
  isActive,
  isDisabled,
  onClick,
  offsetLeft = 'initial',
  offsetRight = 'initial',
}: ITiltedMobileButtonProps) => (
  <TiltedMobileButtonWrapper
    $offsetLeft={offsetLeft}
    $offsetRight={offsetRight}
    id={`id=${offsetLeft}`}>
    <TiltedRightMobileButtonStyled
      $isActive={isActive}
      $isDisabled={isDisabled}
      width="64"
      height="28"
      viewBox="0 0 64 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={`id=${offsetLeft}`}>
      <g onClick={onClick}>
        <path
          id="trmb_path_background"
          d="M1.08711 27.5L24.23 0.5H62.9129L39.77 27.5H1.08711Z"
          fill="url(#paint0_linear_67_36669)"
          stroke="url(#paint1_linear_67_36669)"
        />
        <path
          id="trmb_path_inner_border"
          d="M1.08711 27.5L24.23 0.5H62.9129L39.77 27.5H1.08711Z"
          stroke="none"
        />
        <path
          id="trmb_path_outer_border"
          d="M1.08711 27.5L24.23 0.5H62.9129L39.77 27.5H1.08711Z"
          stroke="none"
        />
        <IconWrapper
          x="25"
          y="8"
          width="12"
          height="12"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          {children}
        </IconWrapper>
      </g>

      <defs>
        <linearGradient
          id="paint0_linear_67_36669"
          x1="19.1883"
          y1="28"
          x2="19.1883"
          y2="0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_67_36669"
          x1="-3"
          y1="14"
          x2="41.3766"
          y2="14"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>

        <linearGradient
          id="paint0_linear_67_36713"
          x1="32"
          y1="0"
          x2="32"
          y2="28"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#1B191A" />
          <stop offset="1" stopColor="#BB9370" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_67_36713"
          x1="32"
          y1="0"
          x2="32"
          y2="28"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_67_36713"
          x1="64"
          y1="14"
          x2="0"
          y2="14"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4BB93" stopOpacity="0" />
          <stop offset="0.5" stopColor="#D4BB93" />
          <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
        </linearGradient>
      </defs>
    </TiltedRightMobileButtonStyled>
  </TiltedMobileButtonWrapper>
);
