import { isOldBrowserIOS, getAspectRatioIphone, AspectRatioIphone } from 'hooks/getDefineDevEnv';
import styled, { css, keyframes } from 'styled-components';
import { Device } from 'types';

const winTextStyles = css`
  fill: #e7d5b9;
  overflow: visible;
`;

export const WinInfoText = styled.text`
  ${winTextStyles}
`;

export const WinTitle = styled.text`
  fill: #e7d5b9;
  text-transform: uppercase;
  letter-spacing: 3px;
`;

export const WinInfoLabel = styled.tspan`
  fill: ${({ theme: { text } }) => text.primary};
  fill-opacity: 0.6;
  text-transform: uppercase;
`;

export const WinInfoValue = styled.tspan`
  fill: ${({ theme: { text } }) => text.primary};
  text-transform: uppercase;
`;

export const WinAmount = styled.text`
  ${winTextStyles};
  letter-spacing: 3px;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-200px)translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0)translateX(-50%);
  }
`;

export const WrapperConfetti = styled.div<{ $deviceType?: Device }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
`;

export const Wrapper = styled.div<{
  $deviceType?: Device;
  $numberCharacters: number;
  $isPlayerWon: boolean;
}>`
  position: absolute;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
  z-index: 100;
  animation: ${fadeIn} 0.5s ease-out;

  svg {
    position: relative;
    z-index: 3;
  }

  ${({ $isPlayerWon }) => {
    if (!$isPlayerWon) {
      return css`
        #id_tablet {
          transform: translate(0, -26px);
        }
        #id_base {
          transform: translate(0, -36px);
        }
        #id_mobile {
          transform: translate(0, -28px);
        }
      `;
    }
  }}

  ${({ $deviceType, $numberCharacters, $isPlayerWon }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 112rem;
        height: 468rem;
        width: 664rem;
        z-index: 3;

        ${WinTitle} {
          font-size: 9px;
          line-height: 14px;
        }

        ${WinInfoText} {
          font-size: 12px;
          line-height: 14px;
        }

        ${WinAmount} {
          font-size: 40px;
          line-height: 60px;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        z-index: 3;

        align-items: center;
        height: 100%;
        width: 100%;
        height: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? '120%'
          : '100%'};
        width: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? '120%'
          : '100%'};

        margin-top: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? '-10%'
          : '0%'};

        svg {
          margin-top: -30%;
          width: 300%;
          height: auto;
          margin-top: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
            ? '-49.5%'
            : '-30%'};
        }

        text {
          font-size: ${$numberCharacters > 11 ? `${19 * (13 / $numberCharacters)}rem` : '20rem'};
          line-height: ${$numberCharacters ? '25rem' : '25rem'};
        }

        ${WinTitle} {
          font-size: 6rem;
          line-height: 12rem;
        }

        ${WinInfoText} {
          font-size: 8rem;
          line-height: 14rem;
        }

        ${WinAmount} {
          font-size: 24rem;
          line-height: 40rem;
          letter-spacing: 3rem;
        }
      `;
    }

    return css`
      bottom: 336rem;
      height: 268rem;
      width: 464rem;
      border-radius: 50%;

      ${WinTitle} {
        font-size: 9px;
        line-height: 14px;
      }

      ${WinInfoText} {
        font-size: 12px;
        line-height: 14px;
      }

      ${WinAmount} {
        font-size: 40px;
        line-height: 60px;
      }
    `;
  }}
`;

export const TextWinMobile = styled.div`
  color: #fff7eb;

  text-align: center;
  text-shadow: 0px 4px 0px #121011;
  font-size: 24rem;
  font-weight: 400;
  line-height: 40rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  z-index: 13;
  position: absolute;
  top: 228rem;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
`;

export const WinMobile = styled.div<{ $isDefaultFont: boolean }>`
  text-align: center;
  font-size: 36rem;
  // font-style: normal;
  font-weight: 700;
  line-height: 56rem;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #fff7eb 16.67%,
    #dfcaa9 48.15%,
    #bb9370 53.13%,
    #ddc8a5 57.58%,
    #fff7eb 89.58%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  z-index: 13;
  position: absolute;
  top: 186rem;
  left: 50%;
  transform: translateX(-50%);
  border-right: 1px solid transparent;
`;

export const WinMobileBlack = styled(WinMobile)`
  z-index: 12;
  color: #121011;

  text-shadow:
    0px 0px 1px rgba(255, 247, 235, 0.6),
    0px 6px 0px #121011,
    0px 0px 20px #121011;
`;

export const TextWinTablet = styled.div`
  color: #fff7eb;

  text-align: center;
  text-shadow: 0px 4px 0px #121011;
  font-size: 40rem;
  font-weight: 400;
  line-height: 75rem; /* 120% */
  letter-spacing: 3px;
  text-transform: uppercase;
  z-index: 13;
  position: absolute;
  top: 215rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const WinTablet = styled.div<{ $isDefaultFont: boolean }>`
  text-align: center;
  font-size: 80rem;
  font-style: 600;
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #fff7eb 16.67%,
    #dfcaa9 48.15%,
    #bb9370 53.13%,
    #ddc8a5 57.58%,
    #fff7eb 89.58%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  z-index: 13;
  position: absolute;
  top: 148rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const WinTabletBlack = styled(WinTablet)`
  z-index: 12;
  color: #121011;

  text-shadow:
    0px 0px 1px rgba(255, 247, 235, 0.6),
    0px 6px 0px #121011,
    0px 0px 20px #121011;
`;

export const TextWin = styled.div<{ $isDefaultFont: boolean; $isPlayerWon: boolean }>`
  text-align: center;
  font-size: 55rem;
  font-style: 600;
  font-weight: 700;
  line-height: 60rem;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #fff7eb 16.67%,
    #dfcaa9 41.15%,
    #bb9370 53.13%,
    #ddc8a5 64.58%,
    #fff7eb 89.58%
  );

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  z-index: 13;
  position: absolute;
  top: 70rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const TextWinJP = styled.div`
  color: #fff7eb;

  text-align: center;
  text-shadow: 0px 4px 0px #121011;
  font-size: 30rem;
  width: max-content;
  font-weight: 400;
  line-height: 48rem; /* 120% */
  letter-spacing: 3px;
  text-transform: uppercase;
  z-index: 13;
  position: absolute;
  /* top: 324px; */
  top: 120rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const TextWinBlack = styled(TextWin)`
  z-index: 12;
  color: #121011;

  text-shadow:
    0px 0px 1px rgba(255, 247, 235, 0.6),
    0px 6px 0px #121011,
    0px 0px 20px #121011;
`;

export const TextSum = styled.div<{
  $deviceType?: Device;
  $numberCharacters: number;
  $isPlayerWon: boolean;
}>`
  text-align: center;
  font-size: ${({ $numberCharacters }) => {
    if ($numberCharacters > 12) {
      const baseFontSize = 21;
      const decreaseFactor = 1.2;
      const extraCharacters = $numberCharacters;
      const steps = Math.floor(extraCharacters / 3);
      return `${baseFontSize - steps * decreaseFactor}rem`;
    } else {
      return '21rem';
    }
  }};
  line-height: ${({ $numberCharacters }) => ($numberCharacters ? '32rem' : '40rem')};
  /* letter-spacing: 3px; */
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #fff7eb 16.67%,
    #dfcaa9 48.15%,
    #bb9370 53.13%,
    #ddc8a5 57.58%,
    #fff7eb 89.58%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  z-index: 13;
  position: absolute;
  // top: 148rem;
  top: ${({ $isPlayerWon }) => ($isPlayerWon ? '171rem' : '148rem')};
  left: 50%;
  transform: translateX(-50%);

  ${({ $deviceType, $numberCharacters, $isPlayerWon }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        // top: 272rem;
        top: ${() => {
          if ($isPlayerWon) {
            return `294rem`;
          } else {
            return '272rem';
          }
        }};
        @media (min-aspect-ratio: 3/4) {
          top: ${() => {
            if ($isPlayerWon) {
              return `282rem`;
            } else {
              return '262rem';
            }
          }};
        }
        font-size: ${() => {
          if ($numberCharacters > 12) {
            const baseFontSize = 29;
            const decreaseFactor = 1.4;
            const extraCharacters = $numberCharacters;
            const steps = Math.floor(extraCharacters / 3);
            return `${baseFontSize - steps * decreaseFactor}rem`;
          } else {
            return '32rem';
          }
        }};
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 266rem;
        top: ${() => {
          if (
            isOldBrowserIOS() &&
            !$isPlayerWon &&
            getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ) {
            return '243rem';
          }

          if (
            isOldBrowserIOS() &&
            $isPlayerWon &&
            getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ) {
            return '261rem';
          }
          if ($isPlayerWon) {
            return `266rem`;
          } else {
            return '248rem';
          }
        }};
        font-size: ${() => {
          if ($numberCharacters > 12) {
            const baseFontSize = 16;
            const decreaseFactor = 1.2;
            const extraCharacters = $numberCharacters;
            const steps = Math.floor(extraCharacters / 3);
            return `${baseFontSize - steps * decreaseFactor}rem`;
          } else {
            return '14rem';
          }
        }};
      `;
    }
  }}
`;
