import React from 'react';
import { Device } from 'types';
import { Icon, IconNames } from 'components';
import { IRepeatBetButtonProps } from './types';
import { IconWrapper, Wrapper } from './styles';

export const RepeatBetButtonDesktop = ({ onRepeatBetClick, isDisabled }: IRepeatBetButtonProps) => (
  <Wrapper
    $isDisabled={isDisabled}
    $deviceType={Device.Desktop}
    width="136"
    height="52"
    viewBox="0 0 136 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g onClick={onRepeatBetClick}>
      <path
        id="path_background"
        d="M1.19073 51.5L51.21 0.5H134.809L84.79 51.5H1.19073Z"
        fill="url(#paint0_linear_776_7746)"
        stroke="url(#paint1_linear_776_7746)"
      />
      {/* <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.1257 26.875C74.6096 26.875 75.0007 27.267 75.0007 27.75C75.0007 29.1974 73.8229 30.3751 72.3756 30.3751H63.9876L65.119 31.5066C65.4611 31.8487 65.4611 32.4017 65.119 32.7439C64.9484 32.9145 64.7244 33.0002 64.5003 33.0002C64.2763 33.0002 64.0523 32.9145 63.8817 32.7439L61.2566 30.1188C60.9145 29.7766 60.9145 29.2236 61.2566 28.8815L63.8817 26.2564C64.2238 25.9142 64.7769 25.9142 65.119 26.2564C65.4611 26.5985 65.4611 27.1515 65.119 27.4937L63.9876 28.6251H72.3756C72.8586 28.6251 73.2507 28.2322 73.2507 27.75C73.2507 27.267 73.6418 26.875 74.1257 26.875ZM61.8753 25.125C61.3914 25.125 61.0002 24.7329 61.0002 24.2499C61.0002 22.8026 62.178 21.6248 63.6253 21.6248H72.0134L70.882 20.4934C70.5398 20.1513 70.5398 19.5983 70.882 19.2561C71.2241 18.914 71.7771 18.914 72.1192 19.2561L74.7443 21.8812C75.0865 22.2233 75.0865 22.7764 74.7443 23.1185L72.1192 25.7436C71.9486 25.9142 71.7246 26 71.5006 26C71.2766 26 71.0526 25.9142 70.882 25.7436C70.5398 25.4015 70.5398 24.8484 70.882 24.5063L72.0134 23.3749H63.6253C63.1423 23.3749 62.7503 23.7678 62.7503 24.2499C62.7503 24.7329 62.3591 25.125 61.8753 25.125Z"
        fill="url(#paint2_linear_47_29745)"
      /> */}

      <IconWrapper
        x="58"
        y="16"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <Icon name={isDisabled ? IconNames.RepeatBetDisabled : IconNames.RepeatBet} />
      </IconWrapper>
    </g>

    <defs>
      <linearGradient
        id="paint0_linear_776_7746"
        x1="68"
        y1="52"
        x2="68"
        y2="0"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" />
        <stop offset="1" stopColor="#343434" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_776_7746"
        x1="0"
        y1="26"
        x2="136"
        y2="26"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" stopOpacity="0" />
        <stop offset="0.5" stopColor="#434343" />
        <stop offset="1" stopColor="#343434" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint0_linear_47_29754"
        x1="68"
        y1="0"
        x2="68"
        y2="52"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" stopOpacity="0" />
        <stop offset="1" stopColor="#BB9370" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_47_29754"
        x1="0"
        y1="26"
        x2="136"
        y2="26"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1E6D2" stopOpacity="0" />
        <stop offset="0.5" stopColor="#F1E6D2" />
        <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_47_29745"
        x1="68.0005"
        y1="18.9995"
        x2="68.0005"
        y2="33.0002"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFF7EB" />
        <stop offset="1" stopColor="#D4BB93" />
      </linearGradient>
    </defs>
  </Wrapper>
);
