import { ActionType, createReducer } from 'typesafe-actions';
import { setRoundWinnersDataAction, resetRoundWinnersDataAction } from './actions';
import { IRoundWinnersState } from './types';

export const roundWinnersData: IRoundWinnersState = {
  winners: null,
};

const actions = {
  setRoundWinnersDataAction,
  resetRoundWinnersDataAction,
};

export const roundWinnersReducer = createReducer<IRoundWinnersState, ActionType<typeof actions>>(
  roundWinnersData,
)
  .handleAction(setRoundWinnersDataAction, (state, { payload: { winners } }) => ({
    ...state,
    winners,
  }))
  .handleAction(resetRoundWinnersDataAction, (state) => ({
    ...state,
    winners: null,
  }));
