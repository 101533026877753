import { isInIframe, isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.div<{ $deviceType?: Device }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: max-height;
  width: max-width;
  z-index: 101;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile)
      return css`
        width: 100vw;
        width: ${isOldBrowserIOS()
          ? isInIframe()
            ? 'calc(100vh * 3 / 5)'
            : 'calc(100vh * 3 / 5 - 70px)'
          : 'calc(100dvh * 3 / 5)'};
        max-height: calc(100vw * 5 / 3);
        height: ${isOldBrowserIOS() ? 'calc(100vh - 70px)' : '100dvh'};
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
      `;

    if ($deviceType === Device.Tablet_Portrait)
      return css`
        width: 100vw;
        width: calc(124dvh * 768 / 1000);
        height: ${isOldBrowserIOS()
          ? 'calc(124vh * 768 / 1000 - 70px)'
          : 'calc(124dvh * 768 / 1000)'};
        max-height: calc(100vw * 1000 / 768);
        height: 100dvh;
        height: ${isOldBrowserIOS() ? 'calc(100vh - 70px)' : '100dvh'};
        overflow: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
      `;
  }}
`;
