import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { winnerBallSelector } from 'core/widgets/WinnerBall';
import { roundStateSelector } from 'core/widgets/RoundStates';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { tableInfoSelector } from 'core/widgets/TableSettings';
import { WinnerBall } from 'constants/common';
import { DrawnBallResponsive } from './Responsive';

import AudioService from 'services/AudioService';
import { Sounds } from 'core/widgets/SoundSettings/types';

export const DrawnBall = () => {
  const winnerBall = useSelector(winnerBallSelector);
  const roundState = useSelector(roundStateSelector);
  const tableInfo = useSelector(tableInfoSelector);

  const displayUntilNewRoundStarted = useMemo(
    () =>
      roundState === RoundStates.DrawStarted ||
      roundState === RoundStates.DrawFinished ||
      roundState === RoundStates.ResultConfirmed ||
      roundState === RoundStates.RoundFinished,
    [roundState],
  );
  const displayUntilRoundFinished = useMemo(
    () =>
      roundState === RoundStates.DrawStarted ||
      roundState === RoundStates.DrawFinished ||
      roundState === RoundStates.ResultConfirmed,
    [roundState],
  );
  const drawnBallVisible = useMemo(
    () => (tableInfo?.displayingWinBall ? displayUntilRoundFinished : displayUntilNewRoundStarted),
    [tableInfo?.displayingWinBall, displayUntilRoundFinished, displayUntilNewRoundStarted],
  );

  const soundRed = () => {
    AudioService.enqueue('red', Sounds.DIGITAL_VOICE, true);
    AudioService.enqueue('congratulations', Sounds.DIGITAL_VOICE, true);
  };

  const soundBlue = () => {
    AudioService.enqueue('blue', Sounds.DIGITAL_VOICE, true);
    AudioService.enqueue('congratulations', Sounds.DIGITAL_VOICE, true);
  };

  const soundGold = () => {
    AudioService.enqueue('gold', Sounds.DIGITAL_VOICE, true);
    AudioService.enqueue('congratulations', Sounds.DIGITAL_VOICE, true);
  };

  useEffect(() => {
    if (drawnBallVisible) {
      if (winnerBall === WinnerBall.RED) {
        soundRed();
        return;
      }

      if (winnerBall === WinnerBall.BLUE) {
        soundBlue();
        return;
      }

      if (winnerBall === WinnerBall.GOLD) {
        soundGold();
      }
    }
  }, [drawnBallVisible, winnerBall]);

  return <>{Boolean(drawnBallVisible) && <DrawnBallResponsive winnerBall={winnerBall} />}</>;
};
