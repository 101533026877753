import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20rem 40rem 20rem; */
`;
