import { ActionType, createReducer } from 'typesafe-actions';
import {
  addConfirmedBetAction,
  clearAllBetsAction,
  clearPlacedBetSuccessAction,
  confirmBetErrorAction,
  confirmBetSuccessAction,
  placeBetSuccessAction,
  setConfirmedBetAction,
} from './actions';
import { EMPTY_BET } from './data';
import { IBetState } from './types';

export const betState: IBetState = {
  bets: {
    placed: EMPTY_BET,
    confirmed: [],
  },
};

const actions = {
  placeBetSuccessAction,
  clearPlacedBetSuccessAction,
  clearAllBetsAction,
  confirmBetSuccessAction,
  confirmBetErrorAction,
  addConfirmedBetAction,
  setConfirmedBetAction,
};

export const betReducer = createReducer<IBetState, ActionType<typeof actions>>(betState)
  .handleAction(placeBetSuccessAction, (state, { payload }) => ({
    ...state,
    bets: {
      ...state.bets,
      placed: payload,
    },
  }))
  .handleAction(clearPlacedBetSuccessAction, (state, { payload: slot }) => ({
    ...state,
    bets: {
      ...state.bets,
      placed: { ...state.bets.placed, [slot]: 0 },
    },
  }))
  .handleAction(clearAllBetsAction, (state) => ({
    ...state,
    bets: {
      ...state.bets,
      placed: EMPTY_BET,
      confirmed: [],
    },
  }))
  .handleAction(confirmBetSuccessAction, (state, { payload }) => ({
    ...state,
    bets: {
      ...state.bets,
      confirmed: [
        ...state.bets.confirmed,
        {
          id: payload,
          body: state.bets.placed,
        },
      ],
      placed: EMPTY_BET,
    },
  }))
  .handleAction(confirmBetErrorAction, (state, { payload }) => ({
    ...state,
    bets: {
      ...state.bets,
      confirmed: [...state.bets.confirmed.filter((confirmedBet) => confirmedBet.id !== payload)],
      placed: EMPTY_BET,
    },
  }))
  .handleAction(addConfirmedBetAction, (state, { payload: confirmedBet }) => ({
    ...state,
    bets: {
      ...state.bets,
      confirmed: [...state.bets.confirmed, confirmedBet],
    },
  }))
  .handleAction(setConfirmedBetAction, (state, { payload: confirmedBet }) => ({
    ...state,
    bets: {
      ...state.bets,
      confirmed: [confirmedBet],
    },
  }));
