import { put, select } from 'redux-saga/effects';
import { call } from 'ramda';
import * as Sentry from '@sentry/react';

import { roundStateSelector } from 'core/widgets/RoundStates';
import { IRoundState, RoundStates } from 'core/widgets/RoundStates/types';
import {
  ICreatePendingBetRequestData,
  ICreatePendingBetResponseData,
  RestService,
} from 'services/RestAPIService';
import { summaryBetSelector } from '../selectors';
import { BetType } from '../types';
import { setTotalBetAction } from 'core/widgets/User/actions';
import { getBetSum } from '../utils';
import { clearAllBetsAction } from '../actions';
import { totalBetSelector } from 'core/widgets/User';
import { transformToCurrency, truncationNumber } from 'utils/transformToCurrency';

export function* createPendingBetSaga() {
  const roundState: IRoundState['roundState'] = yield select(roundStateSelector);

  if (roundState === RoundStates.BettingTimeStarted) {
    const placedBet: BetType = yield select(summaryBetSelector);
    const data: ICreatePendingBetRequestData = {
      banker: truncationNumber(placedBet.banker),
      tie: truncationNumber(placedBet.tie),
      player: truncationNumber(placedBet.player),
      timeStamp: new Date().getTime(),
    };

    try {
      const response: ICreatePendingBetResponseData = yield call(() =>
        RestService.createPendingBet(data),
      );

      const totalBet = getBetSum([placedBet]);
      yield put(setTotalBetAction(totalBet));
    } catch (error) {
      Sentry.captureException({ error, data });
      const totalBet: number | null = yield select(totalBetSelector);

      if (!totalBet) {
        yield put(clearAllBetsAction());
      }

      console.log(error);
    }
  }
}
