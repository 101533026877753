import React from 'react';
import { useTranslation } from 'react-i18next';
import { isIOS } from 'react-device-detect';
import { Device } from 'types';
import { SettingsPanel, Slider, SwitchToggle } from 'components';
import { Sounds } from 'core/widgets/SoundSettings/types';
import { SoundSlider, SoundSwitchToggle, Wrapper, SwitchWrapper } from './styles';
import { ISoundSettingsPanelComponentProps } from './types';

export const SoundSettingsPanelComponent = ({
  deviceType = Device.Desktop,
  music,
  soundEffects,
  digitalVoice,
  studioSound,
  gameSoundMute,
  onSliderChange,
  onSwitchChange,
  onSwitchSoundChange,
  onCloseClick,
}: ISoundSettingsPanelComponentProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SettingsPanel onCloseClick={onCloseClick} deviceType={deviceType}>
        <SettingsPanel.Title>{t('settings.sound.title')}</SettingsPanel.Title>
        <SettingsPanel.Item>
          <SwitchWrapper>
            <SettingsPanel.ItemTitle>{t('settings.sound.soundItemTitle')}</SettingsPanel.ItemTitle>
            <SoundSwitchToggle>
              <SwitchToggle
                isChecked={!gameSoundMute}
                onClick={() => onSwitchChange(!gameSoundMute)}
              />
            </SoundSwitchToggle>
          </SwitchWrapper>
        </SettingsPanel.Item>
        {isIOS ? (
          <>
            <SettingsPanel.Item>
              <SwitchWrapper>
                <SettingsPanel.ItemTitle>
                  {t('settings.sound.musicItemTitle')}
                </SettingsPanel.ItemTitle>
                <SoundSwitchToggle>
                  <SwitchToggle
                    isChecked={!music.mute}
                    onClick={() => onSwitchSoundChange(!music.mute, Sounds.MUSIC)}
                  />
                </SoundSwitchToggle>
              </SwitchWrapper>
            </SettingsPanel.Item>
            <SettingsPanel.Item>
              <SwitchWrapper>
                <SettingsPanel.ItemTitle>
                  {t('settings.sound.soundEffectsItemTitle')}
                </SettingsPanel.ItemTitle>
                <SoundSwitchToggle>
                  <SwitchToggle
                    isChecked={!soundEffects.mute}
                    onClick={() => onSwitchSoundChange(!soundEffects.mute, Sounds.SOUND_EFFECTS)}
                  />
                </SoundSwitchToggle>
              </SwitchWrapper>
            </SettingsPanel.Item>
            <SettingsPanel.Item>
              <SwitchWrapper>
                <SettingsPanel.ItemTitle>
                  {t('settings.sound.digitalVoiceItemTitle')}
                </SettingsPanel.ItemTitle>
                <SoundSwitchToggle>
                  <SwitchToggle
                    isChecked={!digitalVoice.mute}
                    onClick={() => onSwitchSoundChange(!digitalVoice.mute, Sounds.DIGITAL_VOICE)}
                  />
                </SoundSwitchToggle>
              </SwitchWrapper>
            </SettingsPanel.Item>
            <SettingsPanel.Item>
              <SwitchWrapper>
                <SettingsPanel.ItemTitle>
                  {t('settings.sound.studioSoundItemTitle')}
                </SettingsPanel.ItemTitle>
                <SoundSwitchToggle>
                  <SwitchToggle
                    isChecked={!studioSound.mute}
                    onClick={() => onSwitchSoundChange(!studioSound.mute, Sounds.STUDIO_SOUND)}
                  />
                </SoundSwitchToggle>
              </SwitchWrapper>
            </SettingsPanel.Item>
          </>
        ) : (
          <SettingsPanel.Item>
            <SoundSlider>
              <SettingsPanel.ItemTitle>
                {t('settings.sound.musicItemTitle')}
              </SettingsPanel.ItemTitle>
              <Slider
                value={music?.volume}
                onChange={(value) => onSliderChange(value as number, Sounds.MUSIC)}
              />
            </SoundSlider>
            <SoundSlider>
              <SettingsPanel.ItemTitle>
                {t('settings.sound.soundEffectsItemTitle')}
              </SettingsPanel.ItemTitle>
              <Slider
                value={soundEffects?.volume}
                onChange={(value) => onSliderChange(value as number, Sounds.SOUND_EFFECTS)}
              />
            </SoundSlider>
            <SoundSlider>
              <SettingsPanel.ItemTitle>
                {t('settings.sound.digitalVoiceItemTitle')}
              </SettingsPanel.ItemTitle>
              <Slider
                value={digitalVoice?.volume}
                onChange={(value) => onSliderChange(value as number, Sounds.DIGITAL_VOICE)}
              />
            </SoundSlider>
            <SoundSlider>
              <SettingsPanel.ItemTitle>
                {t('settings.sound.studioSoundItemTitle')}
              </SettingsPanel.ItemTitle>
              <Slider
                value={studioSound?.volume}
                onChange={(value) => onSliderChange(value as number, Sounds.STUDIO_SOUND)}
              />
            </SoundSlider>
          </SettingsPanel.Item>
        )}
      </SettingsPanel>
    </Wrapper>
  );
};
