import React from 'react';
import { IconNames, LeftSideButton } from 'components';
import { IDoubleBetButtonProps } from './types';
import { IconStyled } from './styles';

export const DoubleBetButtonPortrait = ({
  onDoubleBetClick,
  isDisabled,
}: IDoubleBetButtonProps) => (
  <LeftSideButton isDisabled={isDisabled} onClick={onDoubleBetClick}>
    <IconStyled name={isDisabled ? IconNames.DoubleBetDisabled : IconNames.DoubleBet} />
  </LeftSideButton>
);
