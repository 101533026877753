import React from 'react';
import { Device } from 'types';
import { transformToCurrency } from 'utils/transformToCurrency';
import { useTranslation } from 'react-i18next';
import { Wrapper, WinInfoText, WinAmount, WinTitle, WinInfoLabel, WinInfoValue } from './styles';
import { IWinStatusComponentProps } from './types';
import { GradientsMobile } from './Components/Gradients.mobile';
import { winStatusColorsMobile } from './utils/getColorForWinStatus';

export const WinStatusMobile = ({
  winnerBall,
  winnerBet,
  winnerAmount,
  currency,
}: IWinStatusComponentProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper $deviceType={Device.Mobile}>
      <svg
        width="344"
        height="144"
        viewBox="0 0 344 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M0 144L344 144V62L0 62L0 144Z" fill="url(#paint0_linear_85_31142)" />
        <path
          d="M0.5 141.5L343.5 141.5V64.5L0.5 64.5L0.5 141.5Z"
          stroke="url(#paint1_linear_85_31142)"
        />
        <path
          d="M172 0.707107L211.293 40L191.793 59.5H152.207L132.707 40L172 0.707107Z"
          fill="#121011"
          stroke="url(#paint2_linear_85_31142)"
        />
        <path
          d="M172 2.75781L209.241 39.9992L190.621 58.6199H153.379L134.759 39.9992L172 2.75781Z"
          fill="url(#winner_main_background)"
        />
        <path
          d="M171.834 11.7344L172.464 32.8374L176.851 12.1859L173.703 33.0622L181.707 13.5261L174.882 33.5048L186.246 15.7119L175.963 34.1507L190.322 18.673L176.912 34.9793L193.803 22.3142L177.697 35.964L196.578 26.5185L178.294 37.073L198.558 31.1507L178.683 38.2708L199.679 36.0621L178.852 39.5188L199.905 41.0947L178.795 40.777L199.229 46.0867L178.515 42.0048L197.672 50.8778L178.02 43.1629L195.285 55.314L177.326 44.2139L192.144 59.2526L176.456 45.1242L188.351 62.5671L175.437 45.8645L184.026 65.1509L174.302 46.4109L179.31 66.921L173.088 46.746L174.353 67.8205L171.834 46.8589L169.315 67.8205L170.58 46.746L164.358 66.921L169.366 46.4109L159.642 65.1509L168.231 45.8645L155.317 62.5671L167.212 45.1242L151.524 59.2526L166.342 44.2139L148.383 55.314L165.648 43.1629L145.996 50.8778L165.153 42.0048L144.439 46.0867L164.873 40.777L143.763 41.0947L164.816 39.5188L143.989 36.0621L164.985 38.2708L145.11 31.1507L165.374 37.073L147.09 26.5185L165.971 35.964L149.865 22.3142L166.756 34.9793L153.346 18.673L167.705 34.1507L157.422 15.7119L168.786 33.5048L161.96 13.5261L169.965 33.0622L166.817 12.1859L171.204 32.8374L171.834 11.7344Z"
          fill="url(#paint4_linear_85_31142)"
          fillOpacity="0.15"
        />
        <circle cx="172" cy="38" r="10" fill="url(#winner_ball_background)" />
        <g filter="url(#filter0_f_85_31142)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M172.625 28.6257C167.102 28.6257 162.625 33.1029 162.625 38.6257C162.625 41.2282 163.619 43.5984 165.249 45.3774C163.252 43.5492 162 40.9212 162 38.0007C162 32.4779 166.477 28.0007 172 28.0007C174.921 28.0007 177.549 29.2526 179.377 31.249C177.598 29.6199 175.228 28.6257 172.625 28.6257Z"
            fill="url(#winner_ball_top_shining)"
          />
        </g>
        <g filter="url(#filter1_f_85_31142)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M181.923 36.75C181.308 41.6829 177.1 45.5 172 45.5C166.9 45.5 162.692 41.6829 162.077 36.75C162.026 37.1595 162 37.5767 162 38C162 43.5228 166.477 48 172 48C177.523 48 182 43.5228 182 38C182 37.5767 181.974 37.1595 181.923 36.75Z"
            fill="url(#winner_ball_bottom_shining)"
          />
        </g>
        <g filter="url(#winner_circle_glare_primary)">
          <circle
            cx="175.056"
            cy="36.0554"
            r="3.61111"
            fill={winStatusColorsMobile.circleGlarePrimary[winnerBall]}
          />
        </g>
        <g filter="url(#winner_circle_glare_secondary)">
          <circle
            cx="175.057"
            cy="34.9443"
            r="2.5"
            fill={winStatusColorsMobile.circleGlareSecondary[winnerBall]}
          />
        </g>
        <path
          d="M125.703 64.9902L133.864 56.5H210.135L218.306 65L210.137 73.4986L133.858 73.0148L125.703 64.9902Z"
          fill="url(#paint8_linear_85_31142)"
        />
        <path
          d="M125.703 64.9902L133.864 56.5H210.135L218.306 65L210.137 73.4986L133.858 73.0148L125.703 64.9902Z"
          fill="url(#winner_label_background)"
        />
        <path
          d="M125.703 64.9902L133.864 56.5H210.135L218.306 65L210.137 73.4986L133.858 73.0148L125.703 64.9902Z"
          stroke="url(#paint10_linear_85_31142)"
        />
        <WinTitle x="50%" y="65" dominantBaseline="central" textAnchor="middle">
          {t('winStatus.title')}
        </WinTitle>
        <WinInfoText x="51" y="125" dominantBaseline="hanging">
          <WinInfoLabel>{t('winStatus.betItemLabel')}: </WinInfoLabel>
          <WinInfoValue>{t(`ball.${winnerBall.toLowerCase()}`)}</WinInfoValue>
        </WinInfoText>
        {/* <WinInfoText x="181" y="125" dominantBaseline="hanging">
          <WinInfoLabel>|</WinInfoLabel>
        </WinInfoText> */}
        <WinInfoText x="214" y="125" dominantBaseline="hanging">
          <WinInfoLabel>{t('winStatus.betLabel')}: </WinInfoLabel>
          <WinInfoValue>{transformToCurrency({ value: winnerBet, currency })}</WinInfoValue>
        </WinInfoText>
        <WinAmount x="50%" y="88" dominantBaseline="hanging" textAnchor="middle">
          {transformToCurrency({ value: winnerAmount, isWithoutCurrencySeparator: true, currency })}
        </WinAmount>
        <GradientsMobile winnerBall={winnerBall} />
      </svg>
    </Wrapper>
  );
};
