import React, { forwardRef } from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BasePopupHistory } from './BasePopupHistory';

import { IPopupConfirmProps } from './types';
import { BasePopupHistoryPortrait } from './BasePopupHistory.mobile';

export const PopupHistory = forwardRef((props: IPopupConfirmProps, ref) => (
  <>
    <DesktopComponent>
      <BasePopupHistory {...props} deviceType={Device.Desktop} ref={ref} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BasePopupHistory {...props} deviceType={Device.Tablet_Landscape} ref={ref} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BasePopupHistoryPortrait {...props} deviceType={Device.Tablet_Portrait} ref={ref} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BasePopupHistoryPortrait {...props} deviceType={Device.Mobile} ref={ref} />
    </MobileComponent>
  </>
));
