import React from 'react';
import { Device, SLOTS } from 'types';
import { ISlotProps } from 'widgets/Slots/types';
import { Wrapper } from '../styles';
import { ISlotInnerPositionsConfig, SlotContent } from '../../SlotContent';

export const SlotRedMaximizedTablet = ({
  activeChip,
  bet,
  isBettingTime,
  isWinner,
  hasPlacedBet,
  isCurrent,
  isMinimized,
  size = { height: 266, width: 237 },
  isActiveJP,
  onClick,
  onBetConfirm,
  onBetClear,
  onErrorClick,
}: ISlotProps) => {
  const innerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '96',
      positionY: '39.5',
    },
    confirmBet: {
      positionX: '176',
      positionY: '-32',
    },
  };

  return (
    <Wrapper
      $activeChip={activeChip}
      $isActive={hasPlacedBet}
      $isBettingTime={isBettingTime}
      $isWinner={isWinner}
      onClick={isBettingTime ? onClick : onErrorClick}
      width={size.width}
      height={size.height}
      viewBox="0 0 237 266"
      x="483"
      y="0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_518_6483)">
        <path d="M237 0V170C237 223.019 194.019 266 141 266H0V0H122.736H237Z" fill="#28080B" />
        <path
          d="M237 0V170C237 223.019 194.019 266 141 266H0V0H122.736H237Z"
          fill="url(#paint0_linear_518_6483)"
        />
      </g>
      <path
        d="M236 1V170C236 222.467 193.467 265 141 265H1V1H122.736H236Z"
        stroke="url(#paint1_linear_518_6483)"
        strokeWidth="2"
      />
      <path
        d="M236 1V170C236 222.467 193.467 265 141 265H1V1H122.736H236Z"
        stroke="url(#paint2_linear_518_6483)"
        strokeWidth="2"
      />

      <g id="reddBets" filter="url(#filter0_i_559_8705)">
        <path d="M237 0V170C237 223.019 194.019 266 141 266H0V0H122.736H237Z" fill="#28080B" />
        <path
          d="M237 0V170C237 223.019 194.019 266 141 266H0V0H122.736H237Z"
          fill="url(#paint0_linear_559_8705)"
        />
      </g>
      <path
        d="M236 1V170C236 222.467 193.467 265 141 265H1V1H122.736H236Z"
        stroke="url(#paint1_linear_559_8705)"
        strokeWidth="2"
      />
      <path
        d="M236 1V170C236 222.467 193.467 265 141 265H1V1H122.736H236Z"
        stroke="url(#paint2_linear_559_8705)"
        strokeWidth="2"
      />
      <SlotContent
        bet={bet}
        isCurrent={isCurrent}
        onBetConfirm={onBetConfirm}
        onBetClear={onBetClear}
        isMinimized={isMinimized}
        isWinner={isWinner}
        slot={SLOTS.banker}
        deviceType={Device.Tablet_Portrait}
        hasPlacedBet={hasPlacedBet}
        innerPositionsConfig={innerContentPositions}
        size={size}
        isActiveJP={isActiveJP}
        isBettingTime={isBettingTime}
      />
      <defs>
        <filter
          id="filter0_i_518_6483"
          x="0"
          y="0"
          width="237"
          height="267"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_518_6483" />
        </filter>
        <linearGradient
          id="paint0_linear_518_6483"
          x1="118.5"
          y1="0"
          x2="118.5"
          y2="266"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C8102E" />
          <stop offset="1" stopColor="#C8102E" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_518_6483"
          x1="118.5"
          y1="0"
          x2="118.5"
          y2="266"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_518_6483"
          x1="296.522"
          y1="133"
          x2="-68.6912"
          y2="133"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF1400" stopOpacity="0" />
          <stop offset="0.5" stopColor="#FF1400" />
          <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
          <stop offset="1" stopColor="#FF1400" stopOpacity="0" />
        </linearGradient>
      </defs>

      <defs id="redBetsDefs">
        <filter
          id="filter0_i_559_8705"
          x="0"
          y="0"
          width="237"
          height="267"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_559_8705" />
        </filter>
        <linearGradient
          id="paint0_linear_559_8705"
          x1="118.5"
          y1="0"
          x2="118.5"
          y2="266"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C8102E" />
          <stop offset="1" stopColor="#C8102E" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_559_8705"
          x1="118.5"
          y1="0"
          x2="118.5"
          y2="266"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_559_8705"
          x1="296.522"
          y1="133"
          x2="-68.6912"
          y2="133"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF1400" stopOpacity="0" />
          <stop offset="0.5" stopColor="#FF1400" />
          <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
          <stop offset="1" stopColor="#FF1400" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Wrapper>
  );
};
