import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BaseWinJP } from './WinJP.base';
import { WinJPTablet } from './WinJP.tablet';
import { WinJPMobile } from './WinJP.mobile';
import { IWinJPComponentProps } from './types';

export const WinJPResponsive = (props: IWinJPComponentProps) => (
  <>
    <DesktopComponent>
      <BaseWinJP {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseWinJP {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <WinJPTablet {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <WinJPMobile {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
