export enum ACTIONS {
  CHANGE_AUTO_CONFIRM = 'CHANGE_AUTO_CONFIRM',
  APPLY_AUTO_CONFIRM = 'APPLY_AUTO_CONFIRM',
}

export interface IAutoConfirm {
  requested: boolean;
  applied: boolean;
}

export interface IGeneralSettingsState {
  autoConfirm: IAutoConfirm;
}
