import styled, { css } from 'styled-components';
import { MobileButtonWrapper } from '../styles';
import { AspectRatioIphone, getAspectRatioIphone, isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const MobileLeftButtonWrapper = styled(MobileButtonWrapper)`
  left: 0;
  clip-path: polygon(0 0, 45% 0, 100% 100%, 0% 100%);
`;

export const TabletLeftButtonWrapper = styled(MobileButtonWrapper)`
  left: 0;
  clip-path: polygon(0 0, 53% 0, 100% 100%, 0% 100%);
`;

export const MobileLeftButtonStyled = styled.svg<{ $isDisabled?: boolean }>`
  height: 28rem;
  width: 44rem;

  transform: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9 ? 'scale(0.9)translateX(-5rem)' : 'none'};

  & > g {
    ${({ $isDisabled }) => {
      if (!$isDisabled)
        return css`
          cursor: pointer;

          &:focus {
            & #lmb_path_background {
              fill: url(#paint0_linear_67_36662);
              stroke: url(#paint1_linear_67_36662);
            }
            & #lmb_path_inner_border {
              stroke: none;
            }
            & #lmb_path_outer_border {
              stroke: none;
            }
          }

          &:active {
            & #lmb_path_background {
              fill: url(#paint0_linear_67_36710);
              stroke: none;
            }
            & #lmb_path_inner_border {
              stroke: url(#paint1_linear_67_36710);
            }
            & #lmb_path_outer_border {
              stroke: url(#paint2_linear_67_36710);
            }
          }
        `;
    }}
`;

export const TabletLeftButtonStyled = styled.svg<{ $isDisabled?: boolean }>`
  height: 52rem;
  width: 100rem;

  & > g {
    ${({ $isDisabled }) => {
      if (!$isDisabled)
        return css`
          cursor: pointer;

          &:focus {
            & #ltb_path_background {
              fill: url(#paint0_linear_64_30991);
              stroke: url(#paint1_linear_64_30991);
            }
            & #ltb_path_inner_border {
              stroke: none;
            }
            & #ltb_path_outer_border {
              stroke: none;
            }
          }

          &:active {
            & #ltb_path_background {
              fill: url(#paint0_linear_64_31075);
              stroke: none;
            }
            & #ltb_path_inner_border {
              stroke: url(#paint1_linear_64_31075);
            }
            & #ltb_path_outer_border {
              stroke: url(#paint2_linear_64_31075);
            }
          }
        `;
    }}
`;
