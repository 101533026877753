import React from 'react';
import { Icon, IconNames, TiltedRightButton } from 'components';
import { IPortraitWinnersButtonProps } from './types';

export const HistoryButtonPortrait = ({
  onOpenWinnersTable,
  offsetLeft,
  offsetRight,
}: IPortraitWinnersButtonProps) => (
  <TiltedRightButton onClick={onOpenWinnersTable} offsetLeft={offsetLeft} offsetRight={offsetRight}>
    <Icon name={IconNames.History} />
  </TiltedRightButton>
);
