import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IActiveChipState } from './types';

const rootActiveChipSelector = (state: IState) => state.activeChipReducer;

export const activeChipSelector = createSelector(
  rootActiveChipSelector,
  (state: IActiveChipState) => state.chip,
);
