import { BetType } from 'core/widgets/Bet/types';
import { getBetSum, getSummaryBet } from 'core/widgets/Bet/utils';
import { put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { clearConfirmationSlotAction } from 'core/widgets/BetConfirmation/actions';
import { saveRoundBetAction, saveRoundBetSuccessAction } from '../actions';

export function* saveRoundBetSaga({
  payload: confirmedBets,
}: ActionType<typeof saveRoundBetAction>) {
  const betSum = confirmedBets.length ? getBetSum(confirmedBets) : 0;
  const roundBet: BetType | null = betSum > 0 ? getSummaryBet(confirmedBets) : null;
  yield put(clearConfirmationSlotAction());

  yield put(saveRoundBetSuccessAction(roundBet));
}
