import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { chipsMAX1 } from './chipsValues';

export const CHIPS_MIN_05: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 0.5,
  },
  {
    color: CHIPS_COLORS.YELLOW,
    value: 1,
  },
  {
    color: CHIPS_COLORS.RED,
    value: 2,
  },
  {
    color: CHIPS_COLORS.VIOLET,
    value: 5,
  },
  {
    color: CHIPS_COLORS.GREEN,
    value: 10,
  },
  {
    color: CHIPS_COLORS.ORANGE,
    value: 20,
  },
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 50,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 100,
  },
  {
    color: CHIPS_COLORS.PURPLE,
    value: 500,
  },
  {
    color: CHIPS_COLORS.LIGHT_GREEN,
    value: 1e3,
  },
  ...chipsMAX1,
];
