import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const SoundSettingsWrapper = styled.div``;

export const SoundSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SoundSwitchToggle = styled.div``;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SoundWrapper = styled.div`
  border-bottom: 1rem solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(270deg, #121011 0%, #434343 52.08%, #121011 100%);
  font-size: 12rem;
  padding-bottom: 40rem;
`;

export const SoundSlider = styled.div`
  margin-bottom: 40rem;
`;

export const ListContainer = styled.div<{ $hasShadow: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ $hasShadow }) => {
    if ($hasShadow) {
      return css`
        &::after {
          transition: 0.2s;
          content: '';
          background: linear-gradient(0deg, rgba(27, 25, 26, 0) 21.79%, #121011 100%);
          top: -5px;
          left: 0;
          position: absolute;
          z-index: 1000;
          height: 72px;
          width: 100%;

          @media only screen and (max-width: 450px) {
            height: 30px;
          }
        }
      `;
    }
  }}
`;

export const ButtonWrapper = styled.div`
  width: calc(100% + 14rem);
  margin-left: -2rem;
  margin-top: 24rem;
`;

export const LimitText = styled.div`
  color: var(--gray-434343, #434343);
  font-family: Goldman;
  font-size: 10rem;
  font-style: normal;
  font-weight: 400;
  line-height: 14rem;
  margin-top: 8rem;
`;

export const ChatContainerScroll = styled.div<{ $deviceType?: Device }>`
  padding: 0rem 12rem 0rem 0;
  z-index: 103;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;
  /* margin-right: 25rem;
  margin-top: 24rem;
  margin-bottom: 40rem; */
  overflow-scrolling: touch;
  overflow-x: hidden;

  .simplebar-scrollable-y {
    width: 100%;
  }

  .simplebar-scrollable-y {
    /* height: calc(100vh - 260rem) !important; */
  }

  .simplebar-track {
    right: -10px;
  }

  .simplebar-track.simplebar-vertical {
    width: 1px;
    background: #434343;

    .simplebar-scrollbar {
      width: 3px;
      border-radius: 2px;

      background-color: #d4bb93;
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 2px;

    background-color: #d4bb93;
  }
  &::-webkit-scrollbar {
    width: 1px;
    background: #434343;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        & > div {
          width: 100%;
        }
        .simplebar-content {
          height: 100%;
        }

        .simplebar-scrollable-x {
          width: 100%;
        }
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        & > div {
          width: 100%;
        }
        .simplebar-content {
          height: 100%;
        }

        .simplebar-scrollable-x {
          width: 100%;
        }
        /* padding: 0rem 16rem 0rem 16rem;
        margin-right: 12rem;
        margin-top: 16rem;
        margin-bottom: 24rem; */

        &::-webkit-scrollbar-thumb {
          width: 2px;
        }
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }

        & p {
          font-size: 10rem;
        }

        & h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 12rem;
        }

        & ul {
          font-size: 10rem;
        }
      `;
    }
  }}
`;
