import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { IRoundWinnersTickerProps } from './types';
import { BaseWinnersTicker } from './WinnersTicker.base';

export const WinnersTickerResponsive = (props: IRoundWinnersTickerProps) => (
  <>
    <DesktopComponent>
      <BaseWinnersTicker {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseWinnersTicker {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseWinnersTicker {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BaseWinnersTicker {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
