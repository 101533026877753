import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { ISoundSettingsState } from './types';

export const soundSettingsRootSelector = (state: IState) => state.soundSettingsReducer;

export const soundRequestedSettingseSelector = createSelector(
  soundSettingsRootSelector,
  (state: ISoundSettingsState) => state.requested,
);

export const soundSettingsSelector = createSelector(
  soundSettingsRootSelector,
  ({
    gameSoundMute,
    music,
    soundEffects,
    digitalVoice,
    studioSound,
    applied,
    isStartLoadingSounds,
  }: ISoundSettingsState) => ({
    gameSoundMute,
    music,
    soundEffects,
    digitalVoice,
    studioSound,
    applied,
    isStartLoadingSounds,
  }),
);
