import React from 'react';
import { BallWrapper, ContentWrapper, CounterRowStyled, TextMobile } from './styles';
import { ballsIconMap, IBallsCounterRowProps } from './types';

export const BallsCounterRowMobile = ({
  ballType,
  quantity,
  size = { width: 70, height: 18 },
  position = [0, 0],
}: IBallsCounterRowProps) => (
  <CounterRowStyled width={size.width} height={size.height} x={position[0]} viewBox="0 0 70 18">
    <ContentWrapper x="24" y="5" width="10" height="22" viewBox="0 0 10 22">
      <>
        <BallWrapper width="8" height="8" y="1" viewBox="0 0 20 20">
          {ballsIconMap[ballType]}
        </BallWrapper>
        <TextMobile x="10" y="5.5" dominantBaseline="middle">
          {quantity}
        </TextMobile>
      </>
    </ContentWrapper>
  </CounterRowStyled>
);
