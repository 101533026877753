import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { Device, SLOTS } from 'types';
import { Wrapper } from '../styles';
import { ISlotInnerPositionsConfig, SlotContent } from '../../SlotContent';

export const SlotBlueMinimizedTablet = ({
  activeChip,
  bet,
  isBettingTime,
  isWinner,
  hasPlacedBet,
  isCurrent,
  isMinimized,
  size = { height: 136, width: 237 },
  isActiveJP,
  onClick,
  onBetConfirm,
  onBetClear,
  onErrorClick,
}: ISlotProps) => {
  const innerContentPositions: ISlotInnerPositionsConfig = {
    betValue: {
      positionX: '97',
      positionY: '80',
    },
    confirmBet: {
      positionX: '176',
      positionY: '-32',
    },
  };

  return (
    <Wrapper
      $activeChip={activeChip}
      $isActive={hasPlacedBet}
      $isBettingTime={isBettingTime}
      $isWinner={isWinner}
      onClick={isBettingTime ? onClick : onErrorClick}
      width={size.width}
      height={size.height}
      viewBox="0 0 237 136"
      x="0"
      y="0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_i_513_3239)">
        <path d="M237 0V136H96C42.9807 136 0 93.0193 0 40V0H122.736H237Z" fill="#28080B" />
        <path
          d="M237 0V136H96C42.9807 136 0 93.0193 0 40V0H122.736H237Z"
          fill="url(#paint0_linear_513_3239)"
        />
      </g>
      <path
        d="M236 1V135H96C43.5329 135 1 92.467 1 40V1H122.736H236Z"
        stroke="url(#paint1_linear_513_3239)"
        strokeWidth="2"
      />
      <path
        d="M236 1V135H96C43.5329 135 1 92.467 1 40V1H122.736H236Z"
        stroke="url(#paint2_linear_513_3239)"
        strokeWidth="2"
      />

      <g id="blueBets" filter="url(#filter0_i_513_3308)">
        <path d="M237 0V136H96C42.9807 136 0 93.0193 0 40V0H122.736H237Z" fill="#28080B" />
        <path
          d="M237 0V136H96C42.9807 136 0 93.0193 0 40V0H122.736H237Z"
          fill="url(#paint0_linear_513_3308)"
        />
      </g>
      <path
        d="M236 1V135H96C43.5329 135 1 92.467 1 40V1H122.736H236Z"
        stroke="url(#paint1_linear_513_3308)"
        strokeWidth="2"
      />
      <path
        d="M236 1V135H96C43.5329 135 1 92.467 1 40V1H122.736H236Z"
        stroke="url(#paint2_linear_513_3308)"
        strokeWidth="2"
      />
      <SlotContent
        bet={bet}
        isCurrent={isCurrent}
        onBetConfirm={onBetConfirm}
        onBetClear={onBetClear}
        isMinimized={isMinimized}
        isWinner={isWinner}
        slot={SLOTS.player}
        deviceType={Device.Tablet_Portrait}
        hasPlacedBet={hasPlacedBet}
        innerPositionsConfig={innerContentPositions}
        size={size}
        isActiveJP={isActiveJP}
        isBettingTime={isBettingTime}
      />
      <defs>
        <filter
          id="filter0_i_513_3239"
          x="0"
          y="0"
          width="237"
          height="137"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_513_3239" />
        </filter>
        <linearGradient
          id="paint0_linear_513_3239"
          x1="118.5"
          y1="0"
          x2="118.5"
          y2="136"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#41B6E6" />
          <stop offset="1" stopColor="#41B6E6" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_513_3239"
          x1="118.5"
          y1="0"
          x2="118.5"
          y2="136"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_513_3239"
          x1="237"
          y1="68"
          x2="0"
          y2="68"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#57B5E3" stopOpacity="0" />
          <stop offset="0.5" stopColor="#57B5E3" />
          <stop offset="1" stopColor="#57B5E3" stopOpacity="0" />
        </linearGradient>
      </defs>
      <defs id="blueBetsDefs">
        <filter
          id="filter0_i_513_3308"
          x="0"
          y="0"
          width="237"
          height="137"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.968627 0 0 0 0 0.921569 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_513_3308" />
        </filter>
        <linearGradient
          id="paint0_linear_513_3308"
          x1="118.5"
          y1="0"
          x2="118.5"
          y2="136"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#41B6E6" />
          <stop offset="1" stopColor="#41B6E6" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_513_3308"
          x1="118.5"
          y1="0"
          x2="118.5"
          y2="136"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_513_3308"
          x1="237"
          y1="68"
          x2="0"
          y2="68"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#57B5E3" stopOpacity="0" />
          <stop offset="0.5" stopColor="#57B5E3" />
          <stop offset="1" stopColor="#57B5E3" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Wrapper>
  );
};
