import React from 'react';
import { useTranslation } from 'react-i18next';
import { MobileAspectRatio } from 'constants/theme';
import { StubLoader, StubText, Wrapper } from './styles';

export const MobileLandscapeStub = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <MobileAspectRatio />

      <StubLoader />
      <StubText>{t('mobile.landscapeStub')}</StubText>
    </Wrapper>
  );
};
