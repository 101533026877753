import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmptySlotsSelector } from 'core/widgets/Bet';
import { betRepeatRootSelector } from 'core/widgets/BetRepeat';
import { repeatBetAction } from 'core/widgets/BetRepeat/actions';
import { roundStateSelector } from 'core/widgets/RoundStates';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { jackpotActivitySelector, hasUserInTop100Selector } from 'core/widgets/Jackpot/selectors';
import { RepeatBetButtonResponsive } from './Responsive';
import AudioService from 'services/AudioService';

export const RepeatBetButton = () => {
  const dispatch = useDispatch();
  const roundState = useSelector(roundStateSelector);
  const isEmptySlots = useSelector(isEmptySlotsSelector);
  const {
    isUsed: isRepeatBetUsed,
    isConfirmed: isRepeatBetConfirmed,
    value: repeatBetValue,
  } = useSelector(betRepeatRootSelector);
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const { stage } = useSelector(jackpotActivitySelector);
  const hasUserInTop100 = useSelector(hasUserInTop100Selector);

  const isRepeatBetAvailable: boolean = useMemo(() => {
    if (stage && stage?.stageNumber === 1 && hasUserInTop100) {
      return false;
    }
    if (roundState !== RoundStates.BettingTimeStarted) {
      return false;
    }

    if (isRepeatBetUsed || isRepeatBetConfirmed) {
      return false;
    }

    if (!repeatBetValue) {
      return false;
    }

    if (!isEmptySlots) {
      return false;
    }

    return true;
  }, [
    stage,
    hasUserInTop100,
    roundState,
    isRepeatBetUsed,
    isRepeatBetConfirmed,
    repeatBetValue,
    isEmptySlots,
  ]);

  const onRepeatBetClick = useCallback(() => {
    if (isRepeatBetAvailable) {
      dispatch(repeatBetAction());
    }

    if (isRepeatBetAvailable && !gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('confirm', soundEffects.volume);
      return;
    }

    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('error', soundEffects.volume);
    }
  }, [dispatch, gameSoundMute, isRepeatBetAvailable, soundEffects]);

  return (
    <>
      <RepeatBetButtonResponsive
        onRepeatBetClick={onRepeatBetClick}
        isDisabled={!isRepeatBetAvailable}
      />
    </>
  );
};
