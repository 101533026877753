import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { CHIP_50K_LIGHT_GREEN, chipsMIN20 } from './chipsValues';

export const CHIPS_MIN_25: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 25,
  },
  ...chipsMIN20,
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 2e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.PURPLE,
    value: 10e3,
  },
  CHIP_50K_LIGHT_GREEN,
];
