import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletPortraitComponent,
  TabletLandscapeComponent,
} from 'components';
import { RepeatBetButtonDesktop } from './RepeatBetButton.desktop';
import { RepeatBetButtonPortrait } from './RepeatBetButton.portrait';
import { RepeatBetButtonTabletLandscape } from './RepeatBetButton.tablet.landscape';
import { IRepeatBetButtonProps } from './types';

export const RepeatBetButtonResponsive = (props: IRepeatBetButtonProps) => (
  <>
    <DesktopComponent>
      <RepeatBetButtonDesktop {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <RepeatBetButtonTabletLandscape {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <RepeatBetButtonPortrait {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <RepeatBetButtonPortrait {...props} />
    </MobileComponent>
  </>
);
