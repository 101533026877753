import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tableBetLimitSelector } from 'core/widgets/TableSettings';
import { balanceSelector, totalBetSelector } from 'core/widgets/User';
import { activeChipSelector } from 'core/widgets/ActiveChip';
import { setActiveChipAction } from 'core/widgets/ActiveChip/actions';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { ChipsResponsive } from './Responsive';
import { ChipType, IChipCarouselItem } from './data/types';
import { CHIPS_CURRENCIES } from './data/constants';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import AudioService from 'services/AudioService';

export const Chips = () => {
  const dispatch = useDispatch();
  const totalBet = useSelector(totalBetSelector);
  const activeChip = useSelector(activeChipSelector);
  const walletBalance = useSelector(balanceSelector);
  const betLimits = useSelector(tableBetLimitSelector);

  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const { currency } = useSelector(authInfoSelector);

  const onChipClick = useCallback(
    (chip: ChipType) => {
      dispatch(setActiveChipAction({ chip }));

      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }
    },
    [dispatch, gameSoundMute, soundEffects.mute, soundEffects.volume],
  );

  const isNoConfirmedBets: boolean = useMemo(() => totalBet === 0, [totalBet]);

  const isUnableToPlaceBets: boolean = useMemo(() => {
    if (walletBalance === null || betLimits === undefined) {
      return true;
    }

    return walletBalance < betLimits.min && isNoConfirmedBets;
  }, [walletBalance, betLimits, isNoConfirmedBets]);

  const chipItems: IChipCarouselItem[] = useMemo(() => {
    if (betLimits === undefined) {
      return [];
    }

    const CHIPS = currency ? CHIPS_CURRENCIES[currency] : CHIPS_CURRENCIES['USD'];

    const availableChips = CHIPS.filter(
      ({ value: chipValue }) =>
        betLimits && chipValue <= betLimits.max && chipValue >= betLimits.min,
    );

    const result: IChipCarouselItem[] = availableChips.map((chip) => {
      const disabled = isUnableToPlaceBets;

      return {
        ...chip,
        disabled,
      };
    });

    return result;
  }, [betLimits, isUnableToPlaceBets, currency]);

  return (
    <>
      <ChipsResponsive
        walletBalance={walletBalance}
        betLimits={betLimits}
        currentChip={activeChip}
        chipItems={chipItems}
        isActiveJP={false}
        onChipClick={onChipClick}
      />
    </>
  );
};
