import { put } from 'redux-saga/effects';
import { Notifications } from 'types';

import { setIsBlockedChat } from 'core/widgets/ChatPanel/actions';
import { OnChatTokenProvidedData } from '../../types';
import { connectToChatWebSocketAction } from '../../actions';

export function* onChatTokenProvidedSaga({
  type: notificationType,
  data,
}: OnChatTokenProvidedData) {
  if (notificationType === Notifications.CHAT_TOKEN_PROVIDED) {
    yield put(connectToChatWebSocketAction({ token: data.token }));
  }
}
