import React, { FunctionComponent } from 'react';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Board, Grid, RoadLabel } from 'widgets/Scoreboard/ScoreboardGrid';
import { IScoreboardRoad } from '../types';
import { SmallRoadChild } from './SmallRoadChild';

export const SmallRoadLandscape: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [844, 6],
}) => (
  <Group size={{ width: 188, height: 102 }} position={position}>
    <g transform="translate(0, 102) rotate(-90)">
      <RoadLabel boardName={Board.SmallRoad} />
    </g>
    <Grid col={10} row={6} cellSize={17} childItemType={SmallRoadChild} roadData={roadData} />
  </Group>
);
