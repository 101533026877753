import { createAction } from 'typesafe-actions';
import { Moment } from 'moment';
import {
  ACTIONS,
  IJackpot,
  IJackpotUpdated,
  IRoundInRow,
  IUserInfo,
  IStagePlayersObjectJackpot,
  IJackpotWon,
  IWinnerJP,
  IJackpotPoints,
} from './types';

export const setCurrentJackpot = createAction(ACTIONS.SET_CURRENT_JP)<IJackpot | null>();

export const updateJackpot = createAction(ACTIONS.UPDATE_CURRENT_JP)<IJackpotUpdated>();

export const updateCurrentJackpotUserPointsJP = createAction(
  ACTIONS.UPDATE_CURRENT_JP_USER_POINTS,
)<IJackpotPoints>();

export const updateNextJackpotUserPointsJP = createAction(
  ACTIONS.UPDATE_NEXT_JP_USER_POINTS,
)<IJackpotPoints>();

export const updateWinners = createAction(ACTIONS.UPDATE_WINNERS)();

export const setPointsInRow = createAction(ACTIONS.SET_POINTS_IN_ROW)<IRoundInRow>();

export const setUserInfo = createAction(ACTIONS.SET_USER_INFO)<IUserInfo>();

export const setNextJackpot = createAction(ACTIONS.SET_NEXT_JP)<IJackpot | null>();

export const updateNextJackpot = createAction(ACTIONS.UPDATE_NEXT_JP)<IJackpotUpdated>();

export const setStageJackpot = createAction(
  ACTIONS.SET_STAGE_JACKPOT,
)<IStagePlayersObjectJackpot | null>();

export const setJackpotWon = createAction(ACTIONS.SET_JACKPOT_WON)<IJackpotWon>();
export const setJackpotWonSum = createAction(ACTIONS.SET_JACKPOT_WON_SUM)<{ sum: number | null }>();
export const setJackpotWonWinners = createAction(ACTIONS.SET_JACKPOT_WON_WINNERS)<{
  players: IWinnerJP[] | null;
}>();

export const setCountdownJackpot = createAction(ACTIONS.SET_COUNTDOWN_JACKPOT)<string | null>();

export const setIsActiveCollectionPeriod = createAction(
  ACTIONS.SET_IS_ACTIVE_COLLECTION_PERIOD,
)<boolean>();

export const setIsUserWonInJP = createAction(ACTIONS.SET_IS_USER_WON_IN_JP)<boolean>();
export const setShowJPPopup = createAction(ACTIONS.SET_SHOW_JP_POPUP)<boolean>();
export const setNewCurrentJackpot = createAction(ACTIONS.SET_NEW_CURRENT_JP)<IJackpot | null>();
export const setInfoJackpotDraw = createAction(ACTIONS.SET_INFO_JACKPOT_DRAW)<{
  jackpotAmount: number | null;
  jackpotCurrency: string | null;
}>();

export const setInfoDisplayingJackpotWin = createAction(ACTIONS.SET_INFO_DISPLAY_JACKPOT_WIN)<{
  winDuration: number | null;
}>();
