import { BetType } from '../Bet/types';

export enum ACTIONS {
  SAVE_ROUND_BET = 'SAVE_ROUND_BET',
  SAVE_ROUND_BET_SUCCESS = 'SAVE_ROUND_BET_SUCCESS',
  REPEAT_BET = 'REPEAT_BET',
  REPEAT_BET_SUCCESS = 'REPEAT_BET_SUCCESS',
  CLEAR_REPEAT_BET = 'CLEAR_REPEAT_BET',
  CONFIRM_REPEAT_BET = 'CONFIRM_REPEAT_BET',
  ERROR_REPEAT_BET = 'ERROR_REPEAT_BET',
  RESET_REPEAT_BET = 'RESET_REPEAT_BET',
}

export interface IBetRepeatState {
  isUsed: boolean;
  isConfirmed: boolean;
  value: BetType | null;
}
