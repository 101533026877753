import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IChatPanelProps } from './types';
import { ChatPanelComponentResponsive } from './Responsive';
import { chatSelector } from 'core/widgets/ChatPanel';
import { sendNewMessage, addNewMessageInput } from 'core/widgets/ChatPanel/actions';

export const ChatPanel = ({ onCloseClick }: IChatPanelProps) => {
  const dispatch = useDispatch();

  const { messages, newMessage, messageError, isBlockedChat, isSendButtonBlocked } =
    useSelector(chatSelector);

  const onSendMessage = () => {
    if (newMessage?.length) {
      dispatch(sendNewMessage(newMessage));
    }
  };

  const handleMessageChange = (event: any) => {
    dispatch(addNewMessageInput(event.target.value));
  };

  console.log({ isSendButtonBlocked, isBlockedChat, r: isBlockedChat || isSendButtonBlocked });

  return (
    <>
      <ChatPanelComponentResponsive
        messages={messages}
        newMessage={newMessage}
        messageError={messageError}
        isBlockedChat={isBlockedChat || isSendButtonBlocked}
        onSendMessage={onSendMessage}
        handleMessageChange={handleMessageChange}
        onCloseClick={onCloseClick}
      />
    </>
  );
};
