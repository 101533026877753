import { betMessages } from 'constants/toastMessages';
import { confirmedBetSelector } from 'core/widgets/Bet';
import {
  addConfirmedBetAction,
  clearAllBetsAction,
  placeBetSuccessAction,
  setConfirmedBetAction,
} from 'core/widgets/Bet/actions';
import { BetType, IBet } from 'core/widgets/Bet/types';
import { getBetSum, getSummaryBet } from 'core/widgets/Bet/utils';
import { addToastAction } from 'core/widgets/Toast/actions';
import { setTotalBetAction, updateBalanceAction } from 'core/widgets/User/actions';
import { put, select } from 'redux-saga/effects';
import { Notifications, SLOTS } from 'types';
import { ToastTypes } from 'types/toast';
import { OnPendingBetsMadeData } from '../types';
import { EMPTY_BET } from 'core/widgets/Bet/data';

export function* onPendingBetsMadeSaga({
  type: notificationType,
  data: result,
}: OnPendingBetsMadeData) {
  if (notificationType === Notifications.PENDING_BETS_MADE) {
    if (result.accepted) {
      // yield put(setConfirmedBetAction({
      //   player: result.player,

      // }));
      const confirmedBets: BetType[] = yield select(confirmedBetSelector);
      const totalConfirmedBets: BetType = getSummaryBet(confirmedBets);

      const confirmedPendingBet: IBet = {
        id: new Date().getTime(),
        body: {
          [SLOTS.player]: result.player,
          [SLOTS.tie]: result.tie,
          [SLOTS.banker]: result.banker,
        },
      };
      const betSum = getBetSum([confirmedPendingBet.body]);

      yield put(placeBetSuccessAction(EMPTY_BET));

      yield put(setTotalBetAction(result.total));
      yield put(setConfirmedBetAction(confirmedPendingBet));
      yield put(addToastAction({ type: ToastTypes.SUCCESS, value: betMessages.ACCEPTED }));
    }

    if (!result.accepted) {
      yield put(clearAllBetsAction());
    }
  }
}
