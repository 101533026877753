import React, { useMemo } from 'react';
// import { Navigation } from 'swiper';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Device } from 'types';
import { ICarouselChipsProps } from './types';
import {
  ButtonNavStyled,
  ChipStyled,
  InnerWrapper,
  Item,
  ItemInnerWrapper,
  Wrapper,
} from './styles';

export const BaseCarouselChips = ({
  chips,
  currentChip,
  deviceType = Device.Desktop,
  isActiveJP,
  onClick,
  onBeforeInit,
  onButtonPrevClick,
  onButtonNextClick,
}: ICarouselChipsProps) => {
  const slides = useMemo(() => {
    const chipsToShow = chips.map((chip) => (
      <SwiperSlide key={chip.color}>
        <Item
          $active={chip.color === currentChip?.color}
          disabled={chip.disabled}
          onClick={chip.disabled ? undefined : () => onClick(chip)}>
          <ItemInnerWrapper>
            <ChipStyled
              isActiveJP={isActiveJP}
              // name={chip.name}
              color={chip.color}
              value={chip.value}
              showValue
            />
          </ItemInnerWrapper>
        </Item>
      </SwiperSlide>
    ));

    return chipsToShow;
  }, [chips, currentChip?.color, isActiveJP, onClick]);

  return (
    <Wrapper $deviceType={deviceType}>
      <ButtonNavStyled type="prev" onClick={onButtonPrevClick} />
      <InnerWrapper>
        <Swiper
          modules={[Navigation]}
          slidesPerView={slides?.length < 5 ? slides?.length : 5}
          shortSwipes={true}
          onBeforeInit={onBeforeInit}>
          {slides}
        </Swiper>
      </InnerWrapper>
      <ButtonNavStyled type="next" onClick={onButtonNextClick} />
    </Wrapper>
  );
};
