import React from 'react';
import { MobileComponent, TabletLandscapeComponent, TabletPortraitComponent } from 'components';
import { Device } from 'types';
import { BaseCountdownStage } from './CountdownStage.base';
import { IDeviceCountdownStageProps } from './types';

export const CountdownStageResponsive = (props: IDeviceCountdownStageProps) => (
  <>
    <TabletLandscapeComponent>
      <BaseCountdownStage {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseCountdownStage {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BaseCountdownStage {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
