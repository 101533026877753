import React from 'react';
import { useTranslation } from 'react-i18next';
import { WinnerBall } from 'constants/common';
import { ContentWrapper, PredictionShortcutStyled, ShortcutTextMobile } from './styles';
import { IPredictionShortcutProps } from './types';

export const PredictionShortcutMobile = ({
  size = { width: 64, height: 18 },
  predictedBall,
  onClick,
  position = [0, 0],
}: IPredictionShortcutProps) => {
  const { t, i18n } = useTranslation();
  const [positionX, positionY] = position;
  const isSmallSizeBox = i18n.language !== 'id';

  return (
    <PredictionShortcutStyled
      onClick={onClick}
      width={size.width}
      height={size.height}
      x={positionX}
      y={positionY}
      viewBox="0 0 64 18"
      fill="none">
      <path d="M63.5 0.5H0.5V9V17.5H63.5V0.5Z" fill="#121011" fillOpacity="0.4" />
      {predictedBall === WinnerBall.RED && (
        <>
          <path d="M63.5 0.5H0.5V9V17.5H63.5V0.5Z" fill="url(#paint0_linear_2000_28070)" />
          <path d="M63.5 0.5H0.5V9V17.5H63.5V0.5Z" stroke="url(#paint1_linear_2000_28070)" />
        </>
      )}
      {predictedBall === WinnerBall.BLUE && (
        <>
          <path d="M63.5 0.5H0.5V9V17.5H63.5V0.5Z" fill="url(#paint0_linear_2000_28067)" />
          <path d="M63.5 0.5H0.5V9V17.5H63.5V0.5Z" stroke="url(#paint1_linear_2000_28067)" />
        </>
      )}
      <defs>
        <linearGradient
          id="paint0_linear_2000_28070"
          x1="32"
          y1="0"
          x2="32"
          y2="18"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#ED1A4C" stopOpacity="0" />
          <stop offset="1" stopColor="#9E1334" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2000_28070"
          x1="89.7391"
          y1="9"
          x2="-15.3043"
          y2="9"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#9E1334" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint0_linear_2000_28067"
          x1="32"
          y1="0"
          x2="32"
          y2="18"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#57B5E3" stopOpacity="0" />
          <stop offset="1" stopColor="#127BAD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2000_28067"
          x1="89.7391"
          y1="9"
          x2="-15.3043"
          y2="9"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#127BAD" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
      </defs>
      <ContentWrapper
        x={isSmallSizeBox ? '16' : '10'}
        y="3.5"
        width={isSmallSizeBox ? '40' : '46'}
        height="12"
        viewBox={isSmallSizeBox ? '0 0 40 12' : '0 0 46 12'}>
        <ShortcutTextMobile y="50%" x="0%" dominantBaseline="middle">
          {predictedBall === WinnerBall.RED ? t('scoreboard.ifBankerWinTitle') : null}
          {predictedBall === WinnerBall.BLUE ? t('scoreboard.ifPlayerWinTitle') : null}
        </ShortcutTextMobile>
      </ContentWrapper>
    </PredictionShortcutStyled>
  );
};
