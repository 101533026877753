import { Chip } from 'components';
import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.svg`
  overflow: visible;
`;

export const ChipStyled = styled(Chip)``;

export const BetValueWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const BetValueInnerWrapper = styled.div``;

export const BetValue = styled.p<{ $deviceType?: Device }>`
  box-sizing: border-box;
  max-width: min-content;
  margin: 0 auto;
  padding: 0 4px;
  text-align: center;
  white-space: nowrap;
  background: linear-gradient(
    90deg,
    rgba(18, 16, 17, 0.4) 0%,
    #121011 50%,
    rgba(18, 16, 17, 0.4) 100%
  );

  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `};

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        min-width: 24px;
        font-size: 10px;
        line-height: 12px;
      `;
    }

    return css`
      min-width: 40px;
      font-size: 12px;
      line-height: 14px;
    `;
  }}
`;
