import React, { FunctionComponent } from 'react';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Grid } from 'widgets/Scoreboard/ScoreboardGrid';
import { IScoreboardRoad } from '../types';
import { BigRoadChild } from './BigRoadChild';

export const BigRoadMobile: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [168, 26],
}) => (
  <Group size={{ width: 190, height: 55 }} position={position}>
    <Grid
      col={20}
      row={6}
      cellSize={9}
      childItemType={BigRoadChild}
      childItemSize={{ width: 9, height: 9 }}
      roadData={roadData}
      position={[0, 0]}
    />
  </Group>
);
