import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BaseWinStatus } from './WinStatus.base';
import { WinStatusMobile } from './WinStatus.mobile';
import { IWinStatusComponentProps } from './types';

export const WinStatusResponsive = (props: IWinStatusComponentProps) => (
  <>
    <DesktopComponent>
      <BaseWinStatus {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseWinStatus {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseWinStatus {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <WinStatusMobile {...props} />
    </MobileComponent>
  </>
);
