import React from 'react';
import { IconStyled, MainSettingsItemStyled, SettingsItemText } from './styles';
import { IMainSettingsItem } from './types';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const MainSettingsItem = ({ icon, text, onClick }: IMainSettingsItem) => (
  <MainSettingsItemStyled onClick={onClick} $isOldBrowser={isOldBrowserIOS()}>
    <IconStyled name={icon} />
    <SettingsItemText>{text}</SettingsItemText>
  </MainSettingsItemStyled>
);
