import { put, select } from 'redux-saga/effects';

import { Notifications } from 'types';
import {
  updateCurrentJackpotUserPointsJP,
  updateNextJackpotUserPointsJP,
} from 'core/widgets/Jackpot/actions';
import { OnPlayerPointsData } from '../../types';
import { currentJackpotSelector, nextJackpotSelector } from 'core/widgets/Jackpot/selectors';
import { IJackpot } from 'core/widgets/Jackpot/types';

export function* onJackpotPlayerPointsSaga({
  type: notificationType,
  data: result,
}: OnPlayerPointsData) {
  if (notificationType === Notifications.JACKPOT_PLAYER_POINTS) {
    const { points } = result;
    const { currentJackpot } = yield select(currentJackpotSelector);
    const nextJackpot: IJackpot = yield select(nextJackpotSelector);

    const jackpotPoints = points?.find(
      (jackpotPoints) => jackpotPoints?.jackpotId === currentJackpot?.jackpotId,
    );

    const nextJackpotpPoints = points?.find(
      (jackpotPoints) => jackpotPoints?.jackpotId === nextJackpot?.jackpotId,
    );

    if (jackpotPoints) {
      yield put(
        updateCurrentJackpotUserPointsJP({
          jackpotId: currentJackpot?.jackpotId,
          value: jackpotPoints?.value ? jackpotPoints?.value : 0,
        }),
      );
    }

    if (nextJackpot) {
      yield put(
        updateNextJackpotUserPointsJP({
          jackpotId: nextJackpot?.jackpotId,
          value: nextJackpotpPoints?.value ? nextJackpotpPoints?.value : 0,
        }),
      );
    }
  }
}
