import React from 'react';
import { useSelector } from 'react-redux';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { scoreboardSelector } from 'core/widgets/Scoreboard/Boards';
import { CockroachPigLandscape } from './CockroachPig.landscape';
import { CockroachPigMobile } from './CockroachPig.mobile';
import { CockroachPigTablet } from './CockroachPig.tablet';

export const CockroachPigRoad = () => {
  const roadData = useSelector(scoreboardSelector);

  return (
    <>
      <DesktopComponent>
        <CockroachPigLandscape roadData={roadData.cockroachPigRoadData} />
      </DesktopComponent>
      <TabletLandscapeComponent>
        <CockroachPigLandscape roadData={roadData.cockroachPigRoadData} />
      </TabletLandscapeComponent>
      <TabletPortraitComponent>
        <CockroachPigTablet roadData={roadData.cockroachPigRoadData} />
      </TabletPortraitComponent>
      <MobileComponent>
        <CockroachPigMobile roadData={roadData.cockroachPigRoadData} />
      </MobileComponent>
    </>
  );
};
