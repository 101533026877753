import { SLOTS } from 'types';

export enum ACTIONS {
  SET_CONFIRMATION_SLOT = 'SET_CONFIRMATION_SLOT',
  SET_CLOSEST_CONFIRMATION_SLOT = 'SET_CLOSEST_CONFIRMATION_SLOT',
  CLEAR_CONFIRMATION_SLOT = 'CLEAR_CONFIRMATION_SLOT',
}

export interface IBetConfirmationState {
  position: {
    previous: SLOTS | null;
    current: SLOTS | null;
  };
}
