import { ActionType, createReducer } from 'typesafe-actions';
import { changeRoundStatusAction } from './actions';
import { IRoundStatus } from './types';

export const defaultRoundStatus: IRoundStatus = {
  roundStatus: null,
};

const actions = {
  changeRoundStatusAction,
};

export const roundStatusReducer = createReducer<IRoundStatus, ActionType<typeof actions>>(
  defaultRoundStatus,
).handleAction(changeRoundStatusAction, (state, { payload: { roundStatus } }) => ({
  ...state,
  roundStatus,
}));
