import { createAction } from 'typesafe-actions';
import { ACTIONS } from './types';

export const saveBettingTimeToStoreAction = createAction(ACTIONS.SAVE_BETTING_TIME_TO_STORE)<{
  bettingTimeEnd: string;
  bettingTime: number;
}>();

export const setIsTheLast2SecondsInBettingTimeToStoreAction = createAction(
  ACTIONS.SET_IS_LAST_2_SECONDS_BETTING_TIME_TO_STORE,
)<boolean>();

export const setTimeDifferenceUserServerToStoreAction = createAction(
  ACTIONS.SET_TIME_DIFFERENCE_USER_SERVER_TO_STORE,
)<number>();

export const setTimeLeftToStoreAction = createAction(ACTIONS.SET_TIME_LEFT_TO_STORE)<number>();

export const decrementTimeLeftAction = createAction(ACTIONS.DECREMENT_TIME_LEFT)();

export const startDecrementTimeLeftAction = createAction(ACTIONS.START_DECREMENT_TIME_LEFT)();

export const stopDecrementTimeLeftAction = createAction(ACTIONS.STOP_DECREMENT_TIME_LEFT)();
