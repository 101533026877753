interface ICrossBrowserFullScreenHTMLElement extends HTMLElement {
  webkitRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  msRequestFullscreen?: () => void;
}

const openFullScreen = () => {
  const documentElem = document.documentElement as ICrossBrowserFullScreenHTMLElement;
  if (documentElem.requestFullscreen) {
    documentElem.requestFullscreen();
    return;
  }
  if (documentElem.webkitRequestFullscreen) {
    /* Safari */
    documentElem.webkitRequestFullscreen();
    return;
  }
  if (documentElem.mozRequestFullScreen) {
    /* Mozilla */
    documentElem.mozRequestFullScreen();
    return;
  }
  if (documentElem.msRequestFullscreen) {
    /* IE11 */
    documentElem.msRequestFullscreen();
  }
};

export default openFullScreen;
