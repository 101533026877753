import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputRadio } from 'components/InputRadio/InputRadio';
import { Wrapper } from './styles';
import { IInputRadioGroupProps } from './types';

export const InputRadioGroup = ({ value, options, onChange, isLoading }: IInputRadioGroupProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {options.map((option) => (
        <InputRadio
          key={option.value}
          name={option.value}
          value={option.value}
          subtitle={option.subtitle}
          label={t(option.label)}
          isChecked={option.value === value}
          onChange={onChange}
          isDisabled={option.value !== value && isLoading}
        />
      ))}
    </Wrapper>
  );
};
