import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { GamePanelLandscape } from './GamePanel.landscape';
import { GamePanelPortrait } from './GamePanel.portrait';

export const GamePanel = () => (
  <>
    <DesktopComponent>
      <GamePanelLandscape />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <GamePanelLandscape />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <GamePanelPortrait deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <GamePanelPortrait deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
