import React, { ChangeEvent, useCallback } from 'react';
import {
  Wrapper,
  InputRadioHidden,
  InputRadioCustom,
  InputRadioLabel,
  InputLabel,
  InputRadioSubtitle,
} from './styles';
import { IInputRadioProps } from './types';

export const InputRadio = ({
  value,
  name,
  label,
  subtitle,
  isChecked,
  onChange,
  isDisabled = false,
}: IInputRadioProps) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { id } = e.target;
      onChange(id);
    },
    [onChange],
  );

  return (
    <Wrapper htmlFor={value}>
      <InputRadioHidden
        type="radio"
        name={name}
        id={value}
        checked={isChecked}
        onChange={handleChange}
        disabled={isDisabled}
      />
      <InputRadioCustom $isChecked={isChecked} $isDisabled={isDisabled} />
      <InputLabel $isDisabled={isDisabled}>
        <InputRadioLabel>{label}</InputRadioLabel>
        <InputRadioSubtitle>{subtitle}</InputRadioSubtitle>
      </InputLabel>
    </Wrapper>
  );
};
