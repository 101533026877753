import React, { ComponentType } from 'react';
import { Circle, Group, TieBallQuantityText, TieLine } from 'widgets/Scoreboard/BaseComponents';
import { WinnerBall } from 'constants/common';
import { IRoadItem } from '../types';

export interface IBigRoadItem {
  children: IRoadItem;
}

export const BigRoadChild: ComponentType<IBigRoadItem> = ({
  children: { outcome, isTie, consecutiveTiesQty = 0 },
}: IBigRoadItem) => (
  <Group>
    {outcome !== WinnerBall.GOLD && <Circle outcomeBall={outcome} />}
    {isTie && <TieLine />}
    {consecutiveTiesQty > 1 && <TieBallQuantityText quantity={consecutiveTiesQty} />}
  </Group>
);
