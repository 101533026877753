import { changeRoundStateAction } from 'core/widgets/RoundStates/actions';
import { put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { OnDrawFinishedData } from '../../WinnerBall/types';
import { RoundStates } from '../../RoundStates/types';

export function* onDrawStartedSaga({ type: notificationType }: OnDrawFinishedData) {
  if (notificationType === Notifications.DRAW_STARTED) {
    yield put(changeRoundStateAction({ roundState: RoundStates.DrawStarted }));
  }
}
