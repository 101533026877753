import { put, takeEvery, delay, select } from 'redux-saga/effects';
// import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { setCountdownJackpot } from './widgets/Jackpot/actions';
import { ACTIONS } from './widgets/Jackpot/types';
import { currentJackpotSelector, jackpotActivitySelector } from './widgets/Jackpot/selectors';

function* checkDateAndDispatch() {
  try {
    const { currentJackpot } = yield select(currentJackpotSelector);
    const { countdownJackpot } = yield select(jackpotActivitySelector);

    if (!currentJackpot) {
      return null;
    }

    const currentDate = moment().utc();

    const timerDateCurrentJP = moment(new Date(currentJackpot.timerDate));
    const drawnDateCurrentJP = moment(new Date(currentJackpot.drawnDate));
    const isDateInRangeCountdown = currentDate.isBetween(timerDateCurrentJP, drawnDateCurrentJP);

    if (!countdownJackpot && isDateInRangeCountdown) {
      yield put(setCountdownJackpot(currentJackpot.drawnDate));
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

export function* watchDatesControlSaga() {
  yield takeEvery(ACTIONS.WATCH_DATE_CONTROL_INTERVAL, checkDateAndDispatch);
  while (true) {
    yield delay(20000);
    yield put({ type: ACTIONS.WATCH_DATE_CONTROL_INTERVAL });
  }
}
