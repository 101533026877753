import { IRoadItem, IRoadItems } from 'widgets/Scoreboard/types';

export enum ACTIONS {
  ADD_NEW_SCOREBOARD_ITEM = 'ADD_NEW_SCOREBOARD_ITEM',
  ADD_SCOREBOARD_PREDICTION = 'ADD_SCOREBOARD_PREDICTION',
  REMOVE_SCOREBOARD_PREDICTION = 'REMOVE_SCOREBOARD_PREDICTION',
  RESET_SCOREBOARD_STATE = 'RESET_SCOREBOARD_STATE',
  SET_INITIAL_SCOREBOARD_STATE = 'SET_INITIAL_SCOREBOARD_STATE',
}

export interface IMatrixes {
  bigRoadMatrix: IMatrix;
  smallRoadMatrix: IMatrix;
  cockroachPigRoadMatrix: IMatrix;
}

export interface IRoads {
  beadRoadData: IRoadItem[];
  bigRoadData: IRoadItems;
  cockroachPigRoadData: IRoadItems;
  smallRoadData: IRoadItems;
}

export interface IScoreboardData {
  data: IRoads;
  matrixes: IMatrixes;
}

export interface IScoreboardState {
  currentState: IScoreboardData;
  confirmedState: IScoreboardData;
}

export type IColumn = IRoadItem[];
export type IMatrix = IColumn[];

export interface IColumnState {
  accumulator: IRoadItems;
  isTailDetected: boolean;
  tailLength: number;
  tailIndex: number;
}
