import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IWebSocketState } from './types';

const webSocketSelector = (state: IState) => state.webSocketReducer;

export const connectionSelector = createSelector(
  webSocketSelector,
  (state: IWebSocketState) => state.isConnected,
);

export const connectionFailedSelector = createSelector(
  webSocketSelector,
  (state: IWebSocketState) => state.isConnectionFailed,
);

export const socketDisconnectedSelector = createSelector(
  webSocketSelector,
  (state: IWebSocketState) => state.isSocketDisconnected,
);
