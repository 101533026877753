import styled, { css } from 'styled-components';
import { Device } from 'types';
import backgroundSVG from './assets/background.svg';
import backgroundMobileSVG from './assets/background-mobile.svg';
import { AspectRatioIphone, getAspectRatioIphone, isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const StageText = styled.span`
  color: #fff7eb;
  font-size: 10rem;
  font-weight: 400;
  text-transform: uppercase;
  fill: #fff7eb;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;
  @media only screen and (max-width: 430px) {
    font-size: 9rem;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;

export const Wrapper = styled.div<{ $deviceType: Device }>`
  position: absolute;
  left: 10rem;
  /* left: 0; */
  left: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
    ? '0'
    : '10rem'};
  transform: translateY(10rem);
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 334rem;
        height: 28rem;
        width: auto;
        left: 20rem;

        ${StageText} {
          font-size: 12rem;
          margin-top: ${isOldBrowserIOS() ? '-2rem' : '-5rem'};
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 168rem;

        height: 24rem;
        width: auto;

        svg {
          height: 100%;
          width: auto;
        }
        @media only screen and (max-width: 430px) {
          top: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
            ? '145rem'
            : '168rem'};
        }
      `;
    }
  }};
`;
