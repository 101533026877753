import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IBallsCounterState } from './types';

const ballsCounterSelector = (state: IState) => state.ballsCounterReducer;

export const totalBallsCounterSelector = createSelector(
  ballsCounterSelector,
  (state: IBallsCounterState) => state.ballsCounter,
);
