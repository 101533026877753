import React from 'react';
import { useSelector } from 'react-redux';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { scoreboardSelector } from 'core/widgets/Scoreboard/Boards';
import { SmallRoadLandscape } from './SmallRoad.landscape';
import { SmallRoadMobile } from './SmallRoad.mobile';
import { SmallRoadTablet } from './SmallRoad.tablet';

export const SmallRoad = () => {
  const roadData = useSelector(scoreboardSelector);

  return (
    <>
      <DesktopComponent>
        <SmallRoadLandscape roadData={roadData.smallRoadData} />
      </DesktopComponent>
      <TabletLandscapeComponent>
        <SmallRoadLandscape roadData={roadData.smallRoadData} />
      </TabletLandscapeComponent>
      <TabletPortraitComponent>
        <SmallRoadTablet roadData={roadData.smallRoadData} />
      </TabletPortraitComponent>
      <MobileComponent>
        <SmallRoadMobile roadData={roadData.smallRoadData} />
      </MobileComponent>
    </>
  );
};
