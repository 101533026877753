import { SLOTS } from 'types';
import { preciseAddSubtract, roundToFixed } from 'utils/roundToFix';
import { BetType } from '../types';

export const getBetSum = (bets: BetType[]) =>
  bets.reduce((sum, bet) => {
    const slotsValue = preciseAddSubtract(
      preciseAddSubtract(bet[SLOTS.player], bet[SLOTS.tie], 'add'),
      bet[SLOTS.banker],
      'add',
    );
    return preciseAddSubtract(sum, slotsValue, 'add');
  }, 0);
