import React from 'react';
import styled from 'styled-components';
import {
  DrawnBall,
  GamePanel,
  PreloaderMain,
  VideoStream,
  Slots,
  Toast,
  Timer,
  Notices,
  WinStatus,
  Settings,
  WinnersTicker,
} from 'widgets';
import { DesktopAspectRatio, TabletLandscapeAspectRatio } from 'constants/theme';
import { Device } from 'types';
import { WinJP } from 'widgets/WinJP';

type LandscapeViewDevices = Device.Desktop | Device.Tablet_Landscape;

const landscapeAspectRatioStyles = {
  [Device.Desktop]: <DesktopAspectRatio />,
  [Device.Tablet_Landscape]: <TabletLandscapeAspectRatio />,
};

interface IAppLandscape {
  deviceType?: LandscapeViewDevices;
}

export const AppLandscape = ({ deviceType = Device.Desktop }: IAppLandscape) => (
  <LandscapeWrapper id="ratio-container" $deviceType={deviceType}>
    {landscapeAspectRatioStyles[deviceType]}

    <VideoStream />
    <ContentWrapper>
      <PreloaderMain />
      <Toast />
      <Slots />
      <Settings />
      <Timer />
      <GamePanel />
      <DrawnBall />
      <Notices />
      <WinStatus />
      <WinJP />
      <WinnersTicker />
      <InvisibleWrapper />
    </ContentWrapper>
  </LandscapeWrapper>
);

const LandscapeWrapper = styled.div<{ $deviceType: LandscapeViewDevices }>`
  position: relative;
  max-height: 100vh;
  height: calc(100vw * 9 / 16);
  max-width: ${({ $deviceType }) =>
    $deviceType === Device.Desktop ? 'calc(100vh * 16 / 9)' : 'calc(100dvh * 16 / 9)'};
  width: 100vw;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

const InvisibleWrapper = styled.div`
  display: none;
`;
