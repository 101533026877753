import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import moment from 'moment';
import { IJackpotState } from './types';

export const jackpotInfoSelector = (state: IState): IJackpotState => state.jackpotReducer;

export const currentJackpotSelector = createSelector(
  jackpotInfoSelector,
  (state: IJackpotState): any => {
    if (!state.currentJackpot || !state.currentJackpot.players?.length) {
      return {
        currentJackpot: state.currentJackpot,
        activePlayers: null,
        outOfStagePlayers: null,
        uuid: state?.uuid,
      };
    }

    const { outOfStagePlayers, activePlayers } = state.currentJackpot?.players?.length
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        state.currentJackpot?.players?.reduce(
          // @ts-ignore
          (acc, member) => {
            if (!state.stage?.playersObject) {
              return {
                activePlayers: [],
                outOfStagePlayers: [],
              };
            }

            if (
              state.stage?.playersObject &&
              // eslint-disable-next-line no-prototype-builtins
              state.stage?.playersObject.hasOwnProperty(member.uuid)
            ) {
              return {
                outOfStagePlayers: [...acc.outOfStagePlayers],
                activePlayers: [...acc.activePlayers, member],
              };
            }

            return {
              activePlayers: [...acc.activePlayers],
              outOfStagePlayers: [...acc.outOfStagePlayers, member],
            };
          },
          {
            activePlayers: [],
            outOfStagePlayers: [],
          } as any,
        )
      : {
          activePlayers: [],
          outOfStagePlayers: [],
        };

    return {
      currentJackpot: state.currentJackpot,
      activePlayers,
      outOfStagePlayers,
      uuid: state?.uuid,
    };
  },
);

export const nextJackpotSelector = createSelector(
  jackpotInfoSelector,
  (state: IJackpotState) => state.nextJackpot,
);

export const hasUserInTop100Selector = createSelector(
  jackpotInfoSelector,
  (state: IJackpotState) => {
    if (!state.currentJackpot?.players) {
      return false;
    }

    const hasUserInJP100 = state.currentJackpot?.players.find(
      ({ uuid }: any) => uuid === state.uuid,
    );
    return hasUserInJP100;
  },
);

export const hasUserInStageSelector = createSelector(
  jackpotInfoSelector,
  (state: IJackpotState) => {
    if (!state.uuid) {
      return false;
    }

    if (
      state.stage?.playersObject &&
      // eslint-disable-next-line no-prototype-builtins
      !state.stage?.playersObject.hasOwnProperty(state?.uuid)
    ) {
      return false;
    }

    if (
      state.stage?.playersObject &&
      // eslint-disable-next-line no-prototype-builtins
      state.stage?.playersObject.hasOwnProperty(state?.uuid)
    ) {
      return true;
    }

    return false;
  },
);

export const jackpotActivitySelector = createSelector(
  jackpotInfoSelector,
  ({ currentJackpot, nextJackpot, stage, countdownJackpot }: IJackpotState) => ({
    stage,
    hasCollectionPeriod: Boolean(
      (currentJackpot?.isCollectionPeriod && !currentJackpot?.isPaused) ||
        (nextJackpot?.isCollectionPeriod && !nextJackpot?.isPaused),
    ),
    countdownJackpot: countdownJackpot ? moment(new Date(countdownJackpot)).toDate() : null,
    minBet: currentJackpot?.minBet,
    sum: currentJackpot?.amount,
    currency: currentJackpot?.currency,
  }),
);

export const isUserInStageJPSelector = createSelector(
  jackpotInfoSelector,
  (state: IJackpotState): boolean => {
    if (!state.uuid || !state.stage?.playersObject) {
      return false;
    }
    const isUserInStageJP = Object.prototype.hasOwnProperty.call(
      state.stage?.playersObject,
      state.uuid,
    );
    return Boolean(isUserInStageJP);
  },
);

export const isUserWonInJPSelector = createSelector(
  jackpotInfoSelector,
  (state: IJackpotState): boolean => {
    const isUserWonInJP = state.winnersListJackpot?.find(({ uuid }: any) => uuid === state.uuid);
    return Boolean(isUserWonInJP);
  },
);

export const jackpotInfoWinningDrawSelector = createSelector(
  jackpotInfoSelector,
  ({
    amountJackpotUserWon,
    winnersListJackpot,
    isUserWonInJP,
    showJPPopup,
    newCurrentJackpot,
    jackpotAmount,
    winDuration,
    jackpotCurrency,
    currencyJackpotUserWon,
  }: IJackpotState) => ({
    amountJackpotUserWon,
    winnersListJackpot,
    isUserWonInJP,
    showJPPopup,
    newCurrentJackpot,
    jackpotAmount,
    winDuration,
    jackpotCurrency,
    currencyJackpotUserWon,
  }),
);

export const jackpotUserPointJPSelector = createSelector(
  jackpotInfoSelector,
  ({ currentJackpotPoints, nextJackpotPoints }: IJackpotState) => ({
    currentJackpotPoints: currentJackpotPoints?.value,
    nextJackpotPoints: nextJackpotPoints?.value,
  }),
);
