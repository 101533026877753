import { put, select } from 'redux-saga/effects';

import { updateJackpot, updateNextJackpot } from 'core/widgets/Jackpot/actions';
import { OnJackpotDetailsUpdatedData } from '../../types';
import { Notifications } from 'types';
import { currentJackpotSelector } from 'core/widgets/Jackpot/selectors';

export function* onJackpotDetailsUpdatedSaga({
  type: notificationType,
  data: result,
}: OnJackpotDetailsUpdatedData) {
  if (notificationType === Notifications.JACKPOT_DETAILS_UPDATED) {
    const { amount, jackpotId, players } = result;
    const { currentJackpot } = yield select(currentJackpotSelector);
    const isCurrent = jackpotId === currentJackpot.jackpotId;

    if (isCurrent) {
      yield put(updateJackpot({ players, amount }));
      return;
    }

    if (!isCurrent) {
      yield put(updateNextJackpot({ players, amount }));
    }
  }
}
