import { ActionType, createReducer } from 'typesafe-actions';
import { addToastAction, removeToastAction } from './actions';
import { IToastState } from './types';

export const toastState: IToastState = {
  toastItem: null,
};

const actions = {
  addToastAction,
  removeToastAction,
};

export const toastReducer = createReducer<IToastState, ActionType<typeof actions>>(toastState)
  .handleAction(addToastAction, (_, { payload }) => ({
    toastItem: payload,
  }))
  .handleAction(removeToastAction, () => ({
    toastItem: null,
  }));
