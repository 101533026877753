import { ActionType, createReducer } from 'typesafe-actions';
import { nth } from 'ramda';
import 'core-js/features/array/at';
import { IRoadItem } from 'widgets/Scoreboard/types';
import {
  addScoreboardItemAction,
  addScoreboardPredictionAction,
  removeScoreboardPredictionAction,
  resetScoreboardStateAction,
  setInitialScoreboardStateAction,
} from './actions';
import { IScoreboardData, IScoreboardState } from './types';
import {
  transformAllRoads,
  transformWinnerBallsToInitialRoad,
  transformWinnerBallToRoadItem,
} from './helpers/transform';

const defaultScoreboardData: IScoreboardData = {
  data: {
    beadRoadData: [],
    bigRoadData: [],
    smallRoadData: [],
    cockroachPigRoadData: [],
  },
  matrixes: {
    bigRoadMatrix: [],
    smallRoadMatrix: [],
    cockroachPigRoadMatrix: [],
  },
};

export const scoreboardState: IScoreboardState = {
  currentState: defaultScoreboardData,
  confirmedState: defaultScoreboardData,
};

const actions = {
  setInitialScoreboardStateAction,
  addScoreboardItemAction,
  addScoreboardPredictionAction,
  removeScoreboardPredictionAction,
  resetScoreboardStateAction,
};

export const scoreboardReducer = createReducer<IScoreboardState, ActionType<typeof actions>>(
  scoreboardState,
)
  .handleAction(setInitialScoreboardStateAction, (state, { payload: { winnerBallsData } }) => {
    const { data: defaultRoads, matrixes: defaultMatrixes } = defaultScoreboardData;

    const transformedBallsHistory = transformWinnerBallsToInitialRoad(winnerBallsData);

    const transformedData = transformAllRoads(
      transformedBallsHistory,
      defaultMatrixes,
      defaultRoads,
    );

    const updatedState: IScoreboardData = {
      data: {
        ...defaultRoads,
        ...transformedData.roads,
      },
      matrixes: {
        ...defaultMatrixes,
        ...transformedData.matrixes,
      },
    };

    return {
      ...state,
      currentState: updatedState,
      confirmedState: updatedState,
    };
  })
  .handleAction(resetScoreboardStateAction, (state) => ({
    ...state,
    currentState: defaultScoreboardData,
    confirmedState: defaultScoreboardData,
  }))
  .handleAction(addScoreboardItemAction, (state, { payload: { winnerBall } }) => {
    const { data: currentRoadsData, matrixes: currentMatrixes } = state.currentState;

    const newScoreboardItem = transformWinnerBallToRoadItem(
      winnerBall,
      currentRoadsData.beadRoadData,
    );

    const transformedData = transformAllRoads(
      [newScoreboardItem],
      currentMatrixes,
      currentRoadsData,
    );

    const updatedState = {
      data: {
        ...currentRoadsData,
        ...transformedData.roads,
      },
      matrixes: {
        ...currentMatrixes,
        ...transformedData.matrixes,
      },
    };

    return {
      ...state,
      currentState: updatedState,
      confirmedState: updatedState,
    };
  })
  .handleAction(addScoreboardPredictionAction, (state, { payload: { winnerBall } }) => {
    const { data: currentRoadsData, matrixes: currentMatrixes } = state.currentState;

    const newScoreboardItem: IRoadItem = {
      outcome: winnerBall,
      isPrediction: true,
    };

    const transformedData = transformAllRoads(
      [newScoreboardItem],
      currentMatrixes,
      currentRoadsData,
    );

    return {
      ...state,
      currentState: {
        data: {
          ...currentRoadsData,
          ...transformedData.roads,
        },
        matrixes: {
          ...currentMatrixes,
          ...transformedData.matrixes,
        },
      },
    };
  })
  .handleAction(removeScoreboardPredictionAction, (state) => {
    const lastItem: IRoadItem | undefined = nth(-1, state.currentState.data.beadRoadData);

    if (lastItem && !lastItem.isPrediction) {
      return state;
    }

    return {
      ...state,
      currentState: state.confirmedState,
    };
  });
