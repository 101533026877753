import React from 'react';
import RcSlider, { Handle, HandleProps } from 'rc-slider';
import { SliderHandle, Wrapper } from './styles';
import 'rc-slider/assets/index.css';
import { ISliderProps } from './types';

const handleRender = (props: HandleProps & { dragging?: boolean }) => {
  const { dragging, ...restProps } = props;
  return (
    <Handle {...{ ...restProps, dragging: dragging?.toString() }}>
      <SliderHandle />
    </Handle>
  );
};

export const Slider = ({ value, onChange }: ISliderProps) => (
  <Wrapper>
    <RcSlider value={value} onChange={onChange} handle={handleRender} />
  </Wrapper>
);
