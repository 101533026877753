import { put } from 'redux-saga/effects';
import { Notifications } from 'types';

import { addNoticeAction } from 'core/widgets/Notices/actions';
import { Notices } from 'core/widgets/Notices/types';
import { OnTableClosedData } from '../types';

export function* onCloseTableNotificationSaga({ type: notificationType }: OnTableClosedData) {
  if (notificationType === Notifications.NOTIFICATION) {
    yield put(addNoticeAction({ notification: Notices.CloseTableInProgress }));
  }
}
