import { colors } from 'constants/theme';
import styled, { css } from 'styled-components';
// import { Device } from 'types';
// import backgroundSVG from './assets/background.svg';
// import backgroundMobileSVG from './assets/background-mobile.svg';

export const Text = styled.p`
  font-weight: 400;
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;

export const Wrapper = styled.div`
  /* height: 50vh;
  width: 95%; */
  overflow-scrolling: touch;
  padding: 0 0 20px;
  width: 100%;
`;

export const ListItem = styled.div<{ $isGray: boolean; $isSelected: boolean; $disable: boolean }>`
  min-height: 36rem;
  width: calc(100% + 0.5rem);
  font-size: 14rem;
  line-height: 20rem;
  font-style: normal;
  font-weight: 400;
  padding: 0 10rem 0;
  margin-left: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 2rem;

  ${({ $isGray, $isSelected }) => css`
    background: ${$isSelected ? colors.Brown : $isGray ? colors.MediumDark : '#1B191A'};
  `};

  ${({ $disable }) => css`
    opacity: ${$disable ? 0.5 : 1};
  `};
`;

export const ItemValue = styled.p``;

export const ItemNickname = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10rem;
`;

export const TabWrapper = styled.div`
  /* height: 50vh;
  width: 95%; */
  overflow-scrolling: touch;
  display: flex;
  /* margin-top: 10rem; */
  /* margin-left: 4%; */
  position: relative;
  z-index: 1;
  /* width: 92%; */
  margin: 10rem 0 0 0;
  width: calc(100% - 1.5px);
`;

export const TabItem = styled.div<{ $isSelected: boolean }>`
  /* min-height: 36px;
  width: 100%;
  font-size: 14rem;
  line-height: 20rem;
  font-style: normal;
  font-weight: 400;
  padding: 0 20rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between; */
  cursor: pointer;
  text-transform: initial;
  padding: 6rem 0;
  text-align: center;
  width: 50%;
  border: 1px solid #f1e6d2;
  background: linear-gradient(348deg, #121011 8.54%, #343434 116.17%);
  /* width: calc(50% - 2px); */

  ${({ $isSelected }) => css`
    background: ${$isSelected
      ? 'linear-gradient(180deg, #1B191A 0%, #BB9370 100%)'
      : 'linear-gradient(348deg, #121011 8.54%, #343434 116.17%)'};
  `};

  &:last-of-type {
    border-left: none;
  }
`;

export const TabItemValue = styled.p`
  color: #fff7eb;

  text-align: center;
  font-weight: 700;
  font-size: 10rem;
  line-height: 16rem;
`;

export const Divider = styled.div`
  color: var(--light-gold-e-7-d-5-b-9, #e7d5b9);
  font-size: 10px;
  font-weight: 700;
  line-height: 18px; /* 180% */
  text-transform: uppercase;
  border-top: 1px solid var(--clash-gold-bb-9370, #bb9370);
  border-bottom: 1px solid var(--clash-gold-bb-9370, #bb9370);
  background: var(--dark-1-b-191-a, #1b191a);
  padding: 6rem 10rem 6rem;
  margin-top: 18rem;
  /* min-height: 30rem; */
`;
