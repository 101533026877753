import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BaseSlotBet } from './SlotBet.base';
import { SlotBetMobile } from './SlotBet.mobile';
import { ISlotBetProps } from './types';

export const SlotBetResponsive = (props: ISlotBetProps) => (
  <>
    <DesktopComponent>
      <BaseSlotBet {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseSlotBet {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseSlotBet {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <SlotBetMobile {...props} />
    </MobileComponent>
  </>
);
