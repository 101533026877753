import { ActionType } from 'typesafe-actions';
import { call, put, select, delay } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { setConfirmationSlotAction } from 'core/widgets/BetConfirmation/actions';
import { clearDoubleBetAction } from 'core/widgets/BetDouble/actions';
import { clearRepeatBetAction } from 'core/widgets/BetRepeat/actions';

import {
  clearPlacedBetAction,
  clearPlacedBetSuccessAction,
  placeBetSuccessAction,
} from '../actions';
import { summaryBetSelector } from '../selectors';
import {
  ICreatePendingBetRequestData,
  ICreatePendingBetResponseData,
  RestService,
} from 'services/RestAPIService';
import { BetType } from '../types';
import { isTheLast2SecondsInBettingTimeSelector } from 'core/widgets/BettingTime';
import { IRoundState, RoundStates } from 'core/widgets/RoundStates/types';
import { roundStateSelector } from 'core/widgets/RoundStates';
import { getBetSum } from '../utils';
import { setTotalBetAction } from 'core/widgets/User/actions';
import { totalBetSelector } from 'core/widgets/User';
import { truncationNumber } from 'utils/transformToCurrency';

export function* clearBetSaga({ payload }: ActionType<typeof clearPlacedBetAction>) {
  const isTheLast2SecondsInBettingTime: boolean = yield select(
    isTheLast2SecondsInBettingTimeSelector,
  );
  const roundState: IRoundState['roundState'] = yield select(roundStateSelector);

  if (!isTheLast2SecondsInBettingTime) {
    yield put(clearPlacedBetSuccessAction(payload));
    const placedBet: BetType = yield select(summaryBetSelector);
    const data: ICreatePendingBetRequestData = {
      banker: truncationNumber(placedBet.banker),
      tie: truncationNumber(placedBet.tie),
      player: truncationNumber(placedBet.player),
      timeStamp: new Date().getTime(),
    };
    yield put(placeBetSuccessAction(data));
    yield put(clearDoubleBetAction());
    yield put(clearRepeatBetAction());
    yield put(setConfirmationSlotAction(null));
    return;
  }

  if (isTheLast2SecondsInBettingTime) {
    if (roundState === RoundStates.BettingTimeStarted) {
      const placedBet: BetType = yield select(summaryBetSelector);
      const newBet = { ...placedBet, [payload]: 0 };
      const placedBetData: ICreatePendingBetRequestData = {
        banker: truncationNumber(placedBet.banker),
        tie: truncationNumber(placedBet.tie),
        player: truncationNumber(placedBet.player),
        [payload]: 0,
        timeStamp: new Date().getTime(),
      };

      try {
        const response: ICreatePendingBetResponseData = yield call(() =>
          RestService.createPendingBet(placedBetData),
        );

        const totalBet = getBetSum([newBet]);
        yield put(setTotalBetAction(totalBet));
        yield put(placeBetSuccessAction(placedBetData));
      } catch (error) {
        Sentry.captureException({ error, placedBetData });
        console.log(error);
      }
    }
  }
}
