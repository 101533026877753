import { changeRoundStateAction } from 'core/widgets/RoundStates/actions';
import { put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { RoundStates } from '../../RoundStates/types';
import { OnRoundFinishedData } from '../types';

export function* onRoundFinishedSaga({ type: notificationType }: OnRoundFinishedData) {
  if (notificationType === Notifications.ROUND_FINISHED) {
    yield put(changeRoundStateAction({ roundState: RoundStates.RoundFinished }));
  }
}
