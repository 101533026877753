import React, { FunctionComponent } from 'react';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Board, Grid, RoadLabel } from 'widgets/Scoreboard/ScoreboardGrid';
import { IScoreboardRoad } from '../types';
import { BigRoadChild } from './BigRoadChild';

export const BigRoadLandscape: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [465, 6],
}) => (
  <Group size={{ width: 358, height: 102 }} position={position}>
    <g transform="translate(0, 102) rotate(-90)">
      <RoadLabel boardName={Board.BigRoad} />
    </g>
    <Grid col={20} row={6} cellSize={17} childItemType={BigRoadChild} roadData={roadData} />
  </Group>
);
