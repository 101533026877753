import { put, select } from 'redux-saga/effects';

import { setCurrentJackpot, setNextJackpot } from 'core/widgets/Jackpot/actions';
import { OnJackpotCreatedData } from '../../types';
import { Notifications } from 'types';
import { currentJackpotSelector } from 'core/widgets/Jackpot/selectors';

export function* onJackpotUpdatedSaga({
  type: notificationType,
  data: result,
}: OnJackpotCreatedData) {
  if (notificationType === Notifications.JACKPOT_UPDATED) {
    const { tableId, ...jackpot } = result;
    const { currentJackpot } = yield select(currentJackpotSelector);
    const isCurrent = jackpot.jackpotId === currentJackpot.jackpotId;

    if (isCurrent) {
      yield put(setCurrentJackpot(jackpot));
      return;
    }

    if (!isCurrent) {
      yield put(setNextJackpot(jackpot));
    }
  }
}
