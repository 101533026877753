import React, { useCallback, useRef } from 'react';
import { VIDEO_QUALITY_OPTIONS } from 'core/widgets/VideoSettings/data';
import { useDispatch, useSelector } from 'react-redux';
import {
  videoQualityLoadingSelector,
  videoRequestedSettingsSelector,
} from 'core/widgets/VideoSettings';
import {
  changeVideoAutoAdjustAction,
  changeVideoPlaybackAction,
  changeVideoQualityAction,
} from 'core/widgets/VideoSettings/action';
import { VideoQualities } from 'core/widgets/VideoSettings/types';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { IVideoSettingsPanelProps } from './types';
import { VideoSettingsPanelResponsive } from './Responsive';
import AudioService from 'services/AudioService';

export const VideoSettingsPanel = ({ onCloseClick }: IVideoSettingsPanelProps) => {
  const dispatch = useDispatch();
  const { playback, autoAdjust, quality } = useSelector(videoRequestedSettingsSelector);
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const isQualityLoading = useSelector(videoQualityLoadingSelector);

  const onPlaybackChange = useCallback(
    (value: boolean) => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }
      dispatch(changeVideoPlaybackAction(value));
    },
    [dispatch, gameSoundMute, soundEffects],
  );

  const onAutoAdjustChange = useCallback(
    (value: boolean) => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }
      dispatch(changeVideoAutoAdjustAction(value));
    },
    [dispatch, gameSoundMute, soundEffects],
  );

  const onQualityChange = useCallback(
    (value: string) => {
      if (isQualityLoading) {
        return false;
      }

      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }
      // TODO: use enum for values type
      dispatch(changeVideoQualityAction(value as VideoQualities));
    },
    [dispatch, gameSoundMute, soundEffects, isQualityLoading],
  );

  if (playback === null || autoAdjust === null) {
    return null;
  }

  return (
    <>
      <VideoSettingsPanelResponsive
        playback={playback}
        autoAdjust={autoAdjust}
        quality={quality}
        qualityOptions={VIDEO_QUALITY_OPTIONS}
        onPlaybackChange={onPlaybackChange}
        onAutoAdjustChange={onAutoAdjustChange}
        onQualityChange={onQualityChange}
        onCloseClick={onCloseClick}
        isQualityLoading={isQualityLoading}
      />
    </>
  );
};
