import { INotificationResponse } from 'types';

export enum ACTIONS {
  SAVE_BETTING_TIME_TO_STORE = 'SAVE_BETTING_TIME_TO_STORE',
  SET_IS_LAST_2_SECONDS_BETTING_TIME_TO_STORE = 'SET_IS_LAST_2_SECONDS_BETTING_TIME_TO_STORE',
  SET_TIME_DIFFERENCE_USER_SERVER_TO_STORE = 'SET_TIME_DIFFERENCE_USER_SERVER_TO_STORE',
  SET_TIME_LEFT_TO_STORE = 'SET_TIME_LEFT_TO_STORE',
  DECREMENT_TIME_LEFT = 'DECREMENT_TIME_LEFT',
  START_DECREMENT_TIME_LEFT = 'START_DECREMENT_TIME_LEFT',
  STOP_DECREMENT_TIME_LEFT = 'STOP_DECREMENT_TIME_LEFT',
}

export interface IBettingTime {
  bettingTimeEnd: string;
  isTheLast2SecondsInBettingTime: boolean;
  timeDifferenceUserServer: number;
  bettingTime: number;
  timeLeft: number;
  isDecrementingTimeLeft: boolean;
}

type OnBettingTimeStartedNotificationData = {
  finishBettingTime: string;
  bettingTime: number;
  betsToken: string;
};

export type OnBettingTimeStartedData = INotificationResponse<OnBettingTimeStartedNotificationData>;
