import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { INoticesState } from './types';

const notificationsSelector = (state: IState) => state.noticesReducer;

export const noticeSelector = createSelector(
  notificationsSelector,
  (state: INoticesState) => state.notifications[0],
);
