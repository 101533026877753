import styled from 'styled-components';

export const PredictionShortcutStyled = styled.svg`
  cursor: pointer;
`;

export const ContentWrapper = styled.svg``;

const BaseShortcutText = styled.text`
  fill: #fff7eb;
  text-transform: capitalize;
`;

export const ShortcutText = styled(BaseShortcutText)<{ $isSmallSize: boolean }>`
  font-size: ${({ $isSmallSize }) => ($isSmallSize ? '9px' : '10px')};
`;

export const ShortcutTextMobile = styled(BaseShortcutText)`
  font-size: 6px;
  /* letter-spacing: 0.24rem; */
`;
