import { all, takeEvery } from 'redux-saga/effects';
import { ACTIONS } from '../types';
import { clearBetSaga } from './clearBetSaga';
import { confirmBetSaga } from './confirmBetSaga';
import { createPendingBetSaga } from './createPendingBetSaga';
import { placeBetSaga } from './placeBetSaga';

export function* betRootSaga() {
  yield all([
    takeEvery(ACTIONS.PLACE_BET, placeBetSaga),
    takeEvery(ACTIONS.CLEAR_PLACED_BET, clearBetSaga),
    takeEvery(ACTIONS.CONFIRM_BET, confirmBetSaga),
    takeEvery([ACTIONS.PLACE_BET_SUCCESS, ACTIONS.CLEAR_PLACED_BET_SUCCESS], createPendingBetSaga),
  ]);
}
