import { createAction } from 'typesafe-actions';

import { ACTIONS, IAuthState, ModeGame } from './types';

export const setUserInfoAuth = createAction(ACTIONS.SET_USER_INFO)<IAuthState>();

export const setUserMode = createAction(ACTIONS.SET_USER_MODE)<{
  mode: ModeGame;
  lobbyUrl: string | null;
  closeGameMessage: string | null;
}>();

export const setDeviceOrientation = createAction(ACTIONS.SET_DEVICE_ORIENTATION)<boolean>();
