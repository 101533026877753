import styled, { css } from 'styled-components';
import { Device, SLOTS } from 'types';

const defaultSlotsContentColorMap: Record<SLOTS, string> = {
  [SLOTS.banker]: '#ff5251',
  [SLOTS.player]: '#57b5e3',
  [SLOTS.tie]: '#e7d5b9',
};

const activeSlotsContentColorMap: Record<SLOTS, string> = {
  [SLOTS.banker]: '#ffbcb2',
  [SLOTS.player]: '#b1e6ff',
  [SLOTS.tie]: '#e7d5b9',
};

const winnerSlotsContentColorMap: Record<SLOTS, string> = {
  [SLOTS.banker]: '#ffbcb2',
  [SLOTS.player]: '#b1e6ff',
  [SLOTS.tie]: '#f1e6d2',
};

export const SlotTitle = styled.text<{ $slot: SLOTS; $isActive?: boolean; $isWinner: boolean }>`
  text-transform: uppercase;
  overflow: visible;

  ${({ $slot, $isWinner, $isActive }) => {
    if ($isWinner) {
      return css`
        fill: ${winnerSlotsContentColorMap[$slot]};
      `;
    }

    if ($isActive) {
      return css`
        fill: ${activeSlotsContentColorMap[$slot]};
      `;
    }

    return css`
      fill: ${defaultSlotsContentColorMap[$slot]};
    `;
  }}
`;

export const SlotValue = styled.text`
  letter-spacing: 2px;
  fill: #fff7eb;
  overflow: visible;
`;

export const Wrapper = styled.g<{
  $deviceType: Device;
  $isMinimized: boolean;
}>`
  ${({ $deviceType, $isMinimized }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        ${SlotTitle} {
          font-size: 24px;
          letter-spacing: 2px;
        }

        ${SlotValue} {
          font-size: ${$isMinimized ? '16px' : '20px'};
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        ${SlotTitle} {
          font-size: ${$isMinimized ? '12px' : '16px'};
          letter-spacing: 1px;
        }

        ${SlotValue} {
          font-size: 12px;
          line-height: 14px;
        }
      `;
    }

    return css`
      ${SlotTitle} {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 2px;
      }

      ${SlotValue} {
        font-size: 14px;
        line-height: 24px;
      }
    `;
  }}
`;
