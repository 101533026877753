import React, { FunctionComponent } from 'react';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Board, Grid, RoadLabel } from 'widgets/Scoreboard/ScoreboardGrid';
import { IScoreboardRoad } from '../types';
import { BigRoadChild } from './BigRoadChild';

export const BigRoadTablet: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [525, 8],
}) => (
  <Group size={{ width: 219, height: 54 }} position={position}>
    <g transform="translate(0, 54) rotate(-90)">
      <RoadLabel boardName={Board.BigRoad} size={{ width: 54, height: 10 }} labelSize="S" />
    </g>
    <Grid
      col={22}
      row={6}
      cellSize={9}
      childItemType={BigRoadChild}
      childItemSize={{ width: 9, height: 9 }}
      roadData={roadData}
      position={[12, 0]}
    />
  </Group>
);
