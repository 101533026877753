import { Moment } from 'moment';

export interface IBrand {
  brand?: string;
}

export interface IRoundInRow {
  pointsInRow: number;
  roundInRow: number;
}

export interface IUserInfo {
  playerName: string | null;
  uuid: string | null;
}

export interface IPlayerJP {
  name: string;
  points: number;
  uuid: string;
}

export interface IWinnerJP extends IPlayerJP {
  currency: string;
  amount: number;
}

export enum PositionJackpot {
  CURRENT = 'current',
  NEXT = 'next',
}

export interface IJackpot {
  jackpotId: string;
  currency: string;
  amount: number;
  collectionPeriodStartDate: string;
  collectionPeriodEndDate: string;
  drawnDate: string;
  timerDate: string;
  position: PositionJackpot;
  minBet: {
    currency: string;
    value: number;
  };

  isCollectionPeriod?: boolean;
  isPaused?: boolean;
  draw?: IStageJackpot;
  players?: IPlayerJP[];
  winDuration?: number;
}

export interface IJackpotUpdated {
  amount: number;
  players: IPlayerJP[];
}

export interface IPlayerIdStage {
  uuid: string;
}

export interface IStageJackpot {
  stageNumber: number;
  players: IPlayerIdStage[];
}

export interface IStagePlayersObjectJackpot {
  stageNumber: number;
  playersObject: { [key: string]: IPlayerIdStage } | null;
}

export interface IJackpotPoints {
  value: number;
  jackpotId: string | null;
}

export interface IJackpotState extends IRoundInRow, IUserInfo {
  currentJackpotPoints: IJackpotPoints;
  nextJackpotPoints: IJackpotPoints;
  currentJackpot: IJackpot | null;
  nextJackpot: IJackpot | null;
  stage: IStagePlayersObjectJackpot | null;
  amountJackpotUserWon: null | number;
  winnersListJackpot: IWinnerJP[] | null;
  countdownJackpot: string | null;
  isUserWonInJP: boolean;
  showJPPopup: boolean;
  newCurrentJackpot: IJackpot | null;
  jackpotAmount: null | number;
  winDuration: null | number;
  jackpotCurrency: string | null;
  currencyJackpotUserWon: string | null;
}

export enum ACTIONS {
  UPDATE_AUTH_DATA = 'UPDATE_AUTH_DATA',
  UPDATE_WINNERS = 'UPDATE_WINNERS',
  UPDATE_CURRENT_JP_USER_POINTS = 'UPDATE_CURRENT_JP_USER_POINTS',
  UPDATE_NEXT_JP_USER_POINTS = 'UPDATE_NEXT_JP_USER_POINTS',
  SET_CURRENT_JP = 'SET_CURRENT_JP',
  SET_POINTS_IN_ROW = 'SET_POINTS_IN_ROW',
  SET_USER_INFO = 'SET_USER_INFO',
  UPDATE_CURRENT_JP = 'UPDATE_CURRENT_JP',
  SET_NEXT_JP = 'SET_NEXT_JP',
  UPDATE_NEXT_JP = 'UPDATE_NEXT_JP',
  SET_STAGE_JACKPOT = 'SET_STAGE_JACKPOT',
  SET_JACKPOT_WON = 'SET_JACKPOT_WON',
  SET_COUNTDOWN_JACKPOT = 'SET_COUNTDOWN_JACKPOT',
  SET_IS_ACTIVE_COLLECTION_PERIOD = 'SET_IS_ACTIVE_COLLECTION_PERIOD',
  SET_JACKPOT_WON_SUM = 'SET_JACKPOT_WON_SUM',
  SET_JACKPOT_WON_WINNERS = 'SET_JACKPOT_WON_WINNERS',
  SET_IS_USER_WON_IN_JP = 'SET_IS_USER_WON_IN_JP',
  SET_SHOW_JP_POPUP = 'SET_SHOW_JP_POPUP',
  SET_NEW_CURRENT_JP = 'SET_NEW_CURRENT_JP',
  SET_INFO_JACKPOT_DRAW = 'SET_INFO_JACKPOT_DRAW',
  SET_INFO_DISPLAY_JACKPOT_WIN = 'SET_INFO_DISPLAY_JACKPOT_WIN',

  WATCH_DATE_CONTROL_INTERVAL = 'WATCH_DATE_CONTROL_INTERVAL',
}

export interface IPlayer {
  uuid: string;
  playerName: string;
  // tableId: string;
  sum: number;
  currency: string;
}

export interface IJackpotWon {
  amount: number | null;
  currency: string;
  players: IWinnerJP[] | null;
}
