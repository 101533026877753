import { ActionType, createReducer } from 'typesafe-actions';
import { saveWinnerBallToStoreAction } from './actions';
import { IWinnerBallState } from './types';

export const winnerBallState: IWinnerBallState = {
  winnerBall: null,
};

const actions = {
  saveWinnerBallToStoreAction,
};

export const winnerBallReducer = createReducer<IWinnerBallState, ActionType<typeof actions>>(
  winnerBallState,
).handleAction(saveWinnerBallToStoreAction, (state, { payload: { winner } }) => ({
  ...state,
  winnerBall: winner,
}));
