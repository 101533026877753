import React from 'react';
import { TabletPortraitComponent } from 'components';
import { FullScreenModeButtonPortrait } from './FullScreenModeButton.portrait';
import { IFullScreenModeButtonProps } from './types';

export const FullScreenModeButtonResponsive = ({
  hasJackpot,
  ...props
}: IFullScreenModeButtonProps) => (
  <TabletPortraitComponent>
    <FullScreenModeButtonPortrait
      {...props}
      hasJackpot={hasJackpot}
      offsetRight={hasJackpot ? 335 : 243}
    />
  </TabletPortraitComponent>
);
