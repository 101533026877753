import { updateDealerAction } from 'core/widgets/TableSettings/actions';
import { put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { OnDealerChangedData } from '../types';

export function* onDealerChangedSaga({
  type: notificationType,
  data: result,
}: OnDealerChangedData) {
  if (notificationType === Notifications.DEALER_CHANGED) {
    const {
      dealer: { nickname: dealerNickname },
    } = result;
    yield put(updateDealerAction({ dealerNickname }));
  }
}
