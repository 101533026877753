import { createAction } from 'typesafe-actions';
import { WinnerBall } from 'constants/common';
import { PredictionBallType } from 'widgets/Scoreboard/types';
import { ACTIONS } from './types';

export const addScoreboardItemAction = createAction(ACTIONS.ADD_NEW_SCOREBOARD_ITEM)<{
  winnerBall: WinnerBall;
}>();

export const addScoreboardPredictionAction = createAction(ACTIONS.ADD_SCOREBOARD_PREDICTION)<{
  winnerBall: PredictionBallType;
}>();

export const removeScoreboardPredictionAction = createAction(
  ACTIONS.REMOVE_SCOREBOARD_PREDICTION,
)();

export const resetScoreboardStateAction = createAction(ACTIONS.RESET_SCOREBOARD_STATE)();

export const setInitialScoreboardStateAction = createAction(ACTIONS.SET_INITIAL_SCOREBOARD_STATE)<{
  winnerBallsData: WinnerBall[];
}>();
