import { SLOTS } from 'types';
import { preciseAddSubtract, roundToFixed } from 'utils/roundToFix';
import { BetType } from '../types';

export const getSummaryBet = (bets: BetType[]): BetType =>
  Object.values(bets).reduce(
    (summaryBet, bet) => ({
      [SLOTS.player]: preciseAddSubtract(summaryBet[SLOTS.player], bet[SLOTS.player], 'add'),
      [SLOTS.tie]: preciseAddSubtract(summaryBet[SLOTS.tie], bet[SLOTS.tie], 'add'),
      [SLOTS.banker]: preciseAddSubtract(summaryBet[SLOTS.banker], bet[SLOTS.banker], 'add'),
    }),
    {
      [SLOTS.player]: 0,
      [SLOTS.tie]: 0,
      [SLOTS.banker]: 0,
    },
  );
