import { ActionType, createReducer } from 'typesafe-actions';
import { applyAutoConfirmAction, changeAutoConfirmAction } from './actions';
import { IGeneralSettingsState } from './types';

export const generalSettingsState: IGeneralSettingsState = {
  autoConfirm: {
    requested: false,
    applied: false,
  },
};

const actions = {
  changeAutoConfirmAction,
  applyAutoConfirmAction,
};

export const generalSettingsReducer = createReducer<
  IGeneralSettingsState,
  ActionType<typeof actions>
>(generalSettingsState)
  .handleAction(changeAutoConfirmAction, (state) => ({
    ...state,
    autoConfirm: {
      ...state.autoConfirm,
      requested: !state.autoConfirm.requested,
    },
  }))
  .handleAction(applyAutoConfirmAction, (state) => ({
    ...state,
    autoConfirm: {
      ...state.autoConfirm,
      applied: state.autoConfirm.requested,
    },
  }));
