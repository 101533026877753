import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPlayerJP, IStagePlayersObjectJackpot } from 'core/widgets/Jackpot/types';
import { ListItem, Wrapper, ItemValue, ItemNickname, Divider } from './styles';

interface IWinnerTable {
  uuid: string;
  members?: IPlayerJP[];
  outOfStagePlayers: IPlayerJP[];
  stage: IStagePlayersObjectJackpot | null;
  activePlayers: IPlayerJP[];
  isCurrentJP: boolean;
}

export const WinnerTable = ({
  members,
  uuid,
  activePlayers,
  outOfStagePlayers,
  stage,
  isCurrentJP,
}: IWinnerTable) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {(!stage || (!stage && isCurrentJP)) &&
        members?.map((member: IPlayerJP, index: number) => (
          <ListItem
            key={`${member?.uuid}_${member.points}`}
            $isGray={!(index % 2)}
            $isSelected={Boolean(member.uuid === uuid)}
            $disable={false}>
            <ItemNickname>{member.name}</ItemNickname>
            <ItemValue>{member.points}</ItemValue>
          </ListItem>
        ))}
      {stage &&
        isCurrentJP &&
        activePlayers?.map((member: IPlayerJP, index: number) => (
          <ListItem
            key={`${member?.uuid}_${member.points}`}
            $isGray={!(index % 2)}
            $isSelected={Boolean(member.uuid === uuid)}
            $disable={false}>
            <ItemNickname>{member.name}</ItemNickname>
            <ItemValue>{member.points}</ItemValue>
          </ListItem>
        ))}
      {stage && isCurrentJP && outOfStagePlayers?.length && (
        <Divider>{t('settings.jackpot.out_stage')}</Divider>
      )}
      {stage &&
        isCurrentJP &&
        outOfStagePlayers?.map((member: IPlayerJP, index: number) => (
          <ListItem
            key={`${member?.uuid}_${member.points}`}
            $isGray={!(index % 2)}
            $isSelected={Boolean(member.uuid === uuid)}
            $disable>
            <ItemNickname>{member.name}</ItemNickname>
            <ItemValue>{member.points}</ItemValue>
          </ListItem>
        ))}
    </Wrapper>
  );
};
