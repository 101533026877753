import { WinnerBall } from 'constants/common';
import { INotificationChatResponse, INotificationResponse, SLOTS } from 'types';
import { RoundStates } from '../RoundStates/types';
import { RoundWinner } from '../WinnersTicker/types';
import {
  IJackpot,
  IJackpotWon,
  IPlayer,
  IPlayerJP,
  IStageJackpot,
  IWinnerJP,
} from '../Jackpot/types';
import { ModeGame } from '../Auth/types';

export interface IWebSocketState {
  isConnected?: boolean;
  isConnectionFailed?: boolean;
  isSocketDisconnected?: boolean;
}

export enum ACTIONS {
  CONNECT_TO_WEBSOCKET = 'CONNECT_TO_WEBSOCKET',
  CONNECT_TO_WEBSOCKET_SUCCESS = 'CONNECT_TO_WEBSOCKET_SUCCESS',
  CONNECT_TO_WEBSOCKET_FAILURE = 'CONNECT_TO_WEBSOCKET_FAILURE',

  CONNECT_TO_CHAT_WEBSOCKET = 'CONNECT_TO_CHAT_WEBSOCKET',

  SOCKET_CONNECTED = 'SOCKET_CONNECTED',
  SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED',
}

export type BetsDataType = {
  [key in SLOTS]: number;
};

export type OnPlayerConnectedNotificationData = {
  user: {
    balance: number;
    betsToken: string;
    apiToken: string;
    playerName: string;
    uuid: string;
    minBet: number;
    maxBet: number;
    lang: string;
    currency: string;
    operatorName: string;
    isBlockedChat: boolean;
  };
  state: {
    table: {
      table_id: string;
      min_bet: number;
      max_bet: number;
      displaying_win_ball: number;
      stream_high?: string;
      stream_medium?: string;
      stream_low?: string;
    };
    dealer: {
      nickname: string;
    };
    round?: {
      gameId: string | null;
      finishBettingTime?: string;
      bettingTime: number;
      bettingTimeLeft: number;
    };
    status: RoundStates;
    scoreBoard: WinnerBall[];
    jackpots: IJackpot[];
  };
  bets?: BetsDataType;
  mode: ModeGame;
  jackpotOn: boolean;
  lobbyUrl: string | null;
  closeGameMessage: string | null;
};

type OnPlayerUpdatedNotificationData = {
  user: {
    balance: number;
  };
};

type OnPlayerWonNotificationData = {
  winner: WinnerBall;
  winningAmount: number;
  user: {
    balance: number;
  };
  bets: BetsDataType;
};

type OnPlayerLostNotificationData = {
  lostAmount: number;
  user: {
    balance: number;
  };
};

type OnPlayerErrorNotificationData = {
  tableId: string;
  message: string;
  status: number;
};

type OnGameClosedNoBetsNotificationData = {};

type OnRoundStartedNotificationData = {
  round: {
    gameId: string;
    startedAt: string;
  };
};

type OnDealerChangedNotificationData = {
  dealer: {
    nickname: string;
  };
};

type OnRoundCanceledNotificationData = {};

type OnTableClosedNotificationData = {};

type OnConnectionFailedNotificationData = {
  status: number;
  message: string;
  mode?: ModeGame;
  lobbyUrl?: string | null;
  closeGameMessage?: string | null;
};

type OnRoundFinishedNotificationData = {};

type OnPendingBetsMadeNotificationData = {
  [SLOTS.player]: number;
  [SLOTS.tie]: number;
  [SLOTS.banker]: number;
  total: number;
  accepted: boolean;
};

type OnDisplayWinnersNotificationData = {
  winners: RoundWinner[];
};

interface IJackpotPoint {
  jackpotId: string;
  value: number;
}

type OnPlayerPointsNotificationData = {
  tableId: string;
  points: IJackpotPoint[];
};

type OnPointAwardedNotificationData = {
  tableId: string;
  jackpotId: string;
  points: number;
};

type OnCurrentJPUpdatedNotificationData = {
  tableId: string;
  jackpotId: string;
  amount: number;
  players: IPlayerJP[];
};

type OnNextJackpotUpdatedNotificationData = {
  tableId: string;
  members: any[];
};

type OnJackpotCreatedNotificationData = {
  tableId: string;
} & IJackpot;

type OnJackpotStageStartedNotificationData = {
  tableId: string;
  jackpotId: string;
  stageNumber: number;
  players: { uuid: string }[];
};

type OnJackpotProvidedNotificationData = {
  currentJackpot: IJackpot;
  nextJackpot: IJackpot;
};

type OnJackpotFinishedNotificationData = {
  amount: number | null;
  currency: string;
  players: IWinnerJP[] | null;
};

type OnJackpotDetailsUpdatedNotificationData = {
  tableId: string;
  jackpotId: string;
  amount: number;
  players: IPlayerJP[];
};

type OnJackpoStateUpdatedNotificationData = {
  tableId: string;
  jackpots: IJackpot[];
};

type OnJackpotChangedNotificationData = {
  tableId: string;
  jackpotId: string;
};

type OnNewChatMessageNotificationData = {
  playerName: string;
  message: string;
  fromSupport: boolean;
};

type OnUserChatUnblockedNotificationData = {
  tableId: string;
  playerId: string;
  operatorId: string;
};

type OnUserChatBlockedNotificationData = {
  tableId: string;
  playerId: string;
  operatorId: string;
};

type OnChatTokenProvidedNotificationData = {
  tableId: string;
  token: string;
};

type OnPlayerConnectedChatNotificationData = {
  tableId: string;
  user: {
    playerName: string;
    tableId: string;
    chatIsBlocked: boolean;
  };
};

export type OnPlayerConnectedData = INotificationResponse<OnPlayerConnectedNotificationData>;
export type OnPlayerUpdatedData = INotificationResponse<OnPlayerUpdatedNotificationData>;
export type OnPlayerWonData = INotificationResponse<OnPlayerWonNotificationData>;
export type OnPlayerLostData = INotificationResponse<OnPlayerLostNotificationData>;
export type OnPlayerErrorData = INotificationResponse<OnPlayerErrorNotificationData>;
export type OnRoundStartedData = INotificationResponse<OnRoundStartedNotificationData>;
export type OnDealerChangedData = INotificationResponse<OnDealerChangedNotificationData>;
export type OnRoundCanceledData = INotificationResponse<OnRoundCanceledNotificationData>;
export type OnTableClosedData = INotificationResponse<OnTableClosedNotificationData>;
export type OnRoundFinishedData = INotificationResponse<OnRoundFinishedNotificationData>;
export type OnConnectionFailedData = INotificationResponse<OnConnectionFailedNotificationData>;
export type OnPendingBetsMadeData = INotificationResponse<OnPendingBetsMadeNotificationData>;
export type OnGameClosedNoBetsData = INotificationResponse<OnGameClosedNoBetsNotificationData>;
export type OnDisplayWinnersData = INotificationResponse<OnDisplayWinnersNotificationData>;
export type OnPlayerPointsData = INotificationResponse<OnPlayerPointsNotificationData>;
export type OnJackpotPointAwardedData = INotificationResponse<OnPointAwardedNotificationData>;
export type OnJackpotStageStartedData =
  INotificationResponse<OnJackpotStageStartedNotificationData>;
export type OnJackpotProvidedData = INotificationResponse<OnJackpotProvidedNotificationData>;
export type OnJackpotFinishedData = INotificationResponse<OnJackpotFinishedNotificationData>;
export type OnJackpotCreatedData = INotificationResponse<OnJackpotCreatedNotificationData>;
export type OnJackpotDetailsUpdatedData =
  INotificationResponse<OnJackpotDetailsUpdatedNotificationData>;
export type OnJackpoStateUpdatedData = INotificationResponse<OnJackpoStateUpdatedNotificationData>;
export type OnJackpotChangedData = INotificationResponse<OnJackpotChangedNotificationData>;
export type OnNewChatMessageData = INotificationChatResponse<OnNewChatMessageNotificationData>;
export type OnUserChatUnblockedData =
  INotificationChatResponse<OnUserChatUnblockedNotificationData>;
export type OnUserChatBlockedData = INotificationChatResponse<OnUserChatBlockedNotificationData>;
export type OnPlayerConnectedChatData =
  INotificationChatResponse<OnPlayerConnectedChatNotificationData>;
export type OnChatTokenProvidedData = INotificationResponse<OnChatTokenProvidedNotificationData>;
