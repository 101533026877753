import React from 'react';
import { ButtonCircle } from 'components';
import { IButtonSettingsProps } from './types';
import { IconStyled } from './styles';

export const ButtonSettings = ({ variant, isActive = false, onClick }: IButtonSettingsProps) => (
  <ButtonCircle isActive={isActive} onClick={onClick}>
    <IconStyled name={variant} />
  </ButtonCircle>
);
