import React from 'react';
import { useSelector } from 'react-redux';
import { jackpotActivitySelector } from 'core/widgets/Jackpot/selectors';
import { CountdownStageResponsive } from './Responsive';

export const CountdownStage = () => {
  const { stage, countdownJackpot } = useSelector(jackpotActivitySelector);

  if (!stage && !countdownJackpot) {
    return null;
  }

  return <CountdownStageResponsive stage={stage} dateTimer={countdownJackpot} />;
};
