import { WinnerBall } from 'constants/common';

type WinStatusBgColorsType = {
  [key in WinnerBall]: string;
};

type WinStatusColorsType = Record<string, WinStatusBgColorsType>;

export const winStatusColorsDesktop: WinStatusColorsType = {
  backgroundColor: {
    [WinnerBall.BLUE]: '#006F9C',
    [WinnerBall.RED]: '#AA001B',
    [WinnerBall.GOLD]: '#FEDB00',
  },
  ballFirstBackgroundColor: {
    [WinnerBall.BLUE]: '#008DC7',
    [WinnerBall.RED]: '#C8102E',
    [WinnerBall.GOLD]: '#AE8800',
  },
  ballSecondBackgroundColor: {
    [WinnerBall.BLUE]: '#7FC4E6',
    [WinnerBall.RED]: '#C8102E',
    [WinnerBall.GOLD]: '#AE8800',
  },
  ballSecondShadowBackgroundColor: {
    [WinnerBall.BLUE]: '#41B6E6',
    [WinnerBall.RED]: '#C8102E',
    [WinnerBall.GOLD]: '#D4B700',
  },
  ballThirdBackgroundColor: {
    [WinnerBall.BLUE]: '#A4E4FF',
    [WinnerBall.RED]: '#C8102E',
    [WinnerBall.GOLD]: '#D4B700',
  },
  ballThirdShadowBackgroundColor: {
    [WinnerBall.BLUE]: '#006F9C',
    [WinnerBall.RED]: '#990202',
    [WinnerBall.GOLD]: '#AE9600',
  },
  circleColor: {
    [WinnerBall.BLUE]: '#51B1E0',
    [WinnerBall.RED]: '#C8102E',
    [WinnerBall.GOLD]: '#AE8800',
  },
  circleBackgroundColor: {
    [WinnerBall.BLUE]: '#BEDCEA',
    [WinnerBall.RED]: '#FFBCB2',
    [WinnerBall.GOLD]: '#FFF7EB',
  },
};

export const winStatusColorsMobile: WinStatusColorsType = {
  mainBackground: {
    [WinnerBall.BLUE]: '#006F9C',
    [WinnerBall.RED]: '#AA001B',
    [WinnerBall.GOLD]: '#FEDB00',
  },
  circleBackground: {
    [WinnerBall.BLUE]: '#008DC7',
    [WinnerBall.RED]: '#C8102E',
    [WinnerBall.GOLD]: '#AE8800',
  },
  circleGlarePrimary: {
    [WinnerBall.BLUE]: '#51B1E0',
    [WinnerBall.RED]: '#C8102E',
    [WinnerBall.GOLD]: '#AE8800',
  },
  circleGlareSecondary: {
    [WinnerBall.BLUE]: '#BEDCEA',
    [WinnerBall.RED]: '#FFBCB2',
    [WinnerBall.GOLD]: '#FFF7EB',
  },
  shiningPrimaryColorTop: {
    [WinnerBall.BLUE]: '#7FC4E6',
    [WinnerBall.RED]: '#FF5251',
    [WinnerBall.GOLD]: '#AE8800',
  },
  shiningSecondaryColorTop: {
    [WinnerBall.BLUE]: '#41B6E6',
    [WinnerBall.RED]: '#C8102E',
    [WinnerBall.GOLD]: '#D4B700',
  },
  shiningPrimaryColorBottom: {
    [WinnerBall.BLUE]: '#A4E4FF',
    [WinnerBall.RED]: '#C8102E',
    [WinnerBall.GOLD]: '#D4B700',
  },
  shiningSecondaryColorBottom: {
    [WinnerBall.BLUE]: '#006F9C',
    [WinnerBall.RED]: '#990202',
    [WinnerBall.GOLD]: '#AE9600',
  },
};
