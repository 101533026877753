import styled, { css } from 'styled-components';
import loaderSVG from './assets/stub-loader.svg';

export const Wrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 101;

  ${({ theme: { background } }) => css`
    background-color: ${background.primary};
  `}
`;

export const StubLoader = styled.div`
  height: 136rem;
  width: 136rem;
  margin-top: 232rem;
  background-image: url(${loaderSVG});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  animation: stub-rotation 1500ms infinite linear;

  @keyframes stub-rotation {
    100% {
      transform: rotate(0deg);
      transform: rotate(360deg);
    }
  }
`;

export const StubText = styled.span`
  font-size: 28rem;
  line-height: 32rem;
  text-transform: uppercase;
  margin-top: 96rem;
`;
