import cursor_blue from './assets/cursor_blue.svg';
import cursor_yellow from './assets/cursor_yellow.svg';
import cursor_red from './assets/cursor_red.svg';
import cursor_violet from './assets/cursor_violet.svg';
import cursor_green from './assets/cursor_green.svg';
import cursor_orange from './assets/cursor_orange.svg';
import cursor_light_blue from './assets/cursor_light_blue.svg';
import cursor_scarlet from './assets/cursor_scarlet.svg';
import cursor_purple from './assets/cursor_purple.svg';
import cursor_light_green from './assets/cursor_light_green.svg';
import cursor_pink from './assets/cursor_pink.svg';
import cursor_turquoise from './assets/cursor_turquoise.svg';
import { CHIPS_COLORS } from 'widgets/Chips/data/currencies/colors';

type CursorChipsType = { [key in CHIPS_COLORS]: string };

export const CursorChips: CursorChipsType = {
  [CHIPS_COLORS.BLUE]: cursor_blue,
  [CHIPS_COLORS.YELLOW]: cursor_yellow,
  [CHIPS_COLORS.RED]: cursor_red,
  [CHIPS_COLORS.VIOLET]: cursor_violet,
  [CHIPS_COLORS.GREEN]: cursor_green,
  [CHIPS_COLORS.ORANGE]: cursor_orange,
  [CHIPS_COLORS.LIGHT_BLUE]: cursor_light_blue,
  [CHIPS_COLORS.SCARLET]: cursor_scarlet,
  [CHIPS_COLORS.PURPLE]: cursor_purple,
  [CHIPS_COLORS.LIGHT_GREEN]: cursor_light_green,
  [CHIPS_COLORS.PINK]: cursor_pink,
  [CHIPS_COLORS.TURQUOISE]: cursor_turquoise,
};
