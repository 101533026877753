import React, { MouseEvent, useCallback } from 'react';
import { BetClearButton, BetConfirmButton, Wrapper } from './styles';
import { Device } from 'types';

interface IConfirmBetProps {
  onBetConfirm: () => void;
  onBetClear: () => void;
  deviceType: Device;
}

export const ConfirmBet = ({ onBetConfirm, onBetClear, deviceType }: IConfirmBetProps) => {
  const onBetClearHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onBetClear();
    },
    [onBetClear],
  );

  const onBetConfirmHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onBetConfirm();
    },
    [onBetConfirm],
  );

  if (deviceType === Device.Mobile) {
    return (
      <Wrapper viewBox="0 0 32 32">
        <BetClearButton
          onClick={onBetClearHandler}
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          x="0"
          y="0"
          viewBox="0 0 32 32"
          fill="none">
          <circle cx="12" cy="12" r="11.5" fill="#121011" />
          <circle cx="12" cy="12" r="11.5" fill="url(#paint0_linear_1097_79621)" />
          <circle cx="12" cy="12" r="11.5" stroke="url(#paint1_linear_1097_79621)" />
          <g filter="url(#filter0_d_1097_79621)">
            <g filter="url(#filter1_i_1097_79621)">
              <path
                d="M8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L10.5858 12L7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L12 13.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L13.4142 12L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L12 10.5858L8.70711 7.29289Z"
                fill="url(#paint2_linear_1097_79621)"
              />
            </g>
          </g>
        </BetClearButton>
      </Wrapper>
    );
  }

  if (deviceType === Device.Tablet_Portrait) {
    return (
      <Wrapper viewBox="0 0 32 32">
        <BetClearButton
          onClick={onBetClearHandler}
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          x="0"
          y="0"
          viewBox="0 0 32 32"
          fill="none">
          <circle cx="16" cy="16" r="15.5" fill="#121011" />
          <circle cx="16" cy="16" r="15.5" fill="url(#paint0_linear_5852_58296)" />
          <circle cx="16" cy="16" r="15.5" stroke="url(#paint1_linear_5852_58296)" />
          <g filter="url(#filter0_d_5852_58296)">
            <g filter="url(#filter1_i_5852_58296)">
              <path
                d="M12.7071 11.2929C12.3166 10.9024 11.6834 10.9024 11.2929 11.2929C10.9024 11.6834 10.9024 12.3166 11.2929 12.7071L14.5858 16L11.2929 19.2929C10.9024 19.6834 10.9024 20.3166 11.2929 20.7071C11.6834 21.0976 12.3166 21.0976 12.7071 20.7071L16 17.4142L19.2929 20.7071C19.6834 21.0976 20.3166 21.0976 20.7071 20.7071C21.0976 20.3166 21.0976 19.6834 20.7071 19.2929L17.4142 16L20.7071 12.7071C21.0976 12.3166 21.0976 11.6834 20.7071 11.2929C20.3166 10.9024 19.6834 10.9024 19.2929 11.2929L16 14.5858L12.7071 11.2929Z"
                fill="url(#paint2_linear_5852_58296)"
              />
            </g>
          </g>
        </BetClearButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper viewBox="0 0 24 24">
      <BetClearButton
        onClick={onBetClearHandler}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        x="0"
        y="0"
        viewBox="0 0 24 24"
        fill="none">
        {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
        <circle cx="12" cy="12" r="11.5" fill="#121011" />
        <circle cx="12" cy="12" r="11.5" fill="url(#paint0_linear_1097_79621)" />
        <circle cx="12" cy="12" r="11.5" stroke="url(#paint1_linear_1097_79621)" />
        <g filter="url(#filter0_d_1097_79621)">
          <g filter="url(#filter1_i_1097_79621)">
            <path
              d="M8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L10.5858 12L7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L12 13.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L13.4142 12L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L12 10.5858L8.70711 7.29289Z"
              fill="url(#paint2_linear_1097_79621)"
            />
          </g>
        </g>
      </BetClearButton>
    </Wrapper>
  );
};
