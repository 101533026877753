import { betMessages } from 'constants/toastMessages';
import { placeBetSuccessAction } from 'core/widgets/Bet/actions';
import { placedBetSelector, confirmedBetSelector } from 'core/widgets/Bet/selectors';
import { BetType } from 'core/widgets/Bet/types';
import { getSummaryBet, getDoubleBet, getBetSum } from 'core/widgets/Bet/utils';
import { tableBetLimitSelector } from 'core/widgets/TableSettings';
import { IBetLimit } from 'core/widgets/TableSettings/types';
import { addToastAction } from 'core/widgets/Toast/actions';
import { balanceSelector } from 'core/widgets/User';
import { put, select } from 'redux-saga/effects';
import { SLOTS } from 'types';
import { ToastTypes } from 'types/toast';
import { doubleBetSuccessAction } from '../actions';

export function* doubleBetSaga() {
  const balance: number | null = yield select(balanceSelector);
  const tableLimits: IBetLimit | undefined = yield select(tableBetLimitSelector);
  const placedBet: BetType = yield select(placedBetSelector);
  const confirmedBets: BetType[] = yield select(confirmedBetSelector);

  if (!balance || !tableLimits) {
    return;
  }

  const summaryConfirmedBet: BetType = getSummaryBet(confirmedBets);
  const { doubledBet, isMaxBetLimitReached } = getDoubleBet({
    confirmedBet: summaryConfirmedBet,
    placedBet: {
      [SLOTS.player]: placedBet.player,
      [SLOTS.banker]: placedBet.banker,
      [SLOTS.tie]: placedBet.tie,
    },
    maxBetLimit: tableLimits.max,
  });
  const doubledBetSum: number = getBetSum([doubledBet]);

  if (doubledBetSum > balance) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
    return;
  }

  if (doubledBetSum > tableLimits.max) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
    return;
  }

  yield put(doubleBetSuccessAction());
  yield put(placeBetSuccessAction(doubledBet));
}
