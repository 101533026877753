import { call, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { RestService } from 'services/RestAPIService';
import { getGameRulesAction, saveGameRulesToStore } from '../actions';

export function* getGameRulesSaga({ payload }: ActionType<typeof getGameRulesAction>) {
  try {
    const { data } = yield call(() => RestService.getGameRules(payload));

    yield put(saveGameRulesToStore({ rules: data.rules, jackpotRules: data?.jackpotRules }));
  } catch (err) {
    // TODO: handle errors and show notifications
    console.log(err);
  }
}
