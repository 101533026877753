import React, { ReactNode, forwardRef } from 'react';
import { createPortal } from 'react-dom';
import { Device } from 'types';
import { Wrapper } from './styles';

interface IPopupContainerProps {
  children: ReactNode;
  deviceType?: Device;
}

export const PopupContainer = forwardRef(
  ({ children, deviceType = Device.Desktop }: IPopupContainerProps, ref) =>
    createPortal(
      // @ts-ignore
      <Wrapper $deviceType={deviceType} ref={ref}>
        {children}
      </Wrapper>,
      document.getElementById('ratio-container') || document.body,
    ),
);
