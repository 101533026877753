import { updateActiveChipAction } from 'core/widgets/ActiveChip/actions';
import { put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { setBalanceAction, updateBalanceAction } from '../actions';
import { balanceSelector } from '../selectors';

export function* updateBalanceSaga({
  payload: balanceDiff,
}: ActionType<typeof updateBalanceAction>) {
  const currentBalance: number | null = yield select(balanceSelector);

  if (currentBalance === null) {
    return;
  }

  const updatedBalance = currentBalance + balanceDiff;
  yield put(setBalanceAction(updatedBalance));
  yield put(updateActiveChipAction());
}
