import { tableBetLimitSelector } from 'core/widgets/TableSettings';
import { IBetLimit } from 'core/widgets/TableSettings/types';
import { balanceSelector, totalBetSelector } from 'core/widgets/User';
import { put, select } from 'redux-saga/effects';
import getChipByValue from 'utils/getChipByValue';
import { setActiveChipAction } from '../actions';
import { activeChipSelector } from '../selectors';
import { ChipType } from '../types';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { CHIPS_CURRENCIES } from 'widgets/Chips/data/constants';

export function* updateActiveChipSaga() {
  const activeChip: ChipType | null = yield select(activeChipSelector);
  const betLimit: IBetLimit | undefined = yield select(tableBetLimitSelector);
  const balance: number | null = yield select(balanceSelector);
  const totalBet: number = yield select(totalBetSelector);
  const { currency } = yield select(authInfoSelector);

  if (!betLimit || !balance) {
    yield put(setActiveChipAction({ chip: null }));
    return;
  }

  if (balance < betLimit.min) {
    if (totalBet === 0) {
      yield put(setActiveChipAction({ chip: null }));
      return;
    }

    const maxBalanceChip = getChipByValue(balance, CHIPS_CURRENCIES[currency]);
    yield put(setActiveChipAction({ chip: maxBalanceChip }));
    return;
  }

  if (activeChip === null) {
    const minBetLimitChip = getChipByValue(betLimit.min, CHIPS_CURRENCIES[currency]);
    yield put(setActiveChipAction({ chip: minBetLimitChip }));
    return;
  }

  if (activeChip.value > balance) {
    const maxBalanceChip = getChipByValue(balance, CHIPS_CURRENCIES[currency]);
    yield put(setActiveChipAction({ chip: maxBalanceChip }));
  }
}
