import { saveWinnerBallToStoreAction } from 'core/widgets/WinnerBall/actions';
import { changeRoundStateAction } from 'core/widgets/RoundStates/actions';
import { put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { addToastAction } from 'core/widgets/Toast/actions';
import { resetRoundWinnersDataAction } from 'core/widgets/WinnersTicker/actions';
import { roundStatusMessages } from 'constants/toastMessages';
import { setJackpotWonSum, setJackpotWonWinners } from 'core/widgets/Jackpot/actions';
import { OnDrawFinishedData } from '../../WinnerBall/types';
import { RoundStates } from '../../RoundStates/types';

export function* onDrawFinishedSaga({ type: notificationType, data: result }: OnDrawFinishedData) {
  const { winner } = result;

  if (notificationType === Notifications.DRAW_FINISHED) {
    yield put(changeRoundStateAction({ roundState: RoundStates.DrawFinished }));
    yield put(addToastAction({ value: roundStatusMessages.DRAW_FINISHED }));
    yield put(resetRoundWinnersDataAction());
    yield put(setJackpotWonSum({ sum: null }));
    yield put(setJackpotWonWinners({ players: null }));

    if (winner !== 'UNDEFINED') {
      yield put(saveWinnerBallToStoreAction({ winner }));
    }
  }
}
