import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { SettingPanels } from 'widgets/Settings/types';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { setIsShowJackpotRuleAction } from 'core/widgets/GameRules/actions';
import {
  currentJackpotSelector,
  hasUserInTop100Selector,
  isUserInStageJPSelector,
  jackpotActivitySelector,
  jackpotInfoWinningDrawSelector,
  jackpotUserPointJPSelector,
  nextJackpotSelector,
} from 'core/widgets/Jackpot/selectors';
import { WinnersTableSettingsPanelResponsive } from './Responsive';
import { IGeneralSettingsPanelProps } from './types';
import { settingsPanelSelector } from 'core/widgets/SettingsPanel';
import { EmptyJackpotSettingsPanelResponsive } from './components/EmptyJackpot/Responsive';

export const WinnersTableSettings = ({ onCloseClick }: IGeneralSettingsPanelProps) => {
  const dispatch = useDispatch();
  const auth = useSelector(authInfoSelector);
  const { currentJackpotPoints, nextJackpotPoints } = useSelector(jackpotUserPointJPSelector);
  const { currentJackpot, activePlayers, outOfStagePlayers } = useSelector(currentJackpotSelector);
  const { jackpotOn } = useSelector(authInfoSelector);
  const nextJackpot = useSelector(nextJackpotSelector);
  const hasUserInTop100 = useSelector(hasUserInTop100Selector);
  const isUserInStageJP = useSelector(isUserInStageJPSelector);
  const { stage } = useSelector(jackpotActivitySelector);
  const openedPanel = useSelector(settingsPanelSelector);

  const onClick = () => {
    dispatch(manageSettingsPanelAction({ settingsPanel: SettingPanels.Rules }));
    dispatch(setIsShowJackpotRuleAction(true));
  };

  useEffect(() => {
    if (!jackpotOn && openedPanel === SettingPanels.Winners) {
      dispatch(manageSettingsPanelAction({ settingsPanel: null }));
    }
  }, [jackpotOn, openedPanel, dispatch]);

  return (
    <>
      {currentJackpot || nextJackpot ? (
        <WinnersTableSettingsPanelResponsive
          onCloseClick={onCloseClick}
          onClick={onClick}
          currentJackpotPoints={currentJackpotPoints}
          nextJackpotPoints={nextJackpotPoints}
          uuid={auth?.uuid as string}
          hasUserInTop100={Boolean(hasUserInTop100)}
          nextJackpot={nextJackpot}
          currentJackpot={currentJackpot}
          isUserInStageJP={isUserInStageJP}
          stage={stage}
          outOfStagePlayers={outOfStagePlayers}
          activePlayers={activePlayers}
        />
      ) : (
        <EmptyJackpotSettingsPanelResponsive onCloseClick={onCloseClick} onClick={onClick} />
      )}
    </>
  );
};
