import styled, { css } from 'styled-components';
import { blinkingStyles } from '../styles';

export const RoadLabelStyled = styled.svg``;

export const ContentWrapper = styled.svg``;

export const Text = styled.text<{ $size: 'L' | 'S' }>`
  fill: #e7d5b9;
  fill-opacity: 0.6;
  text-transform: uppercase;
  ${({ $size }) => {
    if ($size === 'L') {
      return css`
        font-size: 8px;
        line-height: 10px;
      `;
    }
    if ($size === 'S') {
      return css`
        font-size: 6px;
        line-height: 6px;
      `;
    }
  }}
`;

export const CellItemWrapper = styled.svg<{ $isPrediction?: boolean }>`
  ${({ $isPrediction }) => $isPrediction && blinkingStyles}
`;
