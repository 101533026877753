import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchToggle } from 'components';
import { changeSoundMuteAction } from 'core/widgets/SoundSettings/action';
import { useDispatch, useSelector } from 'react-redux';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { Device } from 'types';
import { WrapperButton, WrapperButtonContent, SoundSwitchToggle } from './styles';
import AudioService from 'services/AudioService';

export const SoundButton = ({ deviceType }: { deviceType: Device }) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(true);

  const dispatch = useDispatch();
  const { gameSoundMute, isStartLoadingSounds } = useSelector(soundSettingsSelector);

  const onSwitchSoundChange = useCallback(
    (value: boolean) => {
      dispatch(changeSoundMuteAction(value));
      AudioService.changeSoundSettings(value);
    },
    [dispatch],
  );

  useEffect(() => {
    let timer: any;
    if (isStartLoadingSounds) {
      timer = setTimeout(() => {
        setIsShow(false);
      }, 10000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isStartLoadingSounds]);

  if (!isShow || !gameSoundMute || !isStartLoadingSounds) {
    return null;
  }

  return (
    <WrapperButton $deviceType={deviceType}>
      <WrapperButtonContent>
        {t('settings.sound.sound_on')}{' '}
        <SoundSwitchToggle>
          <SwitchToggle
            isChecked={!gameSoundMute}
            onClick={() => onSwitchSoundChange(!gameSoundMute)}
          />
        </SoundSwitchToggle>
      </WrapperButtonContent>
    </WrapperButton>
  );
};
