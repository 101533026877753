export enum ACTIONS {
  DOUBLE_BET = 'DOUBLE_BET',
  DOUBLE_BET_SUCCESS = 'DOUBLE_BET_SUCCESS',
  CLEAR_DOUBLE_BET = 'CLEAR_DOUBLE_BET',
  CONFIRM_DOUBLE_BET = 'CONFIRM_DOUBLE_BET',
  ERROR_DOUBLE_BET = 'ERROR_DOUBLE_BET',
  RESET_DOUBLE_BET = 'RESET_DOUBLE_BET',
}

export interface IBetDoubleState {
  isUsed: boolean;
  isConfirmed: boolean;
}
