import { ActionType, createReducer } from 'typesafe-actions';
import {
  addedMessageStore,
  addNewMessageInput,
  setIsBlockedChat,
  setIsSendButtonBlocked,
} from './actions';
import { IChatPanelState } from './types';

export const settingsPanelState: IChatPanelState = {
  messages: [],
  messageError: null,
  newMessage: null,
  isBlockedChat: false,
  isSendButtonBlocked: false,
  lastSentMessages: [],
};

const actions = {
  addedMessageStore,
  addNewMessageInput,
  setIsBlockedChat,
  setIsSendButtonBlocked,
};

export const chatPanelReducer = createReducer<IChatPanelState, ActionType<typeof actions>>(
  settingsPanelState,
)
  .handleAction(addedMessageStore, (state, { payload: message }) => ({
    ...state,
    messages: [...state.messages.slice(-49), message],
    lastSentMessages: [...state.lastSentMessages, Date.now()].slice(-10),
  }))
  .handleAction(addNewMessageInput, (state, { payload: newMessage }) => ({
    ...state,
    newMessage,
  }))
  .handleAction(setIsBlockedChat, (state, { payload: isBlockedChat }) => ({
    ...state,
    isBlockedChat,
  }))
  .handleAction(setIsSendButtonBlocked, (state, { payload: isSendButtonBlocked }) => ({
    ...state,
    isSendButtonBlocked,
  }));
