import { ChipType } from 'widgets/Chips/data/types';

const getChipByValue = (value: number, chips: ChipType[]): ChipType => {
  const result = Object.entries(chips).reduce((closestChip: ChipType, [, chip]) => {
    if (chip.value <= value) {
      return chip;
    }

    return closestChip;
  }, chips[0]);

  return result;
};

export default getChipByValue;
