import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IVideoSettingsState } from './types';

export const videoSettingsRootSelector = (state: IState) => state.videoSettingsReducer;

export const streamNamesSelector = createSelector(
  videoSettingsRootSelector,
  (state: IVideoSettingsState) => state.streamNames,
);

export const videoRequestedSettingsSelector = createSelector(
  videoSettingsRootSelector,
  (state: IVideoSettingsState) => state.requested,
);

export const videoAppliedSettingsSelector = createSelector(
  videoSettingsRootSelector,
  (state: IVideoSettingsState) => state.applied,
);

export const videoQualityLoadingSelector = createSelector(
  videoSettingsRootSelector,
  (state: IVideoSettingsState) => state.isQualityLoading,
);
