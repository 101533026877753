import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Device } from 'types';
import { PopupContainer } from 'components/PopupContainer';
import { Icon, IconNames } from 'components/Icon';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { ButtonConfirm, Wrapper, Message, CloseButton } from './styles';
import { IDevicePopupConfirmProps } from './types';
import AudioService from 'services/AudioService';

export const BasePopupConfirm = ({
  children,
  buttonText = 'buttons.confirm',
  onConfirm,
  onClose,
  deviceType = Device.Desktop,
}: IDevicePopupConfirmProps) => {
  const { t } = useTranslation();

  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);

  const onCloseAudio = useCallback(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
  }, [gameSoundMute, soundEffects]);

  useEffect(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('alert', soundEffects.volume);
    }
  }, [gameSoundMute, soundEffects]);

  const closeModalPopup = () => {
    onCloseAudio();
    if (onClose) {
      onClose();
    }
  };

  const onConfirmButton = () => {
    onCloseAudio();
    onConfirm();
  };

  return (
    <>
      <PopupContainer deviceType={deviceType}>
        <Wrapper $deviceType={deviceType}>
          {onClose && (
            <CloseButton onClick={closeModalPopup}>
              <Icon name={IconNames.Cross} />
            </CloseButton>
          )}
          <Message>{children}</Message>
          <ButtonConfirm onClick={onConfirmButton}>{t(buttonText)}</ButtonConfirm>
        </Wrapper>
      </PopupContainer>
    </>
  );
};
