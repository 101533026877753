import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { chipsMIN02 } from './chipsValues';

export const CHIPS_MIN_025: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 0.25,
  },
  ...chipsMIN02,
];
