import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IScoreboardStatusState } from './types';

const isScoreboardSelector = (state: IState) => state.isScoreboardOpenReducer;

export const isScoreboardOpenSelector = createSelector(
  isScoreboardSelector,
  (state: IScoreboardStatusState) => state.isScoreboardOpen,
);
