import React from 'react';
import { totalBallsCounterSelector } from 'core/widgets/Scoreboard/BallsCounter';
import { useSelector } from 'react-redux';
import { WinnerBall } from 'constants/common';
import { BallsCounterStyled, ContentWrapper } from './styles';
import { IBallsCounterProps } from './types';
import { BallsCounterRow } from './components/BallsCounterRow';

export const BallsCounterTablet = ({ size = { width: 81, height: 116 } }: IBallsCounterProps) => {
  const ballsCount = useSelector(totalBallsCounterSelector);

  return (
    <BallsCounterStyled
      width={size.width}
      height={size.height}
      viewBox="0 0 81 116"
      y="8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80 1V115H0V1H41.4299H80Z"
        fill="url(#paint0_linear_84_31114)"
        stroke="url(#paint1_linear_84_31114)"
      />
      <mask id="path-2-inside-1_84_31114" fill="white">
        <path d="M0 38H80V39H0V38Z" />
      </mask>
      <path
        d="M0 38V37H-1V38H0ZM80 38H81V37H80V38ZM80 39V40H81V39H80ZM0 39H-1V40H0V39ZM0 39H80V37H0V39ZM79 38V39H81V38H79ZM80 38H0V40H80V38ZM1 39V38H-1V39H1Z"
        fill="url(#paint2_linear_84_31114)"
        mask="url(#path-2-inside-1_84_31114)"
      />
      <mask id="path-4-inside-2_84_31114" fill="white">
        <path d="M0 77H80V78H0V77Z" />
      </mask>
      <path
        d="M0 77V76H-1V77H0ZM80 77H81V76H80V77ZM80 78V79H81V78H80ZM0 78H-1V79H0V78ZM0 78H80V76H0V78ZM79 77V78H81V77H79ZM80 77H0V79H80V77ZM1 78V77H-1V78H1Z"
        fill="url(#paint3_linear_84_31114)"
        mask="url(#path-4-inside-2_84_31114)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_84_31114"
          x1="40"
          y1="115"
          x2="40"
          y2="1"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_84_31114"
          x1="0"
          y1="58"
          x2="80"
          y2="58"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_84_31114"
          x1="80"
          y1="38.5"
          x2="0"
          y2="38.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#121011" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#121011" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_84_31114"
          x1="80"
          y1="77.5"
          x2="0"
          y2="77.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#121011" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#121011" />
        </linearGradient>
      </defs>
      <ContentWrapper width="80" height="114" viewBox="0 0 80 114">
        <BallsCounterRow ballType={WinnerBall.BLUE} quantity={ballsCount.BLUE} />
        <BallsCounterRow ballType={WinnerBall.GOLD} quantity={ballsCount.GOLD} position={[0, 39]} />
        <BallsCounterRow ballType={WinnerBall.RED} quantity={ballsCount.RED} position={[0, 78]} />
      </ContentWrapper>
    </BallsCounterStyled>
  );
};
