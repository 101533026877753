import React from 'react';
import { Trans } from 'react-i18next';
import { Loader } from 'components';
import { Device } from 'types';
import { LoaderWrapper, TryingToReload, Wrapper } from './styles';
import { IDeviceVideoStreamProps } from './types';

export const BaseVideoStream = ({
  id,
  isLoading,
  isTryingToReload,
  deviceType = Device.Desktop,
}: IDeviceVideoStreamProps) => (
  <Wrapper id={id} $deviceType={deviceType}>
    {isTryingToReload && (
      <TryingToReload>
        <Trans
          i18nKey="stream.supportText"
          components={{
            break: deviceType === Device.Mobile ? <br /> : <span />,
          }}
        />
      </TryingToReload>
    )}
    {isLoading && !isTryingToReload ? (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    ) : null}
  </Wrapper>
);
