import { ActionType, createReducer } from 'typesafe-actions';
import { setActiveChipAction } from './actions';
import { IActiveChipState } from './types';

export const activeChipState: IActiveChipState = {
  chip: null,
};

const actions = {
  setActiveChipAction,
};

export const activeChipReducer = createReducer<IActiveChipState, ActionType<typeof actions>>(
  activeChipState,
).handleAction(setActiveChipAction, (state, { payload: { chip } }) => ({
  ...state,
  chip,
}));
