import { createReducer, ActionType } from 'typesafe-actions';
import {
  saveRoundBetSuccessAction,
  repeatBetSuccessAction,
  clearRepeatBetAction,
  confirmRepeatBetAction,
  errorRepeatBetAction,
  resetRepeatBetAction,
} from './actions';
import { IBetRepeatState } from './types';

export const betRepeatState: IBetRepeatState = {
  isUsed: false,
  isConfirmed: false,
  value: null,
};

const actions = {
  saveRoundBetSuccessAction,
  repeatBetSuccessAction,
  clearRepeatBetAction,
  confirmRepeatBetAction,
  errorRepeatBetAction,
  resetRepeatBetAction,
};

export const betRepeatReducer = createReducer<IBetRepeatState, ActionType<typeof actions>>(
  betRepeatState,
)
  .handleAction(saveRoundBetSuccessAction, (state, { payload }) => ({
    ...state,
    value: payload,
  }))
  .handleAction(repeatBetSuccessAction, (state) => ({
    ...state,
    isUsed: true,
  }))
  .handleAction(clearRepeatBetAction, (state) => ({
    ...state,
    isUsed: state.isConfirmed,
  }))
  .handleAction(confirmRepeatBetAction, (state) => ({
    ...state,
    isConfirmed: state.isUsed,
  }))
  .handleAction(errorRepeatBetAction, (state) => ({
    ...state,
    isUsed: state.isUsed && !state.isConfirmed ? false : state.isUsed,
  }))
  .handleAction(resetRepeatBetAction, (state) => ({
    ...state,
    isUsed: false,
    isConfirmed: false,
  }));
