import { SLOTS } from 'types';

export enum ACTIONS {
  PLACE_BET = 'PLACE_BET',
  PLACE_BET_SUCCESS = 'PLACE_BET_SUCCESS',
  CLEAR_PLACED_BET = 'CLEAR_PLACED_BET',
  CLEAR_PLACED_BET_SUCCESS = 'CLEAR_PLACED_BET_SUCCESS',
  CLEAR_ALL_BETS = 'CLEAR_ALL_BETS',
  CONFIRM_BET = 'CONFIRM_BET',
  CONFIRM_BET_SUCCESS = 'CONFIRM_BET_SUCCESS',
  CONFIRM_BET_ERROR = 'CONFIRM_BET_ERROR',
  ADD_CONFIRMED_BET = 'ADD_CONFIRMED_BET',
  SET_CONFIRMED_BET = 'SET_CONFIRMED_BET',
  PLACE_BET_PENDING = 'PLACE_BET_PENDING',
}

export type BetSlotType = {
  slot: SLOTS;
  value: number;
};

export type BetType = {
  [key in SLOTS]: number;
};

export interface IBet {
  id: number;
  body: BetType;
}

export interface IBetState {
  bets: {
    placed: BetType;
    confirmed: IBet[];
  };
}
