import { isInIframe, isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import styled, { css } from 'styled-components';
import { Device } from 'types';

const WrapperContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: min-content;
  border-style: none;
  ${({ theme: { border } }) => css`
    border-color: ${border.primaryDefault};
  `}

  &:after {
    content: '';
    position: absolute;
    border: 1rem solid;
    border-image-source: linear-gradient(
      270deg,
      rgba(241, 230, 210, 0) 0%,
      #f1e6d2 50%,
      rgba(241, 230, 210, 0) 100%
    );
    border-image-slice: 1;
    pointer-events: none;
  }
`;

export const Wrapper = styled(WrapperContainer)<{ $deviceType?: Device }>`
  width: 927rem;
  height: 478rem;
  margin: 60rem auto 182rem;
  border-width: 4rem;
  background: linear-gradient(180deg, #121011 40%, rgba(52, 52, 52, 0.2) 100%);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #121011;
    pointer-events: none;
  }

  &:after {
    top: 4rem;
    left: 4rem;
    right: 4rem;
    bottom: 4rem;
    z-index: 1002;
  }

  .simplebar-scrollable-y {
    /* height: calc(100vh - 260rem) !important; */
  }

  .simplebar-track {
    right: -8px;
  }

  .simplebar-track.simplebar-vertical {
    width: 3px;
    background: #434343;

    .simplebar-scrollbar {
      width: 3px;
      border-radius: 2px;

      background-color: #d4bb93;
    }
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        width: 77%;
        width: 312rem;
        @media only screen and (max-width: 320px) {
          width: 278rem;
        }
        /* height: 528rem; */
        /* height: 450rem; */
        height: ${isOldBrowserIOS() ? (isInIframe() ? '500rem' : '450rem') : '528rem'};
        margin: 32rem auto 40rem;
        border-width: 2rem;

        &:after {
          top: 2rem;
          left: 2rem;
          right: 2rem;
          bottom: 2rem;
        }

        .simplebar-track.simplebar-vertical {
          width: 1px;
          background: #434343;

          .simplebar-scrollbar {
            width: 1px;
            border-radius: 1px;

            background-color: #d4bb93;
          }
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        width: 568rem;
        height: 656rem;
        margin: 120rem auto 40rem;
        border-width: 4rem;

        .simplebar-track {
          right: -14px;
        }

        .simplebar-track.simplebar-vertical {
          width: 2px;
          background: #434343;

          .simplebar-scrollbar {
            width: 2px;
            border-radius: 1px;

            background-color: #d4bb93;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          pointer-events: none;
        }

        &:after {
          top: 4rem;
          left: 4rem;
          right: 4rem;
          bottom: 4rem;
        }
      `;
    }
  }}
`;

export const CloseButton = styled.div`
  position: absolute;
  right: -16rem;
  top: -16rem;
  height: 32rem;
  width: 32rem;
  border: 1px solid #2b2a2b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #121011;
  &:hover {
    border: 1px solid #f1e6d2;
  }
  z-index: 101;
`;

export const Head = styled.p<{ $deviceType?: Device }>`
  padding: 22rem 0;
  font-size: 14rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  background: linear-gradient(360deg, #121011 0%, #343434 100%);
  z-index: 100;
  border-bottom: 1rem solid #434343;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        font-size: 10rem;
        padding: 10px 0;
      `;
    }
  }}
`;

export const RulesContainer = styled.div<{ $deviceType?: Device }>`
  overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > div {
    width: 100%;
    padding-right: 2px;
  }

  &::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 20px;

    background-color: #d4bb93;
    margin-right: 10px;
  }
  &::-webkit-scrollbar {
    width: 1px;
    background: #434343;
    margin-right: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  & p {
    font-size: 10rem;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        padding: 0rem 33rem;
        width: calc(100% - 104rem);
        z-index: 103;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: 25rem;
        margin-bottom: 16rem;
        margin-top: -30rem;
        overflow: hidden scroll;
        position: relative;
      `;
    }
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        &::-webkit-scrollbar {
          opacity: 0;
        }
        overflow-x: initial;
        overflow-y: initial;
        width: 100%;

        height: calc(100% - 100rem);
        margin-top: 1px;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        &::-webkit-scrollbar {
          opacity: 0;
        }
        overflow-x: initial;
        overflow-y: initial;
        width: 100%;

        & p {
          font-size: 5rem;
        }
      `;
    }
  }}
`;

export const LoaderWrapper = styled.div<{ $deviceType?: Device }>`
  height: 64rem;
  width: 64rem;
  margin-top: 15%;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        margin-top: 40%;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        margin-top: 60%;
      `;
    }
  }}
`;

export const MobileHistoryWrapper = styled.div<{ $deviceType?: Device }>`
  display: flex;
  flex-direction: column;
  width: calc(100% - 64rem);
  position: relative;
  margin-top: 20rem;
  box-sizing: border-box;

  .simplebar-content {
    height: 99%;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        width: calc(100% - 32rem);
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        width: calc(100% - 32rem);
      `;
    }
  }}
`;

export const EmptyHistory = styled.div<{ $deviceType?: Device }>`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-right: 1px solid #bb9370;
  border-left: 1px solid #bb9370;
  background: #2b2a2b;

  color: #686868;

  font-size: 12rem;
  height: 300rem;
  box-sizing: border-box;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        height: 100%;
        border-bottom: 1px solid #bb9370;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        height: 98%;
        border-bottom: 1px solid #bb9370;
      `;
    }
  }}
`;
