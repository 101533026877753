import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { PredictionsSectionLandscape } from './PredictionsSection.landscape';
import { PredictionsSectionMobile } from './PredictionsSection.mobile';
import { PredictionsSectionTablet } from './PredictionsSection.tablet';
import { IPredictionsSection } from './types';

export const PredictionsSectionResponsive = (props: IPredictionsSection) => (
  <>
    <DesktopComponent>
      <PredictionsSectionLandscape {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <PredictionsSectionLandscape {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <PredictionsSectionTablet {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <PredictionsSectionMobile {...props} />
    </MobileComponent>
  </>
);
