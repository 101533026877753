import axios from 'axios';

interface ICredentials {
  URL: string;
}

export class BaseService {
  protected _credentials?: ICredentials;

  protected _prefix: string = '';

  protected _tableID: string = '';

  protected _operatorID: string = '';

  public setCredentials(credentials: ICredentials): void {
    this._credentials = credentials;
  }

  public setTableID(tableID: string): void {
    this._tableID = tableID;
  }

  public setOperatorID(operatorID: string): void {
    this._operatorID = operatorID;
  }

  protected getCurrentUrl(path: string): string {
    if (this.credentials) {
      return `${this.credentials.URL}${this.prefix}${path}`;
    }

    return `${this.prefix}${path}`;
  }

  protected get credentials() {
    return this._credentials;
  }

  protected get headers(): { headers: any } {
    const token = `${this.operatorID}_${this.tableID}_rest_auth_token`;
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(token)}`,
      },
    };
  }

  protected get headersAPI(): { headers: any } {
    const token = `${this.operatorID}_${this.tableID}_api_token`;
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(token)}`,
      },
    };
  }

  public set prefix(prefix: string | undefined) {
    this._prefix = prefix || '';
  }

  public get prefix(): string | undefined {
    return this._prefix;
  }

  protected get tableID() {
    return this._tableID;
  }

  protected get operatorID() {
    return this._operatorID;
  }

  public async get(route: string) {
    const url: string = this.getCurrentUrl(route);
    const result = await axios.get(url, this.headers);
    return result;
  }

  public async getAPI(route: string) {
    const url: string = this.getCurrentUrl(route);
    const result = await axios.get(url, this.headersAPI);
    return result;
  }

  public async post<T = any>(route: string, data: any, customHeaders = {}) {
    const url: string = this.getCurrentUrl(route);
    const result = await axios.post<T>(url, data, {
      headers: { ...this.headers.headers, ...customHeaders },
    });
    return result;
  }

  public async put(route: string, data: any) {
    const url: string = this.getCurrentUrl(route);
    const result = await axios.put(url, data, this.headers);
    return result;
  }

  public async patch(route: string, data: any) {
    const url: string = this.getCurrentUrl(route);
    const result = await axios.patch(url, data, this.headers);
    return result;
  }

  public async remove(route: string) {
    const url: string = this.getCurrentUrl(route);
    const result = await axios.delete(url, this.headers);
    return result;
  }
}
