import React from 'react';
import styled from 'styled-components';
import { IBaseSvgParams } from 'types';

export interface ILine extends IBaseSvgParams {
  color: string;
}

const LineStyled = styled.svg``;

export const Line = ({ size = { width: 12, height: 12 }, color, position = [3, 3] }: ILine) => {
  const [positionX, positionY] = position;
  return (
    <LineStyled
      width={size.width}
      height={size.height}
      viewBox="0 0 12 12"
      x={positionX}
      y={positionY}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="9.72266"
        width="2.0625"
        height="13.75"
        transform="rotate(45 9.72266 0)"
        fill={color}
      />
    </LineStyled>
  );
};
