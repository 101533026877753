import React from 'react';
import { ContentWrapper, CounterRowStyled, Text } from './styles';
import { ballsIconMap, IBallsCounterRowProps } from './types';

export const BallsCounterRowLandscape = ({
  ballType,
  quantity,
  size = { width: 80, height: 33 },
  position = [0, 0],
}: IBallsCounterRowProps) => (
  <CounterRowStyled width={size.width} height={size.height} y={position[1]} viewBox="0 0 80 33">
    <ContentWrapper x="24" y="10">
      <>
        {ballsIconMap[ballType]}
        <Text x="20" y="8" dominantBaseline="middle">
          {quantity}
        </Text>
      </>
    </ContentWrapper>
  </CounterRowStyled>
);
