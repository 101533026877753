import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { SlotRedMaximizedTablet, SlotRedMinimizedTablet } from './components';

export const SlotRedTablet = (props: ISlotProps) => {
  const { isMinimized } = props;
  return isMinimized ? (
    <SlotRedMinimizedTablet {...props} />
  ) : (
    <SlotRedMaximizedTablet {...props} />
  );
};
