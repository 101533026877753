import { ActionType, createReducer } from 'typesafe-actions';
import { setWinStatusAction } from './actions';
import { IWinStatusState } from './types';

export const winStatusState: IWinStatusState | null = null;

export const actions = {
  setWinStatusAction,
};

export const winStatusReducer = createReducer<IWinStatusState | null, ActionType<typeof actions>>(
  winStatusState,
).handleAction(setWinStatusAction, (state, { payload }) => payload);
