import styled, { css } from 'styled-components';
import { CHIPS_COLORS } from 'widgets/Chips/data/currencies/colors';
import { CursorChips } from 'widgets/Slots/data';
import { slotWinnerOpacityAnimation } from 'widgets/Slots/styles';

export const Wrapper = styled.svg<{
  $isActive: boolean;
  $isBettingTime: boolean;
  $isWinner: boolean;
  $activeChip?: CHIPS_COLORS;
}>`
  user-select: none;
  overflow: visible;

  #blueBets,
  #blueBetsDefs {
    opacity: 0;
  }

  ${({ $isActive }) => {
    if ($isActive) {
      return css`
        #blueBets,
        #blueBetsDefs {
          opacity: 1;
        }
      `;
    }
  }}

  ${({ $isBettingTime, $activeChip }) => {
    if (!$isBettingTime) {
      return css`
        cursor: not-allowed;
      `;
    }

    if ($activeChip) {
      return css`
        cursor:
          url(${CursorChips[$activeChip]}) 8 8,
          pointer;
      `;
    }
  }}

  ${({ $isWinner }) => {
    if ($isWinner) {
      return css`
        #blueBets,
        #blueBetsDefs {
          animation: ${slotWinnerOpacityAnimation} 0.6s ease-out 5 forwards alternate;
        }
      `;
    }
  }}
`;
