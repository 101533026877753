import { ActionType, createReducer } from 'typesafe-actions';
import { toggleScoreboardAction } from './actions';
import { IScoreboardStatusState } from './types';

export const scoreboardStatusState: IScoreboardStatusState = {
  isScoreboardOpen: true,
};

const actions = {
  toggleScoreboardAction,
};

export const isScoreboardOpenReducer = createReducer<
  IScoreboardStatusState,
  ActionType<typeof actions>
>(scoreboardStatusState).handleAction(toggleScoreboardAction, (state) => ({
  ...state,
  isScoreboardOpen: !state.isScoreboardOpen,
}));
