import { delay, put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  setCountdownJackpot,
  setCurrentJackpot,
  setNextJackpot,
  updateCurrentJackpotUserPointsJP,
  updateNextJackpotUserPointsJP,
} from 'core/widgets/Jackpot/actions';
import { OnJackpoStateUpdatedData } from '../../types';
import { Notifications } from 'types';
import { ACTIONS, IPlayerJP, PositionJackpot } from 'core/widgets/Jackpot/types';
import { currentJackpotSelector } from 'core/widgets/Jackpot/selectors';

export function* onJackpotStateUpdatedSaga({
  type: notificationType,
  data: result,
}: OnJackpoStateUpdatedData) {
  try {
    if (notificationType === Notifications.JACKPOT_STATE_UPDATED) {
      yield put(setCountdownJackpot(null));
      const { uuid } = yield select(currentJackpotSelector);

      const { jackpots } = result;
      const currentJackpot = jackpots?.find(
        (jackpot) => jackpot.position === PositionJackpot.CURRENT,
      );
      const nextJackpot = jackpots?.find((jackpot) => jackpot.position === PositionJackpot.NEXT);

      if (currentJackpot) {
        yield put(setCurrentJackpot(currentJackpot));

        const player = currentJackpot?.players?.find(
          ({ uuid: playerUid }: IPlayerJP) => playerUid === uuid,
        );
        if (player) {
          yield put(
            updateCurrentJackpotUserPointsJP({
              jackpotId: currentJackpot?.jackpotId,
              value: player?.points,
            }),
          );
        }

        if (!player) {
          yield put(
            updateCurrentJackpotUserPointsJP({
              jackpotId: currentJackpot?.jackpotId,
              value: 0,
            }),
          );
        }
      }

      if (nextJackpot) {
        yield put(setNextJackpot(nextJackpot));

        const playerNextJackpot = nextJackpot?.players?.find(
          ({ uuid: playerUid }: IPlayerJP) => playerUid === uuid,
        );

        if (playerNextJackpot) {
          yield put(
            updateNextJackpotUserPointsJP({
              jackpotId: nextJackpot?.jackpotId,
              value: playerNextJackpot?.points,
            }),
          );
        }

        if (!playerNextJackpot) {
          yield put(
            updateNextJackpotUserPointsJP({
              jackpotId: nextJackpot?.jackpotId,
              value: 0,
            }),
          );
        }
      }

      if (!currentJackpot) {
        yield put(setCurrentJackpot(null));
        yield put(
          updateCurrentJackpotUserPointsJP({
            jackpotId: null,
            value: 0,
          }),
        );
      }

      if (!nextJackpot) {
        yield put(setNextJackpot(null));
        yield put(
          updateNextJackpotUserPointsJP({
            jackpotId: null,
            value: 0,
          }),
        );
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
