import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';

import { Device } from 'types';
import { SettingsPanel } from 'components';
import { Wrapper, ButtonWrapper, ListContainer, LimitText, ChatContainerScroll } from './styles';
import { IChatPanelComponentProps } from './types';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import { Chat } from './components/Chat';
import { Input } from './components/Input';

export const ChatPanelComponent = ({
  deviceType = Device.Desktop,
  isBlockedChat,
  messages,
  newMessage,
  messageError,
  onCloseClick,
  onSendMessage,
  handleMessageChange,
}: IChatPanelComponentProps) => {
  const { t } = useTranslation();
  const ref = useRef(null) as any;

  const [h, setH] = useState(400);

  useEffect(() => {
    // @ts-ignore
    const height = ref?.current?.getBoundingClientRect().height;
    setH(height);
  }, [ref]);

  const scrollableRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function scrollToBottom() {
    if (scrollableRef.current) {
      // @ts-ignore
      scrollableRef.current.scrollTop = 1000;
    }
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      onSendMessage();
    }
  };

  return (
    <Wrapper ref={ref}>
      <SettingsPanel onCloseClick={onCloseClick} deviceType={deviceType} hasVersionInfo={false}>
        <SettingsPanel.Title>{t('chat.title')}</SettingsPanel.Title>
        <SettingsPanel.Item>
          <ListContainer $hasShadow={Boolean(messages?.length > 6)}>
            <ChatContainerScroll $deviceType={deviceType} ref={scrollableRef}>
              {deviceType === Device.Mobile || deviceType === Device.Tablet_Portrait ? (
                <SimpleBar
                  style={{
                    height: `${h - (deviceType === Device.Mobile ? 186 : isOldBrowserIOS() ? 230 : 210)}px`,
                  }}
                  autoHide={false}>
                  <Chat messages={messages} deviceType={deviceType} />
                </SimpleBar>
              ) : (
                <Chat messages={messages} deviceType={deviceType} />
              )}
            </ChatContainerScroll>
          </ListContainer>
          <ButtonWrapper onKeyPress={handleKeyPress}>
            <Input
              value={newMessage || ''}
              placeholder={t('chat.typeMessage')}
              onClick={onSendMessage}
              onChange={handleMessageChange}
              disabled={messageError || isBlockedChat}
            />
            {newMessage && newMessage?.length >= 150 && (
              <LimitText>{t('chat.messageLimit')}</LimitText>
            )}
          </ButtonWrapper>
        </SettingsPanel.Item>
      </SettingsPanel>
    </Wrapper>
  );
};
