import React from 'react';
import { Wrapper } from './styles';
import { ICloseGameButtonProps } from './types';

export const CloseGameButtonLandscape = ({ onCloseGame }: ICloseGameButtonProps) => (
  <Wrapper
    width="102"
    height="52"
    viewBox="0 0 102 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g onClick={onCloseGame}>
      <path
        id="path_background"
        d="M1.19073 51.5L51.21 0.5H101.5V51.5H1.19073Z"
        fill="url(#paint0_linear_884_6295)"
        stroke="url(#paint1_linear_884_6295)"
      />
      <path id="path_border_one" d="M1.19073 51.5L51.21 0.5H101.5V51.5H1.19073Z" stroke="none" />
      <path id="path_border_two" d="M1.19073 51.5L51.21 0.5H101.5V51.5H1.19073Z" stroke="none" />
      <path
        d="M70.2972 28.9567C70.2041 28.8641 70.1301 28.7539 70.0796 28.6326C70.0292 28.5112 70.0032 28.3811 70.0032 28.2497C70.0032 28.1183 70.0292 27.9882 70.0796 27.8669C70.1301 27.7455 70.2041 27.6354 70.2972 27.5427L71.5912 26.2497H65.0022C64.737 26.2497 64.4827 26.1444 64.2951 25.9568C64.1076 25.7693 64.0022 25.5149 64.0022 25.2497C64.0022 24.9845 64.1076 24.7301 64.2951 24.5426C64.4827 24.3551 64.737 24.2497 65.0022 24.2497H71.5912L70.2972 22.9567C70.2044 22.8638 70.1308 22.7535 70.0805 22.6321C70.0303 22.5108 70.0045 22.3807 70.0045 22.2494C70.0046 22.118 70.0305 21.988 70.0808 21.8666C70.1311 21.7453 70.2048 21.6351 70.2977 21.5422C70.3907 21.4494 70.5009 21.3757 70.6223 21.3255C70.7437 21.2753 70.8737 21.2495 71.0051 21.2495C71.1364 21.2496 71.2665 21.2755 71.3878 21.3258C71.5092 21.3761 71.6194 21.4498 71.7122 21.5427L74.7132 24.5427C74.7967 24.6293 74.8638 24.7302 74.9112 24.8407C74.9242 24.8677 74.9322 24.8947 74.9412 24.9227C74.9722 25.0093 74.9904 25.0999 74.9952 25.1917L74.9962 25.2187C75.0047 25.3543 74.9835 25.4901 74.9342 25.6167L74.9312 25.6287V25.6327C74.8832 25.7447 74.8142 25.8407 74.7342 25.9267L74.7242 25.9417L74.7142 25.9567L71.7122 28.9567C71.6194 29.0497 71.5091 29.1234 71.3877 29.1738C71.2663 29.2241 71.1362 29.25 71.0047 29.25C70.8733 29.25 70.7432 29.2241 70.6218 29.1738C70.5004 29.1234 70.3901 29.0497 70.2972 28.9567Z"
        fill="url(#paint2_linear_59_26880)"
      />
      <path
        d="M76 31.75C76 32.1478 75.842 32.5294 75.5607 32.8107C75.2794 33.092 74.8978 33.25 74.5 33.25H61.5C61.1022 33.25 60.7206 33.092 60.4393 32.8107C60.158 32.5294 60 32.1478 60 31.75V18.75C60 18.3522 60.158 17.9706 60.4393 17.6893C60.7206 17.408 61.1022 17.25 61.5 17.25H74.5C74.8978 17.25 75.2794 17.408 75.5607 17.6893C75.842 17.9706 76 18.3522 76 18.75V20.25C76 20.5152 75.8946 20.7696 75.7071 20.9571C75.5196 21.1446 75.2652 21.25 75 21.25C74.7348 21.25 74.4804 21.1446 74.2929 20.9571C74.1054 20.7696 74 20.5152 74 20.25V19.25H62V31.25H74V30.25C74 29.9848 74.1054 29.7304 74.2929 29.5429C74.4804 29.3554 74.7348 29.25 75 29.25C75.2652 29.25 75.5196 29.3554 75.7071 29.5429C75.8946 29.7304 76 29.9848 76 30.25V31.75Z"
        fill="url(#paint3_linear_59_26880)"
      />
    </g>

    <defs>
      <linearGradient
        id="paint0_linear_884_6295"
        x1="68"
        y1="52"
        x2="68"
        y2="0"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" />
        <stop offset="1" stopColor="#343434" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_884_6295"
        x1="0"
        y1="26"
        x2="136"
        y2="26"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" stopOpacity="0" />
        <stop offset="0.5" stopColor="#434343" />
        <stop offset="1" stopColor="#343434" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint0_linear_59_26942"
        x1="51"
        y1="0"
        x2="51"
        y2="52"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" stopOpacity="0" />
        <stop offset="1" stopColor="#BB9370" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_59_26942"
        x1="51"
        y1="0"
        x2="51"
        y2="52"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFF7EB" stopOpacity="0.4" />
        <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_59_26942"
        x1="102"
        y1="26"
        x2="0"
        y2="26"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#D4BB93" stopOpacity="0" />
        <stop offset="0.5" stopColor="#D4BB93" />
        <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_59_26880"
        x1="69.5001"
        y1="21.2495"
        x2="69.5001"
        y2="29.25"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFF7EB" />
        <stop offset="1" stopColor="#D4BB93" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_59_26880"
        x1="68"
        y1="17.25"
        x2="68"
        y2="33.25"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFF7EB" />
        <stop offset="1" stopColor="#D4BB93" />
      </linearGradient>
    </defs>
  </Wrapper>
);
