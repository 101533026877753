import styled, { css } from 'styled-components';
import { Chip } from 'components';
import { Device } from 'types';
import { ButtonNav } from '../ButtonNav';
import backgroundSVG from './assets/background.svg';
import backgroundMobileSVG from './assets/background-mobile.svg';
import {
  AspectRatioIphone,
  getAspectRatioIphone,
  isInIframe,
  isOldBrowserIOS,
} from 'hooks/getDefineDevEnv';

export const InnerWrapper = styled.div`
  overflow: hidden;
`;

export const ButtonNavStyled = styled(ButtonNav)`
  flex-shrink: 0;
`;

export const ItemInnerWrapper = styled.div`
  position: relative;
`;

export const Item = styled.div<{ $active: boolean; disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  transform: translateY(0rem);
  cursor: pointer;

  ${({ $active, disabled }) => {
    if ($active) {
      return css`
        transform: translateY(-4rem);

        ${ItemInnerWrapper} {
          &:before {
            content: '';
            position: absolute;
            left: -4rem;
            right: -4rem;
            bottom: -10rem;
            height: 2rem;
            background: linear-gradient(
              270deg,
              rgba(241, 230, 210, 0) 0%,
              #f1e6d2 50%,
              rgba(241, 230, 210, 0) 100%
            );
          }
        }
      `;
    }

    if (disabled) {
      return css`
        cursor: not-allowed;

        & #chip_body {
          opacity: 0.4;
        }
      `;
    }
  }}
`;

export const Wrapper = styled.div<{ $deviceType: Device }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        height: 52rem;
        width: 630rem;
        padding: 0 52rem;
        background-image: url(${backgroundSVG});

        ${InnerWrapper} {
          margin: -7rem 12rem -8rem;
          width: 100%;
        }

        ${ButtonNavStyled} {
          height: 20rem;
          width: 20rem;
        }

        ${Item} {
          height: 84rem;
          width: 60rem;

          ${ItemInnerWrapper} {
            height: 60rem;
            width: 60rem;
          }
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 52rem;
        width: 630rem;
        padding: 0 69rem;
        background-image: url(${backgroundSVG});

        ${InnerWrapper} {
          margin: -7rem 12rem -8rem;
          width: 100%;
        }

        ${ButtonNavStyled} {
          height: 20rem;
          width: 20rem;
        }

        ${Item} {
          height: 84rem;
          width: 60rem;

          ${ItemInnerWrapper} {
            height: 60rem;
            width: 60rem;
          }
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        position: absolute;
        left: 50%;
        transform: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? isInIframe()
            ? 'translateX(-50%)'
            : 'translateX(-50%) scale(0.84)'
          : ' translateX(-50%)'};
        /* transform: scale(0.7); */
        height: 28rem;
        width: 312rem;
        padding: 0 24rem;
        background-image: url(${backgroundMobileSVG});
        margin-left: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
          ? '-1%'
          : '0'};

        ${InnerWrapper} {
          margin: -7rem 0 -8rem;
        }

        ${ButtonNavStyled} {
          height: 12rem;
          width: 12rem;

          svg {
            width: 12rem;
            height: 12rem;
          }
        }

        ${Item} {
          height: 52rem;
          width: 36rem;

          ${ItemInnerWrapper} {
            height: 36rem;
            width: 36rem;
          }
        }
      `;
    }
  }}
`;

export const ChipStyled = styled(Chip)`
  height: 100%;
  width: 100%;
`;
