import { put } from 'redux-saga/effects';
import { NotificationsChat } from 'types';

import { setIsBlockedChat } from 'core/widgets/ChatPanel/actions';
import { OnPlayerConnectedChatData } from '../../types';

export function* onPlayerConnectedChatSaga({
  type: notificationType,
  data,
}: OnPlayerConnectedChatData) {
  if (notificationType === NotificationsChat.CHAT_PLAYER_CONNECTED) {
    yield put(setIsBlockedChat(data.user.chatIsBlocked));
  }
}
