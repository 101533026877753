import { Device, ISvgStartPosition } from 'types';
import { WinnerBall } from 'constants/common';

export type { PredictionBallType } from './PredictionsSection/components/PredictionShortcut';

export interface IRoadItem {
  outcome: WinnerBall;
  isTie?: boolean;
  consecutiveTiesQty?: number;
  isPrediction?: boolean;
}

export type RoadItemType = IRoadItem | undefined;

export type IRoadItems = RoadItemType[];

export interface IScoreboardRoad {
  roadData: IRoadItems;
  position?: ISvgStartPosition;
}

export enum ScoreboardColors {
  Black = 'Black',
  Blue = 'Blue',
  ClashGold = 'ClashGold',
  LightDark = 'LightDark',
  LightGold = 'LightGold',
  OffWhite = 'OffWhite',
  SignalRed = 'SignalRed',
}

export type IScoreboardColors = Record<ScoreboardColors, string>;

export const scoreboardColors: IScoreboardColors = {
  [ScoreboardColors.Black]: '#121011',
  [ScoreboardColors.Blue]: '#41B6E6',
  [ScoreboardColors.ClashGold]: '#D4B700',
  [ScoreboardColors.LightDark]: '#343434',
  [ScoreboardColors.LightGold]: '#E7D5B9',
  [ScoreboardColors.OffWhite]: '#FFF7EB',
  [ScoreboardColors.SignalRed]: '#C8102E',
};

export interface IDeviceBaseScoreboardProps {
  deviceType?: Device;
}
