import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IScoreboardState } from './types';

const scoreboardDataSelector = (state: IState) => state.scoreboardReducer;

export const scoreboardSelector = createSelector(
  scoreboardDataSelector,
  (state: IScoreboardState) => state.currentState.data,
);
