import { createAction } from 'typesafe-actions';
import { ACTIONS, StreamNamesType, VideoQualities } from './types';

export const setStreamNamesAction = createAction(ACTIONS.SET_STREAM_NAMES)<StreamNamesType>();

export const changeVideoPlaybackAction = createAction(ACTIONS.CHANGE_PLAYBACK)<boolean>();

export const applyVideoPlaybackAction = createAction(ACTIONS.APPLY_PLAYBACK)<boolean>();

export const changeVideoAutoAdjustAction = createAction(ACTIONS.CHANGE_AUTO_ADJUST)<boolean>();

export const applyVideoAutoAdjustAction = createAction(ACTIONS.APPLY_AUTO_ADJUST)<boolean>();

export const changeVideoQualityAction = createAction(ACTIONS.CHANGE_QUALITY)<VideoQualities>();

export const changeVideoQualityFailAction = createAction(ACTIONS.CHANGE_QUALITY_FAIL)();

export const applyVideoQualityAction = createAction(ACTIONS.APPLY_QUALITY)<VideoQualities>();
