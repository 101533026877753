import { put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { setTotalBetAction, updateTotalBetAction } from '../actions';
import { totalBetSelector } from '../selectors';

export function* updateTotalBetSaga({
  payload: totalBetDiff,
}: ActionType<typeof updateTotalBetAction>) {
  const currentTotalBet: number | null = yield select(totalBetSelector);

  if (currentTotalBet === null) {
    return;
  }

  const updatedTotalBet = currentTotalBet + totalBetDiff;

  yield put(setTotalBetAction(updatedTotalBet));
}
