import { SLOTS } from 'types';
import { preciseAddSubtract, roundToFixed } from 'utils/roundToFix';
import { BetType } from '../types';

type GetDoubleBetPropsType = {
  confirmedBet: BetType;
  placedBet: BetType;
  maxBetLimit: number;
};

type GetDoubleBetReturnType = {
  doubledBet: BetType;
  isMaxBetLimitReached: boolean;
};

export const getDoubleBet = ({
  confirmedBet,
  placedBet,
  maxBetLimit,
}: GetDoubleBetPropsType): GetDoubleBetReturnType => {
  const numberFilledSlots = Object.values(placedBet)?.filter((value) => value > 0)?.length;
  const result: GetDoubleBetReturnType = Object.entries(placedBet).reduce(
    ({ doubledBet, isMaxBetLimitReached }: GetDoubleBetReturnType, [slotName, placedValue]) => {
      const confirmedValue = confirmedBet[slotName as SLOTS];
      const doubledSlotValue = preciseAddSubtract(
        confirmedBet[slotName as SLOTS],
        placedValue * 2,
        'add',
      );

      if (doubledSlotValue + confirmedValue > maxBetLimit / numberFilledSlots) {
        return {
          doubledBet: {
            ...doubledBet,
            [slotName]: doubledSlotValue,
          },
          isMaxBetLimitReached: true,
        };
      }

      return {
        doubledBet: {
          ...doubledBet,
          [slotName]: roundToFixed(doubledSlotValue, 2),
        },
        isMaxBetLimitReached,
      };
    },
    {
      doubledBet: {
        [SLOTS.player]: 0,
        [SLOTS.tie]: 0,
        [SLOTS.banker]: 0,
      },
      isMaxBetLimitReached: false,
    },
  );

  return result;
};
