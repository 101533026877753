import React from 'react';
import styled from 'styled-components';
import { WinnerBall } from 'constants/common';
import { scoreboardColors, ScoreboardColors } from '../types';

const CircleStyled = styled.svg``;

export type CircleType = WinnerBall.RED | WinnerBall.BLUE;

export interface ICircle {
  outcomeBall: CircleType;
  radius?: number;
  isPainted?: boolean;
  cellSize?: number;
}

const circleColorMap: Record<CircleType, string> = {
  [WinnerBall.RED]: scoreboardColors[ScoreboardColors.SignalRed],
  [WinnerBall.BLUE]: scoreboardColors[ScoreboardColors.Blue],
};

export const Circle = ({ outcomeBall, radius = 11, cellSize = 17, isPainted = false }: ICircle) => {
  const margin = (cellSize - radius) / 2;
  return (
    <CircleStyled
      width={radius}
      height={radius}
      x={margin}
      y={margin}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="5.50002"
        cy="5.5"
        r="4.5"
        strokeWidth="2"
        stroke={circleColorMap[outcomeBall]}
        {...(isPainted && { fill: circleColorMap[outcomeBall] })}
      />
    </CircleStyled>
  );
};
