import React from 'react';
import { totalBallsCounterSelector } from 'core/widgets/Scoreboard/BallsCounter';
import { useSelector } from 'react-redux';
import { WinnerBall } from 'constants/common';
import { BallsCounterStyled, ContentWrapper } from './styles';
import { IBallsCounterProps } from './types';
import { BallsCounterRow } from './components/BallsCounterRow';

export const BallsCounterMobile = ({ size = { width: 212, height: 20 } }: IBallsCounterProps) => {
  const ballsCount = useSelector(totalBallsCounterSelector);

  return (
    <BallsCounterStyled
      width={size.width}
      height={size.height}
      x="2"
      y="4"
      viewBox="0 0 212 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M211 1V19H1V1H109.754H211Z"
        fill="url(#paint0_linear_84_31114)"
        stroke="url(#paint1_linear_84_31114)"
      />
      <path d="M72.1166 12.96V7.296H72.8846V12.96H72.1166Z" fill="#434343" />
      <path d="M139.117 12.96V7.296H139.885V12.96H139.117Z" fill="#434343" />
      <defs>
        <linearGradient
          id="paint0_linear_84_31114"
          x1="106"
          y1="19"
          x2="106"
          y2="1"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_84_31114"
          x1="1"
          y1="10"
          x2="211"
          y2="10"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
      </defs>
      <ContentWrapper width="211" height="19" viewBox="0 0 211 19">
        <BallsCounterRow ballType={WinnerBall.BLUE} quantity={ballsCount.BLUE} />
        <BallsCounterRow ballType={WinnerBall.GOLD} quantity={ballsCount.GOLD} position={[71, 0]} />
        <BallsCounterRow ballType={WinnerBall.RED} quantity={ballsCount.RED} position={[141, 0]} />
      </ContentWrapper>
    </BallsCounterStyled>
  );
};
