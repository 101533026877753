import { put } from 'redux-saga/effects';

import { connectToWebSocketFailureAction } from '../actions';

import { OnConnectionFailedData } from '../types';
import { setUserMode } from 'core/widgets/Auth/actions';
import { ModeGame } from 'core/widgets/Auth/types';

export function* onConnectionFailedSaga({
  data,
  mode,
  lobbyUrl,
  closeGameMessage,
}: OnConnectionFailedData) {
  yield put(
    setUserMode({
      mode: mode || ModeGame.HOLI,
      lobbyUrl: lobbyUrl || null,
      closeGameMessage: closeGameMessage || null,
    }),
  );
  if (data.status === 409) yield put(connectToWebSocketFailureAction());
}
