import { StreamNamesType, VideoQualities } from 'core/widgets/VideoSettings/types';
import { getDefineDevEnv } from 'hooks/getDefineDevEnv';

export const ADAPTION_RULE = 'deviationOfMean2';
export const PLAYER_DIV_ID = 'playerDiv';

export const getConfig = (streamNames: StreamNamesType, startIndex?: number) => ({
  source: {
    defaults: {
      service: 'bintu',
    },
    entries: [
      {
        index: 0,
        label: VideoQualities.High,
        tag: 'high quality stream',
        h5live: {
          rtmp: {
            streamname: streamNames[VideoQualities.High],
          },
        },
      },
      {
        index: 1,
        label: VideoQualities.Medium,
        tag: 'medium quality stream',
        h5live: {
          rtmp: {
            streamname: streamNames[VideoQualities.Medium],
          },
        },
      },
      {
        index: 2,
        label: VideoQualities.Low,
        tag: 'low quality stream',
        h5live: {
          rtmp: {
            streamname: streamNames[VideoQualities.Low],
          },
        },
      },
    ],
    options: {
      adaption: {
        rule: ADAPTION_RULE,
      },
      switch: {
        method: 'server',
        pauseOnError: false,
        forcePlay: true,
        fastStart: false,
        timeout: 10,
      },
    },
    startIndex: startIndex || 0,
  },
  playback: {
    fastStart: true,
    autoplay: true,
    automute: true,
    muted: false,
    latencyControlMode: 'balancedadaptive',
    reconnect: {
      minDelay: 1,
      maxDelay: 10,
      delaySteps: 10,
      maxRetries: 20,
    },
    timeouts: {
      loading: 30,
    },
  },
  style: {
    view: false,
  },
  metrics: {
    accountId: 'oneblive', // replace with your metrics account id
    accountKey: 'one78dUuDVgG6pXC', // replace with your metrics account key
    userId: 'viewer1', // value can be changed per viewer
    eventId: 'event1', // value can be changed per event
    statsInterval: 10, // statistics interval in seconds
    customField1: `Env: ${getDefineDevEnv()}`,
  },
});
