import { CSSObjectWithLabel, ControlProps, OptionProps, ValueContainerProps } from 'react-select';

import { colors } from 'constants/theme';
import { ISelectOption } from './types';

export const controlStyles = (
  baseStyles: CSSObjectWithLabel,
  props: ControlProps<ISelectOption, false>,
) => {
  const borderInactiveStyle = 'linear-gradient(180deg, #434343 0%, #343434 100%)';
  const borderActiveStyle = 'linear-gradient(180deg, #F1E6D2 0%, #D4BB93 100%)';

  return {
    ...baseStyles,
    alignItems: 'center',
    border: '1rem solid',
    borderImageSource: props.isFocused ? borderActiveStyle : borderInactiveStyle,
    borderImageSlice: 1,
    padding: '9rem 12rem',
    background: 'linear-gradient(360deg, #121011 0%, #343434 100%)',
    cursor: 'pointer',
    minHeight: 'unset',

    ':hover': {
      borderImageSource: borderActiveStyle,
    },
  };
};

export const valueContainerStyles = (
  baseStyles: CSSObjectWithLabel,
  props: ValueContainerProps<ISelectOption, false>,
) => {
  const colorDefaultStyle = props.hasValue ? colors.LightGold : colors.Subtitle;
  const colorDisabledStyle = '#434343';

  return {
    ...baseStyles,
    fontSize: '14rem',
    lineHeight: '18rem',
    color: props.isDisabled ? colorDisabledStyle : colorDefaultStyle,
  };
};

export const menuStyles = (baseStyles: CSSObjectWithLabel) => ({
  ...baseStyles,
  top: 'calc(100% + 8rem)',
  backgroundColor: '#343434',
});

export const optionStyles = (
  baseStyles: CSSObjectWithLabel,
  props: OptionProps<ISelectOption, false>,
) => {
  const backgroundActiveStyle = 'rgba(18, 16, 17, 0.6)';

  return {
    ...baseStyles,
    margin: '6rem 0',
    padding: '9rem 12rem',
    fontSize: '14rem',
    lineHeight: '18rem',
    backgroundColor: props.isSelected ? backgroundActiveStyle : 'transparent',
    color: colors.LightGold,
    cursor: 'pointer',

    ':hover': {
      backgroundColor: props.isSelected ? backgroundActiveStyle : '#434343',
    },
  };
};
