import { put } from 'redux-saga/effects';
import { Notifications } from 'types';

import { addNoticeAction } from 'core/widgets/Notices/actions';
import { Notices } from 'core/widgets/Notices/types';
import { OnTableClosedData } from '../types';

export function* onTableClosedSaga({ type: notificationType }: OnTableClosedData) {
  if (notificationType === Notifications.TABLE_CLOSED) {
    yield put(addNoticeAction({ notification: Notices.TableClosed }));
  }
}
