import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.svg<{ $deviceType: Device; $hasJackpot?: boolean }>`
  ${({ $deviceType, $hasJackpot }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        ${JackpotText} {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 3px;

          tspan {
            font-size: 20px;
          }
        }

        ${JackpotAmountText} {
          letter-spacing: 1px;
          font-size: 20px;
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        ${JackpotText} {
          font-size: 24px;

          tspan {
            font-size: 24px;
          }
        }

        ${JackpotAmountText} {
          letter-spacing: 1px;
          font-size: 24px;
          line-height: 24px;
        }
      `;
    }

    return css`
      ${JackpotText} {
        /* font-size: 16px; */
        font-size: ${$hasJackpot ? '14px' : '16px'};

        tspan {
          font-size: ${$hasJackpot ? '14px' : '16px'};
        }
      }

      ${JackpotAmountText} {
        font-size: ${$hasJackpot ? '14px' : '16px'};
      }
    `;
  }}
`;

export const JackpotText = styled.text`
  letter-spacing: 3px;
  text-transform: uppercase;
  fill: #fff7eb;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

export const JackpotAmountText = styled.text`
  line-height: 16px;
  fill: #fff7eb;
  text-transform: uppercase;
`;

export const StageText = styled.text`
  color: #fff7eb;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
  fill: #fff7eb;
`;
