import { setBalanceAction } from 'core/widgets/User/actions';
import { setWinStatusAction } from 'core/widgets/WinStatus/actions';
import { WinnerBallSlot } from 'core/widgets/WinStatus/types';
import { put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { OnPlayerWonData } from '../types';

export function* onPlayerWonSaga({ type: notificationType, data: result }: OnPlayerWonData) {
  if (notificationType === Notifications.PLAYER_WON) {
    const {
      winner,
      winningAmount,
      user: { balance },
      bets,
    } = result;
    const betAmount = bets[WinnerBallSlot[winner]];

    yield put(setBalanceAction(balance));
    yield put(setWinStatusAction({ winner, winningAmount, betAmount }));
  }
}
