import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { tableSettingsSelector } from 'core/widgets/TableSettings';
import { connectionFailedSelector, socketDisconnectedSelector } from 'core/widgets/WebSocket';
import { Loader, PopupConfirm } from 'components';
import { useCloseGame, useModal } from 'hooks';
import { LoaderWrapper, LoadingText, Wrapper } from './styles';
import { authInfoSelector } from 'core/widgets/Auth/selectors';

export const PreloaderMain = () => {
  const { t } = useTranslation();
  const tableInfo = useSelector(tableSettingsSelector);
  const isConnectionFailed = useSelector(connectionFailedSelector);
  const isSocketDisconnected = useSelector(socketDisconnectedSelector);
  const { mode, lobbyUrl, closeGameMessage } = useSelector(authInfoSelector);

  const {
    isShown: isConnectionFailedNotificationShown,
    openModal: openConnectionFailedNotification,
  } = useModal();

  const closeGame = useCloseGame(mode, closeGameMessage, lobbyUrl);

  useEffect(() => {
    if (isConnectionFailed) {
      openConnectionFailedNotification();
    }
  }, [openConnectionFailedNotification, isConnectionFailed]);

  if (tableInfo === null || isSocketDisconnected) {
    return (
      <Wrapper>
        {isConnectionFailedNotificationShown ? (
          <PopupConfirm onConfirm={closeGame} buttonText="buttons.close">
            {t('notices.gameAlreadyOpened')}
          </PopupConfirm>
        ) : (
          <>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
            <LoadingText>{t('notices.loading')}</LoadingText>
          </>
        )}
      </Wrapper>
    );
  }

  return null;
};
