import { all, takeLatest } from 'redux-saga/effects';
import { ACTIONS } from '../types';
import { connectToChatWebSocketSaga } from './connectToChatWebSocketSaga';
import { connectToWebSocketSaga } from './connectToWebSocketSaga';

export function* webSocketRootSaga() {
  // yield all([takeLatest(ACTIONS.CONNECT_TO_CHAT_WEBSOCKET, connectToChatWebSocketSaga)]);
  yield all([takeLatest(ACTIONS.CONNECT_TO_WEBSOCKET, connectToWebSocketSaga)]);
}

export function* webSocketChatRootSaga() {
  yield all([takeLatest(ACTIONS.CONNECT_TO_CHAT_WEBSOCKET, connectToChatWebSocketSaga)]);
  // yield all([takeLatest(ACTIONS.CONNECT_TO_WEBSOCKET, connectToWebSocketSaga)]);
}
