import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { roundStateSelector } from 'core/widgets/RoundStates';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { winStatusSelector } from 'core/widgets/WinStatus';
import { setWinStatusAction } from 'core/widgets/WinStatus/actions';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';

import { WinStatusResponsive } from './Responsive';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import AudioService from 'services/AudioService';
import { Sounds } from 'core/widgets/SoundSettings/types';

const WIN_STATUS_DISPLAY_TIME = 3000;

export const WinStatus = () => {
  const dispatch = useDispatch();
  const roundState = useSelector(roundStateSelector);
  const winStatusData = useSelector(winStatusSelector);
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const { currency } = useSelector(authInfoSelector);

  const soundTimer = useCallback(() => {
    AudioService.enqueue('win', Sounds.SOUND_EFFECTS, true);
  }, []);

  useEffect(() => {
    if (winStatusData !== null) {
      soundTimer();
      const timer = setTimeout(() => {
        dispatch(setWinStatusAction(null));
      }, WIN_STATUS_DISPLAY_TIME);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, gameSoundMute, soundEffects, soundTimer, winStatusData]);

  return (
    <>
      {roundState !== RoundStates.ResultConfirmed || winStatusData === null ? null : (
        <WinStatusResponsive
          winnerBall={winStatusData.winner}
          winnerBet={winStatusData.betAmount}
          winnerAmount={winStatusData.winningAmount}
          currency={currency}
        />
      )}
    </>
  );
};
