import { all, takeEvery } from 'redux-saga/effects';
import { ACTIONS } from '../types';
import { repeatBetSaga } from './repeatBetSaga';
import { saveRoundBetSaga } from './saveRoundBetSaga';

export function* betRepeatRootSaga() {
  yield all([
    takeEvery(ACTIONS.SAVE_ROUND_BET, saveRoundBetSaga),
    takeEvery(ACTIONS.REPEAT_BET, repeatBetSaga),
  ]);
}
