import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { ISlotProps } from 'widgets/Slots/types';
import { SlotBlueLandscape } from './SlotBlue.landscape';
import { SlotBlueMobile } from './SlotBlue.mobile';
import { SlotBlueTablet } from './SlotBlue.tablet';

export const SlotBlueResponsive = (props: ISlotProps) => (
  <>
    <DesktopComponent>
      <SlotBlueLandscape {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <SlotBlueLandscape {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <SlotBlueTablet {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <SlotBlueMobile {...props} />
    </MobileComponent>
  </>
);
