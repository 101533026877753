export enum ACTIONS {
  ADD_NOTICE = 'ADD_NOTICE',
  REMOVE_NOTICE = 'REMOVE_NOTICE',
}

export enum Notices {
  RoundCanceled = 'ROUND_CANCELED',
  TableClosed = 'TABLE_CLOSED',
  CloseTableInProgress = 'CLOSE_TABLE_IN_PROGRESS',
  NoBetsMade = 'NO_BETS_MADE',
  SessionTimeout = 'SESSION_TIMEOUT',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  CasinoLossLimit = 'CASINO_LOSS_LIMIT',
}

export interface INoticesState {
  notifications: Notices[];
}
