import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from 'types';
import { Icon, IconNames, Loader, PopupContainer } from 'components';
import { Wrapper, CloseButton, Head, RulesContainer, LoaderWrapper } from './styles';
import { IPopupConfirmProps } from './types';
import { HeadTable, Box, ItemValue, HistoryWrapper, HeadRow } from './stylesTable';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { useSelector } from 'react-redux';

export const BasePopupHistory = forwardRef(
  ({ children, onClose, loading, deviceType = Device.Desktop }: IPopupConfirmProps, ref) => {
    const { t } = useTranslation();
    const { jackpotOn } = useSelector(authInfoSelector);

    return (
      <PopupContainer deviceType={deviceType}>
        <Wrapper $deviceType={deviceType} ref={ref as any}>
          <HistoryWrapper />
          <Head $deviceType={deviceType}>{t('history.title')}</Head>
          <CloseButton onClick={onClose}>
            <Icon name={IconNames.Cross} />
          </CloseButton>
          {Boolean(loading) && (
            <LoaderWrapper $deviceType={deviceType}>
              <Loader />
            </LoaderWrapper>
          )}
          {!loading && (
            <HeadTable $deviceType={deviceType}>
              <HeadRow $deviceType={deviceType}>
                <Box $width={22}>
                  <ItemValue>{t('history.info')}</ItemValue>
                </Box>
                <Box $width={jackpotOn ? 19 : 20}>
                  <ItemValue>{t('history.balance')}</ItemValue>
                </Box>
                <Box $width={12}>
                  <ItemValue>{t('history.betType')}</ItemValue>
                </Box>
                <Box $width={jackpotOn ? 19 : 21}>
                  <ItemValue>{t('history.bet')}</ItemValue>
                </Box>
                <Box $width={19}>
                  <ItemValue>{t('history.winLoss')}</ItemValue>
                </Box>
                {jackpotOn && (
                  <Box $width={8}>
                    <ItemValue>{t('history.jackpot')}</ItemValue>
                  </Box>
                )}
                <Box $width={0}>
                  <ItemValue />
                </Box>
              </HeadRow>
            </HeadTable>
          )}
          {!loading && <RulesContainer $deviceType={deviceType}>{children}</RulesContainer>}
        </Wrapper>
      </PopupContainer>
    );
  },
);
