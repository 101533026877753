import { ActionType, createReducer } from 'typesafe-actions';
import {
  applySoundMuteAction,
  applySoundVolumeAction,
  changeSoundMuteAction,
  changeSoundVolumeAction,
  changeIsStartLoadingSounds,
  initSoundAction,
} from './action';
import { ISoundSettingsState, Sounds } from './types';

export const soundSettingsState: ISoundSettingsState = {
  requested: {
    volume: 100,
    mute: false,
  },
  applied: {
    volume: 50,
    mute: null,
  },
  gameSoundMute: true,
  isStartLoadingSounds: false,
  music: {
    volume: 50,
    mute: null,
  },
  soundEffects: {
    volume: 50,
    mute: null,
  },
  digitalVoice: {
    volume: 50,
    mute: null,
  },
  studioSound: {
    volume: 0,
    mute: true,
  },
};

const actions = {
  changeSoundVolumeAction,
  applySoundVolumeAction,
  changeSoundMuteAction,
  initSoundAction,
  applySoundMuteAction,
  changeIsStartLoadingSounds,
};

export const soundSettingsReducer = createReducer<ISoundSettingsState, ActionType<typeof actions>>(
  soundSettingsState,
)
  .handleAction(changeSoundVolumeAction, (state, { payload }) => {
    if (Sounds.MUSIC === payload.type) {
      return {
        ...state,
        music: {
          ...state.music,
          volume: payload.volume,
        },
      };
    }

    if (Sounds.SOUND_EFFECTS === payload.type) {
      return {
        ...state,
        soundEffects: {
          ...state.soundEffects,
          volume: payload.volume,
        },
      };
    }

    if (Sounds.DIGITAL_VOICE === payload.type) {
      return {
        ...state,
        digitalVoice: {
          ...state.digitalVoice,
          volume: payload.volume,
        },
      };
    }

    if (Sounds.STUDIO_SOUND === payload.type) {
      return {
        ...state,
        studioSound: {
          ...state.studioSound,
          volume: payload.volume,
        },
      };
    }

    return {
      ...state,
    };
  })
  .handleAction(applySoundVolumeAction, (state, { payload }) => ({
    ...state,
    requested: {
      ...state.requested,
      volume: payload,
    },
    applied: {
      ...state.applied,
      volume: payload,
    },
  }))
  .handleAction(changeSoundMuteAction, (state, { payload }) => ({
    ...state,
    gameSoundMute: payload,
  }))
  .handleAction(
    initSoundAction,
    (state, { payload: { music, soundEffects, digitalVoice, studioSound } }) => ({
      ...state,
      music: music || state.music,
      soundEffects: soundEffects || state.soundEffects,
      digitalVoice: digitalVoice || state.digitalVoice,
      studioSound: studioSound || state.studioSound,
    }),
  )
  .handleAction(applySoundMuteAction, (state, { payload }) => {
    if (Sounds.MUSIC === payload.type) {
      return {
        ...state,
        music: {
          ...state.music,
          mute: payload.mute,
        },
      };
    }

    if (Sounds.SOUND_EFFECTS === payload.type) {
      return {
        ...state,
        soundEffects: {
          ...state.soundEffects,
          mute: payload.mute,
        },
      };
    }

    if (Sounds.DIGITAL_VOICE === payload.type) {
      return {
        ...state,
        digitalVoice: {
          ...state.digitalVoice,
          mute: payload.mute,
        },
      };
    }

    if (Sounds.STUDIO_SOUND === payload.type) {
      return {
        ...state,
        studioSound: {
          ...state.studioSound,
          mute: payload.mute,
        },
      };
    }

    return {
      ...state,
    };
  })
  .handleAction(changeIsStartLoadingSounds, (state, { payload }) => ({
    ...state,
    isStartLoadingSounds: payload,
  }));
