import React from 'react';
import { useSelector } from 'react-redux';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { scoreboardSelector } from 'core/widgets/Scoreboard/Boards';
import { BeadRoadLandscape } from './BeadRoad.landscape';
import { BeadRoadMobile } from './BeadRoad.mobile';
import { BeadRoadTablet } from './BeadRoad.tablet';

export const BeadRoad = () => {
  const roadData = useSelector(scoreboardSelector);

  return (
    <>
      <DesktopComponent>
        <BeadRoadLandscape roadData={roadData.beadRoadData} />
      </DesktopComponent>
      <TabletLandscapeComponent>
        <BeadRoadLandscape roadData={roadData.beadRoadData} />
      </TabletLandscapeComponent>
      <TabletPortraitComponent>
        <BeadRoadTablet roadData={roadData.beadRoadData} />
      </TabletPortraitComponent>
      <MobileComponent>
        <BeadRoadMobile roadData={roadData.beadRoadData} />
      </MobileComponent>
    </>
  );
};
