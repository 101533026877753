export type RoundWinner = {
  player: string;
  amount: number;
  currency: string;
};

export enum ACTIONS {
  SET_ROUND_WINNERS_DATA = 'SET_ROUND_WINNERS_DATA',
  RESET_ROUND_WINNERS_DATA = 'RESET_ROUND_WINNERS_DATA',
}

export interface IRoundWinnersState {
  winners: RoundWinner[] | null;
}
