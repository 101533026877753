import React from 'react';

import { winStatusColorsDesktop } from '../utils/getColorForWinStatus';
import { IGradientProps } from '../types';

export const BaseGradients = ({ winnerBall }: IGradientProps) => (
  <defs>
    <filter
      id="filter0_f_643_43102"
      x="212"
      y="33.0002"
      width="35.8057"
      height="35.8003"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_643_43102" />
    </filter>
    <filter
      id="filter1_f_643_43102"
      x="214.001"
      y="49"
      width="36"
      height="22"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_643_43102" />
    </filter>
    <filter
      id="filter2_f_643_43102"
      x="227.111"
      y="40.1111"
      width="19.5557"
      height="19.5557"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_643_43102" />
    </filter>
    <filter
      id="filter3_f_643_43102"
      x="228.89"
      y="40.1111"
      width="16"
      height="16"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
      <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_643_43102" />
    </filter>
    <linearGradient
      id="paint0_linear_643_43102"
      x1="0"
      y1="161.5"
      x2="464"
      y2="161.5"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#121011" stopOpacity="0" />
      <stop offset="0.25" stopColor="#121011" />
      <stop offset="0.65" stopColor="#121011" />
      <stop offset="1" stopColor="#121011" stopOpacity="0" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_643_43102"
      x1="232"
      y1="55"
      x2="232"
      y2="268"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.4" stopColor="#121011" />
      <stop offset="1" stopColor="#343434" stopOpacity="0.2" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_643_43102"
      x1="464"
      y1="161.5"
      x2="0"
      y2="161.5"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#F1E6D2" stopOpacity="0" />
      <stop offset="0.5" stopColor="#F1E6D2" />
      <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
    </linearGradient>
    <linearGradient
      id="paint3_linear_643_43102"
      x1="215"
      y1="87"
      x2="215"
      y2="2.00001"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#F1E6D2" stopOpacity="0" />
      <stop offset="0.38864" stopColor="#F1E6D2" />
    </linearGradient>
    <linearGradient
      id="background"
      x1="232"
      y1="4"
      x2="232"
      y2="103.5"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#121011" stopOpacity="0" />
      <stop offset="1" stopColor={winStatusColorsDesktop.backgroundColor[winnerBall]} />
    </linearGradient>
    <linearGradient
      id="paint5_linear_643_43102"
      x1="232.207"
      y1="111.04"
      x2="232.207"
      y2="32.2374"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#F1E6D2" />
      <stop offset="0.168908" stopColor="#F1E6D2" stopOpacity="0.1" />
      <stop offset="1" stopColor="#F1E6D2" />
    </linearGradient>
    <linearGradient
      id="paint6_linear_643_43102"
      x1="274.002"
      y1="82"
      x2="274.002"
      y2="102.65"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#121011" />
      <stop offset="1" stopColor="#121011" />
    </linearGradient>
    <linearGradient
      id="background_text"
      x1="129"
      y1="93.9999"
      x2="326"
      y2="93.9999"
      gradientUnits="userSpaceOnUse">
      <stop stopColor="#343434" stopOpacity="0" />
      <stop offset="0.505822" stopColor={winStatusColorsDesktop.backgroundColor[winnerBall]} />
      <stop offset="1" stopColor="#343434" stopOpacity="0" />
    </linearGradient>
    <linearGradient
      id="paint8_linear_643_43102"
      x1="257.65"
      y1="106.649"
      x2="257.65"
      y2="60.5946"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.25" stopColor="#F1E6D2" stopOpacity="0" />
      <stop offset="0.520833" stopColor="#F1E6D2" />
      <stop offset="0.802083" stopColor="#F1E6D2" stopOpacity="0" />
    </linearGradient>
    <radialGradient
      id="ball_background1"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(232.001 53) rotate(46.3972) scale(12.8889)">
      <stop stopColor={winStatusColorsDesktop.ballFirstBackgroundColor[winnerBall]} />
      <stop offset="0.934818" stopColor={winStatusColorsDesktop.backgroundColor[winnerBall]} />
    </radialGradient>
    <radialGradient
      id="ball_background2"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(232 52.9998) rotate(-133.603) scale(12.8889)">
      <stop stopColor={winStatusColorsDesktop.ballSecondBackgroundColor[winnerBall]} />
      <stop
        offset="0.934818"
        stopColor={winStatusColorsDesktop.ballSecondShadowBackgroundColor[winnerBall]}
      />
    </radialGradient>
    <radialGradient
      id="ball_background3"
      cx="0"
      cy="0"
      r="1"
      gradientUnits="userSpaceOnUse"
      gradientTransform="translate(232.001 53) rotate(46.3972) scale(12.8889)">
      <stop stopColor={winStatusColorsDesktop.ballThirdBackgroundColor[winnerBall]} />
      <stop
        offset="0.973958"
        stopColor={winStatusColorsDesktop.ballThirdShadowBackgroundColor[winnerBall]}
      />
    </radialGradient>
  </defs>
);
