import { Icon } from 'components';
import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.svg<{ $isDisabled: boolean; $deviceType: Device }>`
  position: absolute;
  right: -100rem;
  top: 0rem;
  height: 52rem;
  width: 136rem;

  & g {
    ${({ $deviceType, $isDisabled }) => {
      if ($deviceType === Device.Desktop) {
        if ($isDisabled) {
          return css`
            cursor: not-allowed;
          `;
        }

        return css`
          cursor: pointer;

          &:hover {
            & #path_background {
              cursor: pointer;
              fill: url(#paint0_linear_47_29754);
              stroke: url(#paint1_linear_47_29754);
            }
          }
        `;
      }

      if ($deviceType === Device.Tablet_Landscape) {
        return css`
          &:focus {
            & #rbtl_path_background {
              fill: url(#paint0_linear_2489_25418);
              stroke: url(#paint1_linear_2489_25418);
            }
          }

          &:active {
            & #rbtl_path_background {
              fill: url(#paint0_linear_52_23572);
              stroke: url(#paint1_linear_52_23572);
            }
          }
        `;
      }
    }}
  }
`;

export const IconStyled = styled(Icon)`
  height: 16rem;
  width: 16rem;
`;

export const IconWrapper = styled.svg``;
