import { ActionType, createReducer } from 'typesafe-actions';
import { addNoticeAction, removeNoticeAction } from './actions';
import { INoticesState } from './types';

export const defaultNotices: INoticesState = {
  notifications: [],
};

const actions = {
  addNoticeAction,
  removeNoticeAction,
};

export const noticesReducer = createReducer<INoticesState, ActionType<typeof actions>>(
  defaultNotices,
)
  .handleAction(addNoticeAction, (state, { payload: { notification } }) => ({
    ...state,
    notifications: [...state.notifications, notification],
  }))
  .handleAction(removeNoticeAction, (state) => {
    const [, ...updatedNotifications] = state.notifications;

    return {
      ...state,
      notifications: updatedNotifications,
    };
  });
