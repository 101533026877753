import { call, put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { addNoticeAction } from 'core/widgets/Notices/actions';
import { Notices } from 'core/widgets/Notices/types';
import { webSocketService } from 'services/WebSocketService';
import { applyVideoPlaybackAction } from 'core/widgets/VideoSettings/action';
import { OnPlayerErrorData } from '../types';

export function* onPlayerErrorSaga({ type: notificationType, data: result }: OnPlayerErrorData) {
  if (notificationType === Notifications.PLAYER_ERROR) {
    const { status } = result;

    if (status === 601) {
      yield put(addNoticeAction({ notification: Notices.SessionTimeout }));
      yield call(() => webSocketService.disconnect());
      yield put(applyVideoPlaybackAction(false));
      return;
    }

    if (status === 602) {
      yield put(addNoticeAction({ notification: Notices.InsufficientFunds }));
      return;
    }

    if (status === 603) {
      yield put(addNoticeAction({ notification: Notices.CasinoLossLimit }));
      return;
    }
  }
}
