import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IUserState } from './types';

export const userRootSelector = (state: IState): IUserState => state.userReducer;

export const balanceSelector = createSelector(
  userRootSelector,
  (state: IUserState): number | null => state.balance,
);

export const totalBetSelector = createSelector(
  userRootSelector,
  (state: IUserState): number | null => state.totalBet,
);
