import React, { FunctionComponent } from 'react';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Board, Grid, RoadLabel } from 'widgets/Scoreboard/ScoreboardGrid';
import { BeadRoadChild } from './BeadRoadChild';
import { IScoreboardRoad } from '../types';

export const BeadRoadTablet: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [220, 8],
}) => (
  <Group size={{ width: 305, height: 116 }} position={position}>
    <g transform="translate(0, 116) rotate(-90)">
      <RoadLabel boardName={Board.BeadRoad} size={{ width: 114, height: 14 }} />
    </g>
    <Grid
      col={15}
      row={6}
      cellSize={19}
      childItemType={BeadRoadChild}
      childItemSize={{ width: 19, height: 19 }}
      roadData={roadData}
    />
  </Group>
);
