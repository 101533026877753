import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { SettingPanels } from 'widgets/Settings/types';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { HistoryButtonResponsive } from './Responsive';
import AudioService from 'services/AudioService';

export const HistoryButton = () => {
  const dispatch = useDispatch();
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const { jackpotOn } = useSelector(authInfoSelector);

  const onOpenWinnersTable = useCallback(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }

    dispatch(manageSettingsPanelAction({ settingsPanel: SettingPanels.History }));
  }, [dispatch, gameSoundMute, soundEffects]);

  return (
    <>
      <HistoryButtonResponsive onOpenWinnersTable={onOpenWinnersTable} hasJackpot={jackpotOn} />
    </>
  );
};
