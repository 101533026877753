import styled, { css } from 'styled-components';
import { MobileButtonWrapper } from '../styles';
import { AspectRatioIphone, getAspectRatioIphone, isOldBrowserIOS } from 'hooks/getDefineDevEnv';

interface ITiltedMobileButtonWrapper {
  $offsetLeft: number | string;
  $offsetRight: number | string;
}

interface ITiltedRightMobileButtonStyled {
  $isActive?: boolean;
  $isDisabled?: boolean;
}

export const TiltedMobileButtonWrapper = styled(MobileButtonWrapper)<ITiltedMobileButtonWrapper>`
  clip-path: polygon(37% 0, 100% 0, 63% 100%, 0% 100%);
  ${({ $offsetLeft }) =>
    $offsetLeft === 'initial' ? 'left: initial;' : `left: ${$offsetLeft}rem;`};
  ${({ $offsetRight }) =>
    $offsetRight === 'initial' ? 'right: initial;' : `right: ${$offsetRight}rem;`};
`;

export const TiltedTabletButtonWrapper = styled(MobileButtonWrapper)<ITiltedMobileButtonWrapper>`
  clip-path: polygon(36% 0, 100% 0, 64% 100%, 0% 100%);
  ${({ $offsetLeft }) =>
    $offsetLeft === 'initial' ? 'left: initial;' : `left: ${$offsetLeft}rem;`}
  ${({ $offsetRight }) =>
    $offsetRight === 'initial' ? 'right: initial;' : `right: ${$offsetRight}rem;`}
`;

export const TiltedRightMobileButtonStyled = styled.svg<ITiltedRightMobileButtonStyled>`
  height: 28rem;
  width: 64rem;

  /* transform: scale(0.9); */
  transform: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9 ? 'scale(0.9)' : 'none'};

  & > g {
    ${({ $isActive, $isDisabled }) => {
      if ($isActive)
        return css`
          cursor: pointer;

          & #trmb_path_inner_border {
            stroke: url(#paint1_linear_67_36713);
          }
          & #trmb_path_outer_border {
            stroke: url(#paint2_linear_67_36713);
          }

          &:focus {
            & #trmb_path_background {
              fill: url(#paint0_linear_67_36669);
              stroke: url(#paint1_linear_67_36669);
            }
          }

          &:active {
            & #trmb_path_background {
              fill: url(#paint0_linear_67_36713);
              stroke: none;
            }
          }
        `;

      if (!$isDisabled)
        return css`
          cursor: pointer;

          &:focus {
            & #trmb_path_background {
              fill: url(#paint0_linear_67_36669);
              stroke: url(#paint1_linear_67_36669);
            }
            & #trmb_path_inner_border {
              stroke: none;
            }
            & #trmb_path_outer_border {
              stroke: none;
            }
          }

          &:active {
            & #trmb_path_background {
              fill: url(#paint0_linear_67_36713);
              stroke: none;
            }
            & #trmb_path_inner_border {
              stroke: url(#paint1_linear_67_36713);
            }
            & #trmb_path_outer_border {
              stroke: url(#paint2_linear_67_36713);
            }
          }
        `;
    }}
`;

export const TiltedRightTabletButtonStyled = styled.svg<ITiltedRightMobileButtonStyled>`
  height: 52rem;
  width: 132rem;

  & > g {
    ${({ $isActive, $isDisabled }) => {
      if ($isActive)
        return css`
          cursor: pointer;

          & #trtb_path_inner_border {
            stroke: url(#paint1_linear_64_31071);
          }
          & #trtb_path_outer_border {
            stroke: url(#paint2_linear_64_31071);
          }

          &:focus {
            & #trtb_path_background {
              fill: url(#paint0_linear_64_31023);
              stroke: url(#paint1_linear_64_31023);
            }
          }

          &:active {
            & #trtb_path_background {
              fill: url(#paint0_linear_64_31071);
              stroke: none;
            }
          }
        `;

      if (!$isDisabled)
        return css`
          cursor: pointer;

          &:focus {
            & #trtb_path_background {
              fill: url(#paint0_linear_64_31023);
              stroke: url(#paint1_linear_64_31023);
            }
            & #trtb_path_inner_border {
              stroke: none;
            }
            & #trtb_path_outer_border {
              stroke: none;
            }
          }

          &:active {
            & #trtb_path_background {
              fill: url(#paint0_linear_64_31071);
              stroke: none;
            }
            & #trtb_path_inner_border {
              stroke: url(#paint1_linear_64_31071);
            }
            & #trtb_path_outer_border {
              stroke: url(#paint2_linear_64_31071);
            }
          }
        `;
    }}
`;
