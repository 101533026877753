import { createAction } from 'typesafe-actions';
import { ACTIONS } from './types';

export const doubleBetAction = createAction(ACTIONS.DOUBLE_BET)();

export const doubleBetSuccessAction = createAction(ACTIONS.DOUBLE_BET_SUCCESS)();

export const clearDoubleBetAction = createAction(ACTIONS.CLEAR_DOUBLE_BET)();

export const confirmDoubleBetAction = createAction(ACTIONS.CONFIRM_DOUBLE_BET)();

export const errorDoubleBetAction = createAction(ACTIONS.ERROR_DOUBLE_BET)();

export const resetDoubleBetAction = createAction(ACTIONS.RESET_DOUBLE_BET)();
