import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IBaseSlotProps } from 'widgets/Slots/types';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { SlotRedResponsive } from './Responsive';

export const SlotRed = ({ bet, ...rest }: IBaseSlotProps) => {
  const hasPlacedBet = useMemo(() => bet !== 0, [bet]);
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);

  return (
    <SlotRedResponsive
      {...{ bet, ...rest }}
      hasPlacedBet={hasPlacedBet}
      isMinimized={isScoreboardOpen}
    />
  );
};
