import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { transformToCurrency } from 'utils/transformToCurrency';
import transformToShortNumber from 'utils/transformToShortNumber';
import { tableInfoSelector } from 'core/widgets/TableSettings';
import { totalBetSelector } from 'core/widgets/User';
import { TableInfoResponsive } from './Responsive';
import { ITableInfoItem } from './types';
import { authInfoSelector } from 'core/widgets/Auth/selectors';

export const TableInfo = () => {
  const totalBet = useSelector(totalBetSelector);
  const tableInfo = useSelector(tableInfoSelector);
  const { currency } = useSelector(authInfoSelector);
  const { t } = useTranslation();

  const betLimitsShort: string = useMemo(() => {
    if (tableInfo?.betLimit === undefined) {
      return '';
    }

    const { min: minBetLimit, max: maxBetLimit } = tableInfo.betLimit;
    const transformedMinBetLimit = transformToShortNumber(minBetLimit);
    const transformedMaxBetLimit = transformToShortNumber(maxBetLimit);

    return `${transformedMinBetLimit}-${transformedMaxBetLimit}`;
  }, [tableInfo?.betLimit]);

  if (tableInfo === null) {
    return null;
  }

  const betsInfo: ITableInfoItem[] = [
    {
      label: t('table.totalBetLabel'),
      value: transformToCurrency({ value: totalBet || 0, currency }),
    },
    { label: t('table.betLimitLabel'), value: `${currency} ${betLimitsShort}` },
  ];

  const gameInfo: ITableInfoItem[] = [
    { label: t('table.gameIdLabel'), value: tableInfo?.gameId || '-' },
    { label: t('table.tableIdLabel'), value: tableInfo?.tableId || '-' },
    { label: t('table.dealerLabel'), value: tableInfo?.dealer?.nickname || '-' },
  ];

  return <TableInfoResponsive betsInfo={betsInfo} gameInfo={gameInfo} />;
};
