import { put, delay, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { setCurrentJackpot, setNextJackpot } from 'core/widgets/Jackpot/actions';
import { OnJackpotCreatedData } from '../../types';
import { ACTIONS, IJackpot, PositionJackpot } from 'core/widgets/Jackpot/types';
import { Notifications } from 'types';
import { nextJackpotSelector } from 'core/widgets/Jackpot/selectors';

export function* onJackpotCreatedSaga({
  type: notificationType,
  data: result,
}: OnJackpotCreatedData) {
  try {
    if (notificationType === Notifications.JACKPOT_CREATED) {
      const { tableId, ...jackpot } = result;
      const isCurrent = jackpot.position === PositionJackpot.CURRENT;
      const nextJackpot: IJackpot = yield select(nextJackpotSelector);

      if (isCurrent) {
        yield put(setCurrentJackpot(jackpot));
        yield delay(3000);

        yield put({ type: ACTIONS.WATCH_DATE_CONTROL_INTERVAL });
        return;
      }
      if (!isCurrent && nextJackpot) {
        return;
      }

      if (!isCurrent && !nextJackpot) {
        yield put(setNextJackpot(jackpot));
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
