import styled, { css } from 'styled-components';

export const ButtonStyled = styled.button<{ $fullWidth?: boolean }>`
  /* max-width: 172px; */
  padding: 8px 48px;
  border-width: 1px;
  border-image-source: linear-gradient(180deg, #f1e6d2 0%, #d4bb93 100%);
  border-image-slice: 1;
  font-family: inherit;
  color: inherit;
  background: linear-gradient(348.35deg, #121011 8.54%, #343434 116.17%);
  cursor: pointer;

  &:hover {
    background: linear-gradient(180deg, rgba(52, 52, 52, 0) 0%, #bb9370 100%);
  }

  &:active {
    background: linear-gradient(180deg, #1b191a 0%, #bb9370 100%);
  }

  &:disabled {
    border-width: 1px;
    border-style: solid;
    color: #434343;
    background: linear-gradient(360deg, #1b191a 0%, #343434 100%);

    ${({ theme: { text, border } }) => css`
      border-color: ${border.primaryDisabled};
      color: ${text.primaryDisabled};
    `}
  }

  ${(props) => {
    if (!props.className) {
      return css`
        font-size: 14px;
        line-height: 18px;
      `;
    }
  }}

  ${({ $fullWidth }) => css`
    max-width: ${$fullWidth ? '100%' : '172px'};
    width: ${$fullWidth ? '100%' : 'max-content'};
    padding: ${$fullWidth ? '10rem 8rem' : '10rem 48rem'};
  `};
`;
