import React from 'react';
import { Icon, IconNames } from 'components';
import { ScoreboardButtonLandscapeStyled } from './styles';
import { IScoreboardButtonProps } from './types';

export const ScoreboardButtonLandscape = ({
  isActive,
  onScoreboardButtonClick,
}: IScoreboardButtonProps) => (
  <ScoreboardButtonLandscapeStyled
    $isActive={isActive}
    width="136"
    height="52"
    viewBox="0 0 136 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g onClick={onScoreboardButtonClick}>
      <path
        id="scoreboard_path_background"
        d="M1.18256 51.5L50.7114 0.5H134.809L84.79 51.5H1.18256Z"
        fill="url(#paint0_linear_1904_6269)"
        stroke="url(#paint1_linear_1904_6269)"
      />
      <svg x="58" y="16">
        <Icon name={IconNames.Scoreboard} />
      </svg>
    </g>

    <defs>
      {/* default */}
      <linearGradient
        id="paint0_linear_1904_6269"
        x1="68"
        y1="52"
        x2="68"
        y2="0"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" />
        <stop offset="1" stopColor="#343434" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1904_6269"
        x1="0"
        y1="26"
        x2="136"
        y2="26"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" stopOpacity="0" />
        <stop offset="0.5" stopColor="#434343" />
        <stop offset="1" stopColor="#343434" stopOpacity="0" />
      </linearGradient>

      {/* on hover */}
      <linearGradient
        id="paint0_linear_1904_29754"
        x1="68"
        y1="0"
        x2="68"
        y2="52"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#343434" stopOpacity="0" />
        <stop offset="1" stopColor="#BB9370" />
      </linearGradient>

      {/* active */}
      <linearGradient
        id="paint0_linear_1904_6290"
        x1="68"
        y1="0"
        x2="68"
        y2="52"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#1B191A" />
        <stop offset="1" stopColor="#BB9370" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1904_6290"
        x1="0"
        y1="26"
        x2="136"
        y2="26"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F1E6D2" stopOpacity="0" />
        <stop offset="0.5" stopColor="#F1E6D2" />
        <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
      </linearGradient>
    </defs>
  </ScoreboardButtonLandscapeStyled>
);
