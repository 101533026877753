import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IRoundWinnersState } from './types';

const roundWinnersDataSelector = (state: IState) => state.roundWinnersReducer;

export const roundWinnersSelector = createSelector(
  roundWinnersDataSelector,
  (state: IRoundWinnersState) => state.winners,
);
