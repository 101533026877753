import React, { ComponentType } from 'react';
import { CockroachPigLine } from 'widgets/Scoreboard/BaseComponents';
import { WinnerBall } from 'constants/common';
import { IRoadItem } from '../types';

export interface ICockroachPigItem {
  children: IRoadItem;
}

export const CockroachPigChild: ComponentType<ICockroachPigItem> = ({
  children: { outcome },
}: ICockroachPigItem) =>
  outcome !== WinnerBall.GOLD ? <CockroachPigLine outcome={outcome} /> : null;
