import { setBalanceAction } from 'core/widgets/User/actions';
import { put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { OnPlayerUpdatedData } from '../types';

export function* onPlayerUpdatedSaga({
  type: notificationType,
  data: result,
}: OnPlayerUpdatedData) {
  if (notificationType === Notifications.PLAYER_UPDATED) {
    const {
      user: { balance },
    } = result;
    yield put(setBalanceAction(balance));
  }
}
