import { put } from 'redux-saga/effects';

import { Notifications, NotificationsChat } from 'types';
import { setIsBlockedChat } from 'core/widgets/ChatPanel/actions';
import { OnUserChatUnblockedData } from '../../types';

export function* onUserChatUnblockedSaga({ type: notificationType }: OnUserChatUnblockedData) {
  if (notificationType === NotificationsChat.USER_CHAT_UNBLOCKED) {
    yield put(setIsBlockedChat(false));
  }
}
