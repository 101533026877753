import React, { FunctionComponent } from 'react';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Board, Grid, RoadLabel } from 'widgets/Scoreboard/ScoreboardGrid';
import { BeadRoadChild } from './BeadRoadChild';
import { IScoreboardRoad } from '../types';

export const BeadRoadLandscape: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [223, 6],
}) => (
  <Group size={{ width: 222, height: 102 }} position={position}>
    <g transform="translate(0, 102) rotate(-90)">
      <RoadLabel boardName={Board.BeadRoad} />
    </g>
    <Grid col={12} row={6} cellSize={17} childItemType={BeadRoadChild} roadData={roadData} />
  </Group>
);
