import React from 'react';
import { Device } from 'types';
import { BallsCounter } from './BallsCounter/Responsive';
import { BeadRoad } from './BeadRoad/Responsive';
import { BigRoad } from './BigRoad/Responsive';
import { CockroachPigRoad } from './CockroachPigRoad/Responsive';
import { PredictionsSection } from './PredictionsSection';
import { SmallRoad } from './SmallRoad/Responsive';
import { ScoreboardContent, ScoreboardWrapper } from './styles';
import { IDeviceBaseScoreboardProps } from './types';

export const BaseScoreboard = ({ deviceType = Device.Desktop }: IDeviceBaseScoreboardProps) => {
  const scoreboardViewbox: Record<Device, string> = {
    [Device.Desktop]: '0 0 1280 112',
    [Device.Tablet_Landscape]: '0 0 1280 112',
    [Device.Tablet_Portrait]: '0 0 768 130',
    [Device.Mobile]: '0 0 360 140',
  };

  return (
    <ScoreboardWrapper>
      <ScoreboardContent viewBox={scoreboardViewbox[deviceType]}>
        <BallsCounter />
        <PredictionsSection />
        <BeadRoad />
        <BigRoad />
        <SmallRoad />
        <CockroachPigRoad />
      </ScoreboardContent>
    </ScoreboardWrapper>
  );
};
