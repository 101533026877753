import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { IGeneralSettingsPanelComponentProps } from './types';
import { GeneralSettingsPanelComponent } from './GeneralSettingsPanel.base';

export const GeneralSettingsPanelResponsive = (props: IGeneralSettingsPanelComponentProps) => (
  <>
    <DesktopComponent>
      <GeneralSettingsPanelComponent {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <GeneralSettingsPanelComponent {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <GeneralSettingsPanelComponent {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <GeneralSettingsPanelComponent {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
