import { SettingPanels } from 'widgets/Settings/types';

export enum ACTIONS {
  ADDED_MESSAGE_STORE = 'ADDED_MESSAGE_STORE',
  SEND_NEW_MESSAGE = 'SEND_NEW_MESSAGE',
  SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
  ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE',
  SET_IS_BLOCKED_CHAT = 'SET_IS_BLOCKED_CHAT',
  SET_IS_SEND_BUTTON_BLOCKED = 'SET_IS_SEND_BUTTON_BLOCKED',
}

export interface IChatMessage {
  message: string;
  playerName: string;
  fromSupport: boolean;
}

export interface IChatPanelState {
  messages: IChatMessage[];
  messageError: any | null;
  newMessage: string | null;
  isBlockedChat: boolean;
  isSendButtonBlocked: boolean;
  lastSentMessages: any | null;
}
