import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Device } from 'types';
import { Icon, IconNames, Loader, PopupContainer } from 'components';
import SimpleBar from 'simplebar-react';

import { IPopupConfirmProps } from './types';
import { HeadTable, Box, ItemValue, HeadRow, HistoryWrapper } from './stylesTable';

import 'simplebar-react/dist/simplebar.min.css';
import { historySelector } from 'core/widgets/History';

import { authInfoSelector } from 'core/widgets/Auth/selectors';
import {
  Wrapper,
  CloseButton,
  Head,
  RulesContainer,
  LoaderWrapper,
  MobileHistoryWrapper,
  EmptyHistory,
} from './styles';

export const BasePopupHistoryPortrait = forwardRef(
  ({ children, onClose, loading, deviceType = Device.Desktop }: IPopupConfirmProps, ref) => {
    const { t } = useTranslation();
    const history = useSelector(historySelector);
    const { jackpotOn } = useSelector(authInfoSelector);

    const [h, setH] = useState(400);
    useEffect(() => {
      // @ts-ignore
      const height = ref?.current?.getBoundingClientRect().height;
      setH(height);
    }, [ref]);

    return (
      <PopupContainer deviceType={deviceType}>
        <Wrapper $deviceType={deviceType} ref={ref as any}>
          <HistoryWrapper />
          <Head $deviceType={deviceType}>{t('history.title')}</Head>
          <CloseButton onClick={onClose}>
            <Icon name={IconNames.Cross} />
          </CloseButton>
          {Boolean(loading) && (
            <LoaderWrapper $deviceType={deviceType}>
              <Loader />
            </LoaderWrapper>
          )}
          <MobileHistoryWrapper $deviceType={deviceType}>
            {!loading && (
              <HeadTable $deviceType={deviceType}>
                <HeadRow $deviceType={deviceType}>
                  <Box $width={deviceType === Device.Mobile ? 39.5 : jackpotOn ? 30 : 34}>
                    <ItemValue>{t('history.info')}</ItemValue>
                  </Box>
                  <Box $width={deviceType === Device.Mobile ? 26 : jackpotOn ? 28 : 30}>
                    <ItemValue>{t('history.bet')}</ItemValue>
                  </Box>
                  <Box $width={deviceType === Device.Mobile ? 24.5 : jackpotOn ? 30 : 34}>
                    <ItemValue>{t('history.winLoss')}</ItemValue>
                  </Box>
                  {deviceType !== Device.Mobile && jackpotOn && (
                    <Box $width={12}>
                      <ItemValue>{t('history.jackpot')}</ItemValue>
                    </Box>
                  )}
                </HeadRow>
              </HeadTable>
            )}

            {!loading && (
              <RulesContainer $deviceType={deviceType}>
                <SimpleBar
                  style={{
                    height: `${h - (deviceType === Device.Mobile ? 70 : 120)}px`,
                  }}
                  forceVisible="y"
                  autoHide={false}>
                  {history?.length && <>{children}</>}
                  {history?.length === 0 && (
                    <EmptyHistory $deviceType={deviceType}>
                      {t('history.noBettingHistory')}
                    </EmptyHistory>
                  )}
                </SimpleBar>
              </RulesContainer>
            )}
          </MobileHistoryWrapper>
        </Wrapper>
      </PopupContainer>
    );
  },
);
