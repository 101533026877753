import React from 'react';
import { useSelector } from 'react-redux';
import { Chips } from 'widgets/Chips';
import { TableInfo } from 'widgets/TableInfo';
import { WalletBalance } from 'widgets/WalletBalance';
import { CloseGameButton } from 'widgets/CloseGameButton';
import { Scoreboard } from 'widgets/Scoreboard';
import { ScoreboardButton } from 'widgets/ScoreboardToggleButton';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { InnerWrapper, Wrapper } from './styles';

export const GamePanelLandscape = () => {
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);

  return (
    <Wrapper>
      <InnerWrapper>
        <WalletBalance />
        <Chips />
        <ScoreboardButton />
        <CloseGameButton />
      </InnerWrapper>
      {isScoreboardOpen && <Scoreboard />}
      <TableInfo />
    </Wrapper>
  );
};
