import React, { ReactNode } from 'react';
import { Group } from './Group';
import { Rect } from './Rect';

export interface ICell {
  col: number;
  row: number;
  size: number;
  children?: ReactNode;
}

export const Cell = ({ col = 1, row = 1, size, children }: ICell) => (
  <Group position={[col * size, row * size]}>
    <Rect size={{ width: size, height: size }} />
    {children}
  </Group>
);
