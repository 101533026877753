import React, { FunctionComponent } from 'react';
import { Group } from 'widgets/Scoreboard/BaseComponents';
import { Board, Grid, RoadLabel } from 'widgets/Scoreboard/ScoreboardGrid';
import { IScoreboardRoad } from '../types';
import { CockroachPigChild } from './CockroachPigChild';

export const CockroachPigLandscape: FunctionComponent<IScoreboardRoad> = ({
  roadData,
  position = [1053, 6],
}) => (
  <Group size={{ width: 202, height: 102 }} position={position}>
    <g transform="translate(0, 102) rotate(-90)">
      <RoadLabel boardName={Board.CockroachPig} isMultiline />
    </g>
    <Grid
      col={10}
      row={6}
      cellSize={17}
      childItemType={CockroachPigChild}
      roadData={roadData}
      position={[32, 0]}
    />
  </Group>
);
