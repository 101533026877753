import { init, nth } from 'ramda';
import 'core-js/features/array/at';
import { IRoadItem, IRoadItems } from 'widgets/Scoreboard/types';
import { IColumn, IMatrix } from '../types';

export const removeLastPrediction = (roadData: IRoadItem[]): IRoadItem[] => {
  const lastChildItem = nth(-1, roadData);

  return lastChildItem && lastChildItem.isPrediction ? [...init(roadData)] : roadData;
};

export const removePredictions = (roadData: IRoadItems = []): IRoadItems =>
  roadData.reduce(
    (accumulator: IRoadItems, item: IRoadItem | undefined, index: number) => {
      const result = [...accumulator];

      if (item && item.isPrediction) {
        result[index] = undefined;
      }

      return result;
    },
    [...roadData],
  );

export const removePredictionFromLastColumn = (currentMatrix: IMatrix): IMatrix => {
  const lastColumn: IColumn | undefined = nth(-1, currentMatrix);

  return lastColumn ? [...init(currentMatrix), removeLastPrediction(lastColumn)] : currentMatrix;
};
