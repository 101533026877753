import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import { Empty, WrapperWinnerTable } from './styles';
import { WinnerTable } from './components/WinnerTable';
import { IPlayerJP, IStageJackpot, IStagePlayersObjectJackpot } from 'core/widgets/Jackpot/types';
import { Device } from 'types';

interface IWinnersContent {
  deviceType: Device;
  hasNextJackpot: boolean;
  uuid: string;
  members?: IPlayerJP[];
  outOfStagePlayers: IPlayerJP[];
  stage: IStagePlayersObjectJackpot | null;
  activePlayers: IPlayerJP[];
  isCurrentJP: boolean;
}

export const WinnersContent = ({
  deviceType,
  hasNextJackpot,
  uuid,
  members,
  outOfStagePlayers,
  stage,
  activePlayers,
  isCurrentJP,
}: IWinnersContent) => {
  const { t, i18n } = useTranslation();
  const { height: windowHeight, width: windowWidth } = useWindowSize();
  const isAspectRatioFit = useMemo(
    () => windowHeight > (windowWidth * 9) / 16,
    [windowHeight, windowWidth],
  );
  return (
    <WrapperWinnerTable
      $deviceType={deviceType}
      $isSmallSize={hasNextJackpot || i18n.language === 'tl' || i18n.language === 'pt'}
      $isAspectRatioFit={isAspectRatioFit}>
      {members?.length ? (
        <WinnerTable
          uuid={uuid}
          members={members}
          stage={stage}
          outOfStagePlayers={outOfStagePlayers}
          activePlayers={activePlayers}
          isCurrentJP={isCurrentJP}
        />
      ) : (
        <Empty $deviceType={deviceType}>{t('settings.jackpot.no_players')}</Empty>
      )}
    </WrapperWinnerTable>
  );
};
