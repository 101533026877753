import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import audioSource from 'assets/sounds/soundFX/02_Choose_Bet.wav';

import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { toggleScoreboardAction } from 'core/widgets/Scoreboard/actions';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { ScoreboardButtonResponsive } from './Responsive';
import AudioService from 'services/AudioService';

export const ScoreboardButton = () => {
  const dispatch = useDispatch();
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);
  const audioRef = useRef(null) as any;
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);

  const toggleScoreboard = useCallback(() => {
    dispatch(toggleScoreboardAction());

    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
  }, [dispatch, gameSoundMute, soundEffects]);

  return (
    <>
      <ScoreboardButtonResponsive
        isActive={isScoreboardOpen}
        onScoreboardButtonClick={toggleScoreboard}
      />
    </>
  );
};
