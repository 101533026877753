import styled, { css } from 'styled-components';

export const Wrapper = styled.svg<{ $showValue: boolean }>`
  ${({ $showValue }) => {
    if (!$showValue) {
      return css`
        & #chip_value {
          display: none;
        }
      `;
    }
  }}
`;
