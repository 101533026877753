import React, { forwardRef } from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BasePopupGameRules } from './BasePopupGameRules';

import { IPopupConfirmProps } from './types';

export const PopupGameRules = forwardRef((props: IPopupConfirmProps, ref) => (
  <>
    <DesktopComponent>
      <BasePopupGameRules {...props} deviceType={Device.Desktop} ref={ref} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BasePopupGameRules {...props} deviceType={Device.Tablet_Landscape} ref={ref} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BasePopupGameRules {...props} deviceType={Device.Tablet_Portrait} ref={ref} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BasePopupGameRules {...props} deviceType={Device.Mobile} ref={ref} />
    </MobileComponent>
  </>
));
