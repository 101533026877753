import React, { ComponentType } from 'react';
import { range, slice } from 'ramda';
import { IBeadRoadItem } from 'widgets/Scoreboard/BeadRoad/BeadRoadChild';
import { IBigRoadItem } from 'widgets/Scoreboard/BigRoad/BigRoadChild';
import { ISmallRoadItem } from 'widgets/Scoreboard/SmallRoad/SmallRoadChild';
import { ICockroachPigItem } from 'widgets/Scoreboard/CockroachPigRoad/CockroachPigChild';
import { ISvgSize, ISvgStartPosition } from 'types';
import { Cell, Group, Rect } from '../BaseComponents';
import { IRoadItems, RoadItemType } from '../types';
import { DEFAULT_BOARD_ROWS_COUNT } from './types';
import { CellItemWrapper } from './styles';

export interface IGrid {
  col: number;
  row: number;
  cellSize: number;
  roadData?: IRoadItems;
  position?: ISvgStartPosition;
  childItemType: ComponentType<IBeadRoadItem | IBigRoadItem | ISmallRoadItem | ICockroachPigItem>;
  childItemSize?: ISvgSize;
}

export const Grid = ({
  col,
  row = DEFAULT_BOARD_ROWS_COUNT,
  childItemType,
  childItemSize = { width: 17, height: 17 },
  cellSize = 17,
  roadData,
  position = [16, 0],
}: IGrid) => {
  const gridSize = {
    width: col * cellSize,
    height: row * cellSize,
  };
  const currentColumnsCount = roadData ? Math.ceil(roadData.length / row) : 0;
  const columnsDelta: number = currentColumnsCount - col;
  const fittedRoadData =
    columnsDelta > 0 && roadData ? slice(columnsDelta * row, roadData.length, roadData) : roadData;

  return (
    <Group size={gridSize} position={position}>
      <Rect size={gridSize} defaultGradient />
      {range(0, col).map((columnNumber: number) =>
        range(0, row).map((rowNumber: number) => {
          const CellItem = childItemType;
          const cellNumber: number = columnNumber * row + rowNumber;
          const cellItemChild: RoadItemType = fittedRoadData && fittedRoadData[cellNumber];

          return (
            <Cell key={cellNumber} col={columnNumber} row={rowNumber} size={cellSize}>
              {cellItemChild && (
                <CellItemWrapper
                  // need to re-trigger css animation
                  key={cellItemChild.outcome}
                  $isPrediction={cellItemChild.isPrediction}
                  width={childItemSize.width}
                  height={childItemSize.height}
                  viewBox="0 0 17 17">
                  <CellItem>{cellItemChild}</CellItem>
                </CellItemWrapper>
              )}
            </Cell>
          );
        }),
      )}
    </Group>
  );
};
