import React from 'react';
import { IDeviceTableInfoProps } from './types';
import {
  PortraitWrapper,
  PortraitContainer,
  ItemInfoTopPortrait,
  ItemInfoBottomPortrait,
  ItemInfoLabel,
  ItemInfoValue,
  ItemInfoSeparator,
} from './styles';

export const TableInfoPortrait = ({ betsInfo, gameInfo, deviceType }: IDeviceTableInfoProps) => (
  <PortraitWrapper $deviceType={deviceType}>
    <PortraitContainer>
      {betsInfo.map(({ label, value }, i) => (
        <React.Fragment key={i}>
          <ItemInfoTopPortrait>
            <ItemInfoLabel>{label}: </ItemInfoLabel>
            <ItemInfoValue>{value}</ItemInfoValue>
          </ItemInfoTopPortrait>
          {i !== betsInfo.length - 1 ? <ItemInfoSeparator /> : null}
        </React.Fragment>
      ))}
    </PortraitContainer>
    <PortraitContainer>
      {gameInfo.map(({ label, value }, i) => (
        <React.Fragment key={i}>
          <ItemInfoBottomPortrait>
            <ItemInfoLabel>{label}: </ItemInfoLabel>
            <ItemInfoValue>{value}</ItemInfoValue>
          </ItemInfoBottomPortrait>
          {i !== gameInfo.length - 1 ? <ItemInfoSeparator /> : null}
        </React.Fragment>
      ))}
    </PortraitContainer>
  </PortraitWrapper>
);
