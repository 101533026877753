import React from 'react';
import { ISlotProps } from 'widgets/Slots/types';
import { SlotGoldMaximizedMobile, SlotGoldMinimizedMobile } from './components';

export const SlotGoldMobile = (props: ISlotProps) => {
  const { isMinimized } = props;
  return isMinimized ? (
    <SlotGoldMinimizedMobile {...props} />
  ) : (
    <SlotGoldMaximizedMobile {...props} />
  );
};
