import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { Device } from 'types';
import { Button, Icon, IconNames, SettingsPanel } from 'components';
import { ButtonText, ButtonWrapper, EmptyJackpotItemTitle, Wrapper } from '../../styles';
import { IEmptyJackpotPanelComponentProps } from './types';

export const EmptyJackpotSettingsPanelComponent = ({
  deviceType = Device.Desktop,
  onCloseClick,
  onClick,
}: IEmptyJackpotPanelComponentProps) => {
  const { t, i18n } = useTranslation();

  const ref = useRef(null) as any;

  return (
    <Wrapper ref={ref}>
      <SettingsPanel onCloseClick={onCloseClick} deviceType={deviceType} hasVersionInfo={false}>
        <SettingsPanel.Title>{t('settings.jackpot.title')}</SettingsPanel.Title>
        <SettingsPanel.Item>
          <EmptyJackpotItemTitle $deviceType={deviceType}>
            {t('settings.jackpot.empty_jp')}
          </EmptyJackpotItemTitle>
        </SettingsPanel.Item>

        <ButtonWrapper>
          <Button onClick={onClick} fullWidth>
            <ButtonText $isSmallText={i18n.language === 'tl'}>
              <Icon name={IconNames.GameRule2} />
              {t('settings.jackpot.rules')}
            </ButtonText>
          </Button>
        </ButtonWrapper>
      </SettingsPanel>
    </Wrapper>
  );
};
