import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { IVideoStreamProps } from './types';
import { BaseVideoStream } from './VideoStream.base';

export const VideoStreamResponsive = (props: IVideoStreamProps) => (
  <>
    <DesktopComponent>
      <BaseVideoStream {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseVideoStream {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseVideoStream {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BaseVideoStream {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
