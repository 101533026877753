import { delay, put, select } from 'redux-saga/effects';
import { Notifications } from 'types';
import { setRoundWinnersDataAction } from 'core/widgets/WinnersTicker/actions';
import {
  currentJackpotSelector,
  jackpotActivitySelector,
  jackpotInfoWinningDrawSelector,
} from 'core/widgets/Jackpot/selectors';
import { OnDisplayWinnersData } from '../types';
import { displayingWinBallSelector } from 'core/widgets/TableSettings';

export function* onDisplayWinnersSaga({ type: notificationType, data }: OnDisplayWinnersData) {
  if (notificationType === Notifications.DISPLAY_WINNERS) {
    const { winners } = data;
    const { stage } = yield select(jackpotActivitySelector);
    const { winDuration } = yield select(jackpotInfoWinningDrawSelector);
    const displayingWinBall: number = yield select(displayingWinBallSelector);

    if (stage && winDuration) {
      yield delay(Number((winDuration as number) + displayingWinBall + 1) * 1000 || 5000);

      yield put(setRoundWinnersDataAction({ winners }));
      return;
    }

    yield put(setRoundWinnersDataAction({ winners }));
  }
}
