import { put, select } from 'redux-saga/effects';

import { changeRoundStateAction } from 'core/widgets/RoundStates/actions';
import { Notifications } from 'types';
import { addToastAction } from 'core/widgets/Toast/actions';
import { saveBettingTimeToStoreAction } from 'core/widgets/BettingTime/actions';
import { roundStatusMessages } from 'constants/toastMessages';
import { tableIdSelector } from 'core/widgets/TableSettings';
import { RoundStates } from '../../RoundStates/types';
import { OnBettingTimeStartedData } from '../../BettingTime/types';
import { authInfoSelector } from 'core/widgets/Auth/selectors';

export function* onBettingTimeStartedSaga({
  type: notificationType,
  data: result,
}: OnBettingTimeStartedData) {
  if (notificationType === Notifications.BETTING_TIME_STARTED) {
    const { betsToken, finishBettingTime, bettingTime } = result;

    const tableId: string = yield select(tableIdSelector);
    const { operatorId } = yield select(authInfoSelector);

    localStorage.setItem(`${operatorId}_${tableId}_rest_auth_token`, betsToken);

    yield put(saveBettingTimeToStoreAction({ bettingTimeEnd: finishBettingTime, bettingTime }));
    yield put(changeRoundStateAction({ roundState: RoundStates.BettingTimeStarted }));
    yield put(addToastAction({ value: roundStatusMessages.BETTING_TIME_STARTED }));
  }
}
