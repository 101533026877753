import React from 'react';
import { MobileComponent, TabletPortraitComponent } from 'components';
import { HistoryButtonPortrait } from './HistoryButton.portrait';
import { IWinnersButtonProps } from './types';
import { isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const HistoryButtonResponsive = ({ hasJackpot, ...props }: IWinnersButtonProps) => (
  <>
    <TabletPortraitComponent>
      <HistoryButtonPortrait
        {...props}
        hasJackpot={hasJackpot}
        offsetRight={hasJackpot ? 243 : 151}
      />
    </TabletPortraitComponent>
    <MobileComponent>
      <HistoryButtonPortrait
        {...props}
        hasJackpot={hasJackpot}
        offsetRight={isOldBrowserIOS() ? (hasJackpot ? 104 : 63) : hasJackpot ? 112 : 68}
      />
    </MobileComponent>
  </>
);
