import { createAction } from 'typesafe-actions';
import { ACTIONS } from './types';

export const setBalanceAction = createAction(ACTIONS.SET_BALANCE)<number>();

export const setBalanceInBettingTimeAction = createAction(
  ACTIONS.SET_BALANCE_IN_BETTING_TIME,
)<number>();

export const updateBalanceAction = createAction(ACTIONS.UPDATE_BALANCE)<number>();

export const setTotalBetAction = createAction(ACTIONS.SET_TOTAL_BET)<number>();

export const updateTotalBetAction = createAction(ACTIONS.UPDATE_TOTAL_BET)<number>();
