import styled, { css } from 'styled-components';
import { MobileButtonWrapper } from '../styles';
import { AspectRatioIphone, getAspectRatioIphone, isOldBrowserIOS } from 'hooks/getDefineDevEnv';

export const MobileRightButtonWrapper = styled(MobileButtonWrapper)`
  right: 0;
  clip-path: polygon(55% 0, 100% 0, 100% 100%, 0% 100%);
`;

export const TabletRightButtonWrapper = styled(MobileButtonWrapper)`
  right: 0;
  clip-path: polygon(47% 0, 100% 0, 100% 100%, 0% 100%);
`;

export const MobileRightButtonStyled = styled.svg<{ $isDisabled?: boolean }>`
  height: 28rem;
  width: 44rem;
  /* transform: scale(0.9); */
  transform: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
    ? 'scale(0.9)'
    : 'none'};

  & > g {
    ${({ $isDisabled }) => {
      if (!$isDisabled)
        return css`
          cursor: pointer;

          &:focus {
            & #rmb_path_background {
              fill: url(#paint0_linear_67_36673);
              stroke: url(#paint1_linear_67_36673);
            }
            & #rmb_path_inner_border {
              stroke: none;
            }
            & #rmb_path_outer_border {
              stroke: none;
            }
          }

          &:active {
            & #rmb_path_background {
              fill: url(#paint0_linear_67_36707);
              stroke: none;
            }
            & #rmb_path_inner_border {
              stroke: url(#paint1_linear_67_36707);
            }
            & #rmb_path_outer_border {
              stroke: url(#paint2_linear_67_36707);
            }
          }
        `;
    }}
  }
`;

export const TabletRightButtonStyled = styled.svg<{ $isDisabled?: boolean }>`
  height: 52rem;
  width: 100rem;

  & > g {
    ${({ $isDisabled }) => {
      if (!$isDisabled)
        return css`
          cursor: pointer;

          &:focus {
            & #rtb_path_background {
              fill: url(#paint0_linear_64_31029);
              stroke: url(#paint1_linear_64_31029);
            }
            & #rtb_path_inner_border {
              stroke: none;
            }
            & #rtb_path_outer_border {
              stroke: none;
            }
          }

          &:active {
            & #rtb_path_background {
              fill: url(#paint0_linear_64_31067);
              stroke: none;
            }
            & #rtb_path_inner_border {
              stroke: url(#paint1_linear_64_31067);
            }
            & #rtb_path_outer_border {
              stroke: url(#paint2_linear_64_31067);
            }
          }
        `;
    }}
`;
