import React from 'react';
import { MobileRightButtonWrapper, MobileRightButtonStyled } from './styles';
import { IMobileButtonProps } from '../types';
import { IconWrapper } from '../styles';

export const RightSideMobileButton = ({ children, isDisabled, onClick }: IMobileButtonProps) => (
  <MobileRightButtonWrapper>
    <MobileRightButtonStyled
      $isDisabled={isDisabled}
      width="44"
      height="28"
      viewBox="0 0 44 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g onClick={onClick}>
        <path
          id="rmb_path_background"
          d="M1.08711 27.5L24.23 0.5H43.5V27.5H1.08711Z"
          fill="url(#paint0_linear_67_36673)"
          stroke="url(#paint1_linear_67_36673)"
        />

        <path
          id="rmb_path_inner_border"
          d="M1.08711 27.5L24.23 0.5H43.5V27.5H1.08711Z"
          stroke="none"
        />
        <path
          id="rmb_path_outer_border"
          d="M1.08711 27.5L24.23 0.5H43.5V27.5H1.08711Z"
          stroke="none"
        />
        <IconWrapper
          x="24"
          y="8"
          width="12"
          height="12"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          {children}
        </IconWrapper>
      </g>

      <defs>
        <linearGradient
          id="paint0_linear_67_36673"
          x1="22"
          y1="28"
          x2="22"
          y2="0"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_67_36673"
          x1="-1"
          y1="14"
          x2="44"
          y2="14"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#343434" stopOpacity="0" />
          <stop offset="0.5" stopColor="#434343" />
          <stop offset="1" stopColor="#343434" stopOpacity="0" />
        </linearGradient>

        <linearGradient
          id="paint0_linear_67_36707"
          x1="22"
          y1="0"
          x2="22"
          y2="28"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#1B191A" />
          <stop offset="1" stopColor="#BB9370" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_67_36707"
          x1="22"
          y1="0"
          x2="22"
          y2="28"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF7EB" stopOpacity="0.4" />
          <stop offset="1" stopColor="#FFF7EB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_67_36707"
          x1="44"
          y1="14"
          x2="0"
          y2="14"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4BB93" stopOpacity="0" />
          <stop offset="0.5" stopColor="#D4BB93" />
          <stop offset="1" stopColor="#D4BB93" stopOpacity="0" />
        </linearGradient>
      </defs>
    </MobileRightButtonStyled>
  </MobileRightButtonWrapper>
);
