import { SLOTS } from 'types';

export enum WinnerBall {
  BLUE = 'BLUE',
  GOLD = 'GOLD',
  RED = 'RED',
}

type WinningAmountType = { [key in SLOTS]: number };

export const WINNING_AMOUNT: WinningAmountType = {
  [SLOTS.banker]: 1.1,
  [SLOTS.player]: 1.1,
  [SLOTS.tie]: 10,
};
