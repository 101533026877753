import { colors } from 'constants/theme';
import styled, { css } from 'styled-components';

export const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12rem;
  line-height: 16rem;
  text-transform: uppercase;
  cursor: pointer;
`;

export const InputRadioHidden = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

export const InputLabel = styled.div<{ $isDisabled: boolean }>`
  ${({ $isDisabled, theme: { text } }) => {
    if ($isDisabled) {
      return css`
        color: ${text.primaryDisabled};

        p {
          color: ${text.primaryDisabled};
        }
      `;
    }
  }};
`;

export const InputRadioSubtitle = styled.p`
  color: ${colors.Subtitle};
  font-size: 9rem;
  margin-left: 12rem;
`;

export const InputRadioCustom = styled.div<{ $isChecked: boolean; $isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16rem;
  width: 16rem;
  border-width: 1rem;
  border-style: solid;
  border-radius: 50%;
  background: linear-gradient(360deg, #121011 0%, #343434 100%);

  ${({ theme: { text } }) => css`
    border-color: ${text.secondaryDefault};
  `}

  ${({ $isDisabled, theme: { text } }) => {
    if ($isDisabled) {
      return css`
        border-color: ${text.primaryDisabled};
      `;
    }
  }};

  ${({ $isChecked, theme: { text } }) => {
    if ($isChecked) {
      return css`
        &:before {
          content: '';
          display: block;
          height: 8rem;
          width: 8rem;
          border-radius: 50%;
          background: ${text.primaryDefault};
        }
      `;
    }
  }}
`;

export const InputRadioLabel = styled.span`
  display: inline-block;
  margin-left: 12rem;
  user-select: none;
`;
