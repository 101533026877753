import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';

export const CHIPS_MIN_1k: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 1e3,
  },
  {
    color: CHIPS_COLORS.YELLOW,
    value: 2e3,
  },
  {
    color: CHIPS_COLORS.RED,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.VIOLET,
    value: 10e3,
  },
  {
    color: CHIPS_COLORS.GREEN,
    value: 20e3,
  },
  {
    color: CHIPS_COLORS.ORANGE,
    value: 50e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 100e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 200e3,
  },
  {
    color: CHIPS_COLORS.PURPLE,
    value: 500e3,
  },
];
