import { ActionType, createReducer } from 'typesafe-actions';
import { setHistory } from './actions';
import { IHistory } from './types';

export const defaultHistory: IHistory = {
  history: null,
};

const actions = {
  setHistory,
};

export const historyReducer = createReducer<IHistory, ActionType<typeof actions>>(
  defaultHistory,
).handleAction(setHistory, (state, { payload: { history } }) => ({
  ...state,
  history,
}));
