import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { background } }) => css`
    width: 100%;

    & .rc-slider {
      height: 8rem;
      border-radius: 0;
      padding: 5rem 0;
    }

    & .rc-slider-rail {
      height: 100%;
      border-radius: 0;
      background-color: ${background.disabled};
      clip-path: polygon(
        0% 50%,
        4px 0%,
        calc(100% - 4px) 0%,
        100% 50%,
        calc(100% - 4px) 100%,
        4px 100%
      );
    }

    & .rc-slider-track {
      height: 100%;
      border-radius: 0;
      background-color: ${background.accent};
      clip-path: polygon(0% 50%, 4px 0%, 100% 0%, 100% 100%, 4px 100%);
    }

    & .rc-slider-handle {
      height: auto;
      width: auto;
      margin-top: -2rem;
      border: none;
      border-radius: 0;
      background: unset;

      &:active {
        box-shadow: none;
      }
    }
  `}
`;

export const SliderHandle = styled.div`
  height: 12rem;
  width: 12rem;
  margin-top: 2rem;
  background: linear-gradient(180deg, #fff7eb 0%, #d4bb93 100%);
  box-shadow:
    inset 0rem -1rem 0rem #d4bb93,
    0rem 2rem 0rem rgba(18, 16, 17, 0.4);
  transform: rotate(45deg);
`;
