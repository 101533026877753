import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputRadioGroup, SettingsPanel, SwitchToggle } from 'components';
import { Device } from 'types';
import { SwitchWrapper, Wrapper } from './styles';
import { IVideoSettingsPanelComponentProps } from './types';

export const VideoSettingsPanelComponent = ({
  playback,
  autoAdjust,
  quality,
  qualityOptions,
  onPlaybackChange,
  onAutoAdjustChange,
  onQualityChange,
  onCloseClick,
  deviceType = Device.Desktop,
  isQualityLoading,
}: IVideoSettingsPanelComponentProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SettingsPanel onCloseClick={onCloseClick} deviceType={deviceType}>
        <SettingsPanel.Title>{t('settings.video.title')}</SettingsPanel.Title>
        <SettingsPanel.Item>
          <SwitchWrapper>
            <SettingsPanel.ItemTitle>
              {t('settings.video.playbackItemTitle')}
            </SettingsPanel.ItemTitle>
            <SwitchToggle isChecked={playback} onClick={() => onPlaybackChange(!playback)} />
          </SwitchWrapper>
        </SettingsPanel.Item>
        <SettingsPanel.Item>
          <SwitchWrapper>
            <SettingsPanel.ItemTitle>
              {t('settings.video.autoAdjustItemTitle')}
            </SettingsPanel.ItemTitle>
            <SwitchToggle isChecked={autoAdjust} onClick={() => onAutoAdjustChange(!autoAdjust)} />
          </SwitchWrapper>
        </SettingsPanel.Item>
        <SettingsPanel.Item>
          <InputRadioGroup
            value={quality}
            options={qualityOptions}
            isLoading={isQualityLoading}
            onChange={onQualityChange}
          />
        </SettingsPanel.Item>
      </SettingsPanel>
    </Wrapper>
  );
};
