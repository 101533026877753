import React from 'react';
import { Device } from 'types';
import { IconNames } from 'components';
import { BalanceCount, WalletIcon, Wrapper } from './styles';
import { IDeviceWalletBalanceProps } from './types';

export const BaseWalletBalance = ({
  balance,
  deviceType = Device.Desktop,
}: IDeviceWalletBalanceProps) => (
  <Wrapper $deviceType={deviceType}>
    <WalletIcon name={IconNames.Wallet} />
    <BalanceCount>{balance}</BalanceCount>
  </Wrapper>
);
