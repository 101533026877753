import { put, select } from 'redux-saga/effects';

import { Notifications } from 'types';
import {
  setCountdownJackpot,
  setInfoDisplayingJackpotWin,
  setInfoJackpotDraw,
  setStageJackpot,
} from 'core/widgets/Jackpot/actions';
import { currentJackpotSelector } from 'core/widgets/Jackpot/selectors';
import { IPlayer, IPlayerIdStage } from 'core/widgets/Jackpot/types';
import { OnJackpotStageStartedData } from '../../types';

// eslint-disable-next-line require-yield
export function* onJackpotStageStartedSaga({
  type: notificationType,
  data: result,
}: OnJackpotStageStartedData) {
  const { players, stageNumber } = result;
  if (notificationType === Notifications.JACKPOT_STAGE_STARTED) {
    const { currentJackpot } = yield select(currentJackpotSelector);

    // @TODO remove? setInfoJackpotDraw, setInfoDisplayingJackpotWin
    yield put(
      setInfoJackpotDraw({
        jackpotAmount: currentJackpot?.sum,
        jackpotCurrency: currentJackpot?.currency,
      }),
    );
    yield put(
      setInfoDisplayingJackpotWin({
        winDuration: currentJackpot?.winDuration,
      }),
    );

    const playersObject = players?.length
      ? players.reduce(
          (acc, player) => ({
            ...acc,
            [player.uuid]: player,
          }),
          {} as { [key: string]: IPlayerIdStage },
        )
      : null;

    if (playersObject) {
      yield put(setStageJackpot({ playersObject, stageNumber }));
    } else {
      yield put(setStageJackpot({ playersObject: null, stageNumber }));
    }

    yield put(setCountdownJackpot(null));
  }
}
