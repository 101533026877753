import { EventChannel } from 'redux-saga';
import { call, cancelled, takeEvery, takeLatest } from 'redux-saga/effects';
import { ClientSideNotifications, Notifications, NotificationsChat } from 'types';
import {
  onPlayerConnectedSaga,
  onRoundStartedSaga,
  onDealerChangedSaga,
  onRoundCanceledSaga,
  onTableClosedSaga,
  onCloseTableNotificationSaga,
  onPlayerWonSaga,
  onPlayerLostSaga,
  onPlayerErrorSaga,
  onRoundFinishedSaga,
  onPlayerUpdatedSaga,
  onConnectionFailedSaga,
  onSocketConnectedSaga,
  onSocketDisconnectedSaga,
  onCloseGameNoBetsSaga,
  onDisplayWinnersSaga,
  onJackpotPlayerPointsSaga,
  onJackpotPointAwardedSaga,
  onJackpotStageStartedSaga,
  onJackpotFinishesSaga,
  onJackpotCreatedSaga,
  onJackpotCollectionFinishedSaga,
  onJackpotCollectionStartedSaga,
  onJackpotPausedSaga,
  onJackpotDetailsUpdatedSaga,
  onJackpotUpdatedSaga,
  onJackpotUnpausedSaga,
  onJackpotStateUpdatedSaga,
  onUserChatBlockedSaga,
  onUserChatUnblockedSaga,
} from './sagas';
import { createChatWebSocketChannel, createWebSocketChannel } from './sagas/createWebSocketChannel';
import { simulateWebSocketChannel } from './sagas/simulateWebSocketChannel';
import {
  OnDealerChangedData,
  OnPlayerConnectedData,
  OnRoundStartedData,
  OnRoundCanceledData,
  OnTableClosedData,
  OnPlayerWonData,
  OnPlayerLostData,
  OnRoundFinishedData,
  OnPlayerUpdatedData,
  OnConnectionFailedData,
  OnPendingBetsMadeData,
  OnGameClosedNoBetsData,
  OnDisplayWinnersData,
  OnPlayerPointsData,
  OnJackpotPointAwardedData,
  OnJackpotStageStartedData,
  OnJackpotFinishedData,
  OnJackpotCreatedData,
  OnJackpotDetailsUpdatedData,
  OnJackpotChangedData,
  OnJackpoStateUpdatedData,
  OnUserChatBlockedData,
  OnUserChatUnblockedData,
  OnChatTokenProvidedData,
  OnNewChatMessageData,
  OnPlayerConnectedChatData,
  OnPlayerErrorData,
} from './types';
import { onDrawFinishedSaga } from './sagas/onDrawFinishedSaga';
import { onResultConfirmedSaga } from './sagas/onResultConfirmedSaga';

import { onBettingTimeStartedSaga } from './sagas/onBettingTimeStartedSaga';
import { onBettingTimeFinishedSaga } from './sagas/onBettingTimeFinishedSaga';
import { onDrawStartedSaga } from './sagas/onDrawStartedSaga';

import { OnDrawFinishedData, OnResultConfirmedData } from '../WinnerBall/types';
import { OnBettingTimeStartedData } from '../BettingTime/types';
import { onPendingBetsMadeSaga } from './sagas/onPendingBetsMadeSaga';
import { getHistorySaga } from '../History/sagas/getHistorySaga';
import { onChatTokenProvidedSaga } from './sagas/chat/onChatTokenProvidedSaga';
import { onNewChatMessageSaga } from './sagas/chat/onNewChatMessageSaga';
import { onPlayerConnectedChatSaga } from './sagas/chat/onPlayerConnectedChatSaga';

// -------listenStage

export function* listenChatPlayerConnectedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createChatWebSocketChannel, [
    NotificationsChat.CHAT_PLAYER_CONNECTED,
  ]);
  try {
    yield takeEvery(notificationChannel, (notification) =>
      onChatPlayerConnectedSagaWrapper(notification),
    );
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onChatPlayerConnectedSagaWrapper(notification: OnPlayerConnectedChatData) {
  yield call(onPlayerConnectedChatSaga, notification);
}
// -------listenStage

export function* listenNewChatMessage() {
  const notificationChannel: EventChannel<any> = yield call(createChatWebSocketChannel, [
    NotificationsChat.NEW_CHAT_MESSAGE,
  ]);

  try {
    yield takeEvery(notificationChannel, onNewChatMessageWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onNewChatMessageWrapper(notification: OnNewChatMessageData) {
  yield call(onNewChatMessageSaga, notification);
}

// -------listenStage

export function* listenChatTokenProvided() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.CHAT_TOKEN_PROVIDED,
  ]);

  try {
    yield takeEvery(notificationChannel, onChatTokenProvidedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onChatTokenProvidedWrapper(notification: OnChatTokenProvidedData) {
  yield call(onChatTokenProvidedSaga, notification);
}

// -------listenStage

export function* listenUserChatUnblocked() {
  const notificationChannel: EventChannel<any> = yield call(createChatWebSocketChannel, [
    NotificationsChat.USER_CHAT_UNBLOCKED,
  ]);

  try {
    yield takeEvery(notificationChannel, onUserChatUnblockedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onUserChatUnblockedWrapper(notification: OnUserChatUnblockedData) {
  yield call(onUserChatUnblockedSaga, notification);
}

// -------listenStage

export function* listenUserChatBlocked() {
  const notificationChannel: EventChannel<any> = yield call(createChatWebSocketChannel, [
    NotificationsChat.USER_CHAT_BLOCKED,
  ]);

  try {
    yield takeEvery(notificationChannel, onUserChatBlockedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onUserChatBlockedWrapper(notification: OnUserChatBlockedData) {
  yield call(onUserChatBlockedSaga, notification);
}

// -------listenStage

export function* listenJackpotStageStarted() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_STAGE_STARTED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotStageStartedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotStageStartedWrapper(notification: OnJackpotStageStartedData) {
  yield call(onJackpotStageStartedSaga, notification);
}

// -------listenStage

export function* listenJackpotFinished() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_FINISHED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotFinishedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotFinishedWrapper(notification: OnJackpotFinishedData) {
  yield call(onJackpotFinishesSaga, notification);
}

// -------onJackpotUpdatedSaga

export function* listenJackpotUpdated() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_UPDATED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotUpdatedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotUpdatedWrapper(notification: OnJackpotCreatedData) {
  yield call(onJackpotUpdatedSaga, notification);
}

// -------onJackpotDetailsUpdatedSaga

export function* listenJackpotDetailsUpdated() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_DETAILS_UPDATED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotDetailsUpdatedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotDetailsUpdatedWrapper(notification: OnJackpotDetailsUpdatedData) {
  yield call(onJackpotDetailsUpdatedSaga, notification);
}

// -------onJackpotPausedSaga

export function* listenJackpotPaused() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_PAUSED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotPausedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotPausedWrapper(notification: OnJackpotChangedData) {
  yield call(onJackpotPausedSaga, notification);
}

// -------onJackpotUnpausedSaga

export function* listenJackpotUnpaused() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_UNPAUSED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotUnpausedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotUnpausedWrapper(notification: OnJackpotChangedData) {
  yield call(onJackpotUnpausedSaga, notification);
}

// -------onJackpotCollectionStartedSaga

export function* listenJackpotCollectionStarted() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_COLLECTION_PERIOD_STARTED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotCollectionStartedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotCollectionStartedWrapper(notification: OnJackpotChangedData) {
  yield call(onJackpotCollectionStartedSaga, notification);
}

// -------onJackpotStateUpdatedSaga

export function* listenJackpotStateUpdated() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_STATE_UPDATED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotStateUpdatedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotStateUpdatedWrapper(notification: OnJackpoStateUpdatedData) {
  yield call(onJackpotStateUpdatedSaga, notification);
}

// -------onJackpotCollectionFinishedSaga

export function* listenJackpotCollectionFinished() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_COLLECTION_PERIOD_FINISHED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotCollectionFinishedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotCollectionFinishedWrapper(notification: OnJackpotChangedData) {
  yield call(onJackpotCollectionFinishedSaga, notification);
}

// -------listenPlayerPoints

export function* listenJackpotPlayerPoints() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_PLAYER_POINTS,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotPlayerPointsWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotPlayerPointsWrapper(notification: OnPlayerPointsData) {
  yield call(onJackpotPlayerPointsSaga, notification);
}

// -------listenPointAwarded

export function* listenJackpotPointAwarded() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_POINT_AWARDED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotPointsAwardedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotPointsAwardedWrapper(notification: OnJackpotPointAwardedData) {
  yield call(onJackpotPointAwardedSaga, notification);
}

// -------listenJackpotProvided

export function* listenJackpotCreated() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_CREATED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotCreatedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotCreatedWrapper(notification: OnJackpotCreatedData) {
  yield call(onJackpotCreatedSaga, notification);
}

export function* listenPlayerUpdatedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.PLAYER_UPDATED,
  ]);

  try {
    yield takeEvery(notificationChannel, onPlayerUpdatedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onPlayerUpdatedSagaWrapper(notification: OnPlayerUpdatedData) {
  yield call(onPlayerUpdatedSaga, notification);
}

export function* listenPlayerWonMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.PLAYER_WON,
  ]);

  try {
    yield takeEvery(notificationChannel, onPlayerWonSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onPlayerWonSagaWrapper(notification: OnPlayerWonData) {
  yield call(onPlayerWonSaga, notification);
}

export function* listenPlayerLostMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.PLAYER_LOST,
  ]);

  try {
    yield takeEvery(notificationChannel, onPlayerLostSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onPlayerLostSagaWrapper(notification: OnPlayerLostData) {
  yield call(onPlayerLostSaga, notification);
}

export function* listenPlayerErrorMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.PLAYER_ERROR,
  ]);

  try {
    yield takeEvery(notificationChannel, onPlayerErrorSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onPlayerErrorSagaWrapper(notification: OnPlayerErrorData) {
  yield call(onPlayerErrorSaga, notification);
}

export function* listenDrawFinishedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.DRAW_FINISHED,
  ]);

  try {
    yield takeEvery(notificationChannel, onDrawFinishedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenResultConfirmedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.RESULT_CONFIRMED,
  ]);

  try {
    yield takeEvery(notificationChannel, onResultConfirmedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenBettingTimeStartedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.BETTING_TIME_STARTED,
  ]);

  try {
    yield takeEvery(notificationChannel, onBettingTimeStartedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenBettingTimeFinishedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.BETTING_TIME_FINISHED,
  ]);

  try {
    yield takeEvery(notificationChannel, onBettingTimeFinishedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenRoundStartedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.ROUND_STARTED,
  ]);

  try {
    yield takeEvery(notificationChannel, onRoundStartedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onRoundStartedSagaWrapper(notification: OnRoundStartedData) {
  yield call(onRoundStartedSaga, notification);
}

export function* listenPlayerConnectedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.PLAYER_CONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, (notification) =>
      onPlayerConnectedSagaWrapper(notification),
    );
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onPlayerConnectedSagaWrapper(notification: OnPlayerConnectedData) {
  yield call(onPlayerConnectedSaga, notification);
}

export function* listenDealerChangedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.DEALER_CHANGED,
  ]);

  try {
    yield takeEvery(notificationChannel, onDealerChangedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenDrawStartedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.DRAW_STARTED,
  ]);

  try {
    yield takeEvery(notificationChannel, onDrawStartedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenRoundCanceledMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.ROUND_CANCELED,
  ]);

  try {
    yield takeEvery(notificationChannel, onRoundCanceledSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenTableClosedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.TABLE_CLOSED,
  ]);

  try {
    yield takeEvery(notificationChannel, onTableClosedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenCloseTableNotificationMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.NOTIFICATION,
  ]);

  try {
    yield takeEvery(notificationChannel, onCloseTableNotificationSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenCloseGameNoBetsNotificationMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.PLAYER_AFK,
  ]);

  try {
    yield takeEvery(notificationChannel, onCloseGameNoBetsNotificationSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenRoundFinishedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.ROUND_FINISHED,
  ]);

  try {
    yield takeEvery(notificationChannel, onRoundFinishedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenPendingBetsMadeMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.PENDING_BETS_MADE,
  ]);

  try {
    yield takeEvery(notificationChannel, onPendingBetsMadeSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenDisplayWinnersMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.DISPLAY_WINNERS,
  ]);

  try {
    yield takeEvery(notificationChannel, onDisplayWinnersSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenConnectionFailedMessage() {
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.PLAYER_CONNECTION_FAILED,
  ]);

  try {
    yield takeEvery(notificationChannel, onConnectionFailedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenSocketСonnectedMessage() {
  const notificationChannel: EventChannel<any> = yield call(simulateWebSocketChannel, [
    ClientSideNotifications.SOCKET_CONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, onSocketConnectedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

export function* listenSocketDisconnectedMessage() {
  const notificationChannel: EventChannel<any> = yield call(simulateWebSocketChannel, [
    ClientSideNotifications.SOCKET_DISCONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, onSocketDisconnectedSagaWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onDealerChangedSagaWrapper(notification: OnDealerChangedData) {
  yield call(onDealerChangedSaga, notification);
}

function* onDrawFinishedSagaWrapper(notification: OnDrawFinishedData) {
  yield call(onDrawFinishedSaga, notification);
}

function* onResultConfirmedSagaWrapper(notification: OnResultConfirmedData) {
  yield call(onResultConfirmedSaga, notification);
}

function* onBettingTimeStartedSagaWrapper(notification: OnBettingTimeStartedData) {
  yield call(onBettingTimeStartedSaga, notification);
}

function* onBettingTimeFinishedSagaWrapper(notification: OnDrawFinishedData) {
  yield call(onBettingTimeFinishedSaga, notification);
}

function* onDrawStartedSagaWrapper(notification: OnDrawFinishedData) {
  yield call(onDrawStartedSaga, notification);
}

function* onRoundCanceledSagaWrapper(notification: OnRoundCanceledData) {
  yield call(onRoundCanceledSaga, notification);
}

function* onTableClosedSagaWrapper(notification: OnTableClosedData) {
  yield call(onTableClosedSaga, notification);
}

function* onCloseTableNotificationSagaWrapper(notification: OnTableClosedData) {
  yield call(onCloseTableNotificationSaga, notification);
}

function* onCloseGameNoBetsNotificationSagaWrapper(notification: OnGameClosedNoBetsData) {
  yield call(onCloseGameNoBetsSaga, notification);
}

function* onRoundFinishedSagaWrapper(notification: OnRoundFinishedData) {
  yield call(onRoundFinishedSaga, notification);
  yield call(getHistorySaga);
}

function* onPendingBetsMadeSagaWrapper(notification: OnPendingBetsMadeData) {
  yield call(onPendingBetsMadeSaga, notification);
}

function* onConnectionFailedSagaWrapper(notification: OnConnectionFailedData) {
  yield call(onConnectionFailedSaga, notification);
}

function* onSocketConnectedSagaWrapper(notification: string) {
  yield call(onSocketConnectedSaga, notification);
}

function* onSocketDisconnectedSagaWrapper(notification: string) {
  yield call(onSocketDisconnectedSaga, notification);
}

function* onDisplayWinnersSagaWrapper(notification: OnDisplayWinnersData) {
  yield call(onDisplayWinnersSaga, notification);
}
