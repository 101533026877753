import { ActionType, createReducer } from 'typesafe-actions';
import { setBalanceAction, setTotalBetAction, setBalanceInBettingTimeAction } from './actions';
import { IUserState } from './types';

export const userState: IUserState = {
  balance: null,
  balanceInBettingTime: null,
  totalBet: null,
};

const actions = {
  setBalanceAction,
  setBalanceInBettingTimeAction,
  setTotalBetAction,
};

export const userReducer = createReducer<IUserState, ActionType<typeof actions>>(userState)
  .handleAction(setBalanceAction, (state, { payload }) => ({
    ...state,
    balance: payload,
  }))
  .handleAction(setBalanceInBettingTimeAction, (state, { payload }) => ({
    ...state,
    balanceInBettingTime: payload,
  }))
  .handleAction(setTotalBetAction, (state, { payload }) => ({
    ...state,
    totalBet: payload,
  }));
