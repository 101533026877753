import styled, { css } from 'styled-components';
import { Device } from 'types';

export const TopPanelStyled = styled.div<{ $deviceType: Device }>`
  position: relative;
  max-width: 100%;
  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait)
      return css`
        height: 58rem;
        margin-top: 6rem;
      `;
    if ($deviceType === Device.Mobile)
      return css`
        height: 32rem;
      `;
  }}
`;
