import { init, pathOr } from 'ramda';
import { nth } from 'ramda';
import 'core-js/features/array/at';
import { WinnerBall } from 'constants/common';
import { IRoadItem, IRoadItems } from 'widgets/Scoreboard/types';
import { IColumn, IColumnState, IMatrix } from '../types';

const retrieveOutcome = pathOr(undefined, ['outcome']);

const compareItems = (
  firstItem: IRoadItem | undefined,
  secondItem: IRoadItem | undefined,
): boolean => retrieveOutcome(firstItem) === retrieveOutcome(secondItem);

export const countConsecutiveTies = (roadData: IRoadItem[]) => {
  const lastChildItem = nth(-1, roadData);

  const confirmedLastChildItem =
    lastChildItem && lastChildItem.isPrediction ? nth(-2, roadData) : lastChildItem;

  return confirmedLastChildItem && confirmedLastChildItem.consecutiveTiesQty
    ? confirmedLastChildItem.consecutiveTiesQty + 1
    : 1;
};

const getItemIndex = (columnIndex: number, rowCount: number, offset = 0): number =>
  columnIndex * rowCount + offset;

const getRightCellIndex = (currentItem: number, offset: number, rowCount: number): number =>
  currentItem + offset + rowCount;

const clearItem = (roadData: IRoadItems, index: number): IRoadItems => {
  const updateRoadData = [...roadData];
  updateRoadData[index] = undefined;
  return updateRoadData;
};

const compareColumns = (firstRow: IColumn, secondRow: IColumn): boolean =>
  firstRow.length === secondRow.length;

export const addTieAfterPrediction = (matrix: IMatrix, lastColumn: IColumn): IMatrix => {
  const lastColumnWithoutPrediction: IColumn = init(lastColumn);

  if (lastColumnWithoutPrediction && lastColumnWithoutPrediction.length) {
    const lastItem: IRoadItem | undefined = nth(-1, lastColumnWithoutPrediction);

    if (lastItem) {
      return [
        ...init(matrix),
        [
          ...init(lastColumnWithoutPrediction),
          { ...lastItem, isTie: true, consecutiveTiesQty: countConsecutiveTies(lastColumn) },
        ],
      ];
    }
  }

  const matrixWithoutEmptyColumn: IMatrix = init(matrix);
  if (!matrixWithoutEmptyColumn.length) {
    return [
      [{ outcome: WinnerBall.GOLD, isTie: true, isPrediction: false, consecutiveTiesQty: 1 }],
    ];
  }

  const newLastColumn: IRoadItem[] = nth(-1, matrixWithoutEmptyColumn) || [];
  const newLastItem: IRoadItem | undefined = nth(-1, newLastColumn);

  return newLastItem
    ? [
        ...init(matrixWithoutEmptyColumn),
        [
          ...init(newLastColumn),
          { ...newLastItem, isTie: true, consecutiveTiesQty: countConsecutiveTies(newLastColumn) },
        ],
      ]
    : matrix;
};

export const addNewItemAfterPrediction = (
  newItem: IRoadItem,
  isColumnChanged: boolean,
  road: IMatrix,
  lastColumn: IColumn,
) => {
  const lastColumnWithoutPrediction = init(lastColumn);

  if (isColumnChanged) {
    if (lastColumnWithoutPrediction && lastColumnWithoutPrediction.length) {
      return [...init(road), [...lastColumnWithoutPrediction], [newItem]];
    }

    const roadWithoutLastColumn = init(road);
    const newLast = nth(-1, roadWithoutLastColumn) || [];

    return [...init(roadWithoutLastColumn), [...newLast, newItem]];
  }

  return [...init(road), [...init(lastColumn), newItem]];
};

const checkRightCell = (
  roadData: IRoadItems,
  columnStartIndex: number,
  rowCount: number,
  offset: number,
): boolean => Boolean(roadData[columnStartIndex + rowCount + offset]);

export const removeLastColumn = (
  currentRoadData: IRoadItems,
  columnStart: number,
  lastColumn: IColumn,
  rowCount: number,
  isTie: boolean = false,
): IColumnState => {
  const columnStartIndex = getItemIndex(columnStart, rowCount);
  const cleanedLastColumn = init(lastColumn);
  const columnToUse = isTie ? lastColumn : cleanedLastColumn;

  return columnToUse.reduce(
    (
      { accumulator, isTailDetected, tailLength, tailIndex }: IColumnState,
      _,
      index: number,
    ): IColumnState => {
      if (isTailDetected) {
        return {
          accumulator: clearItem(accumulator, columnStartIndex + tailIndex + rowCount * tailLength),
          isTailDetected,
          tailLength: tailLength + 1,
          tailIndex,
        };
      }

      if (checkRightCell(accumulator, columnStartIndex, rowCount, index)) {
        return {
          accumulator: clearItem(accumulator, columnStartIndex + index),
          isTailDetected: true,
          tailLength: 1,
          tailIndex: index,
        };
      }

      return {
        accumulator: clearItem(accumulator, columnStartIndex + index),
        isTailDetected,
        tailIndex,
        tailLength,
      };
    },
    { accumulator: [...currentRoadData], isTailDetected: false, tailLength: 0, tailIndex: 5 },
  );
};

const getTailStartIndex = (columnIndex: number, rowCount: number, roadData: IRoadItems): number => {
  const startCurrentColumn = getItemIndex(columnIndex, rowCount);
  const currentColumn = roadData.slice(startCurrentColumn, startCurrentColumn + rowCount);

  const nearestTailIndex = currentColumn.findIndex((item) => item !== undefined);

  if (nearestTailIndex > -1) {
    const previousRowIndex = nearestTailIndex - 1;
    return getRightCellIndex(startCurrentColumn, previousRowIndex, rowCount);
  }

  const lastRowIndex = rowCount - 1;

  return getRightCellIndex(startCurrentColumn, lastRowIndex, rowCount);
};

const fillVerticalPart = (
  roadData: IRoadItems,
  columnData: IRoadItem[],
  columnIndex: number,
  rowCount: number,
): IRoadItems =>
  columnData.reduce(
    (accumulator: IRoadItems, item: IRoadItem, index: number) => {
      const currentItemIndex: number = getItemIndex(columnIndex, rowCount, index);
      accumulator[currentItemIndex] = { ...item };

      return accumulator;
    },
    [...roadData],
  );

const fillHorizontalPart = (
  roadData: IRoadItems,
  tailData: IRoadItem[],
  columnToStartTail: number,
  rowCount: number,
  tailRow: number,
): IRoadItems =>
  tailData.reduce(
    (accumulator: IRoadItems, item: IRoadItem, idx: number) => {
      const offset = tailRow + idx * rowCount;
      const currentItemIndex = getItemIndex(columnToStartTail, rowCount, offset);
      accumulator[currentItemIndex] = { ...item };

      return accumulator;
    },
    [...roadData],
  );

export const addColumnToRoad = (
  rowCount: number,
  accumulator: IRoadItems,
  column: IRoadItem[],
  columnIndex: number,
): IRoadItems => {
  const tailStartIndex = getTailStartIndex(columnIndex, rowCount, accumulator);

  const tailRow = tailStartIndex % rowCount;

  const columnToStartTail = columnIndex + 1;

  const verticalPartEnd = tailRow + 1;
  const verticalPart = column.slice(0, verticalPartEnd);
  const horizontalPart = column.slice(verticalPartEnd);

  const roadWithNewColumn = fillVerticalPart(accumulator, verticalPart, columnIndex, rowCount);
  const roadWithNewTail = fillHorizontalPart(
    roadWithNewColumn,
    horizontalPart,
    columnToStartTail,
    rowCount,
    tailRow,
  );

  return roadWithNewTail;
};

const addItem = (winner: WinnerBall, road: IMatrix, isPrediction?: boolean): IMatrix => {
  const newItem: IRoadItem = { outcome: winner, isTie: false, isPrediction };
  const lastColumn = nth(-1, road) || [];
  const lastItem: IRoadItem | undefined = nth(-1, lastColumn);
  const isPreviousPrediction = lastItem ? lastItem.isPrediction : false;
  const outcome: WinnerBall | undefined = retrieveOutcome(lastItem);
  const isOutcomeChanged = outcome !== winner;

  if (isPreviousPrediction) {
    return addNewItemAfterPrediction(newItem, isOutcomeChanged, road, lastColumn);
  }

  if (outcome === winner) {
    return [...init(road), [...lastColumn, newItem]];
  }

  return [...road, [newItem]];
};

const getPredictabilityItem = (isPredictable: boolean): WinnerBall =>
  isPredictable ? WinnerBall.RED : WinnerBall.BLUE;

export const addItemToAnalyticsRoad = (
  accumulator: IMatrix,
  bigRoadMatrix: IMatrix,
  columnNumber: number,
  rowNumber: number,
  offset: number,
  isPrediction?: boolean,
): IMatrix => {
  const road = [...accumulator];

  if (rowNumber === 0) {
    const columnsEqual = compareColumns(
      bigRoadMatrix[columnNumber - 1],
      bigRoadMatrix[columnNumber - offset],
    );

    const predictabilityItem: WinnerBall = getPredictabilityItem(columnsEqual);
    return addItem(predictabilityItem, road, isPrediction);
  }
  const columnToTest = bigRoadMatrix[columnNumber - offset + 1];
  const firstItem = columnToTest[rowNumber];
  const secondItem = columnToTest[rowNumber - 1];

  const itemsEqual = compareItems(firstItem, secondItem);

  const predictabilityOutcome: WinnerBall = getPredictabilityItem(itemsEqual);

  return addItem(predictabilityOutcome, road, isPrediction);
};
