import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { IScoreboardButtonProps } from './types';
import { ScoreboardButtonLandscape } from './ScoreboardToggleButton.landscape';
import { ScoreboardButtonPortrait } from './ScoreboardToggleButton.portrait';

export const ScoreboardButtonResponsive = (props: IScoreboardButtonProps) => (
  <>
    <DesktopComponent>
      <ScoreboardButtonLandscape {...props} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <ScoreboardButtonLandscape {...props} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <ScoreboardButtonPortrait {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <ScoreboardButtonPortrait {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
