import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IHistory } from './types';

const historyStoreSelector = (state: IState) => state.historyReducer;

export const historySelector = createSelector(
  historyStoreSelector,
  (state: IHistory) => state.history,
);
