import styled, { css } from 'styled-components';
import { Icon } from 'components';
import { Device } from 'types';

export const ScoreboardButtonLandscapeStyled = styled.svg<{ $isActive: boolean }>`
  position: absolute;
  top: 15rem;
  right: 66rem;
  height: 52rem;
  width: 136rem;

  & g {
    cursor: pointer;

    &:hover {
      & #scoreboard_path_background {
        fill: url(#paint0_linear_1904_29754);
        stroke: url(#paint1_linear_1904_6290);
      }
    }

    ${({ $isActive }) => {
      if ($isActive) {
        return css`
          & #scoreboard_path_background {
            fill: url(#paint0_linear_1904_6290);
            stroke: url(#paint1_linear_1904_6290);
          }
        `;
      }

      return css`
        & #scoreboard_path_background {
          fill: url(#paint0_linear_1904_6269);
          stroke: url(#paint1_linear_1904_6269);
        }
      `;
    }}
  }
`;

export const Text = styled.span`
  display: inline-block;
  text-transform: uppercase;
  line-height: 14rem;
  padding-top: 1rem;
`;

export const ArrowStyled = styled(Icon)<{ $isActive: boolean }>`
  ${({ $isActive }) => $isActive && 'transform: rotate(180deg);'}
`;

export const ScoreboardPortraitWrapper = styled.div<{ $deviceType: Device }>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    border: 1rem solid;
    border-image-source: linear-gradient(
      270deg,
      rgba(241, 230, 210, 0) 0%,
      #434343 50%,
      rgba(241, 230, 210, 0) 100%
    );
    border-image-slice: 1;
    pointer-events: none;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        height: 28rem;

        ${Text} {
          font-size: 12rem;
        }

        ${ArrowStyled} {
          width: 16rem;
          height: 16rem;
          margin-left: 8rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        height: 20rem;

        ${Text} {
          font-size: 8rem;
        }

        ${ArrowStyled} {
          width: 12rem;
          height: 12rem;
          margin-left: 4rem;
        }
      `;
    }
  }}
`;
