import React from 'react';
import { WinnerBall } from 'constants/common';
import { BlueBall, RedBall, TieBall } from 'components/Icon/Icons';
import { IBaseSvgParams } from 'types';

export interface IBallsCounterRowProps extends IBaseSvgParams {
  ballType: WinnerBall;
  quantity: number;
}

export const ballsIconMap: Record<WinnerBall, React.ReactNode> = {
  [WinnerBall.BLUE]: <BlueBall />,
  [WinnerBall.RED]: <RedBall />,
  [WinnerBall.GOLD]: <TieBall />,
};
