import React from 'react';
import { MobileComponent, TabletPortraitComponent } from 'components';
import { IMobileButtonProps } from '../types';
import { RightSideTabletButton } from './RightSideTabletButton';
import { RightSideMobileButton } from './RightSideMobileButton';

export const RightSideButton = (props: IMobileButtonProps) => (
  <>
    <TabletPortraitComponent>
      <RightSideTabletButton {...props} />
    </TabletPortraitComponent>
    <MobileComponent>
      <RightSideMobileButton {...props} />
    </MobileComponent>
  </>
);
