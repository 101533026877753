import React from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BallsCounterLandscape } from './BallsCounter.landscape';
import { BallsCounterMobile } from './BallsCounter.mobile';
import { BallsCounterTablet } from './BallsCounter.tablet';

export const BallsCounter = () => (
  <>
    <DesktopComponent>
      <BallsCounterLandscape />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BallsCounterLandscape />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BallsCounterTablet />
    </TabletPortraitComponent>
    <MobileComponent>
      <BallsCounterMobile />
    </MobileComponent>
  </>
);
