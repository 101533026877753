import styled, { css } from 'styled-components';
import { Device } from 'types';

const winTextStyles = css`
  fill: #e7d5b9;
  overflow: visible;
`;

export const WinInfoText = styled.text`
  ${winTextStyles}
`;

export const WinTitle = styled.text`
  fill: #e7d5b9;
  text-transform: uppercase;
  letter-spacing: 3px;
`;

export const WinInfoLabel = styled.tspan`
  fill: ${({ theme: { text } }) => text.primary};
  fill-opacity: 0.6;
  text-transform: uppercase;
`;

export const WinInfoValue = styled.tspan`
  fill: ${({ theme: { text } }) => text.primary};
  text-transform: uppercase;
`;

export const WinAmount = styled.text`
  ${winTextStyles};
  letter-spacing: 3px;
`;

export const Wrapper = styled.div<{ $deviceType?: Device }>`
  position: absolute;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 112rem;
        height: 268rem;
        width: 464rem;
        z-index: 3;

        ${WinTitle} {
          font-size: 9rem;
          line-height: 14rem;
        }

        ${WinInfoText} {
          font-size: 12rem;
          line-height: 14rem;
        }

        ${WinAmount} {
          font-size: 40rem;
          line-height: 60rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 48rem;
        height: 144rem;
        width: 344rem;
        z-index: 3;

        @media only screen and (max-width: 430px) {
          width: 270rem;
        }

        ${WinTitle} {
          font-size: 6rem;
          line-height: 12rem;
        }

        ${WinInfoText} {
          font-size: 8rem;
          line-height: 14rem;
        }

        ${WinAmount} {
          font-size: 24rem;
          line-height: 40rem;
          letter-spacing: 3rem;
        }
      `;
    }

    return css`
      bottom: 336rem;
      height: 268rem;
      width: 464rem;
      border-radius: 50%;

      ${WinTitle} {
        font-size: 9px;
        line-height: 14px;
      }

      ${WinInfoText} {
        font-size: 12px;
        line-height: 14px;
      }

      ${WinAmount} {
        font-size: 40px;
        line-height: 60px;
      }
    `;
  }}
`;
