import { eventChannel, END, EventChannel } from 'redux-saga';
import { webSocketService } from 'services/WebSocketService';
import { ClientSideNotifications } from 'types';

export function simulateWebSocketChannel(args: ClientSideNotifications[]): EventChannel<any> {
  return eventChannel((emitter) => {
    const removeListener = webSocketService.onClientMessage((notification) => {
      emitter(notification);
    }, args[0]);

    return () => {
      removeListener();
      emitter(END);
    };
  });
}
