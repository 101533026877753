import { Icon } from 'components';
import styled from 'styled-components';
import { isInIframe } from 'hooks/getDefineDevEnv';

export const MainSettingsItemStyled = styled.div<{ $isOldBrowser: boolean }>`
  padding: ${({ $isOldBrowser }) =>
    $isOldBrowser && !isInIframe() ? '24rem 24rem' : '32rem 24rem'};
  border-top: 1px solid;
  border-image-slice: 1 0 0 0;
  border-image-source: linear-gradient(270deg, #121011 0%, #434343 52.08%, #121011 100%);
  font-size: 12rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

export const SettingsItemText = styled.div`
  margin-left: 12rem;
`;

export const IconStyled = styled(Icon)`
  width: 16rem;
  height: 16rem;
`;
