import React, { memo, useCallback, useEffect } from 'react';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'core-js/features/array/at';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme, GlobalStyles } from 'constants/theme';
import { connectToWebSocketAction } from 'core/widgets/WebSocket/actions';
import { useDevice, PlatformType } from 'hooks';
import { initSoundAction } from 'core/widgets/SoundSettings/action';
import { useTranslation } from 'react-i18next';
import eruda from 'eruda';
import { getDefineDevEnv, isInIframe, isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import { LANGUAGES } from 'i18n';
import AudioService from 'services/AudioService';
import {
  redSounds,
  blueSounds,
  goldSounds,
  congratulationsSounds,
} from 'widgets/DrawnBall/soundSettings';
import { timeSounds, noBetsSounds } from 'widgets/Timer/soundSettings';
import audioSource5 from 'assets/sounds/soundFX/timer.wav';
import audioSource from 'assets/sounds/soundFX/02_Choose_Bet.wav';
import audioSourceSlider from 'assets/sounds/soundFX/03_Bet_Slider.wav';
import audioSourceAlert from 'assets/sounds/soundFX/13_Message_Alert.wav';
import audioSourceWin from 'assets/sounds/soundFX/10_Player_WINS.wav';
import audioSourceBallWasDrown from 'assets/sounds/soundFX/07_Ball_Drawn.wav';
import audioSourceError from 'assets/sounds/soundFX/05_Error.wav';
import { placeBetsSounds, noMoreBetsSounds } from 'widgets/Toast/soundSettings';
import audioSourceConfirm from 'assets/sounds/soundFX/01_Interface_Click.wav';
import audioSourceCancel from 'assets/sounds/soundFX/04b_Cancel_Bet.wav';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { tableIdSelector } from 'core/widgets/TableSettings';

export const App = memo(() => {
  const dispatch = useDispatch();
  const { rootDeviceComponent, platformType } = useDevice();
  const { i18n } = useTranslation();

  const { soundEffects, digitalVoice, gameSoundMute, isStartLoadingSounds } = useSelector(
    soundSettingsSelector,
    shallowEqual,
  );
  const tableId = useSelector(tableIdSelector);
  const { operatorId } = useSelector(authInfoSelector, shallowEqual);

  const brand = new URLSearchParams(window.location.search).get('brand');

  useEffect(() => {
    const authData = {
      sessionId: window.location.pathname.replace('/', ''),
    };

    dispatch(connectToWebSocketAction({ authData, platformType }));
  }, [dispatch, platformType, brand]);

  useEffect(() => {
    // @ts-ignore
    if (getDefineDevEnv() === 'dev') {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/eruda';
      document.body.appendChild(script);
      script.onload = () => {
        eruda.init();
      };
    }
  }, []);

  useEffect(() => {
    const music = localStorage.getItem(`${operatorId}_${tableId}_music`)
      ? JSON.parse(localStorage.getItem(`${operatorId}_${tableId}_music`) as string)
      : null;
    const soundEffects = localStorage.getItem(`${operatorId}_${tableId}_soundEffects`)
      ? JSON.parse(localStorage.getItem(`${operatorId}_${tableId}_soundEffects`) as string)
      : null;
    const digitalVoice = localStorage.getItem(`${operatorId}_${tableId}_digitalVoice`)
      ? JSON.parse(localStorage.getItem(`${operatorId}_${tableId}_digitalVoice`) as string)
      : null;
    const studioSound = localStorage.getItem(`${operatorId}_${tableId}_studioSound`)
      ? JSON.parse(localStorage.getItem(`${operatorId}_${tableId}_studioSound`) as string)
      : null;
    const language = localStorage.getItem(`${operatorId}_${tableId}_language`)
      ? JSON.parse(localStorage.getItem(`${operatorId}_${tableId}_language`) as string)
      : null;
    if (music && operatorId && tableId) {
      dispatch(initSoundAction({ music, soundEffects, digitalVoice, studioSound }));
      AudioService.initSoundSettings({ soundEffects, digitalVoice, music });
    }

    if (language && operatorId && tableId) {
      i18n?.changeLanguage(language);
    }
  }, [dispatch, i18n, operatorId, tableId]);

  const initializeAudio = useCallback(async () => {
    await AudioService.init();
    await AudioService.loadSound(redSounds[i18n.language as LANGUAGES], 'red');
    await AudioService.loadSound(blueSounds[i18n.language as LANGUAGES], 'blue');
    await AudioService.loadSound(goldSounds[i18n.language as LANGUAGES], 'gold');
    await AudioService.loadSound(
      congratulationsSounds[i18n.language as LANGUAGES],
      'congratulations',
    );

    await AudioService.loadSound(timeSounds[i18n.language as LANGUAGES], 'timeEnd');
    await AudioService.loadSound(noBetsSounds[i18n.language as LANGUAGES], 'noBets');
    await AudioService.loadSound(audioSource5, 'timer');
    await AudioService.loadSound(audioSource, 'click');
    await AudioService.loadSound(audioSourceSlider, 'slider');
    await AudioService.loadSound(audioSourceAlert, 'alert');
    await AudioService.loadSound(audioSourceWin, 'win');
    await AudioService.loadSound(audioSourceBallWasDrown, 'ballWasDrawn');
    await AudioService.loadSound(audioSourceError, 'error');
    await AudioService.loadSound(audioSourceConfirm, 'confirm');
    await AudioService.loadSound(audioSourceCancel, 'cancel');
    await AudioService.loadSound(noMoreBetsSounds[i18n.language as LANGUAGES], 'noMoreBets');
    await AudioService.loadSound(placeBetsSounds[i18n.language as LANGUAGES], 'placeBets');
  }, [i18n.language]);

  const initializeAudioLanguage = useCallback(async () => {
    if (AudioService.isInit()) {
      await AudioService.loadSound(redSounds[i18n.language as LANGUAGES], 'red');
      await AudioService.loadSound(blueSounds[i18n.language as LANGUAGES], 'blue');
      await AudioService.loadSound(goldSounds[i18n.language as LANGUAGES], 'gold');
      await AudioService.loadSound(
        congratulationsSounds[i18n.language as LANGUAGES],
        'congratulations',
      );

      await AudioService.loadSound(timeSounds[i18n.language as LANGUAGES], 'timeEnd');
      await AudioService.loadSound(noBetsSounds[i18n.language as LANGUAGES], 'noBets');
      await AudioService.loadSound(noMoreBetsSounds[i18n.language as LANGUAGES], 'noMoreBets');
      await AudioService.loadSound(placeBetsSounds[i18n.language as LANGUAGES], 'placeBets');
    }
  }, [i18n.language]);

  useEffect(() => {
    initializeAudioLanguage();
  }, [initializeAudioLanguage]);

  useEffect(() => {
    if (isStartLoadingSounds) {
      window.addEventListener('click', initializeAudio, { once: true });
      window.addEventListener('touchstart', initializeAudio, { once: true });
    }

    return () => {
      window.removeEventListener('click', initializeAudio);
      window.removeEventListener('touchstart', initializeAudio);
    };
  }, [initializeAudio, isStartLoadingSounds]);

  useEffect(() => {
    if (digitalVoice.mute || gameSoundMute) {
      AudioService.removeFromQueueAndStopSound('red');
      AudioService.removeFromQueueAndStopSound('blue');
      AudioService.removeFromQueueAndStopSound('gold');
      AudioService.removeFromQueueAndStopSound('timeEnd');
      AudioService.removeFromQueueAndStopSound('noBets');
      AudioService.removeFromQueueAndStopSound('noMoreBets');
      AudioService.removeFromQueueAndStopSound('placeBets');
    }
    if (soundEffects.mute || gameSoundMute) {
      AudioService.removeFromQueueAndStopSound('timer');
      AudioService.removeFromQueueAndStopSound('click');
      AudioService.removeFromQueueAndStopSound('slider');
      AudioService.removeFromQueueAndStopSound('alert');
      AudioService.removeFromQueueAndStopSound('win');
      AudioService.removeFromQueueAndStopSound('error');
      AudioService.removeFromQueueAndStopSound('confirm');
      AudioService.removeFromQueueAndStopSound('cancel');
    }
  }, [soundEffects, digitalVoice, gameSoundMute]);

  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles />
      <Wrapper
        className={i18n.language}
        $isDesktop={platformType === PlatformType.Desktop || platformType === PlatformType.Other}
        $isOldBrowser={isOldBrowserIOS()}>
        {rootDeviceComponent}
      </Wrapper>
    </ThemeProvider>
  );
});

const Wrapper = styled.div<{ $isDesktop: boolean; $isOldBrowser: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $isDesktop, $isOldBrowser }) =>
    $isDesktop ? '100vh' : $isOldBrowser ? (isInIframe() ? 'calc(1000vh)' : '100vh') : '100dvh'};
  width: 100vw;
  background-color: #121011;
`;
