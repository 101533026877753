import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roundStateSelector } from 'core/widgets/RoundStates';
import {
  bettingTimeEndSelector,
  bettingTimeSecondsSelector,
  timeDifferenceUserServerSelector,
  timeLeftSelector,
} from 'core/widgets/BettingTime';
import { RoundStates } from 'core/widgets/RoundStates/types';
import getTimeLeft from 'utils/getTimeLeft';
import { summaryBetSelector } from 'core/widgets/Bet';
import { SLOTS } from 'types';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { TimerResponsive } from './Responsive';
import {
  setIsTheLast2SecondsInBettingTimeToStoreAction,
  setTimeLeftToStoreAction,
  startDecrementTimeLeftAction,
  stopDecrementTimeLeftAction,
} from 'core/widgets/BettingTime/actions';
import AudioService from 'services/AudioService';
import { Sounds } from 'core/widgets/SoundSettings/types';

export const Timer = () => {
  const FULL_DASH_ARRAY = 224;
  const dispatch = useDispatch();

  const { jackpotOn } = useSelector(authInfoSelector);
  const roundState = useSelector(roundStateSelector);
  const bettingTimeEnd = useSelector(bettingTimeEndSelector);
  const timeDifference = useSelector(timeDifferenceUserServerSelector);
  const timeLeft = useSelector(timeLeftSelector);
  const slotsData = useSelector(summaryBetSelector);
  const { soundEffects, digitalVoice, gameSoundMute } = useSelector(soundSettingsSelector);

  const bettingTime = useSelector(bettingTimeSecondsSelector);
  const [timerSoundPlayed, setTimerSoundPlayed] = useState<boolean>(false);

  const [circleDasharray, setCircleDasharray] = useState(`${FULL_DASH_ARRAY}`);
  const [startedBettingTimer, setStartedBettingTimer] = useState<boolean>(false);
  const [timeLeftOnUserReturn, setTimeLeftOnUserReturn] = useState<number | null>(null);

  const calculateTimeFraction = useCallback(() => {
    const rawTimeFraction = timeLeft / bettingTime;
    return rawTimeFraction - (1 / (bettingTime + 1)) * (1 - rawTimeFraction);
  }, [timeLeft, bettingTime]);

  const hasBets = Object.keys(slotsData).find((key: string) => {
    if ((slotsData[key as SLOTS] as number) > 0) {
      return key;
    }
    return undefined;
  }) as SLOTS;

  const goAudio = useCallback(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playInBackground('timer');
    }
    if (gameSoundMute || soundEffects.mute) {
      AudioService.stopBackgroundSound('timer');
    }
  }, [gameSoundMute, soundEffects]);

  const goAudioVoice = useCallback(() => {
    AudioService.enqueue('timeEnd', Sounds.DIGITAL_VOICE, true);
    if (!hasBets) {
      AudioService.enqueue('noBets', Sounds.DIGITAL_VOICE, true);
    }
  }, [hasBets]);

  useEffect(() => {
    if (startedBettingTimer) {
      if (timeLeft > 0) {
        setCircleDasharray(`${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(1)} 226`);
      }
      if (timeLeft <= 0) {
        setStartedBettingTimer(false);
        setTimeLeftOnUserReturn(null);
      }

      if (timeLeft === 2 && roundState === RoundStates.BettingTimeStarted) {
        dispatch(setIsTheLast2SecondsInBettingTimeToStoreAction(true));
      }

      if (timeLeft <= 5) {
        if (!timerSoundPlayed) {
          setTimerSoundPlayed(true);
          goAudio();
        }
      }
      if (timeLeft === 7) {
        goAudioVoice();
      }
    }
  }, [
    startedBettingTimer,
    timeLeft,
    calculateTimeFraction,
    goAudio,
    goAudioVoice,
    dispatch,
    timerSoundPlayed,
    setTimerSoundPlayed,
    roundState,
  ]);

  useEffect(() => {
    return () => {
      dispatch(stopDecrementTimeLeftAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!startedBettingTimer) {
      dispatch(stopDecrementTimeLeftAction());
    }
  }, [startedBettingTimer, dispatch]);

  useEffect(() => {
    if (roundState === RoundStates.BettingTimeStarted) {
      dispatch(startDecrementTimeLeftAction());
      setStartedBettingTimer(true);
      setTimerSoundPlayed(false);
    }

    if (roundState === RoundStates.BettingTimeFinished) {
      AudioService.stopBackgroundSound('timer');
      setTimerSoundPlayed(true);

      // AudioService.removeFromQueueAndStopSound('timer');
    }
  }, [roundState, dispatch]);

  useEffect(() => {
    if (!startedBettingTimer) {
      return;
    }

    const onVisibilityChange = () => {
      if (document.hidden) {
        dispatch(stopDecrementTimeLeftAction());
      } else {
        setTimeLeftOnUserReturn(getTimeLeft(bettingTimeEnd, timeDifference));
        dispatch(setTimeLeftToStoreAction(getTimeLeft(bettingTimeEnd, timeDifference)));
        dispatch(startDecrementTimeLeftAction());
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [startedBettingTimer, dispatch, bettingTimeEnd, timeDifference]);

  return (
    <>
      {roundState === RoundStates.BettingTimeStarted ? (
        <TimerResponsive
          timeLeft={timeLeft}
          bettingTime={bettingTime}
          timeLeftOnUserReturn={timeLeftOnUserReturn}
          circleDasharray={circleDasharray}
          hasJP={!jackpotOn}
        />
      ) : null}
    </>
  );
};
